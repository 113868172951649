import PropTypes from 'prop-types';
import { debounce, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import {
    AutoComplete,
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    notification,
    Row,
    Spin,
    Upload,
} from 'antd';
import { BASIC_REQUIRED_VALIDATION_RULES, calculateAvgMargin } from '../../../../../../../../static/utils';
import { getOrganizations, uploadDocuments } from '../../../../../../../services';
import {
    getBiddingFinancialVendorItems,
    getBiddingProjectFinancialSummary,
} from '../../../../../../../store/bidding-management';
import {
    updateBiddingVendorEstimations,
    uploadMainProjectBiddingVendorDocs,
} from '../../../../../../../services/bidding-management';
import { UploadOutlined } from '@ant-design/icons';

const UpdateVendorItem = memo((props) => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [avgMargin, setAvgMargin] = useState(0);

    const [vendors, setVendors] = useState({
        data: [],
        value: '',
        fetching: false,
    });

    useEffect(() => {
        form.setFieldsValue({
            ...props.data,
            vendor: props.data?.vendor?.name,
        });
        setAvgMargin(props.data?.margin_percent);
    }, []);

    const onSearchVendor = debounce(async (value) => {
        try {
            if (!value) {
                throw new Error();
            }
            setVendors({ ...vendors, fetching: true });
            const response = await getOrganizations({ search: value });
            setVendors({
                ...vendors,
                fetching: false,
                data: response.data,
                value,
            });
        } catch (error) {
            setVendors({ ...vendors, fetching: false, data: [] });
        }
    }, 500);

    const uploadDocuments = async (projectId, vendorAssociateId, values) => {
        try {
            const { documents } = values;
            const formData = new FormData();
            documents.fileList.map((file) => formData.append('files', file.originFileObj));
            await uploadMainProjectBiddingVendorDocs(projectId, vendorAssociateId, formData);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            setSubmitting(true);
            const dto = {
                ...values,
                margin_percent: avgMargin,
                description: values?.item_detail,
                master_project: props.data.master_project,
                unit_price: values?.total_sell / values?.quantity,
            };
            const response = await updateBiddingVendorEstimations(projectId, props.data.id, dto);
            if (values?.documents) {
                await uploadDocuments(projectId, response?.data?.id, values);
            }
            setSubmitting(false);
            message.success('Updated Successfully');
            form.resetFields();
            props.onCancel();
            dispatch(getBiddingFinancialVendorItems(projectId));
            dispatch(getBiddingProjectFinancialSummary(projectId));
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                notification.error({
                    message: 'Error',
                    description: error.response.data.message,
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Something unexpected happened',
                });
            }
            setSubmitting(false);
        }
    };
    const onCostSellChange = () => {
        const { total_cost, total_sell } = form.getFieldsValue();
        const margin = calculateAvgMargin(total_cost, total_sell);
        setAvgMargin(margin);
    };

    return (
        <Modal
            okText="Update"
            onOk={form.submit}
            open={props.visible}
            title="Update Vendor Quote"
            onCancel={props.onCancel}
            confirmLoading={submitting}
            className="main_project_vendor_quotes_update_modal"
        >
            <Spin spinning={submitting}>
                <Form layout="vertical" form={form} onFinish={onSubmit}>
                    <Row gutter={30}>
                        <Col span={8}>
                            <Form.Item
                                label="Vendor"
                                name="vendor"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <AutoComplete
                                    showSearch
                                    placeholder="Enter Vendor"
                                    options={map(vendors.data, (org) => ({
                                        name: org?.name,
                                        value: org?.name,
                                    }))}
                                    onSearch={onSearchVendor}
                                    notFoundContent={
                                        vendors.fetching ? (
                                            <div className="text-center">
                                                <Spin />
                                            </div>
                                        ) : null
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Item Details"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                                name="item_detail"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Quote #" name="vendor_quote_no">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Sell"
                                name="total_sell"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <InputNumber
                                    prefix="$"
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    className="full-width"
                                    onChange={onCostSellChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Cost"
                                name="total_cost"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <InputNumber
                                    prefix="$"
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    className="full-width"
                                    onChange={onCostSellChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Avg. Margin" rules={BASIC_REQUIRED_VALIDATION_RULES}>
                                <Input
                                    suffix="%"
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    className="full-width"
                                    readOnly
                                    value={avgMargin}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                                <Form.Item
                                    label="Equipment Name / Catalog #:"
                                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                                    name="tag_name"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Quantity"
                                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                                    name="quantity"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        <Col span={8}>
                            <Form.Item label="Documents" name="documents">
                                <Upload multiple beforeUpload={() => false}>
                                    <Button icon={<UploadOutlined />}>Select Files</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
});

UpdateVendorItem.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => {},
};

UpdateVendorItem.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default UpdateVendorItem;
