import _ from 'lodash';
import GoogleMapReact from 'google-map-react';
import {
  AutoComplete, Button, Form, Input, notification, Select, Row, Col, Modal, Tag,
} from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Marker } from '../../../components';
import { newCreateProject, newUpdateProject } from '../../../services/projects-new';
import { PROJECT_API_ENUM } from '../../../../static/utils';
import SearchAddress from './search-address';
import { getOrganizations } from '../../../services';

function BasicInfo({
  onFinish,
  masterProjectInfo = {},
  basicInfo = {},
  projectId = null,
  setLoader = () => {},
  history,
  onComplete,
  createNewProject,
}) {
  const { user } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  const [apiReady, setApiReady] = useState(false);
  const [map, setMap] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const [address, setAddress] = useState();
  const [center, setCenter] = useState([]);
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [draggable, setDraggable] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchOrgs();
  }, []);

  const setCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter([position.coords.latitude, position.coords.longitude]);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  };

  const fetchOrgs = async (val) => {
    try {
      const results = await getOrganizations(val);
      const filteredMfrs = results.data.filter((item) => item);
      setOrganizations([...filteredMfrs]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ ...basicInfo });
    if (basicInfo?.latitude && basicInfo?.longitude) {
      setCenter([basicInfo.latitude, basicInfo.longitude]);
      setLat(basicInfo.latitude);
      setLng(basicInfo.longitude);
    } else {
      setCurrentLocation();
    }
  }, [basicInfo]);

  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      setApiReady(true);
      setMap(map);
      setMapApi(maps);
    }
  };

  const onNext = async (values) => {
    setLoader(true);
    delete basicInfo.tags;
    delete basicInfo.users;
    values.latitude = lat;
    values.longitude = lng;
    values.branch = user?.branch || null;
    values.master_project = masterProjectInfo?.id;
    let response;
    if (!_.isEqual(basicInfo, values)) {
      if (projectId) {
        response = await newUpdateProject(PROJECT_API_ENUM.BASIC_INFO, projectId, values);
        notification.success({ description: response.message || 'Info updated' });
      } else {
        response = await newCreateProject({ ...values });
        notification.success({ description: response.message || 'Error Updating Info' });
        history.push({
          pathname: `/projects/${masterProjectInfo?.id}/${response?.data.id}/edit`,
          search: '?activeTab=2',
        });
      }
    }
    setLoader(false);

    onFinish(values);
  };

  const _generateAddress = (_lat, _lng) => {
    const geocoder = new mapApi.Geocoder();
    geocoder.geocode({ location: { lat: _lat, lng: _lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          setAddress(results[0].formatted_address);
          form.setFieldsValue({ address: results[0].formatted_address });
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert(`Geocoder failed due to: ${status}`);
      }
    });
  };

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    const { lat, lng } = mouse;
    setDraggable(true);
    setLat(lat);
    setLng(lng);
    _.debounce(() => {
      _generateAddress(lat, lng);
    }, 300);
  };

  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    const { lat, lng } = mouse;
    setDraggable(true);
    setLat(lat);
    setLng(lng);
    _generateAddress(lat, lng);
  };

  const _onChange = ({ center, zoom }) => {
    setCenter(center);
  };

  const _onClick = (value) => {
    const { lat, lng } = value;
    setLat(lat);
    setLng(lng);
    _generateAddress(lat, lng);
  };

  const addPlace = (place) => {
    setLat(place.geometry.location.lat());
    setLng(place.geometry.location.lng());
    setAddress(place.formatted_address);
    form.setFieldsValue({
      address: place.formatted_address,
    });
  };

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      title: 'Do you want to mark this project as completed?',
      icon: <ExclamationCircleFilled />,
      content: 'This action is not reversible. Please recheck project completion',
      onOk() {
        onComplete(projectId);
      },
      onCancel() {
      },
    });
  };

  return (
    <>
      <Row className="row">
        <Col flex="8 0 auto" className="column">
          <h2 className="step-heading mb-20">Subproject Information</h2>
        </Col>
        <Col flex="1 0" className="column close-btn">
          { !createNewProject && !basicInfo.is_draft
                    && (
                    <Button block size="large" danger onClick={showConfirm}>
                      Complete Project
                    </Button>
                    )}
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onNext} initialValues={basicInfo}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Required',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: false,
              message: 'Required',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Owner Organization"
          tooltip="The company to which the equipment will be owned (also known as End User)"
          name="owner"
          rules={[
            {
              required: true,
              message: 'Owner is required',
            },
          ]}
        >
          <Input disabled />
          {/* <AutoComplete
                        virtual
                        showSearch
                        placeholder="Search or Select an organization"
                        optionFilterProp="children"
                        defaultValue={masterProjectInfo?.owner}
                        autoClearSearchValue={false}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        disabled
                    >
                        {organizations.map((org) => (
                            <AutoComplete.Option value={org.name}>{org.name}</AutoComplete.Option>
                        ))}
                    </AutoComplete> */}
        </Form.Item>
        {/* <Form.Item
                    label="Site Address"
                    name="address"
                    rules={[
                        {
                            required: false,
                            message: 'Site address is required',
                        },
                    ]}
                >
                    {apiReady && (
                        <SearchAddress
                            map={map}
                            mapApi={mapApi}
                            addplace={addPlace}
                            onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : '')}
                        />
                    )}
                </Form.Item> */}
        {/* <div style={{ height: '442px', width: '100%' }}>
                    <GoogleMapReact
                        zoom={4}
                        center={center}
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3,
                            libraries: ['places', 'geometry'],
                        }}
                        onChange={_onChange}
                        onClick={_onClick}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    >
                        {lat && lng && <Marker text={address} lat={lat} lng={lng} />}
                    </GoogleMapReact>
                </div> */}
        <Form.Item className="text-right mb-0">
          <Button
            className="btn-lg btn-primary-lg save-btn mt-30"
            type="primary"
            htmlType="submit"
          >
            Save & Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default BasicInfo;
