import { fileHttp, http } from '../../static/utils/http';

export const exportOrPreviewBinder = async (projectId, body) => {
    try {
        const response = await http.post(`/masterprojects/${projectId}/finance/digital-binder/`, body);
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchBinderHistory = async (projectId, params) => {
    try {
        const response = await http.get(`/masterprojects/${projectId}/finance/digital-binder/`, params);
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateBinderHistory = async (projectId, binderId, body) => {
    try {
        const response = await http.patch(`/masterprojects/${projectId}/finance/digital-binder-doc/${binderId}/`, body);
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};
