import PropTypes from 'prop-types';
import { debounce, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import { AutoComplete, Form, Input, message, Modal, Spin } from 'antd';
import { BASIC_REQUIRED_VALIDATION_RULES } from '../../../../../../../static/utils';
import { getOrganizations, updateVendorEstimations } from '../../../../../../services';
import {
    getFinancialVendorItems,
    getProjectFinancialSummary,
    getVendorQuotesSummary,
} from '../../../../../../store/financials';

const UpdateVendorItem = memo((props) => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [vendors, setVendors] = useState({
        data: [],
        value: '',
        fetching: false,
    });

    useEffect(() => {
        form.setFieldsValue({
            ...props.data,
            vendor: props.data?.vendor?.name,
        });
    }, []);

    const onSearchVendor = debounce(async (value) => {
        try {
            if (!value) {
                throw new Error();
            }
            setVendors({ ...vendors, fetching: true });
            const response = await getOrganizations({ search: value });
            setVendors({
                ...vendors,
                fetching: false,
                data: response.data,
                value,
            });
        } catch (error) {
            setVendors({ ...vendors, fetching: false, data: [] });
        }
    }, 500);

    const onSubmit = async (values) => {
        try {
            setSubmitting(true);
            const dto = {
                ...values,
                master_project: props.data.master_project,
            };
            await updateVendorEstimations(projectId, props.data.id, dto);
            setSubmitting(false);
            message.success('Updated Successfully');
            form.resetFields();
            props.onCancel();
            dispatch(getFinancialVendorItems(projectId));
            dispatch(getVendorQuotesSummary(projectId));
        } catch (error) {
            setSubmitting(false);
        }
    };
    return (
        <Modal
            okText="Update"
            onOk={form.submit}
            open={props.visible}
            title="Update Vendor Quote"
            onCancel={props.onCancel}
            confirmLoading={submitting}
        >
            <Spin spinning={submitting}>
                <Form layout="vertical" form={form} onFinish={onSubmit}>
                    <Form.Item label="Vendor" name="vendor" rules={BASIC_REQUIRED_VALIDATION_RULES}>
                        <AutoComplete
                            showSearch
                            placeholder="Enter Vendor"
                            options={map(vendors.data, (org) => ({
                                name: org?.name,
                                value: org?.name,
                            }))}
                            onSearch={onSearchVendor}
                            notFoundContent={
                                vendors.fetching ? (
                                    <div className="text-center">
                                        <Spin />
                                    </div>
                                ) : null
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Equipment Name / Catalog #"
                        rules={BASIC_REQUIRED_VALIDATION_RULES}
                        name="tag_name"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Equipment Description / Item Details"
                        rules={BASIC_REQUIRED_VALIDATION_RULES}
                        name="item_detail"
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
});

UpdateVendorItem.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => { },
};

UpdateVendorItem.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default UpdateVendorItem;
