import { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProjectDetails from './ProjectDetails';
import MasterProjects from '../master-projects/details';
import SubProjectDetail from '../sub-projects/details';
import ProjectMapListing from './ProjectMapListing';
import BiddingMapView from '../BiddingManagement/MainProjects/biddingMapView';
import ContractorDetails from '../BiddingManagement/Contractors/ContractorDetails';
import { BIDDING_MANAGEMENT, CONTRACTORS } from 'static/utils';
import BidProjectDetails from '../BiddingManagement/MainProjects/MainProjectDetails/BidProjectDetails';

const ProjectsView = memo(() => {
    return (
        <ProjectDetails>
            <Switch>
                <Route exact path={'/projects'} component={ProjectMapListing} />
                <Route exact path={'/projects/:projectId'} component={MasterProjects} />
                <Route
                    exact
                    path={'/projects/:masterProjectId/sub-project/:subProjectId'}
                    component={SubProjectDetail}
                />
                <Route exact path={'/bidding-management'} component={BiddingMapView} />

                <Route
                    exact
                    path={`${BIDDING_MANAGEMENT}/:projectId`}
                    component={BidProjectDetails}
                />
                <Route
                    exact
                    path={`${BIDDING_MANAGEMENT}/:projectId${CONTRACTORS}/:contractorId`}
                    component={ContractorDetails}
                />
            </Switch>
        </ProjectDetails>
    );
});

export default ProjectsView;
