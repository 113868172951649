import React from "react";

const GreenDotIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
  <circle cx="5.5" cy="6" r="5.5" fill="#2CC805" />
</svg>
  );
};

export default GreenDotIcon;
