import axios from 'axios';
import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Avatar, Space, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getSignedUrl } from '../../../../../../services';
import { isPackagesActive, makeAvatar, DISPLAY_DATETIME_FORMAT } from '../../../../../../../static/utils';

export default () => {
    const downloadDocument = async ({ file_path, file_name }) => {
        try {
            const response = await getSignedUrl(file_path);
            await axios.get(response.data, { headers: { 'Content-Disposition': 'attachment' } });
            saveAs(response.data, file_name);
        } catch (error) {
            message.error('Error in downloading document');
        }
    };

    return React.useMemo(() => [
        ...[
            isPackagesActive()
                ? {
                      title: 'Package',
                      dataIndex: ['package_room', 'name'],
                      isSearchable: true,
                  }
                : {},
        ],
        {
            title: 'Folder',
            dataIndex: 'dir_value',
            isSearchable: true,
        },
        {
            title: 'File Name',
            dataIndex: 'file_name',
            isSearchable: true,
            render: (_, record) => (
                <Space>
                    <Tooltip title="Download">
                        <DownloadOutlined
                            style={{ fontSize: 18 }}
                            onClick={() => downloadDocument(record)}
                        />
                    </Tooltip>
                    {record?.file_name}
                </Space>
            ),
        },
        {
            title: 'Uploaded at',
            dataIndex: 'created_at',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? moment(value).format(DISPLAY_DATETIME_FORMAT) : ''),
        },
        {
            title: 'Uploaded by',
            align: 'center',
            dataIndex: 'account',
            render: (account) => {
                if (!isEmpty(account)) {
                    return (
                        <Tooltip title={`${account?.first_name} ${account?.last_name}`}>
                            <Avatar
                                size="small"
                                src={makeAvatar(account?.first_name?.[0], account?.last_name?.[0])}
                            />
                        </Tooltip>
                    );
                }
                return '-';
            },
        },
    ]);
};
