import { http, fileHttp } from '../../static/utils/http';

export const fetchChatMessages = async ({ id, page, pageLimit, type }) => {
  try {
    let response;
    if (type) {
      response = await http.get(`/chat/project/${id}/messages/?page=${page}&per_page=${pageLimit}`);
    } else {
      response = await http.get(`/chat/package/${id}/messages/?page=${page}&per_page=${pageLimit}`);
    }
    return response;
  } catch (ex) {
    throw ex;
  }
};

export const fetchUserChatMessages = async ({ id, page, pageLimit }) => {
  try {
    const response = await http.get(`/chat/user/${id}/messages/?page=${page}&per_page=${pageLimit}&ordering=-created_at`);
    return response;
  } catch (ex) {
    throw ex;
  }
};

export const uploadChatDocuments = async (data) => {
  try {
    const response = await fileHttp.post(
      '/chat/upload/',
      data,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
