import React from "react";
import "./AlectifySearchAnimation.scss";

const AlectifySearchAnimation = ({ description }) => {
  return (
    <div className="empty-state-container">
      <embed
        type="video/webm"
        src="https://lottie.host/embed/f0f5e925-83ce-47b7-b572-3772e9ab7a2a/KhpW3EVj9R.json"
        width="400"
        height="300"
      />
      <p>{description}</p>
    </div>
  );
};
export default AlectifySearchAnimation;
