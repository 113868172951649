// React imports
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

// Ant Design component
import { Button, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// Custom components
import { PROJECTS } from 'static/utils';
import AlectifyText from 'static/texts.json';
import SubProjectListing from './subProjectListing';
import MasterProjectDetailCard from './masterProjectDetailCard';
import { PROJECT_SETUP, setActiveProject } from 'app/store/common';
import { fetchMasterProjectDetail, fetchSubProjects } from 'app/services';

// Stylesheet import
import './masterProjectDetailCard.scss';

const MasterProjectDetail = () => {
    const {projectId} = useParams();
    const { activeProject } = useSelector(({ common }) => common);
    const history = useHistory();
    const dispatch = useDispatch();
    const [subProjects, setSubProjects] = useState({ fetching: false, data: [] });

    const getSubProjects = async () => {
        try {
            const params = {
                counts: true,
            };
            setSubProjects({ ...subProjects, fetching: true });
            const response = await fetchSubProjects(projectId, params);
            setSubProjects({ fetching: false, data: response?.data });
        } catch (err) {
            setSubProjects({ ...subProjects, fetching: false });
            console.log(err);
        }
    };

    const getMasterProjectDetail = async () => {
        try {
            const masterProjectDetail = await fetchMasterProjectDetail('', projectId);
            dispatch(setActiveProject(masterProjectDetail?.data[0]));

        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        Promise.all([getSubProjects(), getMasterProjectDetail()]);
    }, [projectId]);

    return (
        <Spin spinning={subProjects.fetching} className="master-project-detail-container">
            <MasterProjectDetailCard project={activeProject} />
            <div className="d-flex justify-space-between align-items-center pR-10">
                <h1 className="list-sub-project-heading">{`${AlectifyText.LIST_SUB_PROJECTS}`}</h1>
                <Button
                    className="alectify-regular-button"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={(ev) => {
                        ev.stopPropagation();
                        dispatch({ type: PROJECT_SETUP, payload: true });
                        history.push(`${PROJECTS}/${projectId}/create`);
                    }}
                >
                    {AlectifyText.NEW_SUB_PROJECT}
                </Button>
            </div>
            <div className="sub-project-listing-container">
                <SubProjectListing subProjects={subProjects} activeProject={activeProject} />
            </div>
        </Spin>
    );
};

export default memo(MasterProjectDetail);
