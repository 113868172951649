import { memo, useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Spin,
    Upload,
} from 'antd';
import {
    ASSOCIATE_VENDOR_ITEMS_REASONS,
    BASIC_REQUIRED_VALIDATION_RULES,
    calculateAvgMargin,
    INPUT_DATE_FORMAT,
    SERVER_DATE_FORMAT,
} from '../../../../../../../../static/utils';
import { UploadOutlined } from '@ant-design/icons';
import {
    createVendorAssociates,
    uploadVendorAssociatesDocuments,
} from '../../../../../../../services';
import { useParams } from 'react-router-dom';
import {
    getFinancialVendorItemDetail,
    getFinancialVendorItems,
    getProjectFinancialSummary,
    getVendorAssociates,
} from '../../../../../../../store/financials';
import { useDispatch } from 'react-redux';

const CreateVendorAssociate = memo((props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { projectId } = useParams();
    const [avgMargin, setAvgMargin] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    const uploadDocuments = async (vendorAssociateId, values) => {
        try {
            const { documents } = values;
            const formData = new FormData();
            documents.fileList.map((file) => formData.append('files', file.originFileObj));
            await uploadVendorAssociatesDocuments(
                projectId,
                props.data?.id,
                vendorAssociateId,
                formData,
            );
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            setSubmitting(true);
            const dto = {
                ...values,
                margin_percent: avgMargin,
                entry_date: values.entry_date.format(SERVER_DATE_FORMAT),
            };
            const response = await createVendorAssociates(projectId, props.data?.id, dto);
            if (values?.documents) {
                await uploadDocuments(response?.data.id, values);
            }
            setSubmitting(false);
            message.success('Created Successfully');
            form.resetFields();
            setAvgMargin(0);
            dispatch(getVendorAssociates(projectId, props.data?.id, { page: 1, per_page: 5 }));
            dispatch(getFinancialVendorItems(projectId, { page: 1, per_page: 10 }));
            dispatch(getProjectFinancialSummary(projectId));
            dispatch(getFinancialVendorItemDetail(projectId, props.data?.id));
        } catch (error) {
            setSubmitting(false);
        }
    };

    const onCostSellChange = () => {
        const { cost, sell } = form.getFieldsValue();
        const margin = calculateAvgMargin(cost, sell);
        setAvgMargin(margin);
    };

    useEffect(() => {
        form.setFieldsValue({ reason: props?.variant });
    }, []);

    return (
        <Spin spinning={submitting}>
            <Form layout="vertical" onFinish={onSubmit} form={form} className="mb-20">
                <Row gutter={30}>
                    <Col span={6}>
                        <Form.Item
                            label="Date"
                            name="entry_date"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                        >
                            <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Reason"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                            name="reason"
                        >
                            <Select placeholder="Please Select">
                                {Object.keys(ASSOCIATE_VENDOR_ITEMS_REASONS).map((key) => (
                                    <Select.Option value={key} key={key}>
                                        {ASSOCIATE_VENDOR_ITEMS_REASONS[key]}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Quote #" name="quote_no">
                            <Input />
                        </Form.Item>
                    </Col>
                    {/* <Col span={6}>
                        <Form.Item
                            name="comments"
                            label="Comments"
                        >
                            <Input />
                        </Form.Item>
                    </Col> */}
                    <Col span={6}>
                        <Form.Item label="Documents" name="documents">
                            <Upload multiple beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Select Files</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Total Sell"
                            name="sell"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                        >
                            <InputNumber
                                prefix="$"
                                precision={2}
                                min={0}
                                step={0.1}
                                className="full-width"
                                onChange={onCostSellChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Total Cost"
                            name="cost"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                        >
                            <InputNumber
                                prefix="$"
                                precision={2}
                                min={0}
                                step={0.1}
                                className="full-width"
                                onChange={onCostSellChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Avg. Margin" rules={BASIC_REQUIRED_VALIDATION_RULES}>
                            <Input
                                suffix="%"
                                precision={2}
                                min={0}
                                step={0.1}
                                className="full-width"
                                readOnly
                                value={avgMargin}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Button type="primary" htmlType="submit" loading={submitting}>
                    Submit
                </Button>
            </Form>
        </Spin>
    );
});

export default CreateVendorAssociate;
