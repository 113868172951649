import React from "react";

const EditIcon = (props) => {
  return (
    <svg
    {...props}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="26" height="26" rx="13" fill="#D1E4FC" />
      <path
        d="M18.1834 10.8812C17.7833 11.2813 17.5833 11.4813 17.3347 11.4813C17.0862 11.4813 16.8861 11.2813 16.4861 10.8812L15.1212 9.51635C14.7212 9.1163 14.5211 8.91628 14.5211 8.66772C14.5211 8.41916 14.7212 8.21914 15.1212 7.81909L15.3378 7.60251C15.7378 7.20247 15.9379 7.00244 16.1864 7.00244C16.435 7.00244 16.635 7.20247 17.0351 7.60251L18.3999 8.96739C18.8 9.36744 19 9.56746 19 9.81602C19 10.0646 18.8 10.2646 18.3999 10.6646L18.1834 10.8812Z"
        fill="#1877F2"
      />
      <path
        d="M8.20014 19.0024C7.63439 19.0024 7.35151 19.0024 7.17576 18.8267C7 18.6509 7 18.368 7 17.8023V16.4374C7 16.1921 7 16.0695 7.04568 15.9592C7.09136 15.8489 7.17808 15.7622 7.35151 15.5888L12.6584 10.2819C13.0585 9.88184 13.2585 9.68181 13.507 9.68181C13.7556 9.68181 13.9556 9.88184 14.3557 10.2819L15.7205 11.6468C16.1206 12.0468 16.3206 12.2468 16.3206 12.4954C16.3206 12.7439 16.1206 12.944 15.7205 13.344L10.4137 18.6509C10.2402 18.8244 10.1535 18.9111 10.0432 18.9568C9.93294 19.0024 9.8103 19.0024 9.56502 19.0024H8.20014Z"
        fill="#1877F2"
      />
    </svg>
  );
};

export default EditIcon;
