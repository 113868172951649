import { http, fileHttp } from '../../static/utils/http';

export const fetchProjectSearchResults = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/search/`, body);
    return response?.data;
  } catch (ex) {
    return ex.response?.data;
  }
};

export const fetchProjectDetail = async (id) => {
  try {
    const response = await http.get(`/projects/${id}/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchMasterProjectDetail = async (type, id) => {
  try {
    const response = await http.post(
      '/paginatedmasterprojects/',
      {
        master_project_id: id,
      },
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const newfetchProjectDetail = async (type, id, full_descriptive = true) => {
  try {
    const response = await http.get(
      `/projects/new/${id}/?type=${type}&full_descriptive=${full_descriptive}`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchProjectUsersDetailForBidding = async (projectId) => {
  try {
    const response = await http.get(`/bidding/main_project/${projectId}/users/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getAllIndividualPackages = async (prjectId) => {
  try {
    const response = await http.get(`/projects/${prjectId}/tags/basic/?pkg_tag_count=1`);
    return response?.data;
  } catch (ex) {
    return ex.response?.data;
  }
};

export const fetchPackagesSectionWise = async (id, section, page = 1, pageSize) => {
  try {
    const response = await http.get(
      `/projects/${id}/?section=${section}&page=${page}&per_page=${pageSize}`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createProject = async (body) => {
  try {
    const response = await http.post('/projects/', body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createMasterProject = async (body) => {
  try {
    const response = await http.post('/masterprojects/new/', body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const updateMasterProject = async (id, body) => {
  try {
    const response = await http.patch(`/masterprojects/${id}/update/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const newCreateProject = async (body) => {
  try {
    const response = await http.post('/projects/new/', body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const newRemoveUserFromProject = async (projectId, body) => {
  try {
    const response = await http.delete(`/projects/new/${projectId}/?type=users`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const removeUserForBiddingProject = async (projectId, userId) => {
  try {
    const response = await http.delete(`/bidding/main_project/${projectId}/users/`, userId);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const removeUserFromProject = async (projectId, userId) => {
  try {
    const response = await http.delete(`/projects/${projectId}/remove-user/${userId}/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const addUserIntoProject = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/add-user/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const updateProject = async (projectId, body) => {
  try {
    const response = await http.patch(`/projects/${projectId}/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const addUserToProject = async (type, projectId, body) => {
  try {
    const response = await http.patch(`/projects/${projectId}/add-user/`, body);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const newUpdateProject = async (type, projectId, body) => {
  try {
    const response = await http.patch(`/projects/new/${projectId}/?type=${type}`, body);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const uploadEmpowerFile = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/empower/upload/`, body);
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const makeProjectActive = async (projectId) => {
  try {
    const response = await http.patch(`/projects/${projectId}/move-from-draft/`, {});
    return response?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const fetchProjectDocs = async (projectId) => {
  try {
    const response = await http.get(`projects/${projectId}/documents/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const removeProjectDocs = async (projectId, body) => {
  try {
    const response = await http.delete(`projects/${projectId}/documents/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const saveProjectDocs = async (projectId, data) => {
  try {
    const response = await fileHttp.post(`projects/${projectId}/documents/`, data);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchProjectUsers = async (projectId) => {
  try {
    const response = await http.get(`projects/${projectId}/users/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchGantChartSeries = async (projectId) => {
  try {
    const response = await http.get(`projects/${projectId}/gantt-chart-data/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchAdminProjects = async (type) => {
  try {
    const response = await http.get(`projects/admin/?type=${type}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const softDeleteProject = async (projectId) => {
  try {
    const response = await http.delete(`projects/${projectId}/soft-delete/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const hardDeleteProject = async (projectId) => {
  try {
    const response = await http.delete(`projects/${projectId}/hard-delete/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const reActivateProject = async (projectId) => {
  try {
    const response = await http.patch(`projects/${projectId}/re-activate/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const generateCsvReport = async (projectId, pkgId) => {
  try {
    const response = await http.get(`projects/${projectId}/package/${pkgId}/csv-kanban/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const generateKanbanAllReport = async (projectId) => {
  try {
    const response = await http.get(`projects/${projectId}/csv-kanban/`);
    return response?.data;
  } catch (ex) {
    return ex.response?.data;
  }
};

export const generateMasterTagReport = async (projectId) => {
  try {
    const response = await http.get(`projects/${projectId}/master-tag-csv/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const postCriticalTags = async (projectId, body, type) => {
  try {
    const response = await http.post(`projects/${projectId}/gantt-charts/?type=${type}`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
