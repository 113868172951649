import React from 'react';
import moment from 'moment';
import { Popconfirm, Space, Tooltip } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    FileSearchOutlined,
    FileTextOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { DISPLAY_DATETIME_FORMAT, DISPLAY_DATE_FORMAT, renderAmount } from '../../../../../../../../../static/utils';

export default (onActionClick = () => {}) => {
    return React.useMemo(() => [
        {
            key: 'tag_name',
            title: 'Equipment / Catalog #',
            dataIndex: 'tag_name',
            render: (value) => value || '-',
        },
        {
            key: 'vendor',
            title: 'Vendor',
            dataIndex: 'vendor',
            render: (vendor) => vendor?.name || '-',
        },
        {
            key: 'vendor_quote_no',
            title: 'Quote #',
            dataIndex: 'vendor_quote_no',
            render: (value) => value || '-',
        },
        {
            key: 'item_detail',
            title: 'Item Detail',
            dataIndex: 'item_detail',
        },
        {
            key: '5',
            title: 'Sell',
            dataIndex: 'total_sell',
            render: renderAmount,
        },
        {
            key: 'total_cost',
            title: 'Cost',
            dataIndex: 'total_cost',
            render: renderAmount,
        },
        {
            key: 'margin_percent',
            title: 'Margin',
            dataIndex: 'margin_percent',
            render: (value) => `${value} %`,
        },
        {
            key: 'entry_date',
            title: 'Entry date',
            dataIndex: 'entry_date',
            render: (value) => moment(value).format(DISPLAY_DATE_FORMAT),
        },
        {
            key: 'created_at',
            title: 'Created on',
            dataIndex: 'created_at',
            render: (value) => moment(value).format(DISPLAY_DATE_FORMAT),
        },
        {
            key: 'files_count',
            title: 'Attachment(s)',
            dataIndex: 'docs_count',
            render: (value) => (
                <Space>
                    <FileTextOutlined />
                    {value || 0}
                </Space>
            ),
        },
        {
            key: 'action',
            title: 'Action',
            align: 'center',
            dataIndex: 'action',
            render: (_, record) => (
                <Space size={10}>
                    <Tooltip title="Details">
                        <FileSearchOutlined
                            style={{ fontSize: 17 }}
                            onClick={() => onActionClick('view', record)}
                        />
                    </Tooltip>
                    <Tooltip title="Edit">
                        <EditOutlined
                            style={{ fontSize: 17 }}
                            onClick={() => onActionClick('edit', record)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure, you want to delete this estimation?"
                            description="Are you sure to delete this estimation?"
                            onConfirm={() => onActionClick('delete', record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteOutlined style={{ fontSize: 17 }} />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ]);
};
