// React imports
import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Ant Design components
import { Typography } from 'antd';

// Custom components
import AlectifyText from 'static/texts.json';
import { fetchAllMasterProjects } from '../../services';
import WelcomeMessage from 'app/components/welcome-message';
import { PROJECTS, PROJECT_TYPES } from '../../../static/utils';
import { mapViewDisplay, setActiveProject } from '../../store/common';
import AlectifyMap from 'app/components/map';

// Stylesheet import
import './projectView.scss';

const { Title, Paragraph} = Typography;

const ProjectMapListing = (props) => {
    const dispatch = useDispatch();
    const [projects, setProjects] = useState({ fetching: false, data: [] });
    const { user } = useSelector(({ auth }) => auth);

    const getProjects = async () => {
        try {
            setProjects({ ...projects, fetching: true });
            const response = await fetchAllMasterProjects();
            setProjects({ fetching: false, data: response.data });
        } catch (error) {
            setProjects({ ...projects, fetching: false });
        }
    };

    const onProjectClickHandler = (masterProject) => {
        dispatch(
            setActiveProject({
                ...masterProject,
                masterProject,
                type: PROJECT_TYPES.MASTER_PROJECT,
            }),
        );
        props.history.push(`${PROJECTS}/${masterProject?.id}`);
    };

    useEffect(() => {
        getProjects();
    }, []);

    return (
        <div className="alectify-map-container">
            <Title level={5} className="mt-0 welcome-message-text">
                <WelcomeMessage />
                {user && ` ${user.first_name} ${user.last_name}`}
            </Title>
            <Paragraph>
            You have total{" "}
            <span className="text-blue-bold">
              {projects?.data?.length || "0"}
            </span>{" "}
            {AlectifyText.PROJECTS}.
          </Paragraph>
            <AlectifyMap projects={projects.data} onClick={onProjectClickHandler} />
        </div>
    );
};

export default memo(ProjectMapListing);
