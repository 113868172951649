// React imports
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Ant Design components
import { Drawer } from 'antd';

// Custom components
import BiddingSidebar from './biddingSidebar';
import { BIDDING_MANAGEMENT } from 'static/utils';
import { setShowBiddingSidebar } from 'app/store/common';
import { setActiveBiddingProject } from 'app/store/bidding-management';

// Stylesheet import
import './styles.scss';

const BiddingManagementSidebarListing = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { showBiddingSidebar } = useSelector(({ common }) => common);

    const onProjectClickHandler = (masterProject) => {
        dispatch(setShowBiddingSidebar(false));
        dispatch(setActiveBiddingProject(masterProject));
        history.push(`${BIDDING_MANAGEMENT}/${masterProject?.id}`);
    };

    const onCloseHandler = () => {
        dispatch(setShowBiddingSidebar(false));
    };

    return (
        <Drawer
            open={showBiddingSidebar}
            placement="left"
            className="alectify-master-project-sidebar-container"
            zIndex={0}
            closable={true}
            headerStyle={{ display: 'none' }}
            destroyOnClose={true}
            width={300}
            onClose={onCloseHandler}
            motion={undefined}
        >
            <BiddingSidebar
                onProjectClickHandler={onProjectClickHandler}
            />
        </Drawer>
    );
};

export default BiddingManagementSidebarListing;
