import _, { isEmpty } from 'lodash';
import { useState } from 'react';
import { Table, Row, Col, Tag, Button, Input, Modal, Select, Form } from 'antd';
import {
    SearchOutlined,
    DownloadOutlined,
    ShareAltOutlined,
    DeleteTwoTone,
} from '@ant-design/icons';
import { PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE, DEFAULT_START_PAGE } from '../../../static/utils';
import './styles.scss';

export function AlectifyTable({
    className = '',
    id = null,
    packageId = null,
    onDataFetch = () => {},
    onRowSelectionAction = () => {},
    onRow = () => {},
    onDownloadZip = () => {},
    onEmailShare = () => {},
    displayRowClass = () => {},
    isDownloadZip = false,
    isEmailSharing = false,
    size = 'small',
    dataSource = [],
    total = 0,
    showSizeChanger = true,
    columns,
    selectRows = true,
    loading = true,
    type = '',
    showPagination = true,
    selectionType = 'Checkbox',
    defaultPageSize = DEFAULT_PAGE_SIZE,
    multipleDeleteOption = false,
    onMultipleDelete = () => {},
    scroll = {},
    bordered = false,
}) {
    const [form] = Form.useForm();
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [page, setPage] = useState(DEFAULT_START_PAGE);
    const [searchedColumn, setSearchedColumn] = useState(null);
    const [orderingColumn, setOrderingColumn] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filters, setFilters] = useState({});
    const [users, setUsers] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);

        // Update Selected Records from keys
        onRowSelectionAction(dataSource.filter((a) => newSelectedRowKeys.includes(a?.id)), newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onChange = (pagination, filters, sorter, extra) => {
        let ordering = sorter?.field;
        if (sorter.order === 'descend') {
            ordering = `-${sorter?.field}`;
        } else if (sorter.order === 'ascend') {
            ordering = `${sorter?.field}`;
        } else {
            ordering = null;
        }
        setOrderingColumn(ordering);
        setPage(pagination?.current);
        setPageSize(pagination?.pageSize);

        // API call on Sorting confirmation
        onDataFetch({
            id,
            packageId,
            page: pagination?.current,
            pageSize: pagination?.pageSize,
            per_page: pagination?.pageSize,
            searchText,
            searchedColumn,
            orderingColumn: ordering,
        });
    };

    const onRemoveFilter = (f) => {
        delete filters[f];
        setFilters(filters);

        // Make API call with existing data
        onDataFetch({
            id,
            packageId,
            page,
            pageSize,
            per_page: pageSize,
        });
    };

    const getEnhancedColumns = (columns) => {
        const enhancedColumns = columns.filter((col) => !isEmpty(col)).map((c) => {
            if (c?.isSearchable) {
                return {
                    ...c,
                    filteredValue: filters[c?.dataIndex] && [filters[c?.dataIndex]],
                    ...getColumnSearchProps(c?.dataIndex, c?.title),
                };
            }
            return c;
        });
        return enhancedColumns;
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText(null);
        setFilters({});
        confirm();
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchedColumn(dataIndex);

        // API call on search confirmation
        onDataFetch({
            id,
            packageId,
            page: 1,
            pageSize,
            per_page: pageSize,
            searchText: filters[dataIndex],
            searchedColumn: dataIndex,
            ordering: orderingColumn,
        });
    };

    const getData = () => {
        onDataFetch({
            id,
            packageId,
            page,
            pageSize,
            searchText,
            searchedColumn,
            per_page: pageSize,
            ordering: orderingColumn,
        });
    };

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, width: 200 }}>
                <Row gutter={5}>
                    <Col span={12} className="mb-10">
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Reset
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: '100%' }}
                        >
                            Search
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Input
                            placeholder={`Search ${title}`}
                            onChange={(e) => {
                                setFilters({ ...filters, [dataIndex]: e.target.value });
                                if (e?.target?.value?.length === 0) {
                                    onRemoveFilter(dataIndex);
                                }
                            }}
                            value={filters?.[dataIndex]}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </Col>
                </Row>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : '#838383' }} />
        ),
        // onFilter:(value, record) =>
        //     record[dataIndex]
        //         ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        //         : '',
        // onFilterDropdownOpenChange: (visible) => { ****** Commenting this because search event was triggered twice
        //     if (!visible) {
        //         getData();
        //     }
        // },
    });

    const getAssineesForFilter = (assignees) => {
        let displayString = '';
        const members = _.map(assignees, (u) => {
            const m = _.find(users, ['id', u]);
            if (m) displayString += `${m?.first_name} ${m?.last_name}, `;
        });
        return displayString.replace(/,\s*$/, '');
    };

    const downloadZip = () => {
        onDownloadZip(dataSource.filter((a) => selectedRowKeys.includes(a?.id)));
    };

    const onSendEmail = (values) => {
        const { emails } = values;
        onEmailShare(
            emails,
            dataSource.filter((a) => selectedRowKeys.includes(a?.id)),
        );
        form.resetFields();
        setIsModalOpen(false);
    };

    return (
        <>
            {(isDownloadZip || isEmailSharing) && (
                <Row className="mb-20" justify="end">
                    {Object.keys(filters).length > 0 && searchedColumn ? (
                        <Col flex={1} span={24} className="table-filter">
                            <div className="label">Filters </div>
                            {Object.keys(filters).map((f) => {
                                const value = filters[f];
                                return (
                                    <Tag
                                        closable
                                        onClose={() => {
                                            onRemoveFilter(f);
                                        }}
                                    >
                                        <span className="filter-name">{`${f} :`}</span>
                                        {f === 'assignees'
                                            ? getAssineesForFilter(value)
                                            : _.isArray(value)
                                            ? _.join(value, ',')
                                            : value}
                                    </Tag>
                                );
                            })}
                        </Col>
                    ) : (
                        <Col span={24} />
                    )}
                    <Col>
                        {isDownloadZip && (
                            <Button
                                onClick={() => downloadZip()}
                                disabled={!selectedRowKeys.length}
                            >
                                <DownloadOutlined /> Download Zip
                            </Button>
                        )}
                        {isEmailSharing && (
                            <Button
                                className="ml-10"
                                onClick={showModal}
                                disabled={!selectedRowKeys.length}
                            >
                                <ShareAltOutlined /> Email
                            </Button>
                        )}
                    </Col>
                </Row>
            )}
            <Row>
                <Col span={24}>
                    {multipleDeleteOption && selectedRowKeys?.length ? (
                        <div className="text-right mb-10">
                            <Button
                                onClick={() => {
                                    onMultipleDelete(selectedRowKeys);
                                    setSelectedRowKeys([]);
                                }}
                            >
                                <DeleteTwoTone twoToneColor="#f56666" /> Delete (
                                {selectedRowKeys.length})
                            </Button>
                        </div>
                    ) : null}
                    <Table
                        bordered={bordered}
                        onRow={(record, rowIndex) => ({
                            onClick: (event) => onRow(record, rowIndex),
                        })}
                        className={className}
                        columns={getEnhancedColumns(columns)}
                        loading={loading}
                        rowSelection={
                            selectRows && {
                                type: selectionType,
                                ...rowSelection,
                            }
                        }
                        onChange={onChange}
                        pagination={
                            showPagination && {
                                total,
                                pageSizeOptions: PAGE_SIZE_OPTIONS,
                                pageSize,
                                showSizeChanger,
                                current: page,
                            }
                        }
                        rowClassName={(record) => displayRowClass(record)}
                        size={size}
                        dataSource={(!isEmpty(dataSource) ? dataSource : []).map((d) => ({ ...d, key: d?.id }))}
                        scroll={scroll}
                    />
                </Col>
            </Row>
            <Modal
                title="Share Documents"
                okText="Send Email"
                open={isModalOpen}
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form form={form} onFinish={onSendEmail} layout="vertical">
                    <Form.Item
                        rules={[{ required: true, message: 'Required' }]}
                        label="Send to:"
                        name="emails"
                    >
                        <Select
                            mode="tags"
                            options={[]}
                            style={{ width: '100%' }}
                            placeholder="Enter email ids"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
