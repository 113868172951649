import { memo, useState, useEffect } from 'react';
import { Breadcrumb, Layout, Spin } from 'antd';
import CalendarFilters from './Filters';
import CalendarEvents from './Calendar';
import { fetchProjects } from '../../services';

const { Content } = Layout;

const Calendar = () => {
    const [masterProjects, setMasterProjects] = useState({ fetching: false, data: [] });
    const [subProjectId, setSubProjectId] = useState(null);

    const getAllProjects = async () => {
        try {
            setMasterProjects({ ...masterProjects, fetching: true });
            const response = await fetchProjects({ active: true });
            setMasterProjects({ fetching: false, data: response?.data });
        } catch (error) {
            setMasterProjects({ ...masterProjects, fetching: false });
            console.log(error);
        }
    };

    useEffect(() => {
        getAllProjects();
    }, []);

    return (
        <Content>
            <Spin spinning={masterProjects.fetching}>
                <CalendarFilters
                    masterProjects={masterProjects.data}
                    onProjectChange={setSubProjectId}
                />
                <CalendarEvents subProjectId={subProjectId} />
            </Spin>
        </Content>
    );
};

export default Calendar;
