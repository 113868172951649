import { useEffect } from 'react';
import { Card, Layout } from 'antd';
import { useDispatch } from 'react-redux';
import { getSharedDocuments } from '../../store/documents';
import { DEFAULT_START_PAGE, DEFAULT_PAGE_SIZE } from '../../../static/utils';
import SharedAccordian from './accordian';

const { Content } = Layout;

function Shared() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSharedDocuments({ page: DEFAULT_START_PAGE, pageSize: DEFAULT_PAGE_SIZE }, 'project'));
    dispatch(getSharedDocuments({ page: DEFAULT_START_PAGE, pageSize: DEFAULT_PAGE_SIZE }, 'equipment'));
    dispatch(getSharedDocuments({ page: DEFAULT_START_PAGE, pageSize: DEFAULT_PAGE_SIZE }, 'review'));
  }, []);

  return (
    <Content>
      <Card title="Shared Files">
        <SharedAccordian />
      </Card>
    </Content>
  );
}
export default Shared;
