// React imports
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Ant Design components
import { Drawer } from 'antd';

// Lodash imports
import { debounce, isEmpty } from 'lodash';

// Custom components
import ProjectSidebar from './projectSidebar';
import { PAGINATION } from 'static/utils/pagination';
import { fetchAllMasterProjects } from '../../services';
import { setActiveProject, setShowSidebar } from '../../store/common';

// Stylesheet import
import './styles.scss';

const MasterProjectSidebarListing = () => {
    const history = useHistory();
    const [masterProjects, setMasterProjects] = useState({
        fetching: false,
        data: [],
        meta: {
            finished_master_proj_count: 0,
            total_count: 0,
            total_pages: 0,
        },
    });
    const { showSidebar } = useSelector(({ common }) => common);
    const dispatch = useDispatch();

    const onProjectClickHandler = (project) => {
        dispatch(setShowSidebar(false));
        dispatch(setActiveProject(project));
        history.push(`/projects/${project?.id}`);
    };

    const onCloseHandler = () => {
        dispatch(setShowSidebar(false));
    };

    const getAllMasterProjects = async (searchText, page) => {
        try {
            setMasterProjects({ ...masterProjects, fetching: true });
            const params = {
                search: searchText || undefined,
                page: page || PAGINATION.DEFAULT_START_PAGE_1,
                per_page: PAGINATION.DEFAULT_PAGE_SIZE_20,
            };
            const response = await fetchAllMasterProjects(params);
            let newData;
            if (searchText && page > 1) {
                newData = [...masterProjects.data, ...response.data];
            } else if (!isEmpty(searchText)) {
                newData = response.data;
            } else {
                newData = [...masterProjects.data, ...response.data];
            }
            setMasterProjects((prevState) => ({
                ...prevState,
                fetching: false,
                data: newData,
                meta: response.meta,
            }));
        } catch (error) {
            setMasterProjects({ ...masterProjects, fetching: false });
            console.error(error);
        }
    };

    const resetSidebarMasterProjectData = async (page) => {
        try {
            setMasterProjects({ ...masterProjects, fetching: true });
            const params = {
                search: undefined,
                page,
                per_page: PAGINATION.DEFAULT_PAGE_SIZE_20,
            };
            const response = await fetchAllMasterProjects(params);
            setMasterProjects({ fetching: false, data: response.data, meta: response.meta });
        } catch (error) {
            setMasterProjects({ ...masterProjects, fetching: false });
            console.error(error);
        }
    };

    const onSearchMasterProject = debounce((searchText, page) => {
        getAllMasterProjects(searchText, page);
    }, 500);

    return (
        <Drawer
            open={showSidebar}
            placement="left"
            className="alectify-master-project-sidebar-container"
            zIndex={0}
            closable={true}
            headerStyle={{ display: 'none' }}
            destroyOnClose={true}
            width={300}
            onClose={onCloseHandler}
            motion={undefined}
        >
            <ProjectSidebar
                onSearchMasterProject={onSearchMasterProject}
                masterProjects={masterProjects}
                onProjectClickHandler={onProjectClickHandler}
                getAllMasterProjects={getAllMasterProjects}
                resetSidebarMasterProjectData={resetSidebarMasterProjectData}
            />
        </Drawer>
    );
};

export default MasterProjectSidebarListing;
