import _ from 'lodash';
import {
  Empty, Spin, Button, Avatar, Tag,
} from 'antd';
import { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RightOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import classNames from 'classnames';
import { PROJECTS, makeAvatar } from '../../../../static/utils';

import MessageBox from './message-box';
import { activePackageRead, getAllChatMessages, setChatMessage } from '../../../store/chat';
import { uploadChatDocuments } from '../../../services';
import { useInViewport } from 'react-in-viewport';

const WS_BASE_URL = process.env.REACT_APP_WS_URL;

function ChatDisplay({
  tag,
  projectId,
  className,
  projectChat,
}) {
  const { user } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [socketUrl, setSocketUrl] = useState(null);
  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const history = useHistory();
  const myRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(myRef);
  const { loading, messageData = [] } = useSelector(({ chat }) => chat);
  const {
    sendMessage, sendJsonMessage, readyState, lastJsonMessage,
  } = useWebSocket(socketUrl, {
    onOpen: () => { },
    onClose: (e) => { },
    onMessage: (m) => { },
    onError: () => { },
    onReconnectStop: () => { },
    retryOnError: false,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const { permissions } = useSelector(({ permission }) => permission);
  const { projects } = useSelector(({ common }) => common);

  const getChatMessages = ({ id, page, pageLimit, type }) => {
    dispatch(getAllChatMessages({
      id,
      page: page + 1,
      pageLimit,
      type,
    }));
    setPage(page + 1);
  };

  const connectToSocket = () => {
    dispatch(activePackageRead(tag));
    if (tag?.project_chat) {
      getChatMessages({ id: tag?.id, page: 0, pageLimit, type: tag?.project_chat });
      setSocketUrl(`${WS_BASE_URL}/project/${tag?.id}/?${user.token}`);
    } else {
      getChatMessages({ id: tag?.id, page: 0, pageLimit, type: tag?.project_chat });
      setSocketUrl(`${WS_BASE_URL}/webpackage/${tag?.id}/?${user.token}`);
    }
  };

  useEffect(() => {
    if (lastJsonMessage !== null) {
      // Ignore if last message is send by same user
      dispatch(setChatMessage({
        id: tag?.id,
        message: lastJsonMessage,
        user: JSON.parse(lastJsonMessage?.sent_by),
      }));

      setTimeout(() => { scrollToBottom(lastJsonMessage); }, 200);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    setPage(0);
    connectToSocket();
  }, [tag]);

  useEffect(() => {
    if (inViewport) {
      connectToSocket();
    } else {
      setSocketUrl(null);
    }
  }, [inViewport]);

  const handleClickSendMessage = useCallback((m) => {
    sendJsonMessage(m);
  }, [tag]);

  const info = _.find(messageData, ['id', tag?.id], null);

  const scrollToBottom = (m) => {
    const element = document.getElementById('chat-content');
    element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
  };

  const onUploadChatDocuments = async (files) => {
    const formData = new FormData();
    files.map((file) => formData.append('files', file.originFileObj, file?.name));
    const result = await uploadChatDocuments(formData);
    result.data.map((r) => {
      sendJsonMessage({
        from: user?.id,
        text: r?.file_name,
        content_type: 'file',
        s3_key: r?.s3_key,
      });
    });
  };

  const goToPackage = () => {
    const url = `${PROJECTS}/${projectId}/package/${tag?.id}`;
    history.push(url);
  };

  return (
    <div className={classNames('chat-box', className)} ref={myRef}>
      <div className="chat-header">
        <div>
          {tag?.name}
          {' '}
          Inbox
          {' '}
        </div>
        {
          !projectChat
          && (
            <div>
              <Button onClick={goToPackage} style={{ marginRight: 10 }}>
                <RightOutlined />
                To Equipment Detail
              </Button>
            </div>
          )
        }
      </div>
      <div className={classNames('chat-content')} id="chat-content">
        {!loading && info?.meta?.total_count > page * pageLimit && (
          <Button
            className="loader"
            onClick={() => {
              if (tag?.id) {
                getChatMessages({ id: tag?.id, page, pageLimit, type: tag?.project_chat });
              }
            }}
          >
            See More ...
          </Button>
        )}
        <div className="messages">
          {
            info?.messages.length
              ? info?.messages.map((m) => (
                <div className={`chat-message-box ${user?.id === m.sent_by?.id && 'justify-end'}`} key={m?.id} id={m?.id}>
                  <div className="chat-message">
                    {
                      user?.id === m.sent_by?.id
                        ? (
                          <Avatar
                            src={
                              user?.image
                              || makeAvatar(user?.first_name[0], user?.last_name[0])
                            }
                          />
                        )
                        : (
                          <Avatar
                            src={
                              m.sent_by?.image
                              || makeAvatar(m.sent_by?.first_name[0], m.sent_by?.last_name[0])
                            }
                          />
                        )
                    }
                    {
                      m?.content_type === 'text' ? (
                        <div className="content text-message">
                          <div className="text">{m.text}</div>
                          <div className="time">{new Date(m.created_at).toLocaleString()}</div>
                        </div>
                      )
                        : (
                          <div className="content file-message">
                            <a href={m?.s3_url} target="_blank" rel="noreferrer">
                              <DownloadOutlined style={{ fontSize: '16px', color: '#08c', marginRight: 10 }} />
                              <div className="text">{m?.text || m?.file_name}</div>
                            </a>
                            <div className="time">{new Date(m?.created_at).toLocaleString()}</div>
                          </div>
                        )
                    }

                  </div>
                </div>
              ))
              : (
                <>
                  {!loading && (
                    <Empty
                      description="No messages to display"
                      className="mt-30"
                    />
                  )}
                </>
              )
          }
        </div>
        {loading && <div className="spinner"><Spin /></div>}
      </div>
      <MessageBox
        sendMessage={handleClickSendMessage}
        readyState={readyState}
        onUploadChatDocuments={onUploadChatDocuments}
      />
    </div>
  );
}

export default ChatDisplay;
