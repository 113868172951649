// Google Lib imports
import GoogleMapReact from 'google-map-react';

// Custom components
import MapMarker from '../marker/mapMarker';

// Stylesheet import
import './alectifyMap.scss';

const AlectifyMap = (props) => {
    const { projects, onClick, updateLocation, masterprojectItem, onChange, handleApiLoaded } =
        props;

    const defaultProps = {
        center: {
            lat: 47.73247288415846,
            lng: -110.3151785794922,
        },
        zoom: 4,
    };
    const handleOnClick = (project) => {
        if (onClick) {
            onClick(project);
        }
    };

    const handleLocationOnClick = (event) => {
        if (updateLocation) {
            updateLocation(event);
        }
    };

    return (
        <div className="alectify-google-map-container">
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAP_KEY_V3,
                    libraries: ['places', 'geometry'],
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                onClick={handleLocationOnClick}
                center={masterprojectItem?.center}
                onChange={onChange}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={
                    handleApiLoaded ? ({ map, maps }) => handleApiLoaded(map, maps) : () => {}
                }
            >
                {projects?.map((project) => (
                    <MapMarker
                        key={project.id}
                        lat={project.latitude}
                        lng={project.longitude}
                        onClick={() => handleOnClick(project)}
                        project={project}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
};
export default AlectifyMap;
