import { http, fileHttp } from '../../static/utils/http';

export const fetchEquipmentDocs = async (projectId, pkgId) => {
  try {
    const response = await http.get(`/projects/${projectId}/package-rooms/${pkgId}/documents/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchConstructionDrawings = async (projectId, pkgId) => {
  try {
    const response = await http.get(
      `/projects/${projectId}/package-rooms/${pkgId}/documents/construction_drawings/`,
    );
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const patchTagDates = async (projectId, tagId, body) => {
  try {
    const response = await http.patch(`/projects/${projectId}/tags/${tagId}/`, body);
    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

export const saveSlineEquipmentDocs = async (projectId, data) => {
  try {
    const response = await fileHttp.post(
      `/projects/${projectId}/sline_with_csv_v2/`,
      data,
    );
    return response?.data;
  } catch (ex) {
    return ex.response?.data;
  }
};

export const saveEquipmentDocs = async (projectId, pkgId, data) => {
  try {
    const response = await fileHttp.post(
      `/projects/${projectId}/package-rooms/${pkgId}/documents/`,
      data,
    );
    return response?.data;
  } catch (ex) {
    return ex.response?.data;
  }
};

export const saveEquipmentDocsForVideos = async (data) => {
  try {
    const response = await fileHttp.post('/videos/list/', data);
    return response?.data;
  } catch (ex) {
    return ex.response?.data;
  }
};

export const fetchImageEquipmentDocs = async (projectId, pkgId) => {
  try {
    const response = await http.get(
      `/projects/${projectId}/package-rooms/${pkgId}/documents/image/`,
    );
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};
