import { detectHttp, fileHttp, http } from '../../static/utils/http';

export const fetchBeforeShipmentImages = async (projectId, packageId) => {
  try {
    const response = await fileHttp.get(
      `/projects/${projectId}/package/${packageId}/shipment-detail/before/`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const uploadBeforeShipmentImages = async (projectId, packageId, formData) => {
  try {
    const response = await fileHttp.post(
      `/projects/${projectId}/package/${packageId}/shipment-list/before/`,
      formData,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchAfterShipmentImages = async (projectId, packageId) => {
  try {
    const response = await fileHttp.get(
      `/projects/${projectId}/package/${packageId}/shipment-detail/after/`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const uploadAfterShipmentImages = async (projectId, packageId, formData) => {
  try {
    const response = await fileHttp.post(
      `/projects/${projectId}/package/${packageId}/shipment-list/after/`,
      formData,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const detectImageStatusBefore = async (body) => {
  try {
    const response = await detectHttp.post(
      'https://cardboard-detect.alectify.com/detect/before/',
      body,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const detectImageStatusAfter = async (body) => {
  try {
    const response = await detectHttp.post(
      'https://cardboard-detect.alectify.com/detect/after/',
      body,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const multiDetectImageStatusAfter = async (body) => {
  try {
    const response = await detectHttp.post(
      'https://cardboard-detect.alectify.com/detect_multiple/after/',
      body,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
