import { http } from '../../static/utils/http';

export const addMemberInPackage = async (projectId, packageId, memberId) => {
  try {
    const response = await http.patch(
      `/projects/${projectId}/packages/${packageId}/team-members/${memberId}`,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const removeMemberFromPackage = async (projectId, packageId, memberId) => {
  try {
    const response = await http.delete(
      `/projects/${projectId}/packages/${packageId}/team-members/${memberId}`,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllPackages = async (projectId, type) => {
  try {
    const response = await http.get(
      `/projects/${projectId}/all-packages/?type=${type}&per_page=15&page=1`,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPackageDetails = async (projectId, pkgId) => {
  try {
    const response = await http.get(`/projects/${projectId}/package-rooms/${pkgId}/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchPackageOperationsDetails = async (projectId, pkgId) => {
  try {
    const response = await http.get(`/projects/${projectId}/package-rooms/${pkgId}/status/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const updatePackageRoom = async (projectId, pkgId, body) => {
  try {
    const response = await http.patch(`/projects/${projectId}/package-rooms/${pkgId}/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deletePackageRoom = async (projectId, pkgId) => {
  try {
    const response = await http.delete(`/projects/${projectId}/package-rooms/${pkgId}/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * --------------------
 * Update Package - Manage Packages
 * --------------------
 */

export const updatePackage = async (pkgId, body) => {
  try {
    const response = await http.patch(`/packages/${pkgId}/update/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteInactivePackages = async () => {
  try {
    const response = await http.delete('/packages/delete-inactive/');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchAllPackagesv2 = async (projectId, params = {}) => {
  try {
    delete params?.id;

    let url = `/projects/${projectId}/all-packages/?page=${params?.page}&per_page=${params?.per_page}`;
    const { searchedColumn, orderingColumn, searchText } = params;
    if (searchedColumn && searchText) {
      url += `&${searchedColumn}=${searchText}`;
    } else if (orderingColumn) {
      url += `&ordering=${orderingColumn}`;
    }

    const response = await http.get(url);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchFilteredPackages = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/packages/list/`, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchFilteredPackages2 = async (data) => {
  try {
    const response = await http.post(`/projects/${data?.id}/packages/list/?page=${data?.page}&per_page=${data.pageSize}`, data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
