import React from "react";

const GreyDotIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
  <circle cx="5.5" cy="6" r="5.5" fill="#9D9D9E" />
</svg>
  );
};

export default GreyDotIcon;
