import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { DeleteTwoTone, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  List,
  message,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  createNotification,
  saveEquipmentDocs,
  saveEquipmentDocsForVideos,
  saveProjectDocs,
  saveSlineEquipmentDocs,
  sendForApproval,
  uploadDocuments,
} from '../../../services';
import {
    APPROVAL_TYPES,
    APPROVAL_TYPES_V2,
    DOCUMENT_CATEGORIES,
    EQUIPMENT_DOCUMENT_TYPES,
    EQUIPMENT_SUB_FOLDERS,
    makeTitle,
    PROJECT_SUB_FOLDERS,
} from '../../../../static/utils';
import { SelectWithAdditonaliList } from '../..';

const { Panel } = Collapse;
const { Option, OptGroup } = Select;

export function UploadDocumentModal({
  defaultPackage = {},
  visible = false,
  projectId = null,
  onCancel = () => {},
  documentsToUpload = [],
  getAllDocuments = () => {},
  setDocumentsToUpload = () => {},
  isEquipmentDocuments = false,
}) {
  const [form] = Form.useForm();
  const { allPackages } = useSelector(({ packages }) => packages);
  const [category, setCategory] = useState('');
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [loader, setLoader] = useState(false);
  const [equipmentType, setEquipmentType] = useState('');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [hasEqpmtApprovalEnabled, setEqpmtApprovalEnabled] = useState(false);
  const [uploadDocumentsList, setuploadDocuments] = useState([]);

  const columns = [
    {
      title: 'File name',
      dataIndex: 'name',
      width: 400,
      render: (value) => (
        <p className="approval-file-name" title={value}>
          {value}
        </p>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: 200,
      render: (value) => (value ? makeTitle(value) : '-'),
    },
    {
      title: 'Sub Folder',
      dataIndex: 'sub_folder',
      width: 200,
      render: (value) => (value ? makeTitle(value) : '-'),
    },
    {
      title: 'Equipment',
      dataIndex: 'equipment',
      width: 200,
      render: (value) => (value ? allPackages.find((pkg) => pkg.id === value)?.name : '-'),
    },
    {
      title: 'Due Date',
      width: 200,
      dataIndex: 'approval_due_time',
      render: (value) => (value ? value.format('YYYY-MM-DD') : '-'),
    },
    {
      title: 'Assignee',
      width: 200,
      dataIndex: 'assignee',
      render: (value, record) => {
        if (!value) return '-';
        const pkg = allPackages.find((pkg) => pkg.id === record.equipment);
        const assignee = pkg.team_members.find((member) => member.id === value);
        return `${assignee.first_name} ${assignee.last_name}`;
      },
    },
    {
      title: '',
      dataIndex: 'uid',
      render: (value, record) => (!record.is_uploaded ? (
        <Tooltip title="Delete">
          <Popconfirm
            okText="Yes"
            onConfirm={() => deleteDocument(value)}
            title="Are your sure?"
          >
            <DeleteTwoTone
              twoToneColor="#f56666"
              style={{ fontSize: 18, cursor: 'pointer' }}
            />
          </Popconfirm>
        </Tooltip>
      ) : null),
    },
  ];

  useEffect(() => {
    const pkg = selectedEquipment || defaultPackage;
    const hasApproval = pkg?.package_type !== 'individual_equiment_without_approval_drwng';
    setEquipmentType('');
    setEqpmtApprovalEnabled(hasApproval);
    form.setFieldsValue({ sub_folder: null });
  }, [selectedEquipment?.id]);

  useEffect(() => {
    if (isEquipmentDocuments) {
      form.setFieldsValue({
        equipment: defaultPackage?.name,
        category: DOCUMENT_CATEGORIES.EQUIPMENT_DOCUMENTS,
      });
      setSelectedEquipment({ ...defaultPackage });
      setCategory(DOCUMENT_CATEGORIES.EQUIPMENT_DOCUMENTS);
    }
  }, []);

  const deleteDocument = (uid) => {
    const idx = documentsToUpload.findIndex((item) => item.uid === uid);
    const selectedIdx = selectedRecords.findIndex((item) => item.uid === uid);
    if (idx > -1) {
      documentsToUpload.splice(idx, 1);
      setDocumentsToUpload([...documentsToUpload]);
      message.success('Removed');
    }
    if (selectedIdx > -1) {
      selectedRecords.splice(selectedIdx, 1);
      setSelectedRecords([...selectedRecords]);
    }
  };

  const resetState = () => {
    setLoader(false);
    setEquipmentType('');
    setCategory('');
  };

  const onUploadDocuments = async (values, _documentsToUpload) => {
    try {
      setLoader(true);
      if (equipmentType === APPROVAL_TYPES_V2.APPROVAL) {
        await submitForApproval(values, _documentsToUpload);
      } else if (category === DOCUMENT_CATEGORIES.EQUIPMENT_DOCUMENTS) {
        await uploadEquipmentDocuments(values, _documentsToUpload);
      } else {
        await uploadProjectDocuments(values, _documentsToUpload);
      }
      resetState();
      await getAllDocuments();
    } catch (error) {
      setLoader(false);
    }
  };

  const onSaveDocuments = async (values) => {
    try {
      if (!selectedRecords.length) {
        message.warning('Please select documents');
        return;
      }
      const _documentsToUpload = documentsToUpload.map((item) => {
        const foundDocument = selectedRecords.some((i) => i.uid === item.uid);
        if (foundDocument) {
          return {
            ...foundDocument,
            ...item,
            ...values,
            is_uploaded: true,
          };
        }
        return item;
      });
      setSelectedRecords(
        selectedRecords.map((item) => ({
          ...item,
          ...values,
        })),
      );
      if (values.sub_folder === 'single_lines' || values.sub_folder === 'site_plan') {
        const isAllFileArePdf = selectedRecords.every(
          (file) => file?.name?.split('.')?.pop() === 'pdf',
        );
        if (!isAllFileArePdf) {
          message.warning("All file should be PDF's for singlelines & site plan");
          return;
        }
      }
      await onUploadDocuments(values, _documentsToUpload);
      setDocumentsToUpload([..._documentsToUpload]);
    } catch (error) {
      setLoader(false);
    }
  };

  const submitForApproval = async (values, _documentsToUpload) => {
    const { approval_due_time, assignee, sub_folder } = values;
    const formData = new FormData();
    selectedRecords.map((file) => formData.append('files', file.originFileObj));
    formData.append('project_id', projectId);
    const approvalDocuments = await uploadDocuments(projectId, selectedEquipment?.id, formData);

    if (approvalDocuments.success) {
      form.resetFields();
      setSelectedRecords([]);
      setuploadDocuments(_documentsToUpload);
      const approvals = approvalDocuments.data.map((item) => ({
        ...item,
        assignee,
        package: selectedEquipment?.id,
        approval_file_name: item.fileName,
        approval_file_path: item.path,
        document_type: "Approval",
        approval_due_time:
                    getDocumentType(sub_folder) === 'Approval'
                      ? moment(`${approval_due_time.format('YYYY-MM-DD')} 23:59:00`).format(
                        'YYYY-MM-DD HH:mm:ssZ',
                      )
                      : null,
      }));
      await Promise.all([
        sendForApproval(selectedEquipment?.id, approvals),
        sendNotificationForApproval(approvals),
      ]);
    } else {
      message.error('Error in uploading file');
    }
  };

  const sendNotificationForApproval = async (approvals) => {
    const uploadedDocuments = approvals.map(({ approval_file_name }) => approval_file_name);
    const files = uploadedDocuments.length > 1 ? uploadedDocuments.join(', ') : uploadedDocuments[0];
    const approvalMessage = `Files sent for approval: ${files}`;
    const message = {
      package: approvals[0].package,
      message: approvalMessage,
    };
    await createNotification(projectId, message);
  };

  const uploadEquipmentDocuments = async (values, _documentsToUpload) => {
    const { sub_folder } = values;
    // if (sub_folder === 'videos') {
    //     const formData = new FormData();
    //     selectedRecords.map((file) => formData.append('files', file.originFileObj));
    //     formData.append('project', projectId);
    //     formData.append('package', selectedEquipment?.id);
    //     const res = await saveEquipmentDocsForVideos(formData);
    //     if (res.success) {
    //         message.success('Uploaded Successfully');
    //     } else {
    //         notification.error({
    //             title: 'Error on Upload',
    //             message: res.message || 'File Error',
    //         });
    //     }
    // }
    const formData = new FormData();
    selectedRecords.map((file) => formData.append('files', file.originFileObj));
    formData.append('is_package', equipmentType === EQUIPMENT_DOCUMENT_TYPES.PACKAGE);
    formData.append('dir_key', sub_folder);
    formData.append('project_id', projectId);
    formData.append('id', selectedEquipment?.id);

    const res = await saveEquipmentDocs(projectId, selectedEquipment?.id, formData);
    if (res.success) {
      message.success('Uploaded Successfully');
      if (equipmentType === APPROVAL_TYPES.INFORMATION_APPROVAL || equipmentType === APPROVAL_TYPES_V2.APPROVAL) {
        await submitForApproval(values, _documentsToUpload);
      }
      form.resetFields();
      setSelectedRecords([]);
      setuploadDocuments(_documentsToUpload);
    } else {
      form.resetFields();
      notification.error({
        title: 'Error on Upload',
        message: res.message || 'File Error',
      });
    }
  };

  const uploadProjectDocuments = async (values, _documentsToUpload) => {
    const { sub_folder } = values;
    const formData = new FormData();
    selectedRecords.map((file) => formData.append('files', file.originFileObj));
    formData.append('dir_key', sub_folder);
    formData.append('id', projectId);
    let res;
    if (sub_folder === 'single_lines' || sub_folder === 'site_plan') {
      res = await saveSlineEquipmentDocs(projectId, formData);
    } else {
      res = await saveProjectDocs(projectId, formData);
    }
    if (res.success) {
      message.success('Uploaded Successfully');

      if (equipmentType === APPROVAL_TYPES.INFORMATION_APPROVAL) {
        await submitForApproval(_documentsToUpload);
      }
      form.resetFields();
      setSelectedRecords([]);
      setuploadDocuments(_documentsToUpload);
    } else {
      form.resetFields();
      notification.error({
        title: 'Error on Upload',
        message: res?.message || 'File Error',
      });
    }
  };

  const getDocumentType = (folder) => {
    if (folder === 'approval_drawings') {
      return 'Approval';
    }

    if (folder === 'approval') {
      return 'Approval';
    }

    if (folder === 'information_for_approval') {
      return 'Information';
    }

    return null;
  };

    return (
        <Modal open={visible} width={1300} onCancel={onCancel} footer={null} centered>
            <Spin spinning={loader}>
                <Row gutter={30}>
                    <Col span={16}>
                        <div className="card-heading d-flex justify-space-between">
                            <span>
                                Upload Documents
                                <Tooltip title="Upload Pre-Construction & Manufacture related files here">
                                    <QuestionCircleOutlined className="cursor-pointer ml-10" />
                                </Tooltip>
                            </span>
                            <Space>
                                <Button
                                    type="default"
                                    shape="round"
                                    className="main-upload-btn"
                                    onClick={() => setDocumentsToUpload([])}
                                >
                                    Clear
                                </Button>
                            </Space>
                        </div>
                        <Collapse
                            bordered={false}
                            defaultActiveKey={['1', '2']}
                            className="site-collapse-custom-collapse"
                        >
                            <Panel
                                key="1"
                                header="Documents To Upload"
                                className="site-collapse-custom-panel-grey"
                            >
                                <Table
                                    className="taglist-table"
                                    size="small"
                                    rowSelection={{
                                        type: 'checkbox',
                                        selectedRowKeys: selectedRecords.map(({ uid }) => uid),
                                        onChange: (_, selectedRows) => {
                                            setSelectedRecords(selectedRows);
                                        },
                                    }}
                                    columns={columns}
                                    rowKey="uid"
                                    dataSource={documentsToUpload.filter((d) => !d?.is_uploaded)}
                                    pagination={documentsToUpload.length > 10}
                                />
                            </Panel>
                            <Panel
                                key="2"
                                header="Uploaded Documents"
                                className="site-collapse-custom-panel"
                            >
                                <Table
                                    className="taglist-table"
                                    size="small"
                                    columns={columns.filter((c) => c.dataIndex !== 'uid')}
                                    rowKey="uid"
                                    dataSource={uploadDocumentsList?.filter((d) => d.is_uploaded)}
                                    pagination={uploadDocumentsList?.length > 10}
                                />
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={8}>
                        <Form layout="vertical" form={form} onFinish={onSaveDocuments}>
                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Please select"
                                    onChange={(value) => {
                                        setCategory(value);
                                        form.setFieldsValue({ sub_folder: '' });
                                    }}
                                >
                                    {Object.values(DOCUMENT_CATEGORIES).map((item) => (
                                        <Option key={item} value={item}>
                                            {makeTitle(item)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {category === DOCUMENT_CATEGORIES.PROJECT_DOCUMENTS && (
                                <Form.Item
                                    label="Type"
                                    name="sub_folder"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Please select">
                                        {Object.values(PROJECT_SUB_FOLDERS).map((item) => (
                                            <Option key={item} value={item}>
                                                {makeTitle(item)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                            {category === DOCUMENT_CATEGORIES.EQUIPMENT_DOCUMENTS && (
                                <>
                                    <SelectWithAdditonaliList
                                        required
                                        form={form}
                                        projectId={projectId}
                                        searchFieldName="equipment"
                                        setValue={setSelectedEquipment}
                                    />
                                    <Form.Item
                                        label="Type"
                                        name="sub_folder"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Please select"
                                            listHeight={500}
                                            onChange={(value) => setEquipmentType(value)}
                                        >
                                            <OptGroup label="Documents">
                                                {Object.values(EQUIPMENT_SUB_FOLDERS).map(
                                                    (item) => (
                                                        <Option key={item} value={item}>
                                                            {makeTitle(item)}
                                                        </Option>
                                                    ),
                                                )}
                                            </OptGroup>
                                              <OptGroup label="Approval Process">
                                                  {Object.values(APPROVAL_TYPES_V2).map((item) => (
                                                      <Option key={item} value={item} disabled={!hasEqpmtApprovalEnabled}>
                                                          {!hasEqpmtApprovalEnabled ? (
                                                            <Tooltip title="Approval Required">
                                                              {makeTitle(item)}
                                                            </Tooltip>
                                                          ) : makeTitle(item)}
                                                      </Option>
                                                  ))}
                                              </OptGroup>
                                        </Select>
                                    </Form.Item>
                                </>
                            )}
                            {equipmentType === APPROVAL_TYPES.APPROVAL_DRAWINGS || equipmentType === APPROVAL_TYPES_V2.APPROVAL && (
                                <>
                                    <Form.Item
                                        label="Due Date"
                                        name="approval_due_time"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                    >
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                </>
                            )}
                        </Form>
                        <Divider orientation="left">Selected Documents</Divider>
                        <List
                            size="small"
                            bordered
                            dataSource={selectedRecords}
                            renderItem={(item) => (
                                <List.Item>
                                    <Typography.Text>{item.name}</Typography.Text>
                                </List.Item>
                            )}
                        />
                        <div className="text-right mt-30">
                            <Button type="primary" onClick={form.submit}>
                                Upload
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}
