export const FETCH_BIDDING_PROJECT_URL = `/bidding/main_project/`;
export const CRAETE_BIDDING_PROJECT_URL = '/bidding/main_project/';
export const GET_BID_PROJECT_FINANCIAL_SUMMARY = `/bidding/main_project/projectId/finance/finance-summary/`;
export const FETCH_BID_VENDOR_ESTIMATIONS =
    '/bidding/main_project/{projectId}/finance/vendor-estimates/';
export const FETCH_BID_PROJECT_FINANCIAL_SUMMARY =
    '/bidding/main_project/{projectId}/finance/finance-summary/';
export const CREATE_BIDDING_VENDOR_ESTIMATION =
    '/bidding/main_project/{projectId}/finance/vendor-estimates/';

/* ===== Contractors Endpoints ===== */
export const FETCH_CONTRACTORS_VENDOR_QUOTES =
    '/bidding/contractor_project/{contractorId}/finance/vendor-estimates/';
export const FETCH_CONTRACTORS_VENDORS_SUMMARY =
    '/bidding/contractor_project/{contractorId}/finance/finance-summary/';
export const CREATE_CONTRACTORS = '/bidding/contractor_project/';
export const UPDATE_CONTRACTORS_VENDOR_QUOTE = `${FETCH_CONTRACTORS_VENDOR_QUOTES}{vendorQuoteId}/`;

export const GET_MAIN_PROJECT_DOC_LIST = `/bidding/main_project/{projectId}/bid-documents/`;
export const UPLOAD_MAUN_PROJECT_VENDOR_DOC =
    '/bidding/main_project/{projectId}/finance/vendor-estimates/{vendorId}/documents/';
export const GET_VENDOR_ESTIMATION_PROJECT_DOCUMENTS =
    '/bidding/main_project/{projectId}/finance/vendor-estimates/documents/';

export const CLONE_CONTACTOR_FINANCIALS_FROM_PROJECT =
    '/bidding/contractor_project/{contractorId}/clone-project-financials/';
export const DELETE_CONTRACTOR_VENDOR_QUOTES =
    '/bidding/contractor_project/{contractorId}/finance/vendor-estimates/';
export const UPLOAD_CONTRACTOR_DOCUMENTS =
    '/bidding/contractor_project/{contractorId}/contractor-documents/';
export const GET_CONTRACTOR_DOCUMENTS =
    '/bidding/contractor_project/{contractorId}/contractor-documents/';
export const GET_CONTRACTOR_FINANCIAL_ESTIMATE_DOCUMENTS =
    '/bidding/contractor_project/{contractorId}/finance/vendor-estimates/documents/';

export const GET_CONTRACTOR_FINANCIAL_ESTIMATE_DETAIL =
    '/bidding/contractor_project/{contractorId}/finance/vendor-estimates/{estimateId}/';

export const UPDATE_CONTRACTOR_WIN_STATUS = '/bidding/contractor_project/bid-won/';

export const UPDATE_BIDDING_USER = `/bidding/main_project/{projectId}/users/`;

export const AUTO_TRANSFER_PROJECT = `/bidding/contractor_project/{projectId}/auto-tranfer-project/`;

export const CONTRACTORS_PROJECTS = `/bidding/main_project/{projectId}/contractor-projects/`;
