import _ from 'lodash';
import {
    ACTIVE_PROJECT,
    ACTIVE_PROJECT_TAB,
    HIDE_LOADER,
    SHOW_LOADER,
    SHOW_NOTIFICATION,
    RESET_STATE,
    PROJECT_DOCS,
    PROJECT_SETUP,
    MAP_VIEW,
    TITLE_STATE,
    PROJECTS_LIST,
    FETCH_PROJECTS_START,
    FETCH_PROJECTS_SUCCESS,
    FETCH_PROJECTS_FAILURE,
    SHOW_SIDEBAR,
    SHOW_BIDDING_SIDEBAR,
    ENABLE_EVENT,
    ACTIVE_SUB_PROJECT,
    GET_BIDDING_PROJECTS,
} from './types';

const initialState = {
    loader: false,
    notification: {
        open: false,
        message: '',
        description: '',
    },
    projects: [],
    fetching_projects: false,
    error: null,
    activeProject: null,
    activeSubProject: null,
    activeProjectTab: 'insights',
    projectDocs: [],
    mapView: true,
    createNewProject: true,
    titleValue: 'Construction',
    showSidebar: false,
    showBiddingSidebar: false,
    biddingProjects: [],
};

export const commonReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_LOADER:
            return { ...state, loader: payload };

        case HIDE_LOADER:
            return { ...state, loader: true };

        case SHOW_NOTIFICATION:
            return { ...state, notification: { ...payload } };

        case ACTIVE_PROJECT:
            return { ...state, activeProject: payload };

        case ACTIVE_SUB_PROJECT:
            return { ...state, activeSubProject: payload };

        case GET_BIDDING_PROJECTS:
            return { ...state, biddingProjects: payload };

        case ACTIVE_PROJECT_TAB:
            return { ...state, activeProjectTab: payload };

        case PROJECT_DOCS:
            return { ...state, projectDocs: payload };

        case PROJECT_SETUP:
            return { ...state, createNewProject: payload };

        case PROJECTS_LIST:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    list: payload,
                },
            };

        case FETCH_PROJECTS_START:
            return {
                ...state,
                fetching_projects: true,
            };

        case FETCH_PROJECTS_SUCCESS:
            // Sort Logic for Default rendering of projects
            const projectsSorted = _.orderBy(
                payload,
                [
                    function (item) {
                        return item.is_finished;
                    },
                    [(item) => item.name.toLowerCase()],
                ],
                ['asc', 'desc'],
            );
            return {
                ...state,
                projects: projectsSorted,
                fetching_projects: false,
            };

        case FETCH_PROJECTS_FAILURE:
            return {
                ...state,
                fetching_projects: false,
            };

        case MAP_VIEW:
            return { ...state, mapView: payload };

        case RESET_STATE:
            return initialState;

        case TITLE_STATE:
            return { ...state, titleValue: payload };

        case SHOW_SIDEBAR:
            return { ...state, showSidebar: payload };

        case SHOW_BIDDING_SIDEBAR:
            return { ...state, showBiddingSidebar: payload };
        default:
            return state;
    }
};
