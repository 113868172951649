import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { QuestionCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Modal, Row, Space, Spin, Tooltip, message, Popconfirm } from 'antd';
import _ from 'lodash';
import { DISPLAY_DATE_FORMAT, renderAmount } from '../../../../../../../static/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
    getBiddingFinancialVendorItems,
    getBiddingProjectFinancialSummary,
} from '../../../../../../store/bidding-management';
import { AlectifyTable } from '../../../../../../components';
import { cloneContractorFinancialsFromProject } from '../../../../../../services/bidding-management';

const { Panel } = Collapse;

const VendorQuotesAutoCreation = ({ visible = false, onCancel = () => {} }) => {
    const { projectId, contractorId } = useParams();
    const [loader, setLoader] = useState(false);
    const [selectedQuotes, setSelectedQuotes] = useState([]);
    const [copiedItems, setCopiedItems] = useState([]);
    const [itemsToCopy, setItemsToCopy] = useState([]);
    const [popover, setPopover] = useState(false);

    const {
        financials: { vendors },
    } = useSelector(({ bidding }) => bidding);
    const dispatch = useDispatch();

    const columns = [
        {
            key: 'entry_date',
            title: 'Date',
            dataIndex: 'entry_date',
            render: (value) => moment(value).format(DISPLAY_DATE_FORMAT),
        },
        {
            key: 'vendor',
            title: 'Vendor',
            dataIndex: 'vendor',
            render: (vendor) => vendor?.name || '-',
        },
        {
            key: 'vendor_quote_no',
            title: 'Quote #',
            dataIndex: 'vendor_quote_no',
            render: (value) => value || '-',
        },
        {
            key: 'item_detail',
            title: 'Item Detail',
            dataIndex: 'item_detail',
        },
        {
            key: '5',
            title: 'Sell',
            dataIndex: 'total_sell',
            render: renderAmount,
        },
        {
            key: 'total_cost',
            title: 'Cost',
            dataIndex: 'total_cost',
            render: renderAmount,
        },
        {
            key: 'margin_percent',
            title: 'Margin',
            dataIndex: 'margin_percent',
            render: (value) => `${value} %`,
        },
        {
            key: 'docs_count',
            title: 'Attachment(s)',
            dataIndex: 'docs_count',
            render: (value) => (
                <Space>
                    <FileTextOutlined />
                    {value || 0}
                </Space>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getBiddingProjectFinancialSummary(projectId));
        dispatch(getBiddingFinancialVendorItems(projectId, { page: 1, per_page: 10 }));
    }, []);

    useEffect(() => {
        if (vendors.data.length > 0) {
            setItemsToCopy(vendors.data);
        }
    }, [vendors]);

    const getProjectVendorsQuoteslist = (pagination) => {
        const params = {
            page: pagination?.page || 1,
            per_page: pagination?.per_page || 10,
        };
        dispatch(getBiddingFinancialVendorItems(projectId, params));
    };

    const copySelectedItems = async () => {
        if (selectedQuotes.length === 0) {
            message.error('Please select at least 1 file.');
            return;
        }
        setLoader(true);
        try {
            await cloneContractorFinancialsFromProject(contractorId, {
                vendor_estimate_ids: selectedQuotes?.map((d) => d.id) || [],
            });
            setCopiedItems((prevCopiedItems) => [...prevCopiedItems, ...selectedQuotes]);
            setItemsToCopy((prevItemsToCopy) => {
                return prevItemsToCopy.filter((item) => {
                    return !selectedQuotes.some((selectedItem) => item.id === selectedItem.id);
                });
            });
            setSelectedQuotes([]);
            setLoader(false);
        } catch (ex) {
            setLoader(false);
            message.error(ex?.response?.data?.message || 'Something unexpected happened');
        }
    };

    const onModalClose = () => {
        setCopiedItems([]);
        setSelectedQuotes([]);
        onCancel(copiedItems.length > 0);
    };

    const onCancelClick = () => {
        setPopover(false);
        setTimeout(copySelectedItems, 100);
    };

    return (
        <Modal open={visible} width={1300} onCancel={onModalClose} footer={null} centered>
            <Spin spinning={loader}>
                <Row gutter={30}>
                    <Col span={16}>
                        <div className="card-heading d-flex justify-space-between">
                            <span>
                                Auto Create Vendor Quotes
                                <Tooltip title="Upload Pre-Construction & Manufacture related files here">
                                    <QuestionCircleOutlined className="cursor-pointer ml-10" />
                                </Tooltip>
                            </span>
                        </div>
                        <Collapse
                            bordered={false}
                            defaultActiveKey={['1', '2']}
                            className="site-collapse-custom-collapse"
                        >
                            <Panel
                                key="1"
                                header="Items To Copy"
                                className="site-collapse-custom-panel-grey"
                            >
                                <AlectifyTable
                                    selectRows={true}
                                    columns={columns}
                                    loading={vendors.fetching}
                                    total={vendors.meta?.total_count}
                                    dataSource={itemsToCopy || []}
                                    onRowSelectionAction={(selected) => {
                                        setSelectedQuotes(selected);
                                    }}
                                    defaultPageSize={10}
                                    onDataFetch={getProjectVendorsQuoteslist}
                                    // showSizeChanger={false}
                                    // onMultipleDelete={(ids) => deleteEstimations(ids)}
                                />
                            </Panel>
                            <Panel
                                key="2"
                                header="Copied Items"
                                className="site-collapse-custom-panel"
                            >
                                <AlectifyTable
                                    className="taglist-table"
                                    size="small"
                                    columns={columns}
                                    loading={vendors.fetching}
                                    dataSource={copiedItems || []}
                                    selectRows={false}
                                />
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={8}>
                        <Card title="Selected Items">
                            <AlectifyTable
                                className="taglist-table"
                                size="small"
                                columns={columns.filter(
                                    (d) =>
                                        d.dataIndex === 'vendor' ||
                                        d.dataIndex === 'vendor_quote_no' ||
                                        d.dataIndex === 'item_detail',
                                )}
                                loading={vendors.fetching}
                                dataSource={selectedQuotes || []}
                                // showPagination={false}
                                total={selectedQuotes.length}
                                selectRows={false}
                                defaultPageSize={10}
                            />
                            <div className="text-right mt-30">
                                <Popconfirm
                                    title="This will override any existing Items. Are you sure? "
                                    onConfirm={onCancelClick}
                                    onCancel={() => setPopover(false)}
                                    okText="Yes"
                                    cancelText="No"
                                    open={popover}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => setPopover(true)}
                                        disabled={selectedQuotes.length === 0}
                                    >
                                        Copy
                                    </Button>
                                </Popconfirm>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
};

export default VendorQuotesAutoCreation;
