import React from 'react';
import { Avatar, Space, Tooltip } from 'antd';
import { isPackagesActive, PACKAGES_TYPES } from '../../../../static/utils';

export default () =>
    React.useMemo(() => [
        {
            title: 'Ref #',
            dataIndex: 'reference_number',
            width: 100,
            sorter: false,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Line #',
            dataIndex: 'line_number',
            width: 140,
            sorter: false,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Tag Name',
            dataIndex: 'name',
            width: 150,
            // ...getColumnSearchProps('name'),
            render: (text, record) => (
                <Space size="middle">
                    <Avatar
                        src={
                            record.tag_image_url ||
                            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlBRzUn5IDV4IYZHfu5NUJ7OaY9WEMTTWOQQ&usqp=CAU'
                        }
                    />
                    <Tooltip placement="topLeft" title={text}>
                        <p className="mb-0 tagName-master-td" title={text}>
                            {text}
                        </p>
                    </Tooltip>
                </Space>
            ),
            sorter: false,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: 150,
            render: (text, record) => ({
                props: {
                    className: 'description-td',
                },
                children: (
                    <Tooltip placement="topLeft" title={text}>
                        {text}
                    </Tooltip>
                ),
            }),
            sorter: false,
            sortDirections: ['ascend', 'descend'],
        },
        ...[
            isPackagesActive()
                ? {
                      title: 'Package Type',
                      dataIndex: 'packageroom',
                      width: 150,
                      render: (packageroom) => PACKAGES_TYPES[packageroom?.[0]?.package_type],
                      sorter: false,
                      sortDirections: ['ascend', 'descend'],
                  }
                : {},
        ],
    ]);
