import { BellOutlined, EditOutlined, InboxOutlined } from '@ant-design/icons';
import { Badge, Space, Tag, Tooltip } from 'antd';
import { memo } from 'react';
import {
    PROJECT_PERMISSIONS,
    hasProjectPermission,
    isActivityActive,
    isInboxActive,
} from '../../../static/utils';

const SidebarListItem = ({
    title = '',
    desc = '',
    project,
    activityCount = 0,
    inboxCount = 0,
    isDraft = false,
    isCompleted = false,
    onClick = () => {},
    addActiveClass = false,
    editBtn = false,
    onEdit = () => {},
    packages,
    id,
}) => {
    return (
        <li>
            <div
                title={desc}
                className={`list-item-container ${addActiveClass ? 'active' : ''}`}
                onClick={onClick}
            >
                <div className="sub-project-details">
                    <Space size={15}>
                        {isDraft ? (
                            <Tag color="orange" style={{ margin: 0 }}>
                                Draft
                            </Tag>
                        ) : null}
                        {isCompleted ? (
                            <Tag color="green" style={{ margin: 0 }}>
                                Completed
                            </Tag>
                        ) : null}
                        {isInboxActive() &&
                        hasProjectPermission(
                            project?.permissions,
                            PROJECT_PERMISSIONS.CAN_USE_INBOX_PACKAGE_CHAT,
                        ) &&
                        inboxCount ? (
                            <Badge
                                size="small"
                                count={inboxCount}
                                overflowCount={9}
                                color="#ff6f71"
                                title="Inbox"
                            >
                                <InboxOutlined
                                    title="Inbox"
                                    style={{ fontSize: 15, color: '#777' }}
                                />
                            </Badge>
                        ) : null}
                        {isActivityActive() &&
                        hasProjectPermission(
                            project?.permissions,
                            PROJECT_PERMISSIONS.CAN_VIEW_ACTIVITY,
                        ) &&
                        activityCount ? (
                            <Badge
                                size="small"
                                count={activityCount}
                                overflowCount={9}
                                color="#faad14"
                                title="Activity"
                            >
                                <BellOutlined
                                    title="Activity"
                                    style={{ fontSize: 15, color: '#777' }}
                                />
                            </Badge>
                        ) : null}
                    </Space>
                </div>
                <div className="list-detail">
                    <Tooltip title={title}>
                        <span className="sidebar-list-text">{title}</span>
                    </Tooltip>
                    {editBtn && !isCompleted ? (
                        <Tooltip title="Edit Subproject">
                            <button className="edit-icon" onClick={onEdit}>
                                <EditOutlined style={{ fontSize: 14 }} />
                            </button>
                        </Tooltip>
                    ) : null}
                </div>
            </div>
        </li>
    );
};

export default memo(SidebarListItem);
