// React imports
import { useState } from "react";

// Ant imports
import { Popover, Typography } from "antd";

// Custom components
import { MarkerHoveredIcon, MarkerIcon } from "../icons/custom-icons";

// Stylesheet import
import "./marker.scss";

export const MapMarker = (props) => {
  const { onClick, project } = props;
  const [isHovered, setHovered] = useState(false);

  const content = (
    <div className="cursor-pointer" onClick={() => project && onClick(project)}>
      <Typography.Paragraph>{project?.description || "-"}</Typography.Paragraph>
    </div>
  );

  const title = (
    <Typography.Paragraph
      onClick={() => project && onClick(project)}
      className="cursor-pointer"
    >
      {project?.name || "-"}
    </Typography.Paragraph>
  );

  return (
    <>
      {project ? (
        <Popover
          content={content}
          title={title}
          trigger="hover"
          placement="topLeft"

        >
          <div
            style={{ position: "relative" }}
            onClick={() => onClick(project)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <div style={{ display: isHovered ? "none" : "block" }}>
              <MarkerIcon />
            </div>
            <div style={{ display: isHovered ? "block" : "none" }}>
              <MarkerHoveredIcon />
            </div>
          </div>
        </Popover>
      ) : (
        <Popover title={props?.title || "-"} trigger="hover" placement="top">
          <div
            style={{ position: "relative" }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <div style={{ display: isHovered ? "none" : "block" }}>
              <MarkerIcon />
            </div>
            <div style={{ display: isHovered ? "block" : "none" }}>
              <MarkerHoveredIcon />
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};

export default MapMarker;
