export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const ACTIVE_PROJECT = 'ACTIVE_PROJECT';
export const ACTIVE_PROJECT_TAB = 'ACTIVE_PROJECT_TAB';
export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const PROJECTS_LIST = 'PROJECTS_LIST';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';
export const RESET_STATE = 'RESET_STATE';
export const PROJECT_DOCS = 'PROJECT_DOCS';
export const PROJECT_SETUP = 'PROJECT_SETUP';
export const MAP_VIEW = 'MAP_VIEW';
export const TITLE_STATE = 'TITLE_STATE';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const SHOW_BIDDING_SIDEBAR = 'SHOW_BIDDING_SIDEBAR';
export const ENABLE_EVENT = 'ENABLE_EVENT';
export const ACTIVE_SUB_PROJECT = 'ACTIVE_SUB_PROJECT';
export const GET_BIDDING_PROJECTS = "GET_BIDDING_PROJECTS";
