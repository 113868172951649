import moment from 'moment';
import { memo } from 'react';
import { Card, Col, Row, Space, Switch, Tag } from 'antd';
import {
    displayValue,
    DISPLAY_DATE_FORMAT,
    renderAmount,
    renderDate,
    SERVER_DATE_FORMAT,
} from '../../../static/utils';

const EquipmentDetails = memo(({ record, hideManualScheudle = false }) => {
    return (
        <>
            <Card
                title="Main Information"
                className="mb-20 verticial-form-display"
                extra={!hideManualScheudle && ((
                    <Space>
                        <label>Manual Schedule:</label>
                        <Tag
                            className="custom-tag"
                            style={!record?.is_manual_tracking ? { color: '#fff' } : {}}
                            color={record?.is_manual_tracking ? '#1dbf73' : '#efa84a'}
                        >
                            {record?.is_manual_tracking ? 'Yes' : 'No'}
                        </Tag>
                    </Space>
                ))}>
                <Row gutter={15}>
                    <Col span={6}>
                        <div className="property">
                            <div className="title">
                                Ref #:
                                <span className="value">
                                    {displayValue(record?.reference_number)}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="property">
                            <div className="title">
                                Line No.:
                                <span className="value">{displayValue(record?.line_number)}</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="property">
                            <div className="title">
                                Tag Name:{' '}
                                <span className="value">{displayValue(record?.name)}</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="property">
                            <div className="title">
                                Manufacturer:
                                <span className="value">
                                    {displayValue(record?.manufacture?.name)}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="property mb-0">
                            <div className="title">
                                Description:
                                <span className="value">{displayValue(record?.description)}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card>
            <Card title="Dates" className="mb-20 verticial-form-display">
                <div className="custom-row">
                    <div className="col">
                        <h1 className="col-title">Order</h1>
                        <div className="property">
                            <div className="title">
                                Order Entry:
                                <span className="value">{renderDate(record?.order_date)}</span>
                            </div>
                        </div>
                        <div className="property">
                            <div className="title">
                                Order Entry Manufacturer:
                                <span className="value">
                                    {renderDate(record?.actual_order_date)}
                                </span>
                            </div>
                        </div>
                        <div className="property">
                            <div className="title">
                                Lead Time (Weeks):
                                <span className="value">
                                    {displayValue(record?.lead_time_weeks)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <h1 className="col-title">Approval</h1>
                        <div className="property">
                            <div className="title">
                                Expected Approval Dwg.:
                                <span className="value">{renderDate(record?.approval_date)}</span>
                            </div>
                        </div>
                        <div className="property">
                            <div className="title">
                                Actual Approval Dwg:
                                <span className="value">
                                    {renderDate(record?.actual_approval_date)}
                                </span>
                            </div>
                        </div>
                        <div className="property">
                            <div className="title">
                                Approved Dwg Returned:
                                <span className="value">
                                    {renderDate(record?.actual_approved_date)}
                                </span>
                            </div>
                        </div>
                        <div className="property">
                            <div className="title">
                                Resubmittal Date:
                                <span className="value">
                                    {renderDate(record?.resubmittal_date)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <h1 className="col-title">Release</h1>
                        <div className="property">
                            <div className="title">
                                Expected Release:
                                <span className="value">{renderDate(record?.release_date)}</span>
                            </div>
                        </div>
                        <div className="property">
                            <div className="title">
                                Actual Release:
                                <span className="value">
                                    {renderDate(record?.actual_release_date)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <h1 className="col-title">Testing</h1>
                        <div className="property">
                            <div className="title">
                                Witness Testing:
                                <span className="value">{renderDate(record?.test_date)}</span>
                            </div>
                        </div>
                        <div className="property">
                            <div className="title">
                                Record Dwgs:
                                <span className="value">
                                    {renderDate(record?.actual_final_date)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <h1 className="col-title">Ship</h1>
                        <div className="property">
                            <div className="title">
                                Customer OnSite Request:
                                <span className="value">
                                    {renderDate(record?.expected_ship_date_by_customer)}
                                </span>
                            </div>
                        </div>
                        <div className="property">
                            <div className="title">
                                Planned Ship:
                                <span className="value">{renderDate(record?.ship_date)}</span>
                            </div>
                        </div>
                        <div className="property">
                            <div className="title">
                                Actual Ship:
                                <span className="value">
                                    {renderDate(record?.actual_ship_date)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Row gutter={15}>
                    <Col span={6}>
                        <div className="property">
                            <div className="title">
                                Manual Schedule:
                                <span className="value">
                                    <Tag
                                        className="custom-tag mt-5"
                                        style={!record?.is_manual_tracking ? { color: '#555' } : {}}
                                        color={record?.is_manual_tracking ? '#1dbf73' : '#eee'}
                                    >
                                        {record?.is_manual_tracking ? 'Yes' : 'No'}
                                    </Tag>
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">
                                Lead Time (Weeks):
                                <span className="value">
                                    {displayValue(record?.lead_time_weeks)}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">
                                Expected Approval Dwg.:
                                <span className="value">{renderDate(record?.approval_date)}</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">
                                Actual Approval Dwg:
                                <span className="value">
                                    {renderDate(record?.actual_approval_date)}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">
                                Approved Dwg Returned:
                                <span className="value">
                                    {renderDate(record?.actual_approved_date)}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">
                                Resubmittal Date:
                                <span className="value">
                                    {renderDate(record?.resubmittal_date)}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">
                                Expected Release:
                                <span className="value">{renderDate(record?.release_date)}</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">
                                Actual Release:
                                <span className="value">
                                    {renderDate(record?.actual_release_date)}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="property">
                            <div className="title">
                                For Approval:
                                <span className="value">
                                    <Tag
                                        className="custom-tag mt-5"
                                        style={!record?.approval_dwg ? { color: '#555' } : {}}
                                        color={record?.approval_dwg ? '#1dbf73' : '#eee'}
                                    >
                                        {record?.approval_dwg ? 'Yes' : 'No'}
                                    </Tag>
                                </span>
                            </div>
                        </div>
                    </Col>
                    {record?.approval_dwg && (
                        <>
                            <Col span={6}>
                                <div className="property">
                                    <div className="title">
                                        Expected Approval Dwg.:
                                        <span className="value">
                                            {renderDate(record?.approval_date)}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="property">
                                    <div className="title">
                                        Actual Approval Dwg:
                                        <span className="value">
                                            {renderDate(record?.actual_approval_date)}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="property">
                                    <div className="title">
                                        Expected Release:
                                        <span className="value">
                                            {renderDate(record?.release_date)}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="property">
                                    <div className="title">
                                        Actual Release:
                                        <span className="value">
                                            {renderDate(record?.actual_release_date)}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="property">
                                    <div className="title">
                                        Witness Testing:
                                        <span className="value">
                                            {renderDate(record?.test_date)}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </>
                    )}
                    <Col span={24}>
                        <div className="property">
                            <div className="title">
                                Comments:
                                <span className="value">{displayValue(record?.comments)}</span>
                            </div>
                        </div>
                    </Col>
            </Row> */}
            </Card>
            <Card className="verticial-form-display" title="Other Details">
                <div className="custom-row no-bordered">
                    <div className="col">
                        <div className="property">
                            <div className="title">
                                Quote No.:
                                <span className="value">{displayValue(record?.quote_number)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="property">
                            <div className="title">
                                Marks:
                                <span className="value">{displayValue(record?.marks)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="property">
                            <div className="title">
                                Catalog No.:
                                <span className="value">
                                    {displayValue(record?.catalog_number)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="property">
                            <div className="title">
                                Price ($):
                                <span className="value">{renderAmount(record?.price)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="property">
                            <div className="title">
                                Quantity:
                                <span className="value">{displayValue(record?.quantity)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="property">
                    <div className="title">
                        Comments:
                        <span className="value">{displayValue(record?.comments)}</span>
                    </div>
                </div>
            </Card>
        </>
    );
});

export default EquipmentDetails;
