import { useEffect } from 'react';
import { Card, Col, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getFinancialVendorItemDetail } from '../../../../../../../store/financials';
import { renderAmount } from '../../../../../../../../static/utils';

const VendorItemInfo = (props) => {
    const dispatch = useDispatch();
    const { vendorItemDetail } = useSelector(({ financials }) => financials);

    useEffect(() => {
        dispatch(getFinancialVendorItemDetail(props.master_project, props.id));
    }, []);

    return (
        <Card title="Vendor Information" className="mb-20" loading={vendorItemDetail?.fetching}>
            <Row gutter={15} className="details-view">
                <Col span={8}>
                    <label>Vendor:</label>
                    <span>{vendorItemDetail?.data?.vendor?.name || '-'}</span>
                </Col>
                <Col span={16}>
                    <label>Item Details:</label>
                    <span>{vendorItemDetail?.data?.item_detail || '-'}</span>
                </Col>
                <Col span={8}>
                    <label>Equipment / Catalog #:</label>
                    <span>{vendorItemDetail.data?.tag_name || '-'}</span>
                </Col>
                <Col span={8}>
                    <label>Quantity:</label>
                    <span>{vendorItemDetail.data?.quantity || '-'}</span>
                </Col>
                <Col span={8}>
                    <label>Unit Price:</label>
                    <span>{renderAmount(vendorItemDetail.data?.unit_price)}</span>
                </Col>
                <Col span={8}>
                    <label>Total Sell:</label>
                    <span>{renderAmount(vendorItemDetail?.data?.total_sell)}</span>
                </Col>
                <Col span={8}>
                    <label>Total Cost:</label>
                    <span>{renderAmount(vendorItemDetail?.data?.total_cost)}</span>
                </Col>
                <Col span={8}>
                    <label>Avg. Margin:</label>
                    <span>{vendorItemDetail?.data?.margin_percent || 0} %</span>
                </Col>
            </Row>
        </Card>
    );
};

export default VendorItemInfo;
