import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { debounce, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    AutoComplete,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Spin,
    Upload,
} from 'antd';
import moment from 'moment';
import {
    BASIC_REQUIRED_VALIDATION_RULES,
    INPUT_DATE_FORMAT,
    PROJECT_COST_REASONS,
    SERVER_DATE_FORMAT,
} from '../../../../../../../static/utils';
import {
    fetchProjectCostInvoice,
    getOrganizations,
    updateProjectCost,
    uploadProjectCostDocuments,
} from '../../../../../../services';
import { getProjectCosts, getProjectCostsSummary } from '../../../../../../store/billing';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const EditProjectCost = memo((props) => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [vendors, setVendors] = useState({
        data: [],
        value: '',
        fetching: false,
    });
    const [invoice, setInvoice] = useState({ data: null, fetching: false, total: 0 });

    const getInvoiceDetails = async () => {
        try {
            setSubmitting(true);
            const params = {
                page: 1,
                per_page: 2,
            };
            const { id, master_project } = props.data;
            const response = await fetchProjectCostInvoice(master_project, id, params);
            if (response?.data?.length) {
                form.setFieldsValue({
                    invoice_number: response.data[0].invoice_number,
                    invoice_date: moment(response.data[0].invoice_date),
                });
            }
            setSubmitting(false);
        } catch (error) {
            console.log(error);
            setSubmitting(false);
        }
    };

    useEffect(() => {
        getInvoiceDetails();
        form.setFieldsValue({
            ...props.data,
            vendor: props.data?.vendor?.name,
            po_date: moment(props.data?.po_date),
        });
    }, []);

    const onSearchVendor = debounce(async (value) => {
        try {
            if (!value) {
                throw new Error();
            }
            setVendors({ ...vendors, fetching: true });
            const response = await getOrganizations({ search: value });
            setVendors({
                ...vendors,
                fetching: false,
                data: response?.data,
                value,
            });
        } catch (error) {
            setVendors({ ...vendors, fetching: false, data: [] });
        }
    }, 500);

    const uploadDocuments = async (projectCostId, values) => {
        try {
            const { invoice_date, invoice_number, documents } = values;
            const formData = new FormData();
            formData.append('invoice_date', invoice_date);
            formData.append('invoice_number', invoice_number);
            documents.fileList.map((file) => formData.append('files', file.originFileObj));
            await uploadProjectCostDocuments(projectId, projectCostId, formData);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            const { invoice_date, invoice_number, documents } = values;
            values.po_date = values.po_date.format(SERVER_DATE_FORMAT);
            setSubmitting(true);
            await updateProjectCost(projectId, props.data.id, values);
            if (documents || invoice_date || invoice_number) {
                values.invoice_date = values?.invoice_date?.format(SERVER_DATE_FORMAT);
                await uploadDocuments(props.data.id, values);
            }
            message.success('Updated Successfully');
            dispatch(getProjectCosts(projectId, { page: 1, per_page: 10 }));
            dispatch(getProjectCostsSummary(projectId));
            setSubmitting(false);
            form.resetFields();
            props.onCancel();
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <Modal
            width={800}
            okText="Update"
            onOk={form.submit}
            open={props.visible}
            title="Edit Vendor Invoice"
            onCancel={props.onCancel}
        >
            <Spin spinning={submitting}>
                <Form layout="vertical" className="mb-20" onFinish={onSubmit} form={form}>
                    <Row gutter={30}>
                        <Col span={8}>
                            <Form.Item
                                label="Date"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                                name="po_date"
                            >
                                <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item
                                label="Reason"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                                name="reason"
                            >
                                <Select placeholder="Please Select">
                                    {Object.keys(PROJECT_COST_REASONS).map((key) => (
                                        <Option value={key} key={key}>
                                            {PROJECT_COST_REASONS[key]}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col span={8}>
                            <Form.Item
                                label="Amount"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                                name="amount"
                            >
                                <Input prefix="$" placeholder="0.00" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Vendor"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                                name="vendor"
                            >
                                <AutoComplete
                                    showSearch
                                    placeholder="Enter Vendor"
                                    options={map(vendors.data, (org) => {
                                        return {
                                            name: org?.name,
                                            value: org?.name,
                                        };
                                    })}
                                    onSearch={onSearchVendor}
                                    notFoundContent={
                                        vendors.fetching ? (
                                            <div className="text-center">
                                                <Spin />
                                            </div>
                                        ) : null
                                    }
                                />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item
                                label="Comments"
                                name="comments"
                            >
                                <Input placeholder="Enter Comments" />
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <h1 style={{ fontSize: 18 }}>Invoice Details</h1>
                    <Row gutter={30}>
                        <Col span={8}>
                            <Form.Item label="Invoice Date" name="invoice_date">
                                <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Invoice #" name="invoice_number">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Documents" name="documents">
                                <Upload
                                    multiple
                                    beforeUpload={() => false}
                                    // onChange={onSelectDocuments}
                                >
                                    <Button icon={<UploadOutlined />}>Select Files</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
});

EditProjectCost.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => {},
};

EditProjectCost.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default EditProjectCost;
