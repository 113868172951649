// React imports
import { useState } from 'react';

// Ant imports
import { Tabs } from 'antd';

// Custom imports
import VendorQuotes from './VendorQuotes';
import BidDocuments from './BidDocuments';
import VendorDocuments from './VendorDocuments';
import BiddingProjectDetail from 'app/components/master-project/biddingProjectDetail';

const BidProjectDetailTabs = () => {
    const [activeTab, setActiveTab] = useState('overview');

    const tabItems = [
        {
            key: 'overview',
            label: 'Overview',
            children: <BiddingProjectDetail />,
        },
        {
            key: 'master-vendor-quotes',
            label: 'Master Vendor Quotes',
            children: <VendorQuotes />,
        },
        {
            key: 'bid-documents',
            label: 'Master Bid Documents',
            children: <BidDocuments />,
        },
        {
            key: 'master-vendor-docs',
            label: 'Master Vendor Documents',
            children: <VendorDocuments />,
        },
    ];

    return (
        <div className="dashboard-tabs">
            <Tabs
                animated={false}
                items={tabItems}
                activeKey={activeTab}
                onChange={setActiveTab}
                destroyInactiveTabPane
                className="project-detail-tabs white-bg-tabs nested-tabs"
            />
        </div>
    );
};

export default BidProjectDetailTabs;
