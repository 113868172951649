import _ from 'lodash';
import moment from 'moment';
import {
  Tooltip, Avatar, Space, Button, Popconfirm,
} from 'antd';
import { DeleteTwoTone, DownloadOutlined, ContainerOutlined } from '@ant-design/icons';
import { DISPLAY_DATETIME_FORMAT, makeAvatar } from '../../../static/utils';

export const historyColumns = ({
  downloadDocument = () => { },
  showHistory = () => { },
}) => [
    {
      title: 'Shared on',
      dataIndex: 'shared_on',
      // sorter: true,
      // sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? moment(value).format(DISPLAY_DATETIME_FORMAT) : ''),
    },
    {
      title: 'Shared By',
      dataIndex: 'shared_by',
      render: (shared_by) => (!_.isEmpty(shared_by) ? (
        <Tooltip title={`${shared_by?.first_name} ${shared_by?.last_name}`}>
          <Avatar
            size="small"
            src={makeAvatar(shared_by?.first_name?.[0], shared_by?.last_name?.[0])}
          />
        </Tooltip>
      ) : (
        ''
      )),
    },
  ];
