import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, Col, Form, Input, message, Modal, Row, Spin } from 'antd';
import { AlectifyTable } from '../../../../../../components';
import { createAutoSubProject } from '../../../../../../services';
import { BASIC_REQUIRED_VALIDATION_RULES } from '../../../../../../../static/utils';
import { REFRESH_PROJECTS_LIST } from '../../../../../../store/projects';

const SubProjectCreation = memo((props) => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (values) => {
        try {
            setSubmitting(true);
            values.vendor_estimate_ids = props.selectedQuotes.map(({ id }) => id);
            await createAutoSubProject(projectId, values);
            // Added a delay because backend takes sometime to create entry in the database and we have to show the entry immediately.
            setTimeout(() => {
                setSubmitting(false);
                form.resetFields();
                props.setSelectedQuotes([]);
                message.success('Created Successfully');
                props.onCancel();
                dispatch({ type: REFRESH_PROJECTS_LIST });
            }, 300);
        } catch (error) {
            setSubmitting(false);
        }
    };
    return (
        <Modal
            width={700}
            okText="Submit"
            onOk={form.submit}
            open={props.visible}
            title="Create Sub Project"
            onCancel={props.onCancel}
            confirmLoading={submitting}
        >
            <Spin spinning={submitting}>
                <Form layout="vertical" form={form} onFinish={onSubmit}>
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item
                                label="Sub Project Name"
                                name="sub_project_name"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Ref #"
                                name="reference_no"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Card>
                    <AlectifyTable
                        loading={false}
                        selectRows={false}
                        columns={[
                            {
                                key: 'tag_name',
                                dataIndex: 'tag_name',
                                title: 'Equipment / Catalog #',
                            },
                            {
                                key: 'quantity',
                                dataIndex: 'quantity',
                                title: 'Quantity',
                            },
                            {
                                key: 'vendor_quote_no',
                                title: 'Quote #',
                                dataIndex: 'vendor_quote_no',
                                render: (value) => value || '-',
                            },
                            {
                                key: 'item_detail',
                                title: 'Item Detail',
                                dataIndex: 'item_detail',
                            },
                        ]}
                        dataSource={props.selectedQuotes}
                        total={props.selectedQuotes.length}
                    />
                </Card>
            </Spin>
        </Modal>
    );
});

SubProjectCreation.defaultProps = {
    visible: false,
    selectedQuotes: [],
    onCancel: () => {},
    setSelectedQuotes: () => {},
};

SubProjectCreation.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    selectedQuotes: PropTypes.array,
    setSelectedQuotes: PropTypes.func,
};

export default SubProjectCreation;
