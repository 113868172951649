import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Col, Row, Form, Input, Button, notification,
} from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { login } from '../../../../app/services';
import { GET_AUTH_USER } from '../../../../app/store/auth';
import { saveToLocal } from '../../../../static/utils';
import signupbanner from '../../../assets/images/signup-banner.jpeg';
import './style.scss';

function Login({ history }) {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await login(values);
      dispatch({ type: GET_AUTH_USER, payload: response?.data });
      saveToLocal('token', response?.data.token);
      saveToLocal('refresh_token', response?.data.refresh_token);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      const msg = {};
      if (ex?.response?.data?.message) {
        msg.message = ex?.response?.data?.message;
        msg.description = 'You have entered the wrong credentials';
      } else {
        msg.message = 'Something went wrong';
        msg.description = 'Something went wrong, please try again!';
      }
      notification.error(msg);
    } finally {
      history.push('/projects');
    }
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      history.push('/projects');
    }
  }, []);

  return (
    <Row className="auth-container height-100vh" align="middle">
      <Col span={12}>
        <img src={signupbanner} className="auth-image" />
      </Col>
      <Col span={12}>
        <div className="auth-form-container">
          <div className="auth-form-header">
            <h3>
              Welcome to
              {' '}
              <span className="color-primary">Alectify!</span>
            </h3>
            <p>Login to your account</p>
          </div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={() => {}}
            validateTrigger="onSubmit"
          >
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please enter your email',
                },
              ]}
            >
              <Input placeholder="Enter your email address here" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please enter valid password',
                },
                {
                  min: 6,
                  message: 'Password must contain atleast 6 characters.',
                },
              ]}
            >
              <Input.Password placeholder="Enter your password here" />
            </Form.Item>

            <Form.Item noStyle>
              <Link style={{ fontSize: 15, marginRight: 20 }} to="/forgot-password">
                Forgot password?
              </Link>
              <Link style={{ fontSize: 15 }} to="/forgot-password">
                Change password
              </Link>
            </Form.Item>

            <Form.Item className="mt-50">
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="btn-lg btn-primary-lg"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
          <div className="mt-50">
            <p style={{ fontSize: 15 }}>
              Don't have an account?
              {' '}
              <Link
                className="btn btn-outline-primary"
                to="/login"
                onClick={(ev) => {
                  ev.stopPropagation();
                  window.open('https://alectify.ai/');
                }}
              >
                Signup
              </Link>
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Login;
