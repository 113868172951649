// React imports
import { useHistory } from 'react-router-dom';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Ant Design components
import { Spin, Typography } from 'antd';

// Lib imports
import { debounce, isEmpty } from 'lodash';

// Custom components
import AlectifyText from 'static/texts.json';
import AlectifyInput from '../alectify-input';
import { BIDDING_MANAGEMENT } from 'static/utils';
import AlectifyEmpty from '../empty/alectifyEmpty';
import { SearchIcon } from '../icons/custom-icons';
import { getBiddingProjects, setShowBiddingSidebar } from 'app/store/common';

// Stylesheet import
import './styles.scss';

const { Text } = Typography;

const BiddingSidebar = ({ onProjectClickHandler }) => {
    const { activeProject } = useSelector(({ bidding }) => bidding);
    const { loader, biddingProjects } = useSelector(({ common }) => common);

    const dispatch = useDispatch();
    const history = useHistory();
    const gotoCreateNewMasterProject = () => {
        dispatch(setShowBiddingSidebar(false));
        history.push(`${BIDDING_MANAGEMENT}/create`);
    };
    const handleSearchChange = debounce((ev) => {
        dispatch(
            getBiddingProjects({
                search: ev.target.value || undefined,
            }),
        );
    }, 500);

    useEffect(() => {
        dispatch(getBiddingProjects());
    }, []);

    return (
        <Spin spinning={loader}>
            <div className="project-sidebar-container">
                <div className="project-sidebar-header">
                    <div className="project-header">
                        <h2>{AlectifyText.BIDDING_MANAGEMENT}</h2>
                    </div>
                    <div className="project-create-new-link" onClick={gotoCreateNewMasterProject}>
                        <Text underline>{AlectifyText.CREATE_NEW}</Text>
                    </div>

                    <AlectifyInput
                        name="Search"
                        type="text"
                        placeholder="Search"
                        onChange={handleSearchChange}
                        prefix={<SearchIcon />}
                        className="alectify-table-search-input"
                        allowClear
                    />
                </div>
                <div className="bidding-sidebar-content">
                    {!isEmpty(biddingProjects) ? (
                        biddingProjects?.map((project, index) => (
                            <div
                                key={index}
                                onClick={() => onProjectClickHandler(project)}
                                className={`bidding-sidebar-box ${
                                    activeProject?.id === project?.id ? 'active-project' : ''
                                }`}
                            >
                                <div className="bidding-sidebar-title">
                                    <span>{project?.name || '-'}</span>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="alectify-centered-container">
                            <AlectifyEmpty description="Project not found" />
                        </div>
                    )}
                </div>
            </div>
        </Spin>
    );
};

export default memo(BiddingSidebar);
