import _ from 'lodash';
import {
  Spin, Modal, Row, Col, Button, Form, Input, Select, AutoComplete, Table, Checkbox,
} from 'antd';
import { useEffect, useState } from 'react';
import { getOrganizations } from '../../../services';
import { AlectifyTable } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { projectUserColumns } from '../columns/projectUserColumns';
import { DEFAULT_START_PAGE, PAGE_SIZE_OPTIONS } from '../../../../static/utils';
import {
  getProjectUsers,
  addPermissionToProject,
  removePermissionFromProject,
} from '../../../store/admin';

function UserPermissionsModal({
  title,
  record,
  visible = false,
  role_templates = [],
  organizationTypes = [],
  roleTypes = [],
  branches = [],
  permissions = [],
  onOk,
  onCancel,
  onFinish,
  updateGroup,
  loading = false,
}) {
  const [newOrganizations, setNewOrganizations] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const {
    project_users, project_users_loading,
  } = useSelector(({ admin }) => admin);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [displayPermissions, setDisplayPermissions] = useState([]);

  useEffect(() => {
    dispatch(getProjectUsers({
      id: record?.id,
      page: DEFAULT_START_PAGE,
      pageSize: 10,
    }));
  }, []);

  const getPermissions = () => {
    const dPermissions = [];
    permissions.map((p) => {
      const hasPermission = !!(selectedRow?.project_permissions.indexOf(p?.codename) >= 0);
       // Filter project permissions
       if (p?.name.includes('Project')) {
        dPermissions.push({
          ...p,
          exists: hasPermission,
        });
      }
    });
    setDisplayPermissions(dPermissions);
  };

  useEffect(() => {
    getPermissions();
  }, [selectedRow, record]);

  const getPermissionStatus = (record) => {
    const p = _.find(displayPermissions, ['id', record?.id]);
    return p.exists;
  };

  const columns = [
    {
      title: 'Features',
      dataIndex: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const a1 = a.name.split(' ')[1];
        const b1 = b.name.split(' ')[1];
        return a1.localeCompare(b1);
      },
      render: (value, rec) => value.split(' ')[1],
    },
    {
      title: 'Enable/Disable',
      dataIndex: 'name',
      render: (value, rec) => (
        <Checkbox
          checked={getPermissionStatus(rec)}
          onChange={(ev) => {
            if (ev?.target?.checked) {
              dispatch(addPermissionToProject({
                projectId: record?.id,
                userId: selectedRow?.user?.id,
                payload: {
                  permissions: [rec?.codename],
                },
              })).then(() => {
                // Local update
                setSelectedRow({
                  ...selectedRow,
                  project_permissions: _.union(selectedRow?.project_permissions, [rec?.codename]),
                });
              });
            } else {
              dispatch(removePermissionFromProject({
                projectId: record?.id,
                userId: selectedRow?.user?.id,
                payload: {
                  permissions: [rec?.codename],
                },
              })).then(() => {
                // Local update
                setSelectedRow({
                  ...selectedRow,
                  project_permissions: _.without(selectedRow?.project_permissions, rec?.codename),
                });
              });
            }
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      title={title}
      centered
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Close"
      maskClosable={false}
      className="equipment-detail-modal"
      footer={null}
      width={800}
    >
      <Row gutter={[15]}>
        <Col span={12}>
          <AlectifyTable
            size="small"
            onDataFetch={(data) => dispatch(getProjectUsers(data))}
            columns={projectUserColumns({
            })}
            isEmailSharing={false}
            onRow={(row) => setSelectedRow(row)}
            onDownloadZip={() => { }}
            onEmailShare={() => { }}
            id={record?.id}
            dataSource={project_users?.data}
            loading={project_users_loading}
            selectRows={false}
            total={project_users?.meta?.total_count}
            defaultPageSize={10}
            key="branch-permissions"
            displayRowClass={(record) => {
              return record?.user?.id === selectedRow?.user?.id ? 'active-row' : '';
            }}
          />
        </Col>
        <Col span={12}>
          {
            selectedRow
              ? (
                <Table
                  onRow={() => { }}
                  columns={(columns)}
                  loading={loading}
                  onChange={(pagination) => {
                    setPage(pagination?.current);
                    setPageSize(pagination?.pageSize);
                  }}
                  onSelect={(record) => { }}
                  pagination={{
                    total: permissions?.length,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    pageSize,
                    showSizeChanger: true,
                    current: page,
                  }}
                  dataSource={displayPermissions.map((d) => ({ ...d, key: d?.id }))}
                />
              )
              : <> Click on row to display user permissions</>
          }
        </Col>
      </Row>
    </Modal>
  );
}

export default UserPermissionsModal;
