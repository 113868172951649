import _ from 'lodash';
import { FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_FAILURE, FETCH_PROJECTS_INITIATE, REFRESH_PROJECTS_LIST } from './types';
import { RESET_STATE } from '../common';

const initialState = {
  loading: false,
  projects: [],
  inboxMsgCount: 0,
  refreshList: false,
};

export const projectsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REFRESH_PROJECTS_LIST:
      return { ...state, refreshList: !state.refreshList };

    case FETCH_PROJECTS_INITIATE:
      return { ...state, loading: true };

    case FETCH_PROJECTS_SUCCESS:
      // Sort Logic for Default rendering of projects
      const projectsSorted = _.orderBy(payload, [
        function (item) { return item.is_finished; },
        [(item) => item.name.toLowerCase()],
      ], ['asc', 'desc']);
      return { ...state, loading: false, projects: projectsSorted };

    case FETCH_PROJECTS_FAILURE:
      return { ...state, loading: false };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
