import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, Col, Progress, Row,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PROGRESS_GRADIENT_COLOR, renderAmount } from '../../../../../../../static/utils';
import { getCustomerPurchaseOrdersSummary } from '../../../../../../store/billing';

const CustomerPurchaseOrderSummary = memo(() => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { customerPurchaseOrdersSummary } = useSelector(({ billings }) => billings);

  useEffect(() => {
    dispatch(getCustomerPurchaseOrdersSummary(projectId));
  }, [projectId]);

    return (
        <Card
            title="Customer Summary"
            loading={customerPurchaseOrdersSummary.fetching}
        >
            <Row gutter={30} className="po-information">
                {/* <Col span={8}>
                    <label>Original Purchase Order:</label>
                    <span>{renderAmount(customerPurchaseOrdersSummary?.data?.originalCost)}</span>
                </Col> */}
                <Col span={12}>
                    <label>Total Sell:</label>
                    <span>{renderAmount(customerPurchaseOrdersSummary?.data?.totalSell)}</span>
                </Col>
                <Col span={12}>
                    <label>Remaining to bill:</label>
                    <span>{renderAmount(customerPurchaseOrdersSummary?.data?.remainingCost)}</span>
                </Col>
                {/* <Col span={8}>
                    <label>Order Change:</label>
                    <span>
                        {customerPurchaseOrdersSummary?.data?.charged > 0 ? '+' : ''}
                        {customerPurchaseOrdersSummary?.data?.charged || 0} %
                    </span>
                </Col> */}
                <Col span={24}>
                    <label>Billed so far: {renderAmount(customerPurchaseOrdersSummary?.data?.billedSoFar)} ({customerPurchaseOrdersSummary?.data?.completed || 0} %)</label>
                    <div className='d-flex'>
                        <Progress
                            percent={customerPurchaseOrdersSummary?.data?.completed || 0}
                            strokeColor={PROGRESS_GRADIENT_COLOR}
                            showInfo={false}
                            status="normal"
                        />
                    </div>
                </Col>
            </Row>
        </Card>
    );
});

export default CustomerPurchaseOrderSummary;
