import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@ant-design/icons';
import { Avatar, message, Space, Tooltip } from 'antd';
import { DISPLAY_DATETIME_FORMAT, makeAvatar } from '../../../../../../../../static/utils';

export default () => {
    const downloadDocument = async ({ signed_url, file_name }) => {
        try {
            await axios.get(signed_url, { headers: { 'Content-Disposition': 'attachment' } });
            saveAs(signed_url, file_name);
        } catch (error) {
            message.error('Error in downloading document');
        }
    };

    return React.useMemo(() => [
        {
            key: 'file_name',
            title: 'File Name',
            dataIndex: 'file_name',
            render: (value, record) => (
                <Tooltip title="Download">
                    <Space className="file_name_link" onClick={() => downloadDocument(record)}>
                        <DownloadOutlined className="cursor-pointer" />
                        <span>{value || ''}</span>
                    </Space>
                </Tooltip>
            ),
        },
        {
            key: 'vendor_estimate',
            title: 'Vendor',
            dataIndex: 'vendor_estimate',
            render: (value, record) => record?.vendor_estimate?.vendor?.name || '-',
        },
        {
            key: 'vendor_estimate',
            title: 'Quote#',
            dataIndex: 'vendor_estimate',
            render: (value, record) => record?.vendor_estimate?.vendor_quote_no || '-',
        },
        {
            key: 'vendor_estimate',
            title: 'Item Detail',
            dataIndex: 'vendor_estimate',
            render: (value, record) => record?.vendor_estimate?.item_detail || '-',
        },
        {
            key: 'created_at',
            title: 'Uploaded At',
            dataIndex: 'created_at',
            render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
        },
        {
            key: 'uploaded_by',
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            align: 'center',
            render: (user) => (
                <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
                    <Avatar
                        size="small"
                        src={user?.image || makeAvatar(user?.first_name?.[0], user?.last_name?.[0])}
                    />
                </Tooltip>
            ),
        },
    ]);
};
