import _ from 'lodash';
import moment from 'moment';
import {
  Tooltip, Avatar, Space, Button, Popconfirm,
} from 'antd';
import { DeleteTwoTone, DownloadOutlined } from '@ant-design/icons';
import { DISPLAY_DATETIME_FORMAT, makeAvatar } from '../../../static/utils';

export const packageColumns = ({
  downloadDocument = () => { },
}) => [
    {
      title: 'Folder',
      dataIndex: 'dir_value',
      isSearchable: true,
    },
    {
      title: 'File Name',
      dataIndex: 'file_name',
      isSearchable: true,
      render: (_, record) => (
        <Space>
          <Tooltip title="Download">
            <Button
              style={{
                border: 'none',
              }}
              data-key="download"
              type="ghost"
              onClick={() => downloadDocument(record)}
            >
              {record?.file_name}
              {' '}
              <DownloadOutlined style={{ fontSize: 18, cursor: 'pointer' }} />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Uploaded at',
      dataIndex: 'created_at',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? moment(value).format(DISPLAY_DATETIME_FORMAT) : ''),
    },
    {
      title: 'Uploaded by',
      align: 'center',
      dataIndex: 'account',
      render: (account) => (!_.isEmpty(account) ? (
        <Tooltip title={`${account?.first_name} ${account?.last_name}`}>
          <Avatar
            size="small"
            src={makeAvatar(account?.first_name?.[0], account?.last_name?.[0])}
          />
        </Tooltip>
      ) : (
        ''
      )),
    },
  ];
