import _ from 'lodash';
import { memo, useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  DATES_KEYS, INPUT_DATE_FORMAT, SERVER_DATE_FORMAT, makeAvatar, DEFAULT_START_PAGE, DEFAULT_PAGE_SIZE,
} from '../../../static/utils';
import { AlectifyTable } from '../../components';
import { historyColumns } from './history_columns';
import { getFileHistory } from '../../store/documents';

function ViewHistoryModal({
  title = '',
  details = {},
  onCancel = () => { },
  visible = false,
  loading = false,
}) {
  const dispatch = useDispatch();
  const {
    file_history,
    file_history_loading,
  } = useSelector(({ documents }) => documents);

  /**
     * Handle Project level Search
     */

  const fetchFileHistory = () => {
    dispatch(getFileHistory({
      projectId: details?.id,
      type: details?.type,
      page: DEFAULT_START_PAGE,
      pageSize: 20,
    }));
  };

  useEffect(() => {
    fetchFileHistory();
  }, [details?.id]);

  const cancleHandler = () => {
    onCancel();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      footer={null}
      onOk={() => { }}
      onCancel={cancleHandler}
      confirmLoading={loading}
      style={{ minWidth: '1000px !important' }}
      className="review-room-modal"
    >
      <Spin spinning={loading}>
        <AlectifyTable
          size="small"
          onDataFetch={fetchFileHistory}
          columns={historyColumns({
            downloadDocument: () => { },
          })}
          isDownloadZip={false}
          isEmailSharing={false}
          onDownloadZip={() => { }}
          onEmailShare={() => { }}
          id={details?.id}
          dataSource={file_history?.data}
          loading={file_history_loading}
          rowSelection={false}
          selectRows={false}
          onRowSelectionAction={() => { }}
          total={file_history?.meta?.total_count}
          key={`${details?.id}-history`}
          showPagination={false}
        />
      </Spin>
    </Modal>
  );
}

export default memo(ViewHistoryModal);
