import { message, notification } from 'antd';
import {
    CLEAR_ACTIVE_CONTRACTOR,
    CLEAR_BIDDING_ACTIVE_PROJECT,
    CLEAR_SELECTED_BIDDING_PROJECT,
    CREATE_BIDDING_PROJECT_FAILURE,
    CREATE_BIDDING_PROJECT_INITIATE,
    CREATE_BIDDING_PROJECT_SUCCESS,
    CREATE_CONTRACTOR_FAILURE,
    CREATE_CONTRACTOR_INITIATE,
    CREATE_CONTRACTOR_SUCCESS,
    FETCH_BIDDING_PROJECTS_FAILURE,
    FETCH_BIDDING_PROJECTS_INITIATE,
    FETCH_BIDDING_PROJECTS_SUCCESS,
    FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_FAILURE,
    FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_INITATE,
    FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_SUCCESS,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_FAILURE,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_INITIATE,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_SUCCESS,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_FAILURE,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_INITIATE,
    FETCH_BIDDING_VENDOR_ESTIMATIONS_SUCCESS,
    FETCH_CONTRACTOR_BY_ID_FAILURE,
    FETCH_CONTRACTOR_BY_ID_INITIATE,
    FETCH_CONTRACTOR_BY_ID_SUCCESS,
    FETCH_CONTRACTOR_DOCUMENTS_FAILURE,
    FETCH_CONTRACTOR_DOCUMENTS_INITIATE,
    FETCH_CONTRACTOR_DOCUMENTS_SUCCESS,
    FETCH_MAIN_PROJECT_DETAIL_INITIATE,
    FETCH_MAIN_PROJECT_DETAIL_SUCCESS,
    FETCH_MAIN_PROJECT_DOCUMENTS_FAILURE,
    FETCH_MAIN_PROJECT_DOCUMENTS_INITIATE,
    FETCH_MAIN_PROJECT_DOCUMENTS_SUCCESS,
    FETCH_ORGANIZATION_FAILURE,
    FETCH_ORGANIZATION_INITIATE,
    FETCH_ORGANIZATION_SUCCESS,
    GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_FAILURE,
    GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_INITIATE,
    GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_SUCCESS,
    GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_FAILURE,
    GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_INITIATE,
    GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_SUCCESS,
    SET_ACTIVE_CONTRACTOR,
    SET_BIDDING_ACTIVE_PROJECT,
    UPDATE_BIDDING_PROJECT_FAILURE,
    UPDATE_BIDDING_PROJECT_INITIATE,
    UPDATE_BIDDING_PROJECT_SUCCESS,
    UPDATE_CONTRACTOR_BY_ID_FAILURE,
    UPDATE_CONTRACTOR_BY_ID_INITIATE,
    UPDATE_CONTRACTOR_BY_ID_SUCCESS,
} from '.';
import { getOrganizations } from '../../services';
import {
    createContractors,
    fetchBiddingProjectFinancialSummary,
    fetchBiddingProjects,
    fetchBiddingVendorEstimations,
    fetchBiddingVendorEstimationsById,
    fetchContractor,
    fetchMainProjectDocuments,
    fetchProjectDetailById,
    postMainProjectAPI,
    updateContractorById,
    updateMainProjectAPI,
    vendorEstimatesDocumentMainProject,
    fetchAllContractorDocs,
    fetchContractorFinancialDocs,
} from '../../services/bidding-management';
import { BIDDING_MANAGEMENT, CONTRACTORS } from '../../../static/utils';
import { MESSAGES } from '../../../static/utils/messages';

export const getAllBiddingProjects = (params) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_BIDDING_PROJECTS_INITIATE });
        const response = await fetchBiddingProjects(params);
        dispatch({ type: FETCH_BIDDING_PROJECTS_SUCCESS, payload: response?.data?.data });
    } catch (error) {
        dispatch({
            type: FETCH_BIDDING_PROJECTS_FAILURE,
            payload: {
                message: error.response?.data?.message || 'something unexpected happened',
                state: true,
            },
        });
    }
};

export const getOrganizationsForBidding =
    (value = '') =>
    async (dispatch) => {
        try {
            dispatch({
                type: FETCH_ORGANIZATION_INITIATE,
            });
            const response = await getOrganizations(value);
            dispatch({
                type: FETCH_ORGANIZATION_SUCCESS,
                payload: response.data || [],
            });
        } catch (error) {
            dispatch({
                type: FETCH_ORGANIZATION_FAILURE,
                error: error.response?.data?.message || '',
            });
        }
    };

export const createBiddingProject = (payload, history) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_BIDDING_PROJECT_INITIATE,
        });
        const response = await postMainProjectAPI(payload);
        dispatch({
            type: CREATE_BIDDING_PROJECT_SUCCESS,
            payload: response,
        });
        dispatch(setActiveBiddingProject(response));
        notification.success({
            message: 'Success',
            description: MESSAGES.BIDDING.BASIC_INFO_SAVED,
        });
        history.push({
            pathname: `${BIDDING_MANAGEMENT}/create/${response?.id}`,
            search: `?activeTab=2`,
        });
    } catch (error) {
        dispatch({
            type: CREATE_BIDDING_PROJECT_FAILURE,
            error: error.response?.data?.message || '',
        });
        notification.error({
            message: error.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
        });
    }
};

export const getProjectDetailById = (id) => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_MAIN_PROJECT_DETAIL_INITIATE,
        });
        const response = await fetchProjectDetailById(id);
        dispatch({
            type: FETCH_MAIN_PROJECT_DETAIL_SUCCESS,
            payload: response.data?.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_BIDDING_PROJECTS_FAILURE,
            payload: error.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
        });
    }
};

export const updateBiddingProject = (payload, history) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_BIDDING_PROJECT_INITIATE,
        });
        const response = await updateMainProjectAPI(payload);
        dispatch({
            type: UPDATE_BIDDING_PROJECT_SUCCESS,
            payload: response,
        });
        dispatch(setActiveBiddingProject(response));
        message.success(MESSAGES.BIDDING.PROJECT_UPDATED);
        dispatch({
            type: CLEAR_SELECTED_BIDDING_PROJECT,
        });
        history.push(`${BIDDING_MANAGEMENT}/${response?.id}`);
        history.push({
            pathname: `${BIDDING_MANAGEMENT}/create/${response?.id}`,
            search: `?activeTab=2`,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_BIDDING_PROJECT_FAILURE,
            error: error.response?.data?.message || '',
        });
        notification.error({
            message: error.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
        });
    }
};

export const clearSelectBiddingProject = () => (dispatch) => {
    dispatch({
        type: CLEAR_SELECTED_BIDDING_PROJECT,
    });
};

export const setActiveBiddingProject = (payload) => (dispatch) => {
    dispatch({
        type: SET_BIDDING_ACTIVE_PROJECT,
        payload,
    });
};

export const clearActiveBiddingProject = () => (dispatch) => {
    dispatch({
        type: CLEAR_BIDDING_ACTIVE_PROJECT,
    });
};

export const setActiveContractor = (payload) => (dispatch) => {
    dispatch({
        type: SET_ACTIVE_CONTRACTOR,
        payload,
    });
};

export const clearActiveContractor = () => (dispatch) => {
    dispatch({
        type: CLEAR_ACTIVE_CONTRACTOR,
    });
};

export const getBiddingFinancialVendorItems = (projectId, params) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_BIDDING_VENDOR_ESTIMATIONS_INITIATE });
        const response = await fetchBiddingVendorEstimations(projectId, params);
        dispatch({ type: FETCH_BIDDING_VENDOR_ESTIMATIONS_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: FETCH_BIDDING_VENDOR_ESTIMATIONS_FAILURE });
    }
};

export const getBiddingProjectFinancialSummary = (projectId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_INITATE });
        const response = await fetchBiddingProjectFinancialSummary(projectId);
        dispatch({ type: FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: FETCH_BIDDING_PROJECT_FINANCIAL_SUMMARY_FAILURE });
    }
};

export const getBiddingVendorEstimationsById =
    (projectId, vendorId, params = {}) =>
    async (dispatch) => {
        try {
            dispatch({ type: FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_INITIATE });
            const response = await fetchBiddingVendorEstimationsById(projectId, vendorId, params);
            dispatch({ type: FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_SUCCESS, payload: response });
        } catch (error) {
            console.log('error', error);
            dispatch({ type: FETCH_BIDDING_VENDOR_ESTIMATIONS_BY_ID_FAILURE });
        }
    };

export const getContractors = (contractorId) => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_CONTRACTOR_BY_ID_INITIATE,
        });
        const response = await fetchContractor(contractorId);
        dispatch({
            type: FETCH_CONTRACTOR_BY_ID_SUCCESS,
            payload: response?.data,
        });
    } catch (ex) {
        dispatch({
            type: FETCH_CONTRACTOR_BY_ID_FAILURE,
        });
    }
};

export const updateContractors = (body, history) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_CONTRACTOR_BY_ID_INITIATE,
        });
        const response = await updateContractorById(body);
        dispatch({
            type: UPDATE_CONTRACTOR_BY_ID_SUCCESS,
            payload: response?.data,
        });
        dispatch(setActiveContractor(response?.data));
        message.success('Contractor updated successfully.');
        history.push(`${BIDDING_MANAGEMENT}/${body.main_project}${CONTRACTORS}/${body.id}`);
    } catch (ex) {
        dispatch({
            type: UPDATE_CONTRACTOR_BY_ID_FAILURE,
        });
    }
};

export const createContractor = (body, history) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_CONTRACTOR_INITIATE,
        });
        const response = await createContractors(body);
        dispatch({
            type: CREATE_CONTRACTOR_SUCCESS,
        });
        dispatch(setActiveContractor(response?.data));
        history.push(
            `${BIDDING_MANAGEMENT}/${response.data?.main_project}${CONTRACTORS}/${response.data?.id}`,
        );
    } catch (Ex) {
        dispatch({
            type: CREATE_CONTRACTOR_FAILURE,
        });
        throw Ex;
    }
};

export const getMainProjectDocuments = (projectId, params) => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_MAIN_PROJECT_DOCUMENTS_INITIATE,
        });
        const response = await fetchMainProjectDocuments(projectId, params);
        dispatch({
            type: FETCH_MAIN_PROJECT_DOCUMENTS_SUCCESS,
            payload: response,
        });
    } catch (ex) {
        dispatch({
            type: FETCH_MAIN_PROJECT_DOCUMENTS_FAILURE,
        });
    }
};

export const getFinancialEstimationDocumentsMainProject =
    (projectId, params) => async (dispatch) => {
        try {
            dispatch({
                type: GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_INITIATE,
            });
            const response = await vendorEstimatesDocumentMainProject(projectId, params);
            dispatch({
                type: GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_SUCCESS,
                payload: response,
            });
        } catch (ex) {
            dispatch({
                type: GET_FINANCIAL_ESTIMATION_DOCS_MAIN_PROJECT_FAILURE,
            });
        }
    };

export const getAllContractorDocs = (contractorId, params) => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_CONTRACTOR_DOCUMENTS_INITIATE,
        });
        const response = await fetchAllContractorDocs(contractorId, params);
        dispatch({
            type: FETCH_CONTRACTOR_DOCUMENTS_SUCCESS,
            payload: response,
        });
    } catch (ex) {
        dispatch({
            type: FETCH_CONTRACTOR_DOCUMENTS_FAILURE,
        });
    }
};

export const getContractorFinancialEstimateDocuments =
    (contractorId, params) => async (dispatch) => {
        try {
            dispatch({
                type: GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_INITIATE,
            });
            const response = await fetchContractorFinancialDocs(contractorId, params);
            dispatch({
                type: GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_SUCCESS,
                payload: response,
            });
        } catch (ex) {
            dispatch({
                type: GET_FINANCIAL_ESTIMATION_DOCS_CONTRUCTOR_FAILURE,
            });
        }
    };
