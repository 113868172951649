import React from 'react';
import moment from 'moment';
import { Space, Tooltip } from 'antd';
import {
    EditOutlined,
    FileSearchOutlined,
    FileTextOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { DISPLAY_DATE_FORMAT, renderAmount } from '../../../../../../../../static/utils';

export default (onActionClick = () => {}) => {

    return React.useMemo(() => [
        {
            key: 'entry_date',
            title: 'Date',
            dataIndex: 'entry_date',
            render: (value) => moment(value).format(DISPLAY_DATE_FORMAT),
        },
        {
            key: 'tag_name',
            title: 'Equipment / Catalog #',
            dataIndex: 'tag_name',
            render: (value) => value || '-',
        },
        {
            key: 'vendor',
            title: 'Vendor',
            dataIndex: 'vendor',
            render: (vendor) => vendor?.name || '-',
        },
        {
            key: 'vendor_quote_no',
            title: 'Quote #',
            dataIndex: 'vendor_quote_no',
            render: (value) => value || '-',
        },
        {
            key: 'item_detail',
            title: 'Item Detail',
            dataIndex: 'item_detail',
        },
        {
            key: 'quantity',
            title: 'Quantity',
            dataIndex: 'quantity',
            render: (value) => value || '-',
        },
        {
            key: 'unit_price',
            title: 'Unit Price',
            dataIndex: 'unit_price',
            render: renderAmount,
        },
        {
            key: '5',
            title: 'Sell',
            dataIndex: 'total_sell',
            render: renderAmount,
        },
        {
            key: 'total_cost',
            title: 'Cost',
            dataIndex: 'total_cost',
            render: renderAmount,
        },
        {
            key: 'margin_percent',
            title: 'Margin',
            dataIndex: 'margin_percent',
            render: (value) => (!_.isEmpty(value) ? `${value} %` : '-'),
        },
        {
            key: 'files_count',
            title: 'Attachment(s)',
            dataIndex: 'files_count',
            render: (value) => (
                <Space>
                    <FileTextOutlined />
                    {value || 0}
                </Space>
            ),
        },
        {
            key: 'action',
            title: 'Action',
            align: 'center',
            dataIndex: 'action',
            render: (_, record) => (
                <Space size={10}>
                    <Tooltip title="Details">
                        <FileSearchOutlined
                            style={{ fontSize: 17 }}
                            onClick={() => onActionClick('view', record)}
                        />
                    </Tooltip>
                    <Tooltip title="Adder">
                        <PlusCircleOutlined
                            style={{ fontSize: 17 }}
                            onClick={() => onActionClick('add', record)}
                        />
                    </Tooltip>
                    <Tooltip title="Deduct">
                        <MinusCircleOutlined
                            style={{ fontSize: 17 }}
                            onClick={() => onActionClick('deduct', record)}
                        />
                    </Tooltip>
                    <Tooltip title="Edit">
                        <EditOutlined
                            style={{ fontSize: 17 }}
                            onClick={() => onActionClick('edit', record)}
                        />
                    </Tooltip>
                    {/* <Popconfirm
                            title="Are you sure?"
                            onConfirm={(ev) => {
                                ev.stopPropagation();
                                dispatch(removeProjectCosts(record.master_project, [record.id]));
                            }}
                        >
                            <Tooltip title="Delete">
                                <DeleteTwoTone style={{ fontSize: 17 }} twoToneColor="#f56666" />
                            </Tooltip>
                        </Popconfirm> */}
                </Space>
            ),
        },
    ]);
};
