import moment from 'moment';
import { debounce, map } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
    AutoComplete,
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Space,
    Spin,
} from 'antd';
import { getProjectFinancialSummary } from '../../../../../../store/financials';
import {
    BASIC_REQUIRED_VALIDATION_RULES,
    INPUT_DATE_FORMAT,
    SERVER_DATE_FORMAT,
} from '../../../../../../../static/utils';
import {
    createProjectFinancialSummary,
    getOrganizations,
    updateProjectFinancialSummary,
} from '../../../../../../services';
import { FETCH_PROJECTS_INITIATE } from '../../../../../../store/projects';
import NotFoundMessage from './NotFoundMessage';
import PurchaseOrderInfo from './PurchaseOrderInfo';
import {
    getCustomerPurchaseOrdersSummary,
    getProjectCostsSummary,
} from '../../../../../../store/billing';

function PurchaseOrderForm() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { projectId } = useParams();
    const { summarySheet, verticalType } = useSelector(({ financials }) => financials);
    const [summaryForm, setSummaryForm] = useState({
        edit: false,
        create: false,
    });
    const [customers, setCustomers] = useState({
        data: [],
        value: '',
        fetching: false,
    });

    useEffect(() => {
        if (summaryForm.edit) {
            form.setFieldsValue({
                ...summarySheet.data,
                vendor_cost_date: moment(summarySheet?.data?.vendor_cost_date),
                original_po_date: moment(summarySheet?.data?.original_po_date),
                customer: summarySheet?.data?.customer?.name,
            });
        }
    }, [summaryForm?.edit, projectId]);

    useEffect(() => {
        form.resetFields();
        dispatch(getProjectFinancialSummary(projectId, verticalType));
    }, [projectId]);

    const showNotFoundMessage = () => !summarySheet?.data && !summaryForm?.create;

    const onSearchCustomer = debounce(async (value) => {
        try {
            if (!value) {
                throw new Error();
            }
            setCustomers({ ...customers, fetching: true });
            const response = await getOrganizations({ search: value });
            setCustomers({
                ...customers,
                fetching: false,
                data: response?.data,
                value,
            });
        } catch (error) {
            setCustomers({ ...customers, fetching: false, data: [] });
        }
    }, 500);

    const onSubmit = async (values) => {
        dispatch({ type: FETCH_PROJECTS_INITIATE });
        const dto = {
            ...values,
            original_po_date: values?.original_po_date.format(SERVER_DATE_FORMAT),
        };
        let response;
        if (summaryForm.create) {
            response = await createProjectFinancialSummary(projectId, dto);
        } else {
            response = await updateProjectFinancialSummary(projectId, summarySheet?.data?.id, dto);
        }
        dispatch(getProjectFinancialSummary(projectId, verticalType));
        dispatch(getProjectCostsSummary(projectId, verticalType));
        dispatch(getCustomerPurchaseOrdersSummary(projectId, verticalType));
        message.success(`${summaryForm?.create ? 'Created' : 'Updated'} Successfully`);
        setSummaryForm({ create: false, edit: false });
    };

    return (
        <Card
            title={`Customer Purchase Order`}
            style={{ minHeight: 59 }}
            loading={summarySheet?.fetching}
            extra={
                showNotFoundMessage() ? (
                    <PlusCircleOutlined
                        style={{ fontSize: 20 }}
                        onClick={() => setSummaryForm({ ...summaryForm, create: true })}
                    />
                ) : !summaryForm.edit ? (
                    <EditOutlined
                        style={{ fontSize: 20 }}
                        onClick={() => setSummaryForm({ ...summaryForm, edit: true })}
                    />
                ) : null
            }
            className="full-height"
        >
            {showNotFoundMessage() ? (
                <NotFoundMessage
                    setCreateSummary={(value) => setSummaryForm({ ...summaryForm, create: value })}
                />
            ) : summaryForm?.create || summaryForm?.edit ? (
                <Form layout="vertical" form={form} onFinish={onSubmit}>
                    <Row gutter={30}>
                        {/* <Col span={24} className="mb-0">
                            <h1 className="po-summary-heading">Total Sell</h1>
                        </Col> */}
                        <Col span={6}>
                            <Form.Item
                                label="Customer"
                                name="customer"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <AutoComplete
                                    showSearch
                                    placeholder="Enter Customer"
                                    options={map(customers.data, (org) => {
                                        return {
                                            name: org?.name,
                                            value: org?.name,
                                        };
                                    })}
                                    onSearch={onSearchCustomer}
                                    notFoundContent={
                                        customers.fetching ? (
                                            <div className="text-center">
                                                <Spin />
                                            </div>
                                        ) : null
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Customer PO #"
                                name="customer_po_number"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Original PO Date"
                                name="original_po_date"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Original PO"
                                name="original_po_value"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <InputNumber
                                    prefix="$"
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    className="full-width"
                                />
                            </Form.Item>
                        </Col>
                        {/* <Col span={24} className="mb-0">
                            <h1 className="po-summary-heading">Total Cost</h1>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Date"
                                name="vendor_cost_date"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Original Vendor Cost"
                                name="vendor_original_cost"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <InputNumber
                                    prefix="$"
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    className="full-width"
                                />
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Space>
                        <Button
                            type="default"
                            onClick={() => setSummaryForm({ edit: false, create: false })}
                        >
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {summaryForm.edit ? 'Update' : 'Submit'}
                        </Button>
                    </Space>
                </Form>
            ) : (
                <PurchaseOrderInfo />
            )}
        </Card>
    );
}

export default PurchaseOrderForm;
