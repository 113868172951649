import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import {
  Card,
  Col,
  Row,
  Modal,
  Table,
  Select,
  Collapse,
  Typography,
  Space,
  Button,
} from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PieChart, PredictGantt } from '../../../../../components';
import {
  EQUIPMENT_PROGRESS_OPTIONS,
  projectGantOptions,
  PROJECT_OVERVIEW_OPTIONS,
} from '../../../../../static';
import {
  deleteFollowTagsGantt,
  fetchEquipmentProgressStatistics,
  fetchTagCompletion,
  fetchTagStatistics,
  fetchTagStatisticsDetails,
  GanttChartGetImage,
  fetchDeadlines,
  getGanttChartAllTags,
  getGanttChartTypeData,
  postCriticalTags,
  postFollowTagsGantt,
  fetchMasterProjectDetail,
} from '../../../../../services';
import '../../../../../assets/styles/components/task-inbox.scss';
import { useDispatch } from 'react-redux';
import {
  CRITICAL_TAGS_COUNT,
  DISPLAY_DATE_FORMAT,
  SERVER_DATE_FORMAT,
  PROJECTS,
  renderDate,
  PROJECT_API_ENUM,
} from '../../../../../../static/utils';
import { setActiveProject, setActiveProjectTab } from '../../../../../store/common';

const { Option } = Select;
const { Panel } = Collapse;

const Insights = ({ _projectId }) => {
const { subProjectId, masterProjectId } = useParams();
const projectId = _projectId || subProjectId;
  const dispatch = useDispatch();
  const history = useHistory();
  const [statistics, setStatistics] = useState([]);
  const [modalType, setModalType] = useState([]);
  const [equipStatistics, setEquipStatistics] = useState([]);
  const [deadlines, setDeadlines] = useState({});
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [deadlinesLoading, setDeadLinesLoading] = useState(false);
  const [equipmentStatistics, setEquipmentStatistics] = useState([]);
  const [equipmentStatisticsLoading, setEquipmentStatisticsLoading] = useState([]);
  const [equipmentStatsLoading, setEquipmentStatsLoading] = useState(false);
  const [ganttChart, setGanttChart] = useState([]);
  const [completion, setCompletion] = useState([]);
  const [completionLoading, setCompletionLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gantSeries, setGantSeries] = useState([]);
  const [gantLoading, setGantLoading] = useState(false);
  const [gantSeriesNames, setGantSeriesNames] = useState([]);
  const [visible, setVisible] = useState(false);
  const [allGrantSeriesNames, setAllGrantSeriesNames] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('Tags Detail');

  const [statisticsTags, setStatisticsTags] = useState([]);
  const [dateTitle, setDateTitle] = useState('Ordered Date');
  const [tagName, setTagName] = useState('Tag Name');

  const [chartType, setChartType] = useState('Planned');

  const [allGanttTags, setAllGanttTags] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [trackedTags, setTrackedTags] = useState(null);

  const columns = [
    {
      title: tagName,
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: dateTitle,
      dataIndex: 'date',
      render: (value, record) => {
        if (tagName === 'Package Name') {
          return <p>{record.date}</p>;
        }

        return renderDate(value);
      },
    },
    {
      title: '',
      render: (record) => <Link to={`/projects/${projectId}/packages/${record.packageroom_id}?tag_id=${record?.id}`}>View Details</Link>,
    },
  ];
  const upComingDeadlinesColumns = [
    {
      title: 'Package Name',
      dataIndex: 'package_name',
    },
    {
      title: tagName,
      dataIndex: 'tag_name',
    },
    {
      title: 'Deadline Date',
      dataIndex: 'upcoming_date',
      render: (value, record) => moment(value).format(DISPLAY_DATE_FORMAT),
    },
    {
      title: 'Type',
      dataIndex: 'upcoming_date_type',
    },
    {
      title: '',
      render: (record) => <Link onClick={() => history.push(`projects/${projectId}/packages/${record.package_id}`)}>View Details</Link>,
    },
  ];

  const missedDeadlinesColumns = [
    {
      title: 'Package Name',
      dataIndex: 'package_name',
    },
    {
      title: tagName,
      dataIndex: 'tag_name',
    },
    {
      title: 'Missed Date',
      dataIndex: 'missed_date',
      render: (value, record) => moment(value).format(DISPLAY_DATE_FORMAT),
    },
    {
      title: 'Type',
      dataIndex: 'missed_date_type',
    },
    {
      title: '',
      render: (record) => <Link onClick={() => history.push(`projects/${projectId}/packages/${record.package_id}`)}>View Details</Link>,
    },
  ];

  const getGanttTags = async () => {
    const res = await getGanttChartAllTags(projectId, {});

    if (res.success && res.data.length) {
      const options = [];

      for (let i = 0; i < res.data.length; i++) {
        options.push({
          label: res.data[i].name,
          value: res.data[i].id,
          isCritical: res.data[i].is_critical,
        });
      }

      if (options.length === res.data.length) {
        setAllGanttTags(options);
      }
    }
  };
  const getStatistics = async () => {
    try {
      setStatisticsLoading(true);
      setDeadLinesLoading(true);
      setEquipmentStatisticsLoading(true);
      const response = await fetchTagStatistics(projectId, 'projectsummary');
      const equipResponse = await fetchTagStatistics(projectId, 'equipmentstatus');
      const deadlinesResponse = await fetchDeadlines(projectId);

      setDeadlines(deadlinesResponse?.data);
      setStatistics([...response?.data]);
      setEquipStatistics([...equipResponse?.data]);

      setDeadLinesLoading(false);
      setStatisticsLoading(false);
      setEquipmentStatisticsLoading(false);
    } catch (ex) {
      setDeadLinesLoading(false);
      setStatisticsLoading(false);
      setEquipmentStatisticsLoading(false);
      throw (ex);
    }
  };

  const getCompletion = async () => {
    try {
      setCompletionLoading(true);
      const response = await fetchTagCompletion(projectId);
      setCompletion([...[response?.data.completed, response?.data.remaining]]);
      setCompletionLoading(false);
    } catch (ex) {
      setCompletionLoading(false);
    }
  };

  const getEquipmentStatistics = async () => {
    try {
      setEquipmentStatsLoading(true);
      const response = await fetchEquipmentProgressStatistics(projectId);
      setEquipmentStatistics(response?.data);

      setEquipmentStatsLoading(false);
    } catch (ex) {
      setEquipmentStatsLoading(false);
    }
  };

  const getGantImageChart = async () => {
    try {
      setLoading(true);
      const response = await GanttChartGetImage(projectId);
      setGanttChart(response?.data);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      throw (ex);
    }
  };

  const getGantChartSeries = async () => {
    try {
      setGantLoading(true);
      const response = await getGanttChartTypeData(projectId, {}, chartType);
      getTagsAndSetSeriesData(response?.data);
      setGantLoading(false);
    } catch (error) {
      setGantLoading(false);
    }
  };

  const getTagsAndSetSeriesData = (entries) => {
    const apprvlTagNames = entries[0].data.map(({ x }) => x);
    const reviewTagNames = entries[1].data.map(({ x }) => x);
    const mfrngTagNames = entries[2].data.map(({ x }) => x);

    const allTags = [...apprvlTagNames, ...reviewTagNames, ...mfrngTagNames];
    const uniqTags = _.uniq(allTags);
    setTagsSelected(uniqTags);
    setGantSeriesNames([...uniqTags]);
    setAllGrantSeriesNames([...uniqTags]);
    setGantSeries([...entries]);
  };

  const getTrackedTags = (allTags) => {
    const trackedItems = allTags.filter((item) => {
      if (tagsSelected.includes(item.label)) {
        return item;
      }
    });
    return trackedItems;
  };

  const handleFollowTag = (tagId) => {
    const updateFollowingTags = async () => {
      const res = await postFollowTagsGantt(projectId, {
        tags: [tagId],
      });

      if (res.success) {
        getGantChartSeries(chartType);
      }
    };

    updateFollowingTags();
  };

  const handleUnFollowTag = (tagId) => {
    const updateFollowingTags = async () => {
      const res = await deleteFollowTagsGantt(projectId, {
        tags: [tagId],
      });

      if (res.success) {
        getGantChartSeries(chartType);
      }
    };

    updateFollowingTags();
  };

  useEffect(() => {
    if (allGanttTags.length) {
      setTrackedTags(getTrackedTags(allGanttTags));
    }
  }, [tagsSelected, allGanttTags]);

  const showCardDetails = async (stats, type = 'stats') => {
    if (type === 'stats') {
      setModalTitle(
        stats.key === 'pending'
          ? 'Equipment Under Review'
          : stats.key === 'approval_dwg'
            ? 'Approval Drawings Required'
            : stats.key === 'released'
              ? 'Equipment Released'
              : 'Shipped',
      );
      setIsModalOpen(true);
      setModalType(stats.key);

      const statisticsDetail = await fetchTagStatisticsDetails(projectId, stats.key);
      setDateTitle(
        stats.key === 'released'
          ? 'Released Date'
          : stats.key === 'pending' || stats.key === 'approval_dwg'
            ? 'Planned Approval Date'
            : stats.key === 'resubmitted'
              ? 'Resubmittal Date'
              : 'Shipped Date',
      );

      if (stats.key !== 'resubmitted') {
        const data = statisticsDetail.data.map(
          ({
            id,
            name,
            description,
            approval_date,
            actual_approval_date,
            actual_release_date,
            order_date,
            packageroom_id,
            actual_ship_date,
          }) => ({
            id,
            name,
            description,
            packageroom_id,
            date:
              stats.key === 'released'
                ? actual_release_date
                : stats.key === 'pending'
                  ? actual_approval_date
                  : stats.key === 'approval_dwg'
                    ? approval_date
                    : actual_ship_date,
          }),
        );
        setTagName('Tag Name');
        setStatisticsTags(data);
      } else {
        setTagName('Tag Name');
        const data = statisticsDetail.data.map(({
          resubmittal_date, name, description, packageroom_id,
        }) => ({
          id: `${name}-${resubmittal_date}`,
          name,
          description,
          packageroom_id,
          date: resubmittal_date,
        }));

        setStatisticsTags(data);
      }
    } else if (type === 'deadlines' || type === 'missed') {
      setModalTitle(
        stats.key === 'upcoming'
          ? 'Upcoming Deadlines'
          : stats.key === 'missed'
            ? 'Missed Deadlines'
            : null,
      );
      setIsModalOpen(true);
      const data = stats.data || [];
      setModalType(stats.key);
      setStatisticsTags(data);
    }
  };
const getProjectDetails = async () => {
 const response = await fetchMasterProjectDetail(
    PROJECT_API_ENUM.BASIC_INFO,
    masterProjectId,
  );
if (response.success) {
  if (!isEmpty(response.data) && !isEmpty(response.data[0].projects)) {
    const found = response.data[0].projects.find((project) => project.id === subProjectId);
    dispatch(setActiveProject(found));
  }
}
};
  useEffect(() => {
    Promise.all([
      // createGantImageChart(),
      // getGantChartSeries(chartType),
      // getProjectDetails(),
      getCompletion(),
      getEquipmentStatistics(),
      getStatistics(),

      // getFullGantData(),
      // getGanttTags(),
      // getGanttChartWithType(),
      // getWordCloud(),
    ]);
  }, [projectId]);

  useEffect(() => {
    getGantChartSeries(chartType);
  }, [chartType, projectId]);

  useEffect(() => {
    sendCriticalTags();
  }, []);

  const modifyGantSeries = (series) => {
    const newSeries = series.map((s) => {
      const data = s.data
        .filter((item) => gantSeriesNames.includes(item.x))
        .map((d) => {
          const y1 = d.y[1]
            ? moment(d.y[0], SERVER_DATE_FORMAT).format('DD-MMM-YYYY')
            : d.y[0];
          const y2 = d.y[1]
            ? moment(d.y[1], SERVER_DATE_FORMAT).format('DD-MMM-YYYY')
            : d.y[1];
          return {
            ...d,
            y: [y1, y2],
          };
        });
      return {
        ...s,
        data,
      };
    });
    return newSeries;
  };

  const handleMenuClick = (e) => {
    if (e.key === '3') {
      setVisible(false);
    }
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const handleChartTypeChange = (value) => {
    setChartType(value);
  };

  const sendCriticalTags = async () => {
    const body = {
      critical: CRITICAL_TAGS_COUNT,
      type: chartType.toLowerCase(),
    };
    const criticalApiResp = await postCriticalTags(projectId, body, chartType.toLowerCase());

    if (criticalApiResp.success) {
      getTagsAndSetSeriesData(criticalApiResp.data);
    }
  };

  return (
    <div className="dashboard-container">
      <Row>
        <p className="card-heading summary-heading">Project Summary/ Deadlines</p>
      </Row>
      <Row gutter={15} className="mb-20">
        {!statisticsLoading ? (
          statistics.map((stat) => (
            <Col span={6} key={stat.key}>
              <div
                className={`page-content-container dashboard-card colorful-card cursor-pointer`}
                onClick={() => {
                  if (stat.key === 'total') {
                    if (_projectId) dispatch(setActiveProjectTab('tags'));
                    else history.push({ search: 'page=equipments' });
                  }
                  if (stat.key === 'total_manual_tracking_tags') {
                    if (_projectId) dispatch(setActiveProjectTab('manual-schedule'));
                    else history.push({ search: 'page=manual-schedule' });
                  }
                }}
                style={{ backgroundColor: stat?.color || '' }}
              >
                <h4 className="card-heading custom-card-heading">{stat.label}</h4>
                <h1 className="dashboard-card-text">{stat.value || 0}</h1>
              </div>
            </Col>
          ))
        ) : (
          <>
            <Col span={6}>
              <Card
                className="mb-30"
                style={{ height: 134 }}
                loading={statisticsLoading}
              />
            </Col>
            <Col span={6}>
              <Card
                className="mb-30"
                style={{ height: 134 }}
                loading={statisticsLoading}
              />
            </Col>
          </>
        )}
        {!deadlinesLoading ? (
          <>
            <Col span={6} key="upcoming">
              <div
                className="page-content-container dashboard-card colorful-card cursor-pointer"
                onClick={() => {
                  const stats = {};
                  stats.key = 'upcoming';
                  stats.data = deadlines?.upcoming_deadlines;
                  showCardDetails(stats, 'deadlines');
                }}
                style={{ backgroundColor: '#C1ECE4' }}
              >
                <h4 className="card-heading custom-card-heading">Upcoming Deadlines</h4>
                <h1 className="dashboard-card-text">{deadlines?.upcoming_deadlines?.length || 0}</h1>
              </div>
            </Col>
            <Col span={6} key="missed">
              <div
                className="page-content-container dashboard-card colorful-card cursor-pointer"
                onClick={() => {
                  const stats = {};
                  stats.key = 'missed';
                  stats.data = deadlines?.missed_deadlines;
                  showCardDetails(stats, 'missed');
                }}
                style={{ backgroundColor: '#F5E9CF' }}
              >
                <h4 className="card-heading custom-card-heading">Missed Deadlines</h4>
                <h1 className="dashboard-card-text">{deadlines?.missed_deadlines?.length || 0}</h1>
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col span={6}>
              <Card
                style={{ height: 134 }}
                loading={deadlinesLoading}
              />
            </Col>
            <Col span={6}>
              <Card
                style={{ height: 134 }}
                loading={deadlinesLoading}
              />
            </Col>
          </>
        )}
      </Row>
    <Row>
        <p className="card-heading summary-heading">Equipment Status</p>
      </Row>
      <Row gutter={15} className="mb-30">
        {!equipmentStatisticsLoading ? (
          equipStatistics.map((stat) => stat.label !== '# To Resubmit' && (
            <Col span={6} key={stat.key}>
              <div
                className={`page-content-container dashboard-card colorful-card cursor-pointer`}
                onClick={() => {
                  if (["approval_dwg", "pending", "released", "shipped"].includes(stat.key)) {
                    showCardDetails(stat, 'stats');
                  }
                }}
                style={{ backgroundColor: stat.color }}
              >
                <h4 className="card-heading custom-card-heading">
                  {stat.label === '# To Resubmit' ? 'Resubmittals' : stat.label}
                </h4>
                <h1 className="dashboard-card-text">{stat.value}</h1>
              </div>
            </Col>
          ))
        ) : (
          <>
            <Col span={6}>
              <Card
                className="mb-30"
                style={{ height: 134 }}
                loading={statisticsLoading}
              />
            </Col>
            <Col span={6}>
              <Card
                className="mb-30"
                style={{ height: 134 }}
                loading={statisticsLoading}
              />
            </Col>
            <Col span={6}>
              <Card
                className="mb-30"
                style={{ height: 134 }}
                loading={statisticsLoading}
              />
            </Col>
            {/* <Col span={6}>
              <Card
                className="mb-30"
                style={{ height: 134 }}
                loading={statisticsLoading}
              />
            </Col> */}
          </>
        )}
      </Row>
      <Row gutter={15}>
          <Col span={9} className="mb-15">
            {!completionLoading ? (
              <div
                className="page-content-container"
                style={{ height: '100%', marginBottom: 0, paddingBottom: 30 }}
              >
                <h4 className="card-heading">Project Overview</h4>
                <PieChart
                  series={JSON.parse(JSON.stringify(completion))}
                  options={PROJECT_OVERVIEW_OPTIONS}
                />
              </div>
            ) : (
              <Card style={{ height: 500 }} loading={completionLoading} />
            )}
          </Col>
          <Col span={15} className="mb-15">
            {!equipmentStatsLoading ? (
              <div
                className="page-content-container equpmt-chart"
                style={{ height: '100%', marginBottom: 0, paddingBottom: 0 }}
              >
                <h4 className="card-heading">Equipment</h4>

                {!_.isEmpty(tagsSelected) ? (
                  <PredictGantt
                    height={400}
                    seriesData={equipmentStatistics}
                    optionsData={EQUIPMENT_PROGRESS_OPTIONS}
                  />
                ) : (
                  <Row style={{ marginLeft: '10px' }}>
                    <Typography.Title level={5} italic>
                      No data found...
                    </Typography.Title>
                  </Row>
                )}
              </div>
            ) : (
              <Card
                style={{ height: 500 }}
                loading={equipmentStatsLoading}
              />
            )}
          </Col>
        </Row>
      <>
        {/* <Row gutter={20}>
                        <Col span={24}>
                            <div className="page-content-container">
                                <h4 className="card-heading">Alectify Predictions: Gantt Chart</h4>
                                <img
                                    src={`data:image/jpeg;base64,${ganttChart.all}`}
                                    style={{ maxWidth: '100%' }}
                                />
                            </div>
                        </Col>
                    </Row> */}
        <Collapse onChange={() => { getGantChartSeries(chartType); }} key={projectId}>
          <Panel className="card-heading" header="ALECTIFY PREDICTIONS: GANTT CHART" key="1">
            <Row>
              <Col span={24} className="mb-15">
                {!gantLoading ? (
                  <div
                    style={{
                      height: '100%',
                      marginBottom: 0,
                      paddingBottom: 12,
                    }}
                  >
                    <div className="gantt-chart-card">
                      <div className="text-right">
                        <p />
                        <Space size={15}>
                          {/* <div className="d-flex align-center text-left">
                            <p className="mb-0 mr-14">Tags</p>
                            <div id="ganttTagsDropdwn">
                              <Select
                                mode="tags"
                                showSearch
                                getPopupContainer={() => document.getElementById(
                                  'ganttTagsDropdwn',
                                )}
                                showArrow
                                optionFilterProp="children"
                                filterOption={(
                                  input,
                                  option,
                                ) => option.label.includes(
                                  input,
                                )}
                                style={{
                                  width: '100%',
                                  minWidth: 200,
                                  maxWidth: 650,
                                }}
                                maxTagCount={5}
                                virtual
                                value={
                                  trackedTags !== null
                                    ? trackedTags
                                    : []
                                }
                                placeholder="Select Tags to track"
                                onSelect={handleFollowTag}
                                onDeselect={handleUnFollowTag}
                                options={allGanttTags}
                              />
                            </div>
                          </div> */}

                          <div className='text-left'>
                          <Select
                            defaultValue={chartType}
                            style={{
                              width: '130px',
                            }}
                            onChange={handleChartTypeChange}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {['Planned', 'Actual'].map((item) => (
                              <Option key={item} value={item}>{item}</Option>
                            ))}
                          </Select>
                          </div>
                          <Button
                            type="primary"
                            onClick={sendCriticalTags}
                          >
                            Critical
                          </Button>
                        </Space>
                      </div>
                      {!_.isEmpty(tagsSelected) ? (
                        <Row>
                          <Col span={24}>
                            <Typography.Title
                              level={5}
                              className="card-heading sub-chart-heading"
                            >
                              {chartType}
                            </Typography.Title>
                            <PredictGantt
                              height={600}
                              seriesData={modifyGantSeries(
                                gantSeries,
                              )}
                              optionsData={projectGantOptions(
                                gantSeries,
                                gantSeriesNames,
                                chartType,
                              )}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row style={{ marginLeft: '10px' }}>
                          <Typography.Title level={5} italic>
                            No data found...
                          </Typography.Title>
                        </Row>
                      )}
                    </div>
                  </div>
                ) : (
                  <Card loading={gantLoading} style={{ height: 600 }} />
                )}
              </Col>
               {/* <Col span={24} className="mb-15">
                                {!gantLoading ? (
                                    <div
                                        className="page-content-container"
                                        style={{
                                            height: '100%',
                                            marginBottom: 0,
                                            paddingBottom: 12,
                                        }}
                                    >
                                        <h4 className="card-heading d-flex justify-space-between">
                                            Alectify Predictions: Gantt Chart
                                        </h4>
                                        <div className="gantt-chart-card">
                                            <div className="d-flex justify-space-between">
                                                <p></p>
                                                {feature_insights_gantt_chart_filter(
                                                    user.features,
                                                ) && (
                                                    <div className="d-flex">
                                                        <div id="ganttTagsDropdwn">
                                                            <Select
                                                                mode="tags"
                                                                showSearch
                                                                getPopupContainer={() =>
                                                                    document.getElementById(
                                                                        'ganttTagsDropdwn',
                                                                    )
                                                                }
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => {
                                                                    return option.label.includes(
                                                                        input,
                                                                    );
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                    minWidth: 200,
                                                                    maxWidth: 650,
                                                                }}
                                                                maxTagCount={5}
                                                                virtual={true}
                                                                value={
                                                                    trackedTags !== null
                                                                        ? trackedTags
                                                                        : []
                                                                }
                                                                placeholder="Select Tags to track"
                                                                onSelect={handleFollowTag}
                                                                onDeselect={handleUnFollowTag}
                                                                options={allGanttTags}
                                                            />
                                                        </div>

                                                        <Button
                                                            type="primary"
                                                            style={{ marginLeft: '20px' }}
                                                            onClick={sendCriticalTags}
                                                        >
                                                            Critical
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                            {!_.isEmpty(tagsSelected) ? (
                                                <Row>
                                                    <Col span={24}>
                                                        <CompleteGanttChart />
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row style={{ marginLeft: '10px' }}>
                                                    <Typography.Title level={5} italic>
                                                        No data found...
                                                    </Typography.Title>
                                                </Row>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <Card loading={gantLoading} style={{ height: 600 }} />
                                )}
                            </Col> */}
            </Row>
          </Panel>
        </Collapse>
      </>
      <Modal
        visible={isModalOpen}
        title={modalTitle}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
        width={800}
        key={`${modalType}-${projectId}`}
        className="taglist-popup"
      >
        {
          modalType === 'upcoming' ? (
            <Table
              size="small"
              columns={upComingDeadlinesColumns}
              pagination={statisticsTags.length > 10}
              className="popup-taglist-table"
              onRow={(record, rowIndex) => ({
                onClick: (event) => {
                  history.push(`${PROJECTS}/${projectId}/package/${record?.package_id}`);
                },
              })}
              dataSource={statisticsTags.map((tag) => ({
                ...tag,
                key: tag?.id || tag?.name,
              }))}
            />
          )
            : modalType === 'missed' ? (
              <Table
                size="small"
                columns={missedDeadlinesColumns}
                pagination={statisticsTags.length > 10}
                className="popup-taglist-table"
                onRow={(record, rowIndex) => ({
                  onClick: (event) => {
                    history.push(`${PROJECTS}/${projectId}/package/${record?.package_id}`);
                  },
                })}
                dataSource={statisticsTags.map((tag) => ({
                  ...tag,
                  key: tag?.id || tag?.name,
                }))}
              />
            )
              : (
                <Table
                  size="small"
                  columns={columns}
                  pagination={statisticsTags.length > 10}
                  className="popup-taglist-table"
                  onRow={(record, rowIndex) => ({
                    onClick: (event) => {
                      history.push(`${PROJECTS}/${projectId}/package/${record?.packageroom_id}`);
                    },
                  })}
                  dataSource={statisticsTags.map((tag) => ({
                    ...tag,
                    key: tag?.id || tag?.name,
                  }))}
                />
              )
        }

      </Modal>
    </div>
  );
};

export default memo(Insights);
