import { GET_BID_PROJECT_FINANCIAL_SUMMARY } from '../../static/utils/endpoints';
import { fileHttp, http } from '../../static/utils/http';

export const fetchProjectFinancialSummary = async (projectId, verticalType) => {
    try {
        let URL = `/masterprojects/${projectId}/finance/finance-summary/`;
        if (verticalType === 'bid') {
            URL = GET_BID_PROJECT_FINANCIAL_SUMMARY.replace('projectId', projectId);
        }
        const response = await http.get(URL);
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchProjectFinancialVendors = async (projectId) => {
    try {
        const response = await http.get(`/masterprojects/${projectId}/finance/finance-summary/`);
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const createProjectFinancialSummary = async (projectId, body) => {
    try {
        const response = await http.post(
            `/masterprojects/${projectId}/finance/finance-summary/`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateProjectFinancialSummary = async (projectId, summaryId, body) => {
    try {
        const response = await http.patch(
            `/masterprojects/${projectId}/finance/finance-summary/${summaryId}`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const createVendorEstimation = async (projectId, body) => {
    try {
        const response = await http.post(
            `/masterprojects/${projectId}/finance/vendor-estimates/`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchVendorEstimations = async (projectId, params) => {
    try {
        const response = await http.get(
            `/masterprojects/${projectId}/finance/vendor-estimates/`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchVendorEstimationDetail = async (projectId, vendorItemId) => {
    try {
        const response = await http.get(
            `/masterprojects/${projectId}/finance/vendor-estimates/${vendorItemId}`,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateVendorEstimations = async (projectId, vendorItemId, body) => {
    try {
        const response = await http.patch(
            `/masterprojects/${projectId}/finance/vendor-estimates/${vendorItemId}`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const deleteVendorEstimations = async (projectId, vendorEstimateIds) => {
    try {
        const response = await http.delete(
            `/masterprojects/${projectId}/finance/vendor-estimates/`,
            { vendorEstimateIds },
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchVendorAssociates = async (projectId, vendorItemId, params) => {
    try {
        const response = await http.get(
            `/masterprojects/${projectId}/finance/vendor-estimates/${vendorItemId}/associated-estimations/`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const createVendorAssociates = async (projectId, vendorItemId, body) => {
    try {
        const response = await http.post(
            `/masterprojects/${projectId}/finance/vendor-estimates/${vendorItemId}/associated-estimations/`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const updateVendorAssociates = async (projectId, vendorItemId, vendorAssociateId, body) => {
    try {
        const response = await http.patch(
            `/masterprojects/${projectId}/finance/vendor-estimates/${vendorItemId}/associated-estimations/${vendorAssociateId}`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const deleteVendorAssociates = async (projectId, vendorItemId, vendorAssociateIds) => {
    try {
        const response = await http.delete(
            `/masterprojects/${projectId}/finance/vendor-estimates/${vendorItemId}/associated-estimations/`,
            { vendor_estimate_associated_detail_ids: vendorAssociateIds },
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchVendorAssociatesDocuments = async (
    projectId,
    vendorItemId,
    vendorAssociateId,
    params,
) => {
    try {
        const response = await http.get(
            `/masterprojects/${projectId}/finance/vendor-estimates/${vendorItemId}/associated-estimations/${vendorAssociateId}/documents/`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const uploadVendorAssociatesDocuments = async (
    projectId,
    vendorItemId,
    vendorAssociateId,
    body,
) => {
    try {
        const response = await fileHttp.post(
            `/masterprojects/${projectId}/finance/vendor-estimates/${vendorItemId}/associated-estimations/${vendorAssociateId}/documents/`,
            body,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchFinancialDocuments = async (projectId, params) => {
    try {
        const response = await http.get(
            `/masterprojects/${projectId}/finance/vendor-associated-estimations/docs/`,
            params,
        );
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};

export const fetchVendorQuotesSummary = async (projectId) => {
    try {
        const response = await http.get(`/masterprojects/${projectId}/finance/vendor-estimates/summary/`);
        return response?.data;
    } catch (ex) {
        throw ex;
    }
};
