import React from 'react';
import moment from 'moment';
import { Avatar } from 'antd';
import { displayValue, DISPLAY_DATETIME_FORMAT, makeAvatar } from '../../../../static/utils';

export default (onActionClick = () => {}) =>
    React.useMemo(() => [
        {
            title: 'Tracking Id',
            dataIndex: 'carrier_tracking_id',
            render: (carrier_tracking_id) => displayValue(carrier_tracking_id),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            render: (quantity) => displayValue(quantity),
        },
        {
            title: 'Ship Date',
            dataIndex: 'ship_date',
            render: (date) => moment(date, moment.ISO_8601).format(DISPLAY_DATETIME_FORMAT),
        },
        {
            title: 'Updated By',
            dataIndex: 'updated_by',
            render: (user) => (
                <Avatar
                    className="circle-icon"
                    src={user?.image || makeAvatar(user.first_name[0], user.last_name[0])}
                />
            ),
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            render: (date) => moment(date, moment.ISO_8601).format(DISPLAY_DATETIME_FORMAT),
        },
        {
            title: 'Status',
            dataIndex: 'shipment_status',
        },
    ]);
