import PropTypes from 'prop-types';
import { memo } from 'react';
import { Col, Row, Switch, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    CheckOutlined,
    CloseCircleOutlined,
    EnvironmentOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { SearchBox } from '../../components';
import { mapViewDisplay } from '../../store/common';
import { PROJECT_TYPES } from '../../../static/utils';

const ProjectsSidebarHeader = memo((props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { mapView, activeProject } = useSelector(({ common }) => common);

    return (
        <>
        <div className='switch-body'>
        <>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseCircleOutlined />}
                        onChange={props.setActive}
                        defaultChecked
                    />
                    <span>
                   {`${props.active ? 'Active' : 'Completed'} Projects (${
                       props.projectsMeta?.total_count || 0
                    })`}
                    </span>
                </>
            <div className="d-flex">
                <Tooltip title="Map View">
                    <div
                        className={`map-toggle-btn ${mapView ? 'active-toggle' : ''}`}
                        onClick={() => {
                            dispatch(mapViewDisplay(true));
                            history.push('/projects');
                        }}
                        >
                        <EnvironmentOutlined
                            style={{
                                fontSize: '1rem',
                                marginRight: '4px',
                            }}
                            />
                    </div>
                </Tooltip>
                <Tooltip title="Details View">
                    <div
                        className={`map-toggle-btn ${!mapView ? 'active-toggle' : ''}`}
                        onClick={() => {
                            dispatch(mapViewDisplay(false));
                            let url = `/projects/${activeProject?.id}`;
                            if (activeProject?.type === PROJECT_TYPES.SUB_PROJECT) {
                                url = `/projects/${activeProject.masterProject.id}/sub-project/${activeProject.id}?page=insights`;
                            }
                            history.push(url);
                        }}
                    >
                        <UnorderedListOutlined
                            style={{
                                fontSize: '1rem',
                                marginRight: '4px',
                            }}
                            />
                    </div>
                </Tooltip>
            </div>
            </div>
            <SearchBox
                className="mb-20 mt-10"
                placeholder="Search Projects ..."
                value={props.projectSearchText}
                onChange={(ev) => {
                    props.setProjectSearchText(ev.target.value);
                }}
            />
        </>
    );
});

ProjectsSidebarHeader.defaultProps = {
    active: true,
    projectsMeta: {},
    projectSearchText: '',
    setActive: () => {},
    setProjectSearchText: () => {},
};

ProjectsSidebarHeader.propTypes = {
    active: PropTypes.bool,
    setActive: PropTypes.func,
    projectsMeta: PropTypes.object,
    projectSearchText: PropTypes.string,
    setProjectSearchText: PropTypes.func,
};

export default ProjectsSidebarHeader;
