import { memo } from 'react';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PROJECTS, PROJECT_TYPES } from '../../../../static/utils';
import { etActiveProject, setShowSidebar } from '../../../store/common';
import SubProjectDetailTabs from './tabs';
import AlectifyText from 'static/texts.json';

const SubProjectDetail = memo((props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { activeProject, activeSubProject, showSidebar } = useSelector(({ common }) => common);

    const goToProjects = () => {
        history.push(PROJECTS);
        dispatch(setShowSidebar(!showSidebar));
    };
    return (
        <>
            <div className="mt-20" />
            <Breadcrumb separator=">">
                <Breadcrumb.Item onClick={goToProjects}>
                    <span className="cursor-pointer">{AlectifyText.PROJECTS}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item
                // className="cursor-pointer"
                // onClick={() => {
                //     dispatch(
                //         setActiveProject({
                //             ...activeProject?.masterProject,
                //             type: PROJECT_TYPES.MASTER_PROJECT,
                //             masterProject: activeProject?.masterProject,
                //         }),
                //     );
                //     history.push(`${PROJECTS}/${activeProject?.masterProject?.id}`);
                // }}
                >
                    {activeProject?.masterProject?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span>{activeProject?.name}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push(`${PROJECTS}/${activeProject?.id}`)}>
                    <span className="cursor-pointer">{AlectifyText.SUB_PROJECT}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span className="cursor-pointer">{activeSubProject?.name}</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <SubProjectDetailTabs {...props} />
        </>
    );
});

export default SubProjectDetail;
