import { useState } from 'react';
import { debounce, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    AutoComplete,
    Button,
    Col,
    Collapse,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Spin,
    Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getOrganizations, uploadVendorAssociatesDocuments } from '../../../../../../../services';
import {
    INPUT_DATE_FORMAT,
    SERVER_DATE_FORMAT,
    BASIC_REQUIRED_VALIDATION_RULES,
    calculateAvgMargin,
} from '../../../../../../../../static/utils';
import {
    getBiddingFinancialVendorItems,
    getBiddingProjectFinancialSummary,
} from '../../../../../../../store/bidding-management';
import {
    createBiddingVendorEstimation,
    uploadMainProjectBiddingVendorDocs,
} from '../../../../../../../services/bidding-management';

const { Panel } = Collapse;

function CreateVendorItem() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { projectId } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [avgMargin, setAvgMargin] = useState(0);
    const [vendors, setVendors] = useState({
        data: [],
        value: '',
        fetching: false,
    });

    const onSearchVendor = debounce(async (value) => {
        try {
            if (!value) {
                throw new Error();
            }
            setVendors({ ...vendors, fetching: true });
            const response = await getOrganizations({ search: value });
            setVendors({
                ...vendors,
                fetching: false,
                data: response?.data,
                value,
            });
        } catch (error) {
            setVendors({ ...vendors, fetching: false, data: [] });
        }
    }, 500);

    const uploadDocuments = async (projectId, vendorAssociateId, values) => {
        try {
            const { documents } = values;
            const formData = new FormData();
            documents.fileList.map((file) => formData.append('files', file.originFileObj));
            await uploadMainProjectBiddingVendorDocs(projectId, vendorAssociateId, formData);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            setSubmitting(true);
            const dto = {
                ...values,
                description: values?.item_detail,
                master_project: projectId,
                unit_price: values?.total_sell / values?.quantity,
                entry_date: values.entry_date.format(SERVER_DATE_FORMAT),
            };
            const response = await createBiddingVendorEstimation(projectId, dto);
            if (values?.documents) {
                await uploadDocuments(projectId, response?.data?.id, values);
            }
            setSubmitting(false);
            message.success('Created Successfully');
            form.resetFields();
            setAvgMargin(0);
            dispatch(getBiddingFinancialVendorItems(projectId, { page: 1, per_page: 10 }));
            dispatch(getBiddingProjectFinancialSummary(projectId));
        } catch (error) {
            setSubmitting(false);
        }
    };

    const onCostSellChange = () => {
        const { total_cost, total_sell } = form.getFieldsValue();
        const margin = calculateAvgMargin(total_cost, total_sell);
        setAvgMargin(margin);
    };

    return (
        <Collapse>
            <Panel header="Create New Entry" key="1" className="white-bg">
                <Spin spinning={submitting}>
                    <Form layout="vertical" form={form} onFinish={onSubmit}>
                        <Row gutter={30}>
                            <Col span={8}>
                                <Form.Item
                                    label="Entry Date"
                                    name="entry_date"
                                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                                >
                                    <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Vendor"
                                    name="vendor"
                                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                                >
                                    <AutoComplete
                                        showSearch
                                        placeholder="Enter Vendor"
                                        options={map(vendors.data, (org) => ({
                                            name: org?.name,
                                            value: org?.name,
                                        }))}
                                        onSearch={onSearchVendor}
                                        notFoundContent={
                                            vendors.fetching ? (
                                                <div className="text-center">
                                                    <Spin />
                                                </div>
                                            ) : null
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Quote #" name="vendor_quote_no">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Sell"
                                    name="total_sell"
                                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                                >
                                    <InputNumber
                                        prefix="$"
                                        precision={2}
                                        min={0}
                                        maxLength={9}
                                        step={0.1}
                                        className="full-width"
                                        onChange={onCostSellChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Cost"
                                    name="total_cost"
                                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                                >
                                    <InputNumber
                                        prefix="$"
                                        precision={2}
                                        min={0}
                                        maxLength={9}
                                        step={0.1}
                                        className="full-width"
                                        onChange={onCostSellChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Avg. Margin"
                                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                                >
                                    <Input
                                        suffix="%"
                                        precision={2}
                                        min={0}
                                        step={0.1}
                                        className="full-width"
                                        readOnly
                                        value={avgMargin}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Equipment Name / Catalog #:"
                                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                                    name="tag_name"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Quantity"
                                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                                    name="quantity"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Equipment Desciption / Item Details"
                                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                                    name="item_detail"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Documents" name="documents">
                                    <Upload multiple beforeUpload={() => false}>
                                        <Button icon={<UploadOutlined />}>Select Files</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button type="primary" htmlType="submit" loading={submitting}>
                            Submit
                        </Button>
                    </Form>
                </Spin>
            </Panel>
        </Collapse>
    );
}

export default CreateVendorItem;
