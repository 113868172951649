import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Select, Form, Button, Checkbox, message, Spin } from 'antd';
import { BASIC_REQUIRED_VALIDATION_RULES } from '../../../../../../static/utils';
import { sendCombinedPdf } from '../../../../../services';

const BINDER_CHOICES = [
    {
        label: 'Financial Summary',
        value: 'finance_summary',
    },
    {
        label: 'Pricing Breakdown',
        value: 'pricing_breakdown',
    },
    {
        label: 'Billing Vendor Invoice',
        value: 'dist_pos',
    },
    {
        label: 'Billing Customer Invoice',
        value: 'cust_pos',
    },
    {
        label: 'Financial Documents',
        value: 'associate_detail_docs',
    },
    {
        label: 'Vendor Invoices',
        value: 'dist_po_docs',
    },
    {
        label: 'Customer Invoices',
        value: 'cust_po_docs',
    },
];

const DownloadDigitalBinder = (props) => {
    const { projectId } = useParams();
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (values) => {
        try {
            setSubmitting(true);
            await sendCombinedPdf(projectId, values);
            message.success('Email Sent!');
            setSubmitting(false);
            props.onCancel();
        } catch (e) {
            setSubmitting(false);
            message.error('Failed to send email');
        }
    };

    return (
        <Modal
            open={props.visible}
            footer={null}
            okText="Send Email"
            onCancel={props.onCancel}
            title="Send Combined PDF"
        >
            <Spin spinning={submitting}>
                <Form onFinish={onSubmit} layout="vertical">
                    <Form.Item
                        label="Send To"
                        name="email_ids"
                        rules={BASIC_REQUIRED_VALIDATION_RULES}
                    >
                        <Select mode="tags" options={null} placeholder="Enter Email Addresses" />
                    </Form.Item>
                    <Form.Item
                        label="Select Features"
                        name="choice"
                        rules={BASIC_REQUIRED_VALIDATION_RULES}
                    >
                        <Checkbox.Group
                            className="checkbox-group-block-items"
                            options={BINDER_CHOICES}
                        />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" block loading={submitting}>
                        Submit
                    </Button>
                </Form>
            </Spin>
        </Modal>
    );
};

DownloadDigitalBinder.defaultProps = {
    visible: false,
    onCancel: () => {},
};

DownloadDigitalBinder.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default DownloadDigitalBinder;
