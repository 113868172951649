import { memo, useState } from 'react';
import { DoubleRightOutlined } from '@ant-design/icons';
import { Col, Row, Spin, Layout, Tooltip } from 'antd';
import ProjectsSidebar from './ProjectsSidebar';

const { Content } = Layout;

const ProjectDetails = (props) => {
    return (
        <Spin spinning={false}>
            <Content className={'content-width-sidebar'}>
                {props.children}
            </Content>
        </Spin>
    );
};

export default memo(ProjectDetails);
