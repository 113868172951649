// React, React-redux, react-router-dom imports
import { useHistory } from 'react-router-dom';

// Ant Design components
import { Space, Tooltip, Typography } from 'antd';

// Custom components
import { EditIcon } from '../icons/custom-icons';
import { BIDDING_MANAGEMENT, PROJECTS } from 'static/utils';
import AlectifyLogo from '../../assets/images/alectify-logo.svg';
import LocationMarkerGreen from '../../assets/images/location-marker-green.svg';
import AlectifyText from 'static/texts.json';

// Stylesheet import
import './masterProjectDetailCard.scss';

const MasterProjectDetailCard = ({ isBiddingPageActive, biddingActiveProject, project }) => {
    const history = useHistory();

    const handleNavigation = () => {
        if (isBiddingPageActive) {
            history.push(`${BIDDING_MANAGEMENT}/create/${biddingActiveProject?.id}`);
        } else {
            history.push(`/projects/master/${project?.id}`);
        }
    };

    return (
        <div className="master-project-detail-card">
            <div className="header">
                <div className="d-flex">
                    <span>
                        <Typography.Title>{project?.name || '-'}</Typography.Title>
                    </span>
                    <div className="cursor-pointer ml-10" onClick={handleNavigation}>
                        <Tooltip title={AlectifyText.EDIT}>
                            <EditIcon />
                        </Tooltip>
                    </div>
                </div>
                {project?.is_finished && (
                    <div className="completed-container">{AlectifyText.COMPLETED}</div>
                )}
            </div>
            <div className="body">
                <Typography.Paragraph>{project?.description || '-'}</Typography.Paragraph>
                <div className="details">
                    <div className="organization">
                        <Space>
                            <img src={AlectifyLogo} alt="" />
                            {project?.owner || '-'}
                        </Space>
                    </div>
                    <div className="location">
                        <Space>
                            <img src={LocationMarkerGreen} alt="" />
                            {project?.address || '-'}
                        </Space>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MasterProjectDetailCard;
