import { useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SLineThumbnail } from '../../../components';
import { fetchSinglelines } from '../../../store/singleline';
import { SLINES } from '../../../../static/utils';

function SinglelineListing({ history }) {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(({ singleline }) => singleline);

  useEffect(() => {
    dispatch(fetchSinglelines());
  }, []);

  return (
    <div className="container page-content-container">
      <Spin spinning={loading}>
        <h1 className="project-heading text-center">Project 1</h1>
        <Row gutter={30}>
          <Col span={8} />
          <Col span={16}>
            <Row gutter={30}>
              {list
                                    && list.map((sline) => (
                                      <Col span={6} key={sline.name + sline.id}>
                                        <SLineThumbnail
                                          {...sline}
                                          onView={() => history.push(`${SLINES}/${sline.id}`)}
                                        />
                                      </Col>
                                    ))}
            </Row>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default SinglelineListing;
