import React from "react";
import dayjs from "dayjs";

const WelcomeMessage = () => {
    const GreetingEnum = {
        MORNING: "Good Morning",
        AFTERNOON: "Good Afternoon",
        EVENING: "Good Evening",
        NIGHT: "Good Night",
      };
  const determineGreeting = () => {
    const currentHour = dayjs().hour();

    if (currentHour >= 4 && currentHour < 12) {
      return GreetingEnum.MORNING;
    } if (currentHour >= 12 && currentHour < 17) {
      return GreetingEnum.AFTERNOON;
    } if (currentHour >= 17 && currentHour < 21) {
      return GreetingEnum.EVENING;
    }
      return GreetingEnum.NIGHT;

  };

  const greeting = determineGreeting();

  return <>{greeting}</>;
};

export default WelcomeMessage;
