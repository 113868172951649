// Library imports
import { useDispatch } from 'react-redux';
import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Ant Design component
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Space, Tooltip, Typography, notification } from 'antd';

// Custom component imports
import AlectifyText from 'static/texts.json';
import { PAGINATION } from 'static/utils/pagination';
import { BIDDING_MANAGEMENT, PROJECTS } from 'static/utils';
import { PROJECT_SETUP, setEnableEvent } from 'app/store/common';
import { getAllBiddingProjects } from 'app/store/bidding-management';
import { BidWonIcon, CheckMarkIcon, EditIcon, MoreVerticalIcon } from '../icons/custom-icons';
import { updateBidStatusForContractor } from 'app/services/bidding-management';
import ProjectOwnerModal from 'app/pages/BiddingManagement/Layout/Sidebar/ProjectOwnerModal';
import BidManagementWarningModal from '../modals/upload-document/bid-management/BidManagementWarningModal';

// Stylesheet import
import './subProjectCard.scss';

const SubProjectCard = ({
    activeProject,
    subProject,
    handleNavigation,
    isBiddingPageActive,
    getContractorProjects,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showBidWonWarningModal, setShowBidWonWarningModal] = useState(false);
    const [selectedContractorId, setSelectedContractorId] = useState('');
    const [contractorAlreadyWon, setContractorAlreadyWon] = useState(false);
    const [showProjectOwnerModal, setShowProjectOwnerModal] = useState(false);
    const gotoSubProjectEdit = (subProject, event) => {
        event.stopPropagation();
        dispatch({ type: PROJECT_SETUP, payload: false });
        history.push(`${PROJECTS}/${activeProject?.id}/${subProject?.id}/edit?activeTab=1`);
    };
    const generateItems = (contractor) => {
        const items = [
            {
                label: 'Edit',
                key: '1',
                onClick: (ev) => {
                    ev.domEvent.stopPropagation();
                    history.push(
                        `${BIDDING_MANAGEMENT}/contractor/create/${activeProject.id}/${contractor.id}`,
                    );
                },
            },
        ];

        if (!contractor?.has_won) {
            items.push({
                label: 'Bid Won',
                key: '2',
                onClick: (ev) => {
                    ev.domEvent.stopPropagation();
                    contractorBidWon(contractor);
                },
            });
        }
        if (contractor?.has_won) {
            items.push({
                label: 'Auto Transfer Project',
                key: '3',
                onClick: (ev) => {
                    ev.domEvent.stopPropagation();
                    setShowProjectOwnerModal(true);
                    setSelectedContractorId(contractor.id);
                },
            });
        }
        return items;
    };

    const contractorBidWon = (contractorDetail) => {
        const { id, has_won } = contractorDetail;
        setSelectedContractorId(id);
        if (has_won) {
            setShowBidWonWarningModal(true);
            setContractorAlreadyWon(has_won);
        } else {
            executeContractorBidUpdate(id);
        }
    };

    const executeContractorBidUpdate = async (contractorId) => {
        try {
            const response = await updateBidStatusForContractor({ contractor_id: contractorId });
            if (response.success) {
                notification.success({
                    message: `${response.message}`,
                });
                const params = {
                    page: PAGINATION.DEFAULT_START_PAGE_1,
                    per_page: PAGINATION.DEFAULT_PAGE_SIZE_20,
                };
                dispatch(getAllBiddingProjects(params));
            }
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Something unexpected happened',
            });
        } finally {
            getContractorProjects();
        }
    };

    const confirmAndUpdateBidWinner = (event) => {
        dispatch(setEnableEvent(false));
        event.stopPropagation();
        setShowBidWonWarningModal(false);
        executeContractorBidUpdate(selectedContractorId);
    };

    const closeProjectOwnerModal = () => {
        setShowProjectOwnerModal(false);
    };

    return (
        <>
            <div className="sub-project-card" onClick={() => handleNavigation(subProject)}>
                <div className="header">
                    <Typography.Title ellipsis={{ tooltip: true, rows: 1 }}>
                        {subProject?.name || '-'}
                    </Typography.Title>

                    {isBiddingPageActive ? (
                        <div className="draft-edit-icons">
                            <div
                                className="bidding-icons-container"
                                onClick={(event) => event.stopPropagation()}
                            >
                                <Dropdown
                                    className="ml-5"
                                    menu={{ items: generateItems(subProject) }}
                                >
                                    <MoreVerticalIcon />
                                </Dropdown>
                            </div>
                        </div>
                    ) : (
                        <div className="draft-edit-icons">
                            <div
                                onClick={(event) => gotoSubProjectEdit(subProject, event)}
                                className="ml-5 bidding-icons-container"
                            >
                                <Tooltip title={AlectifyText.EDIT}>
                                    <EditIcon />
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`cursor-pointer alectify-sub-project-card-description body`}>
                    <Typography.Paragraph>{subProject?.description || '-'}</Typography.Paragraph>
                </div>
                {subProject?.is_finished && (
                    <div className="bid-won-container">{AlectifyText.COMPLETED}</div>
                )}
                {subProject?.is_draft && <div className="draft-container">Draft</div>}
                {subProject.has_won ? (
                    <div className="bid-won-container">
                        <Space>
                            <BidWonIcon style={{ marginTop: '4px' }} />
                            {AlectifyText.BID_WON}
                        </Space>
                    </div>
                ) : null}
            </div>
            {showBidWonWarningModal && (
                <BidManagementWarningModal
                    showBidWonWarningModal={showBidWonWarningModal}
                    setShowBidWonWarningModal={setShowBidWonWarningModal}
                    confirmAndUpdateBidWinner={confirmAndUpdateBidWinner}
                    contractorAlreadyWon={contractorAlreadyWon}
                />
            )}
            {showProjectOwnerModal && (
                <ProjectOwnerModal
                    visible={showProjectOwnerModal}
                    contractorId={selectedContractorId}
                    onCancel={closeProjectOwnerModal}
                />
            )}
        </>
    );
};

export default memo(SubProjectCard);
