const MarkerHoveredIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="61" height="60" rx="31.5" fill="#D8F6C7" />
      <rect x="0.5" y="0.5" width="61" height="60" rx="31.5" stroke="#13A90F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8582 12.9973C21.6089 9.09913 26.6959 6.90918 32.0002 6.90918C37.3045 6.90918 42.3915 9.09913 46.1422 12.9973C49.8929 16.8954 52 22.1824 52 27.6952C52 33.208 49.8929 38.495 46.1422 42.3931L32.0002 57.091L17.8582 42.3931C16.001 40.463 14.5277 38.1716 13.5225 35.6497C12.5174 33.1278 12 30.4249 12 27.6952C12 24.9655 12.5174 22.2625 13.5225 19.7406C14.5277 17.2187 16.001 14.9273 17.8582 12.9973ZM32.0002 33.6337C33.5156 33.6337 34.969 33.008 36.0406 31.8944C37.1121 30.7807 37.7141 29.2702 37.7141 27.6952C37.7141 26.1202 37.1121 24.6097 36.0406 23.496C34.969 22.3823 33.5156 21.7566 32.0002 21.7566C30.4848 21.7566 29.0314 22.3823 27.9599 23.496C26.8883 24.6097 26.2863 26.1202 26.2863 27.6952C26.2863 29.2702 26.8883 30.7807 27.9599 31.8944C29.0314 33.008 30.4848 33.6337 32.0002 33.6337Z"
        fill="#0954F1"
      />
    </svg>
  );
};

export default MarkerHoveredIcon;
