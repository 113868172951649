import { Breadcrumb } from 'antd';
import ContractorsDetailTabs from './Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BIDDING_MANAGEMENT } from '../../../../../static/utils';
import AlectifyText from 'static/texts.json';
import { setShowBiddingSidebar } from 'app/store/common';

const ContractorDetails = (props) => {
    const { activeProject, activeContractor } = useSelector(({ bidding }) => bidding);
    const history = useHistory();
    const dispatch = useDispatch();

    const goToBiddingProjects = () => {
        history.push(`${BIDDING_MANAGEMENT}`);
        dispatch(setShowBiddingSidebar(true));
    };
    return (
        <>
            <div className="mt-20" />
            <Breadcrumb separator=">">
                <Breadcrumb.Item onClick={goToBiddingProjects}>
                    <span className="cursor-pointer">{AlectifyText.BIDDING_MANAGEMENT}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span>{activeProject?.name}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    onClick={() => history.push(`${BIDDING_MANAGEMENT}/${activeProject.id}`)}
                >
                    <span className="cursor-pointer">{AlectifyText.CONTRACTOR}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span>{activeContractor?.name}</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <ContractorsDetailTabs {...props} />
        </>
    );
};

export default ContractorDetails;
