// React imports
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useEffect, useRef, useState } from 'react';

// Ant Design components
import { Divider, Skeleton, Spin, Typography } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

// Lib imports
import { isEmpty } from 'lodash';

// Custom components
import AlectifyText from 'static/texts.json';
import { PROJECTS } from 'static/utils';
import AlectifyInput from '../alectify-input';
import { MESSAGES } from 'static/utils/messages';
import AlectifyEmpty from '../empty/alectifyEmpty';
import { PAGINATION } from 'static/utils/pagination';
import { PROJECT_SETUP, setShowSidebar } from 'app/store/common';
import { GreenDotIcon, GreyDotIcon, SearchIcon } from '../icons/custom-icons';

// Stylesheet import
import './styles.scss';

const { Text } = Typography;

const ProjectSidebar = ({
    onSearchMasterProject,
    masterProjects,
    onProjectClickHandler,
    getAllMasterProjects,
    resetSidebarMasterProjectData,
}) => {
    const { activeProject } = useSelector(({ common }) => common);
    const dispatch = useDispatch();
    const history = useHistory();
    const pageRef = useRef(PAGINATION.DEFAULT_START_PAGE_1);
    const pageSearchRef = useRef(PAGINATION.DEFAULT_START_PAGE_1);
    const searchRef = useRef('');

    const gotoCreateNewMasterProject = () => {
        dispatch({ type: PROJECT_SETUP, payload: true });
        dispatch(setShowSidebar(false));
        history.push(`${PROJECTS}/create-master`);
    };
    const fetchMoreData = () => {
        pageRef.current += 1;
        if (!isEmpty(searchRef.current)) {
            pageSearchRef.current += 1;
            onSearchMasterProject(searchRef.current, pageSearchRef.current);
            return;
        }
        getAllMasterProjects('', pageRef.current);
    };

    const handleSearchChange = (ev) => {
        searchRef.current = ev.target.value;
        onSearchMasterProject(searchRef.current, PAGINATION.DEFAULT_START_PAGE_1);
    };

    useEffect(() => {
        resetSidebarMasterProjectData(PAGINATION.DEFAULT_START_PAGE_1);
        return () => {
            searchRef.current = '';
            pageSearchRef.current = PAGINATION.DEFAULT_START_PAGE_1;
            pageRef.current = PAGINATION.DEFAULT_START_PAGE_1;
        };
    }, []);
    return (
        <Spin spinning={masterProjects.fetching}>
            <div className="project-sidebar-container">
                <div className="project-sidebar-header">
                    <div className="project-header">
                        <h2>{AlectifyText.CONSTRUCTION_PROJECT}</h2>
                    </div>
                    <div className="project-create-new-link" onClick={gotoCreateNewMasterProject}>
                        <Text underline>{AlectifyText.CREATE_NEW}</Text>
                    </div>

                    <AlectifyInput
                        name="Search"
                        type="text"
                        placeholder="Search"
                        onChange={handleSearchChange}
                        prefix={<SearchIcon />}
                        className="alectify-table-search-input"
                        allowClear
                    />
                </div>
                <div id="scrollableDiv" className="project-sidebar-content">
                    <InfiniteScroll
                        key="activity-sidebar"
                        dataLength={masterProjects?.data?.length}
                        next={fetchMoreData}
                        hasMore={masterProjects?.meta?.total_count > masterProjects?.data?.length}
                        loader={
                            !isEmpty(masterProjects.data) ? (
                                <Skeleton avatar paragraph={{ rows: 1 }} active />
                            ) : null
                        }
                        scrollableTarget="scrollableDiv"
                        endMessage={
                            !masterProjects.fetching && !isEmpty(masterProjects.data) ? (
                                <Divider plain>{MESSAGES.ALERTS.NOTHING_MORE}</Divider>
                            ) : null
                        }
                        height={760}
                    >
                        {!isEmpty(masterProjects?.data) ? (
                            masterProjects?.data?.map((project, index) => (
                                <div
                                    key={index}
                                    onClick={() => onProjectClickHandler(project)}
                                    className={`project-sidebar-box ${
                                        activeProject?.id === project?.id ? 'active-project' : ''
                                    }`}
                                >
                                    <div className="project-sidebar-title">
                                        {project?.is_finished ? <GreyDotIcon /> : <GreenDotIcon />}
                                        <span>{project?.name || '-'}</span>
                                    </div>
                                    <div className="project-sidebar-subProject-count">{`(${
                                        project?.sub_project_count || 0
                                    })`}</div>
                                </div>
                            ))
                        ) : (
                            <div className="alectify-centered-container">
                                <AlectifyEmpty description="Project not found" />
                            </div>
                        )}
                    </InfiniteScroll>
                </div>

                <div className="project-sidebar-footer">
                    <div className="project-sidebar-active">
                        <div className="footer-icon">
                            <GreenDotIcon />
                        </div>
                        <span>
                            {AlectifyText.ACTIVE}
                            {`(${
                                masterProjects?.meta?.total_count -
                                    masterProjects?.meta?.finished_master_proj_count || 0
                            })`}
                        </span>
                    </div>
                    <div className="project-sidebar-completed">
                        <GreyDotIcon />
                        <span>
                            {AlectifyText.COMPLETED}
                            {`(${masterProjects?.meta?.finished_master_proj_count || 0})`}
                        </span>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default memo(ProjectSidebar);
