// React imports
import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Ant Design components
import { Breadcrumb } from 'antd';

// Custom components
import MasterProjectTabs from './tabs';
import { PROJECTS } from 'static/utils';
import AlectifyText from 'static/texts.json';
import { setShowSidebar } from 'app/store/common';

const MasterProjects = memo(() => {
    const { activeProject } = useSelector(({ common }) => common);
    const history = useHistory();
    const dispatch = useDispatch();

    const goToProjects = () => {
        history.push(PROJECTS);
        dispatch(setShowSidebar(true));
    };

    return (
        <>
            <div className="mt-20 ml-10" />
            <Breadcrumb separator=">" className="ml-20">
                <Breadcrumb.Item onClick={goToProjects}>
                    <span className="cursor-pointer">{AlectifyText.PROJECT}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{activeProject?.name}</Breadcrumb.Item>
            </Breadcrumb>
            <MasterProjectTabs />
        </>
    );
});

export default MasterProjects;
