import moment from 'moment';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Spin } from 'antd';
import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_START_PAGE,
    DISPLAY_DATE_FORMAT,
    PROJECT_COST_REASONS,
    renderAmount,
} from '../../../../../../../../static/utils';
import { fetchVendorAssociatesDocuments } from '../../../../../../../services';
import { AlectifyTable } from '../../../../../../../components';
import useDocumentColumns from './effects/useDocumentColumns';

const VendorAssociateDetail = memo((props) => {
    const columns = useDocumentColumns();
    const { projectId } = useParams();
    const [documents, setDocuments] = useState({ data: null, fetching: false, total: 0 });

    const getDocuments = async (pagination) => {
        try {
            const params = {
                page: pagination?.page || DEFAULT_START_PAGE,
                per_page: pagination?.pageSize || DEFAULT_PAGE_SIZE,
            };
            const { id, vendorItemId } = props.data;
            setDocuments({ ...documents, fetching: true });
            const response = await fetchVendorAssociatesDocuments(
                projectId,
                vendorItemId,
                id,
                params,
            );
            setDocuments({
                ...documents,
                fetching: false,
                data: response?.data,
                total: response.meta.total_count,
            });
        } catch (error) {
            setDocuments({ ...documents, fetching: false });
        }
    };

    useEffect(() => {
        getDocuments();
    }, [props.visible]);

    return (
        <Modal
            centered
            width={900}
            footer={false}
            open={props.visible}
            onCancel={props.onCancel}
            title="Price Summary Detail"
            className="equipment-detail-modal"
        >
            <Card title="Information" className="mb-20">
                <Row className="details-view" gutter={30}>
                    <Col span={8}>
                        <label>Date:</label>
                        <span>
                            {moment(props.data?.created_at).format(DISPLAY_DATE_FORMAT) || '-'}
                        </span>
                    </Col>
                    <Col span={8}>
                        <label>Reason:</label>
                        <span>{PROJECT_COST_REASONS[props.data?.reason] || 'Original'}</span>
                    </Col>
                    <Col span={8}>
                        <label>Quote #:</label>
                        <span>{props.data?.quote_no || '-'}</span>
                    </Col>
                    <Col span={8}>
                        <label>Total Sell:</label>
                        <span>{renderAmount(props.data?.sell)}</span>
                    </Col>
                    <Col span={8}>
                        <label>Total Cost:</label>
                        <span>{renderAmount(props.data?.cost)}</span>
                    </Col>
                    <Col span={8}>
                        <label>Avg. Margin:</label>
                        <span>{props.data?.margin_percent || 0} %</span>
                    </Col>
                    {/* <Col span={24} className="mb-0">
                        <label>Comments:</label>
                        <span>{props.data?.comments || '-'}</span>
                    </Col> */}
                </Row>
            </Card>
            <Card title="Attachment(s)">
                <Spin spinning={documents.fetching}>
                    <AlectifyTable
                        size="small"
                        columns={columns}
                        loading={false}
                        selectRows={false}
                        total={documents.total}
                        dataSource={documents.data}
                        onDataFetch={getDocuments}
                    />
                </Spin>
            </Card>
        </Modal>
    );
});

VendorAssociateDetail.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => {},
};

VendorAssociateDetail.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default VendorAssociateDetail;
