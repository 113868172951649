import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { memo, useEffect, useState } from 'react';
import { Col, Empty, Pagination, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { SLineThumbnail } from '../../../../../components';
import { fetchSlinesByProject } from '../../../../../services';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS, PROJECTS, SLINES } from '../../../../../../static/utils';

const BirdsEyeView = () => {
    const history = useHistory();
    const { subProjectId } = useParams();
    const { activeProject } = useSelector(({ common }) => common);
    const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
    const [birdsEyesViews, setBirdsEyesViews] = useState({ fetching: false, data: [], total: 0 });

    const getSinglelines = async () => {
        try {
            const params = {
                page: pagination.page,
                per_page: pagination.pageSize,
            };
            setBirdsEyesViews({ ...birdsEyesViews, fetching: true });
            const response = await fetchSlinesByProject(subProjectId, params);
            setBirdsEyesViews({
                ...birdsEyesViews,
                fetching: false,
                data: response.data,
                total: response?.meta?.total_count,
            });
        } catch (error) {
            console.log(error);
            setBirdsEyesViews({ ...birdsEyesViews, fetching: false });
        }
    };

    useEffect(() => {
        getSinglelines();
    }, [subProjectId, pagination]);

    return (
        <>
            <Row gutter={30}>
                {!isEmpty(birdsEyesViews.data) ? (
                    birdsEyesViews.data.map((sline) => (
                        <Col span={6} key={sline.name + sline.id}>
                            <SLineThumbnail
                                {...sline}
                                onView={() =>
                                    history.push({
                                        pathname: `${PROJECTS}/${activeProject?.id}${SLINES}/${sline.id}`,
                                        state: activeProject?.name,
                                    })
                                }
                            />
                        </Col>
                    ))
                ) : (
                    <Col span={24}>
                        <Empty description="single lines are not found for this project" />
                    </Col>
                )}
            </Row>
            <Row justify="end">
                <Col>
                    {!isEmpty(birdsEyesViews.data) && (
                        <Pagination
                            showSizeChanger
                            total={birdsEyesViews.total}
                            defaultCurrent={pagination.page}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            defaultPageSize={DEFAULT_PAGE_SIZE}
                            onChange={(page, pageSize) => setPagination({ page, pageSize })}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default memo(BirdsEyeView);
