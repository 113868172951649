import React, { useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/webviewer';
import { saveAs } from 'file-saver';
import { notification } from 'antd';
import { s3 } from '../../../static/utils';

export function PdfEditor({
  pdf, s3Key, uploadPdf = () => {}, onClosePdfEditor = () => {},
}) {
  const viewer = useRef(null);
  const [instance, setInstance] = useState(null);

  const initWebViewer = () => {
    WebViewer(
      {
        path: '/webviewer/lib',
        disabledElements: ['freeHandHighlightToolGroupButton', 'freeHandToolGroupButton'],
      },
      viewer.current,
    ).then((instance) => {
      const { documentViewer, annotationManager, Annotations } = instance.Core;

      setInstance(instance);

      instance.UI.setHeaderItems((header) => {
        header.push({
          type: 'actionButton',
          img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
          onClick: async (ev) => {
            const doc = documentViewer.getDocument();
            const xfdfString = await annotationManager.exportAnnotations();
            const options = { xfdfString };
            const data = await doc.getFileData(options);
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: 'application/pdf' });
            uploadPdf(blob);
            notification.success({
              message: 'Document Uploaded',
              description: 'Document has been successfully uploaded.',
            });
            // if (window.confirm('Do you want to download this document? ')) {
            //     const filename = s3Key.split('/').pop();
            //     saveAs(blob, filename);
            // }
            onClosePdfEditor();
          },
        });
      });
    });
  };

  useEffect(() => {
    initWebViewer();
  }, []);

  useEffect(() => {
    if (instance && pdf) {
      instance.loadDocument(pdf);
    }
  }, [pdf, instance]);

  return (
    <div className="webviewer" ref={viewer} style={{ height: '100%' }} />
  );
}
