import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import DeadlinesModal from './DeadlinesModal';
import { fetchSubProjects } from 'app/services';

const { Option } = Select;

const CalendarFilters = memo((props) => {
    const [form] = Form.useForm();
    const [subProjects, setSubProjects] = useState([]);
    const [showDeadlines, setShowDeadlines] = useState(false);

    const onMasterProjectSelect = (masterProjectId) => {
        setSubProjects([]);
        form.setFieldsValue({ sub_project: null });
        getSubProjects(masterProjectId);
    };

    const getSubProjects = async (masterProjectId) => {
        try {
            const response = await fetchSubProjects(masterProjectId);
            setSubProjects(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <Form layout="vertical" form={form}>
                <Row gutter={15} align="middle">
                    <Col span={8}>
                        <h2>Calendar: Upcoming Events</h2>
                    </Col>
                    <Col span={8}>
                        <Row gutter={15}>
                            <Col span={12}>
                                <Form.Item
                                    name="master_project"
                                    label="Master Project"
                                    className="mb-10"
                                >
                                    <Select
                                        placeholder="Please Select"
                                        onChange={onMasterProjectSelect}
                                    >
                                        {props.masterProjects?.map((project) => (
                                            <Option key={project?.id} value={project?.id}>
                                                {project?.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="sub_project" label="Sub Project" className="mb-10">
                                    <Select
                                        placeholder="Please Select"
                                        onChange={props.onProjectChange}
                                    >
                                        {subProjects?.map((project) => (
                                            <Option key={project?.id} value={project?.id}>
                                                {project?.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} className="text-right">
                        <Button type="primary" onClick={() => setShowDeadlines(true)}>
                            Today’s Deadlines
                        </Button>
                    </Col>
                </Row>
            </Form>
            {showDeadlines && (
                <DeadlinesModal visible={showDeadlines} onCancel={() => setShowDeadlines(false)} />
            )}
        </div>
    );
});

CalendarFilters.defaultProps = {
    masterProjects: [],
    onProjectChange: () => {},
};

CalendarFilters.propTypes = {
    masterProjects: PropTypes.array,
    onProjectChange: PropTypes.func,
};

export default CalendarFilters;
