import { isAssetDetailReviewActive } from "./configs";

export const DATE_FORMAT = 'DD-MM-YYYY';
export const DISPLAY_DATE_FORMAT = 'MMM DD, YYYY';
export const DISPLAY_DATETIME_FORMAT = 'MMM DD, YYYY  h:mm a';
export const CSV_DATE_FORMAT = 'MM/DD/YYYY';
export const INPUT_DATE_FORMAT = 'MM-DD-YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const DATES_KEYS = [
  'order_date',
  'approval_date',
  'release_date',
  'installation_date',
  'ship_date',
  'test_date',
  'pm_start_internal',
  'pm_start_external',
  'pm_end_external',
  'warranty_date',
  'order_date',
  'actual_order_date',
  'actual_approval_date',
  'actual_approved_date',
  'resubmittal_date',
  'release_date',
  'actual_release_date',
  'ship_date',
  'actual_test_date',
  'actual_ship_date',
  'expected_ship_date_by_customer',
  'actual_final_date',
];

export const PROJECT_ATTACHMENTS = [
  {
    heading: 'Electrical Packages',
    key: 'electricalPackages',
    dir_key: 'electrical_packages',
    order: 1,
  },
  {
    heading: 'Addendums',
    key: 'addendums',
    dir_key: 'addendums',
    order: 2,
  },
  {
    heading: 'Power Studies',
    key: 'powerStudies',
    dir_key: 'power_studies',
    order: 3,
  },
  {
    heading: 'Others',
    key: 'others',
    dir_key: 'others',
    order: 4,
  },
  {
    heading: 'Single Lines',
    key: 'single_lines',
    dir_key: 'single_lines',
    order: 5,
  },
  {
    heading: 'Site Layout',
    key: 'site_layout',
    dir_key: 'site_layout',
    order: 6,
  },
];

export const DATES_KEYS_WITH_VALUES = [
  { key: 'approval_date', value: 2 },
  { key: 'release_date', value: 3 },
  // { key: 'final_date', value: 4 },
  { key: 'ship_date', value: 5 },
  // { key: 'test_date', value: 6 },
];

export const PAGE_SIZE_OPTIONS = [5, 10, 20, 50];
export const DEFAULT_PAGE_SIZE = 5;
export const DEFAULT_START_PAGE = 1;

export const DATE_STATUSES = [
  {
    label: 'Ordered/Under Design',
    value: 1,
  },
  {
    label: 'Under Review',
    value: 2,
  },
  {
    label: 'Under Manufacturing',
    value: 3,
  },
  {
    label: 'Final Done',
    value: 4,
  },
  {
    label: 'Test Done',
    value: 5,
  },
  {
    label: 'Shipment',
    value: 6,
  },
  {
    label: 'Installation',
    value: 7,
  },
  {
    label: 'ReSubmitall',
    value: 8,
  },
];

export const DATE_FIELDS = [
  'order_date',
  'approval_date',
  'release_date',
  'final_date',
  'installation_date',
  'test_date',
  'ship_date',
  'pm_start_internal',
  'pm_start_external',
  'pm_end_external',
  'warranty_date',
];

export const MARKUP_COMMENTS = [
  {
    label: 'Approved',
    value: 1,
  },
  {
    label: 'Approved with comments',
    value: 2,
  },
  // {
  //   label: 'Resubmit',
  //   value: 3,
  // },
  // {
  //     label: 'Changes needed',
  //     value: 4,
  // },
  // {
  //     label: 'More info needed',
  //     value: 5,
  // },
];

export const COMMENTS_VALUE = {
  1: 'Approved',
  2: 'Approved with comments',
  3: 'Resubmit',
  // 4: 'Changes needed',
  // 5: 'More info needed',
};

export const TAGS_CSV_HEADERS = [
  'Reference #',
  'Tag',
  'Description',
  'Manufacturer',
  'Order Date',
  'Approval Drawings',
  'Release For Manufacture',
  'Final Date',
  'Test Date',
  'Ship Date',
];

export const TAGS_OPERATON_CSV_HEADERS = ['Reference #', 'Tag', 'Description', 'Manufacturer'];

export const PACKAGE_MENU = [
  {
    title: "Details",
    visible: true,
  },
  {
    title: "Review",
    visible: isAssetDetailReviewActive(),
  },
  {
    title: "Shipment",
    visible: true,
  },
  {
    title: "Documents",
    visible: true,
  },
]; // 'Manufacturing'

export const APPROVAL_STATUSES = {
  ORDERED: 1,
  SAVE_DRAFT: 2,
  SEND_FOR_APPROVAL: 3,
};

export const DISPLAY_SERVICE_HISTORY_STATUS = {
  0: 'Ongoing',
  1: 'Done',
  2: 'Delayed',
  3: 'Skip',
};

export const SERVICE_HISTORY_STATUS = {
  ONGOING: 0,
  DONE: 1,
  DELAYED: 2,
  SKIP: 3,
};

export const MAINTENANCE_INVENTORY_STATUSES = {
  1: 'Available',
  2: 'Low',
  3: 'Not Available',
};

export const DEFINITIONS = {
  TAG_NAME:
        'TAG is used to identify the equipment, normally what the consultant has labeled the equipment on the plans and should be Unqiue',
  APPROVAL_DATE:
        'If date of approval is known or in the past, please put here. Equipment with Approval requires review and approval of the manufacturers drawings by consultant or other before put into production',
  RELEASE_DATE:
        'If date of release is known, please enter here. Release is short for Release for Manufacture and is date which the manufacture gets the go ahead to start production of equipment identified in release.',
  TEST_DATE:
        'If date of test is known, please enter here. This is the optional date which is arranged with Manufacture to test the equipment at end of production. Sometimes called witness test when others are present to see testing procedures.',
  SHIP_DATE:
        'If date of shipment is known, please enter here. This is date which the equipment is expected to ship after all production and test are finished.',
};

export const MARKUP_TYPES = {
  // INFORMATION: 'Information',
  APPROVAL: 'Approval',
};

export const DOCUMENT_CATEGORIES = {
  PROJECT_DOCUMENTS: 'project_documents',
  EQUIPMENT_DOCUMENTS: 'equipment_documents',
};

export const PROJECT_SUB_FOLDERS = {
  ELECTRICAL_PACKAGES: 'electrical_packages',
  ADDENDUMS: 'addendums',
  POWER_STUDIES: 'power_studies',
  OTHERS: 'others',
  SINGLE_LINES: 'single_lines',
  SITE_PLAN: 'site_plan',
  PRIVATE: 'private',
};

export const EQUIPMENT_SUB_FOLDERS = {
  FINAL_DRAWINGS: 'final_drawings',
  INSTRUCTION_MANUAL: 'instruction_manual',
  TEST_REPORTS: 'test_reports',
  IMAGES: 'images',
  VIDEOS: 'videos',
};

// TODO: will check, might remove it
export const APPROVAL_TYPES = {
  APPROVAL_DRAWINGS: 'approval_drawings',
  INFORMATION_APPROVAL: 'information_for_approval',
  // CONSTRUCTION_DRAWINGS: 'construction_drawings',
};

export const APPROVAL_TYPES_V2 = {
    APPROVAL: 'approval',
    // APPROVED: 'approved',
};

export const CRITICAL_TAGS_COUNT = 5;

export const SENTIMENTS = ['Positive', 'Negative', 'Neutral', 'Mixed'];

export const EQUIPMENT_DOCUMENT_TYPES = {
  PACKAGE: 'package',
  EQUIPMENT: 'equipment',
};

export const PACKAGES_TYPES = {
  equiment_with_package_room: 'Package Room',
  individual_equiment_with_approval_drwng: 'Equipment with Approval Drawing',
  individual_equiment_without_approval_drwng: 'Equipment without Approval Drawing',
};

export const CUSTOMER_PO_REASONS = {
  invoice: 'Customer Invoice',
  add: 'Adder to Customer',
  deduct: 'Deduct from Customer',
};

export const PROJECT_COST_REASONS = {
  invoice: 'Vendor Invoice',
  add: 'Adder to Vendor',
  deduct: 'Deduct from Vendor',
};

export const ASSOCIATE_VENDOR_ITEMS_REASONS = {
  add: 'Adder to Vendor',
  deduct: 'Deduct from Vendor',
};

export const PROJECT_PERMISSIONS = {
  CAN_ADD_FILES: 'can_add_project',
  CAN_EDIT_PROJECT: 'can_edit_project',
  CAN_USE_ONE_TO_ONE_CHATS: 'can_modify_birds_eye_view_tags',
  CAN_MODIFY_TASKS: 'can_modify_tasks',
  CAN_USE_INBOX_PACKAGE_CHAT: 'can_use_inbox_package_chat',
  CAN_USE_PACKAGE_DETAIL: 'can_use_package_detail',
  CAN_VIEW_ACTIVITY: 'can_view_activity',
  CAN_VIEW_BIRDS_EYE_VIEW: 'can_view_birds_eye_view',
  CAN_VIEW_BIRDS_EYE_VIEW_TAGS: 'can_view_birds_eye_view_tags',
  CAN_VIEW_FILES: 'can_view_files',
  CAN_VIEW_TASKS: 'can_view_tasks',
  CAN_VIEW_INSIGHTS: 'can_view_insights',
  CAN_VIEW_PACKAGES: 'can_view_packages',
  CAN_USE_MANUAL_SCHEDULE: 'can_use_dates_tracking',
};

export const PLATFORM_PERMISSIONS = {
  CAN_ADD_FILES: 'can_add_project',
  CAN_USE_ADMIN_PANEL: 'can_use_admin_panel',
  CAN_USE_ONE_TO_ONE_CHATS: 'can_use_one_to_one_chats',
  CAN_VIEW_CONTACTS_SIDEBAR: 'can_view_contacts_sidebar',
  CAN_VIEW_DASHBOARD_SIDEBAR: 'can_view_dashboard_sidebar',
  CAN_VIEW_PROJECTS_SIDEBAR: 'can_view_projects_sidebar',
  CAN_VIEW_SHAREWITHME_SIDEBAR: 'can_view_sharedwithme_sidebar',
};

export const USER_PERMISSIONS = {
  ADMIN: "admin",
  STANDARD: "standard",
  EXTERNAL: "external",
};

export const PROJECT_TYPES = {
  SUB_PROJECT: "SUB_PROJECT",
  MASTER_PROJECT: "MASTER_PROJECT",
};

export const SHIPMENT_STATUSES = {
  OPEN: 1,
  SHIPPED: 2,
  CANCELLED: 3,
  PREPARING_TO_SHIP: 4,
};
