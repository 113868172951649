import PropTypes from 'prop-types';
import { debounce, map } from 'lodash';
import { memo, useState } from 'react';
import { AutoComplete, Form, Spin } from 'antd';
import { getOrganizations } from '../../../services';
import { BASIC_REQUIRED_VALIDATION_RULES } from '../../../../static/utils';

const OrganizationsAutoComplete = memo((props) => {
    const [vendors, setVendors] = useState({
        data: [],
        value: '',
        fetching: false,
    });

    const onSearchVendor = debounce(async (value) => {
        try {
            if (!value) {
                throw new Error();
            }
            setVendors({ ...vendors, fetching: true });
            const response = await getOrganizations({ search: value });
            setVendors({ ...vendors, fetching: false, data: response?.data, value });
        } catch (error) {
            setVendors({ ...vendors, fetching: false, data: [] });
        }
    }, 500);

    return (
        <Form.Item label={props.label} name={props.name} rules={props.required ? BASIC_REQUIRED_VALIDATION_RULES : []}>
            <AutoComplete
                showSearch
                placeholder={props.placeholder}
                options={map(vendors.data, (org) => {
                    return {
                        name: org?.name,
                        value: org?.name,
                    };
                })}
                onSearch={onSearchVendor}
                notFoundContent={
                    vendors.fetching ? (
                        <div className="text-center">
                            <Spin />
                        </div>
                    ) : null
                }
                disabled={props.disabled}
            />
        </Form.Item>
    );
});

OrganizationsAutoComplete.defaultProps = {
    required: true,
    name: 'organization',
    label: 'Organization',
    placeholder: "Enter Organization",
    disabled: false,
};

OrganizationsAutoComplete.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
};

export default OrganizationsAutoComplete;
