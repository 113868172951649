import { http, fileHttp, openHttp } from '../../static/utils/http';

export const login = async (body) => {
    try {
        const response = await http.post('/login/', body);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const refreshAuth = async (body) => {
    try {
        const response = await openHttp.post('/token/refresh/', body);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const register = async (body) => {
    try {
        const response = await http.post('/register/', body);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const registeredUsers = async () => {
    try {
        const response = await http.get('/users/');
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const updateUserProfile = async (data) => {
    try {
        const response = await fileHttp.patch('/user/', data);
        return response?.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const changePassword = async (body) => {
    try {
        const response = await http.patch('/change-password/', body);
        return response?.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestResetPassword = async (body) => {
    try {
        const response = await http.post('/request-reset-password/', body);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const verifyResetPasswordToken = async (uidb64, token) => {
    try {
        const response = await http.get(`/reset-password/${uidb64}/${token}/`);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (body) => {
    try {
        const response = await http.patch('/reset-password/', body);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const validateEmail = async (body) => {
    try {
        const response = await http.post('/user/validate-email/', body);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const fetchContacts = async (filters = {}) => {
    try {
        const params = {
            page: filters?.page || 1,
            per_page: filters?.pageSize || 10,
            name: filters?.searchText || null,
        };
        const response = await http.get('/user/contacts/', params);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const createContact = async (body) => {
    try {
        const response = await http.post('/user/contacts/', body);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const updateContact = async (contactId, body) => {
  try {
      const response = await http.patch(`/user/${contactId}`, body);
      return response?.data;
  } catch (error) {
      throw error;
  }
};

export const removeContacts = async (userIds = []) => {
    try {
        const response = await http.delete('/user/contacts/', { user_ids: userIds });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const fetchUserDetails = async () => {
    try {
        const response = await http.get('/user/');
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const fetchUsersWithBranches = async () => {
    try {
        const response = await http.get('/admin/users/');
        return response?.data;
    } catch (error) {
        throw error;
    }
};
