import { memo, useEffect, useState } from 'react';
import { Avatar, Breadcrumb, Tooltip, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import BidProjectDetailTabs from './Tabs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BIDDING_MANAGEMENT, makeAvatar } from '../../../../../static/utils';
import { fetchProjectUsersDetailForBidding } from '../../../../services';
import { MESSAGES } from '../../../../../static/utils/messages';
import { isEmpty } from 'lodash';
import AlectifyText from 'static/texts.json';
import { setShowBiddingSidebar } from 'app/store/common';

const BidProjectDetails = memo(() => {
    const { activeProject } = useSelector(({ bidding }) => bidding);
    const history = useHistory();
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const { user } = useSelector(({ auth }) => auth);

    useEffect(() => {
        fetchUsersForBidding();
    }, [activeProject?.id]);

    const fetchUsersForBidding = async () => {
        try {
            const response = await fetchProjectUsersDetailForBidding(activeProject?.id);
            const projectUsers = response?.data.filter((usr) => usr.id !== user.id);
            setUsers([...projectUsers]);
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || MESSAGES.GENERAL.UNEXPECTED_ERROR,
            });
        }
    };

    const goToBiddingProjects = () => {
        history.push(`${BIDDING_MANAGEMENT}`);
        dispatch(setShowBiddingSidebar(true));
    };

    return (
        <>
            <div className="mt-20 d-flex justify-space-between avatar-group-plus-icon">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item onClick={goToBiddingProjects}>
                    <span className="cursor-pointer">{AlectifyText.BIDDING_MANAGEMENT}</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{activeProject?.name}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="d-flex align-items-center">
                    <div className="fontSize-13 ">Team Members: </div>
                    {!isEmpty(users) ? (
                        <Avatar.Group className="ml-5" maxCount={4}>
                            {users?.map((member) => (
                                <Tooltip
                                    key={member.email}
                                    title={`${member.first_name} ${member.last_name}`}
                                >
                                    <Avatar
                                        className="avatar-icon"
                                        src={
                                            member?.image_url
                                                ? member?.image
                                                : makeAvatar(
                                                      member.first_name[0],
                                                      member.last_name[0],
                                                  )
                                        }
                                    />
                                </Tooltip>
                            ))}
                        </Avatar.Group>
                    ) : (
                        <div className="ml-5 fontSize-13">N/A</div>
                    )}
                </div>
            </div>
            <BidProjectDetailTabs />
        </>
    );
});

export default BidProjectDetails;
