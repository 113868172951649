import _ from 'lodash';
import {
  Checkbox, Modal, Row, Table,
} from 'antd';
import { useState, useEffect } from 'react';
import { PAGE_SIZE_OPTIONS } from '../../../../static/utils';

function RoleModal({
  title,
  visible = false,
  record,
  user,
  permissions = [],
  onOk,
  onCancel,
  addPermission,
  removePermission,
  loading,
}) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [displayPermissions, setDisplayPermissions] = useState([]);

  const getPermissions = () => {
    const dPermissions = [];
    permissions.map((p, idx) => {
      const hasPermission = _.filter(record?.permissions, ['id', p?.id]);

      dPermissions.push({
        ...p,
        exists: hasPermission.length,
      });
    });
    setDisplayPermissions(dPermissions);
  };

  useEffect(() => {
    getPermissions();
  }, [record, loading]);

  const getPermissionStatus = (record) => {
    const p = _.find(displayPermissions, ['id', record?.id]);
    return p.exists;
  };

  const columns = [
    {
      title: 'Feature Type',
      dataIndex: 'name',
      render: (value, rec) => value.split(' ')[0],
    },
    {
      title: 'Features',
      dataIndex: 'name',
      render: (value, rec) => value.split(' ')[1],
    },
    {
      title: 'Enable/Disable',
      dataIndex: 'name',
      render: (value, rec) => {

        // Edit access enabled
        let disabled = true;
        if (user?.is_superuser) {
          disabled = false;
        } else if (record?.owned_by?.id === user?.company?.id) { // if template is owned by me
          disabled = false;
        }

        return (
          <Checkbox
            disabled={disabled}
            checked={getPermissionStatus(rec)}
            onChange={(ev) => {
              if (ev?.target?.checked) {
                addPermission({
                  groupId: record?.id,
                  payload: {
                    permissions: [rec?.id],
                  },
                });
              } else {
                removePermission({
                  groupId: record?.id,
                  payload: {
                    permissions: [rec?.id],
                  },
                });
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <Modal
      title={title}
      centered
      width={800}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Close"
      maskClosable={false}
      className="equipment-detail-modal"
      footer={null}
    >
      <Row>
        <div style={{ width: '100%' }}>
          <Table
            columns={(columns)}
            loading={loading}
            onChange={(pagination) => {
              setPage(pagination?.current);
              setPageSize(pagination?.pageSize);
            }}
            pagination={{
              total: displayPermissions?.length,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              pageSize,
              showSizeChanger: true,
              current: page,
            }}
            dataSource={displayPermissions.map((d) => ({ ...d, key: d?.id }))}
          />
        </div>
      </Row>
    </Modal>
  );
}

export default RoleModal;
