import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce, sumBy } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Avatar, Input, Spin, Divider, Skeleton } from 'antd';
import { CloseCircleOutlined, MessageFilled } from '@ant-design/icons';
import { makeAvatar } from '../../../static/utils';
import { SingleChat } from './single-chat';
import { getContactList, activeContactRead } from '../../store/chat';
import './chat-widget.scss';
import '../../assets/styles/components/dnd.scss';

const ChatWidget = () => {
    const dispatch = useDispatch();
    const { contactList } = useSelector(({ chat }) => chat);
    const [collapsed, setCollapsed] = useState(true);
    const [activeContact, setActiveContact] = useState(null);
    const [filters, setFilters] = useState({ page: 1, per_page: 10, searchText: '' });

    useEffect(() => {
        dispatch(getContactList(filters));
    }, []);

    const onContactClick = (c) => {
        setActiveContact(c);
        dispatch(activeContactRead(c));
    };

    const onSearchUser = debounce((searchText) => {
        setFilters({ ...filters, searchText });
        dispatch(getContactList({ searchText }));
    }, 1000);

    const onPageChange = () => {
        const params = {
            ...filters,
            page: filters.page + 1,
        };
        dispatch(getContactList(params));
    };

    return (
        <div className="chat-widget">
            {!collapsed ? (
                <>
                    <div className="chat-display">
                        {!activeContact && (
                            <>
                                <div className="chat-header">
                                    <div> Users ({contactList?.total || 0})</div>
                                </div>
                                <div className="search-bar">
                                    <Input
                                        placeholder="Search Contacts ..."
                                        allowClear
                                        onChange={(e) => onSearchUser(e.target.value)}
                                    />
                                </div>
                            </>
                        )}
                        <Spin spinning={contactList.fetching}>
                            <div className="chat-content">
                                {!activeContact ? (
                                    <div id="scrollableDiv" className="contact-list">
                                        <InfiniteScroll
                                            dataLength={contactList.data?.length}
                                            next={onPageChange}
                                            hasMore={contactList?.total > contactList.data?.length}
                                            endMessage={
                                                !contactList.fetching && (
                                                    <Divider plain>
                                                        It is all, nothing more.
                                                    </Divider>
                                                )
                                            }
                                            scrollableTarget="scrollableDiv"
                                            loader={
                                                contactList.fetching && (
                                                    <Skeleton
                                                        paragraph={{ rows: 4 }}
                                                        block={true}
                                                        active
                                                    />
                                                )
                                            }
                                        >
                                            {contactList.data.map((c) => (
                                                <div
                                                    className="contact-item"
                                                    onClick={() => onContactClick(c)}
                                                    key={`${c?.id} - ${c?.unread_chat_count}`}
                                                >
                                                    <Avatar
                                                        size="small"
                                                        src={
                                                            c.image ||
                                                            makeAvatar(
                                                                c?.first_name?.[0],
                                                                c?.last_name?.[0],
                                                            )
                                                        }
                                                    />
                                                    <div>
                                                        <Badge
                                                            className="site-badge-count-109"
                                                            count={c?.unread_chat_count}
                                                            overflowCount={9}
                                                            offset={[25, 0]}
                                                        >
                                                            {c?.first_name} {c?.last_name}
                                                        </Badge>
                                                    </div>
                                                </div>
                                            ))}
                                        </InfiniteScroll>
                                    </div>
                                ) : (
                                    <SingleChat
                                        key={activeContact?.id}
                                        contact={activeContact}
                                        closeChat={() => setActiveContact(null)}
                                    />
                                )}
                            </div>
                        </Spin>
                    </div>
                    <div className="chat-icon">
                        <CloseCircleOutlined
                            onClick={() => setCollapsed(!collapsed)}
                            style={{ fontSize: '24px', color: '#16aaff' }}
                        />
                    </div>
                </>
            ) : (
                <Badge
                    className="site-badge-count-109"
                    count={sumBy(contactList.data, 'unread_chat_count')}
                    overflowCount={9}
                    offset={[-5, 5]}
                >
                    <MessageFilled
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ fontSize: '48px', color: '#16aaff' }}
                    />
                </Badge>
            )}
        </div>
    );
};

export default memo(ChatWidget);
