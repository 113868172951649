import * as L from 'leaflet';
import { memo, useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import './styles.css';

import { Badge, Button, Col, Modal, Row, Space } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CheckOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { DATES_KEYS, DATES_KEYS_WITH_VALUES, DATE_FORMAT, makeTitle, PROJECTS } from '../../../static/utils';

const { confirm } = Modal;

function TagContent({ color, tag, status_msg, deleteTag, mode }) {
    return (
        <div style={{ width: '500px' }} className="tag-content-body">
            <Row>
                <Col span={12}>
                    <h4>Name: </h4>
                    <p style={{ wordBreak: 'break-all' }}>{tag?.name || 'NA'}</p>
                    <h4 className="mb-0">Status: </h4>
                    <p style={{ wordBreak: 'break-all' }}>{tag?.status || 'NA'}</p>
                </Col>
                <Col span={12}>
                    <h4 className="mb-0">Description: </h4>
                    <p style={{ wordBreak: 'break-all' }}>{tag?.description || 'NA'}</p>
                </Col>
            </Row>
            <Row>
                {tag?.show_dates &&
                    tag?.show_dates.map((data, index) => (
                        <Col
                            span={6}
                            style={data?.is_active === "1" ? {
                                border: '1px solid #02259e',
                                boxShadow: '0px 0px 2px #000',
                                zIndex: 99,
                            } : {
                                border: '1px solid #dcdfe3',
                            }}
                            key={index}
                        >
                            <h4
                                className="mb-0"
                                style={{
                                    backgroundColor: `${data.event_color}`,
                                    paddingLeft: '10px',
                                }}
                            >
                                {data.event_name}
                            </h4>
                            <div style={{ padding: '10px' }}>
                                <h4 className="mb-0">Original date: </h4>
                                <p>{data?.original_event_date || '-'}</p>
                                <h4 className="mb-0">Actual date: </h4>
                                <p>{data?.actual_event_date || '-'}</p>
                                <h4 className="mb-0">Status: </h4>
                                <p
                                    style={{
                                        color: `${data.color}`,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {data.status_message || '-'}
                                </p>
                            </div>
                        </Col>
                    ))}
            </Row>
            {/* <p
        style={{
          display: 'block',
          marginTop: '1.33em',
          marginBottom: '1.33em',
          marginLeft: '0',
          marginRight: '0',
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          Status:
        </span>
        <span
          style={{
            float: 'right',
          }}
        >
          <Badge
            count={tag?.status}
            style={{ backgroundColor: color, marginRight: '0px' }}
          />
        </span>
      </p> */}

            {/* <h4 className="mb-0">Stage: </h4>
      <p style={{ color }}>
        {' '}
        {status_msg || 'NA'}
        {' '}
      </p> */}
            {/* {tag?.next_due_date !== null && (
        <>
          <h4 className="mb-0">Next Due Date:</h4>
          <p className="mb-0">{moment(tag?.next_due_date).format('MMM Do YYYY')}</p>
        </>
      )} */}
            {mode === 'Save' && (
                <Button className="mt-10" danger type="dashed" block onClick={() => deleteTag(tag)}>
                    Delete Tag
                </Button>
            )}
        </div>
    );
}

export const MapMarkers = memo(
    ({
        mapRef = null,
        coords = [],
        setCoords,
        markersDraggable,
        slineId,
        projectId,
        deleteTag,
        mode,
    }) => {
        const [customTags, setCustomTags] = useState([]);
        const history = useHistory();
        const map = mapRef?.current?.leafletElement;

        // const [map, setMap] = useState(null);
        // useEffect(() => {
        //    setMap(mapRef?.current?.leafletElement);
        // }, []);

        useEffect(() => {
            // const timer1 = setTimeout(() => {
            const _tags = coords.map((tag) => ({
                ...tag,
                ...map?.layerPointToLatLng([tag?.coords?.x_axis, tag?.coords?.y_axis]),
            }));
            setCustomTags(_tags);
            // }, 1500);

            // return () => {
            //   clearTimeout(timer1);
            // };
        }, [coords]);

        const onTagClickHandler = (tag) => {
            const url = `${PROJECTS}/${tag?.project}/package/${tag?.package}`;
            history.push(url);
        };

        const getDeadlines = (tag) => {
            const deadlines = [];
            const now = new Date().getTime();
            DATES_KEYS_WITH_VALUES.forEach(({ key, value }) => {
                if (!_.isEmpty(tag[key])) {
                    if (new Date(tag[key]).getTime() < now && tag?.status < value) {
                        const title = makeTitle(key);
                        deadlines.push({ title, key });
                    }
                }
            });
            return deadlines;
        };

        const getNearestDates = (tag) => {
            const now = new Date();
            let nearestDate;
            let date_key;
            DATES_KEYS.forEach((key) => {
                if (!_.isEmpty(tag[key])) {
                    const diff = moment(tag[key]).diff(moment(now), 'days');

                    if (diff > 0) {
                        if (nearestDate) {
                            if (moment(tag[key]).diff(moment(nearestDate), 'days') < 0) {
                                nearestDate = tag[key];
                                date_key = key;
                            }
                        } else {
                            nearestDate = tag[key];
                            date_key = key;
                        }
                    }
                }
            });
            return nearestDate ? { title: makeTitle(date_key), key: date_key } : null;
        };

        const getMissingInformation = (tag) => {
            const missingInfo = [];
            DATES_KEYS.forEach((key) => {
                if (_.isEmpty(tag[key])) {
                    const title = makeTitle(key);
                    missingInfo.push({ title });
                }
            });
            return missingInfo;
        };

        const updateTag = (e, tag) => {
            const coordIndex = coords.findIndex((coord) => coord.id === tag.id);
            coords[coordIndex] = {
                ...coords[coordIndex],
                coords: {
                    ...coords[coordIndex].coords,
                    x_axis: e.target.dragging._draggable._newPos.x,
                    y_axis: e.target.dragging._draggable._newPos.y,
                },
            };
            setCoords([...coords]);
        };

        const customMarkerIcon = (text, color) =>
            new L.divIcon({
                html: `<p class="text-center singline-marker-container" style="background-color: ${color}">${text}</p>`,
                iconSize: [50, 25],
            });

        const showConfirm = (tag) => {
            confirm({
                title: 'Do you Want to delete the tag?',
                icon: <ExclamationCircleFilled />,
                content: '',
                onOk() {
                    deleteTag({ projectId, slineId, coord_ids: [tag?.coords?.id] });
                },
                onCancel() {
                    // Do nothing
                },
            });
        };

        return customTags.map((tag, i) => (
            <Marker
                key={`${tag.name}-${i}`}
                position={[tag.lat, tag.lng]}
                onDragEnd={(e) => updateTag(e, tag)}
                draggable={markersDraggable}
                onMouseOver={(e) => {
                    e.target.openPopup();
                }}
                onMouseOut={(e) => {
                    // e.target.closePopup();
                }}
                icon={customMarkerIcon(tag.name, tag?.construction_color[1])}
                onClick={() => onTagClickHandler(tag)}
            >
                <Popup>
                    <TagContent
                        mode={mode}
                        {...tag}
                        status_msg={tag.construction_status_msg}
                        tag={tag}
                        color={tag?.construction_color[1]}
                        deadlines={getDeadlines(tag)}
                        nearestDate={getNearestDates(tag)}
                        missingInfo={getMissingInformation(tag)}
                        deleteTag={showConfirm}
                    />
                </Popup>
            </Marker>
        ));
    },
);
