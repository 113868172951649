import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartstTimeline from 'highcharts/modules/timeline';
import accessibility from 'highcharts/modules/accessibility';
import exporting from 'highcharts/modules/exporting';
import { Col, Row, Collapse } from 'antd';
import { Column } from '@ant-design/charts';
import { breackdownChartConfig, timlineChartConfig } from '../../../../static/utils';

const { Panel } = Collapse;
highchartstTimeline(Highcharts);
accessibility(Highcharts);
exporting(Highcharts);

function Timeline({ packageRoom = false }) {
  return (
    <div className="">
      <Row gutter={30}>
        <Col span={24}>
          {packageRoom ? (
            <Collapse ghost>
              <Panel header="Breaddowns" key="1" className="breakdown-collapse">
                <div
                  style={{
                    maxWidth: '100%',
                    width: '100%',
                    display: 'inline-block',
                  }}
                >
                  <Column {...breackdownChartConfig} />
                </div>
              </Panel>
            </Collapse>
          ) : (
            <div className="page-content-container">
              <h1 className="card-heading ">Breakdowns</h1>
              <div
                style={{ maxWidth: '100%', width: '100%', display: 'inline-block' }}
              >
                <Column {...breackdownChartConfig} />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Timeline;
