import PropTypes from 'prop-types';
import { memo } from 'react';
import { DatePicker, Form, Input } from 'antd';
import { INPUT_DATE_FORMAT } from '../../../static/utils';

const UpdateEquipmentDates = (props) => {
    return (
        <>
            <div className="custom-row">
                <div className="col">
                    <h1 className="col-title">Order</h1>
                    <Form.Item name="order_date" label="Order Entry">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                    <Form.Item name="actual_order_date" label="Order Entry Manufacturer">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                    <Form.Item name="lead_time_weeks" label="Lead Time (Weeks)">
                        <Input type="number" />
                    </Form.Item>
                </div>
                <div className="col">
                    <h1 className="col-title">Approval</h1>
                    <Form.Item name="approval_date" label="Expected Approval Dwg.">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                    <Form.Item name="actual_approval_date" label="Actual Approval Dwg.">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                    <Form.Item name="actual_approved_date" label="Approved Dwg. Returned">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                    <Form.Item name="resubmittal_date" label="Resubmittal Date">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                </div>
                <div className="col">
                    <h1 className="col-title">Release</h1>
                    <Form.Item name="release_date" label="Expected Release">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                    <Form.Item name="actual_release_date" label="Actual Release">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                </div>
                <div className="col">
                    <h1 className="col-title">Testing</h1>
                    <Form.Item name="test_date" label="Witness Testing">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                    <Form.Item name="actual_final_date" label="Record Dwgs.">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                </div>
                <div className="col">
                    <h1 className="col-title">Ship</h1>
                    <Form.Item
                        name="expected_ship_date_by_customer"
                        label="Customer OnSite Request"
                    >
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                    <Form.Item name="ship_date" label="Planned Ship">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                    <Form.Item name="actual_ship_date" label="Actual Ship">
                        <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
                    </Form.Item>
                </div>
            </div>
            {!props.hideComments && (
                <Form.Item name="comments" label="Comments">
                    <Input.TextArea />
                </Form.Item>
            )}
        </>
    );
};

UpdateEquipmentDates.defaultProps = {
    hideComments: false,
};

UpdateEquipmentDates.propTypes = {
    hideComments: PropTypes.bool,
};

export default memo(UpdateEquipmentDates);
