import { http } from '../../static/utils/http';

export const fetchSinglelineDetails = async (projectId, slineId, type) => {
  try {
    const response = await http.get(`/projects/${projectId}/singlelines/${slineId}/`);
    return response?.data;
  } catch (ex) {
    console.log({ ...ex });
  }
};

export const fetchTagsBySline = async (projectId, slineId) => {
  try {
    const response = await http.get(`/projects/${projectId}/singlelines/${slineId}/tags/`);
    return response?.data;
  } catch (ex) {
    console.log({ ...ex });
  }
};

export const fetchSlinesByProject = async (projectId, params) => {
  try {
    const response = await http.get(`/projects/${projectId}/singlelines/`, params);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchCalibratedDetailsBySlines = async (projectId, slineId, type) => {
  try {
    const response = await http.get(
      `/projects/${projectId}/singlelines/${slineId}/calibrated/?type=${type}`,
    );
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchCalibratedBySlines = async (projectId, slineId, body) => {
  try {
    const response = await http.post(
      `/projects/${projectId}/singlelines/${slineId}/calibrated/`,
      body,
    );
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const updateTagsCalibrated = async (projectId, slineId, body) => {
  try {
    const response = await http.patch(
      `/projects/${projectId}/singlelines/${slineId}/calibrated/`,
      body,
    );
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const rotateSlineCalibrated = async (projectId, slineId, body) => {
  try {
    const response = await http.put(
      `/projects/${projectId}/singlelines/${slineId}/calibrated/`,
      body,
    );
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};
