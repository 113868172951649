// React imports
import React, { memo } from "react";

// Ant Design components
import { Form, Input } from "antd";

// Stylesheet import
import "./alectifyInput.scss";

const TextFields = (props) => {
  const {
    name,
    placeholder,
    type,
    prefix,
    onChange,
    allowClear,
    onFocus,
    rules,
    ...restProps
  } = props;

  return (
    <>
      {type === "password" ? (
        <Input.Password
          placeholder={placeholder || `Enter your ${name} here`}
          prefix={prefix || null}
          {...props}
          {...restProps}
        />
      ) : type === "textarea" ? (
        <Input.TextArea
          {...props}
          {...(restProps)}
          placeholder={placeholder || `Enter your ${name} here`}
          rows={props.rows}
          style={{
            resize: props.resizeable ? "inherit" : "none",
          }}
        />
      ) : (
        <>
          <Input
            placeholder={placeholder || `Enter your ${name} here`}
            prefix={prefix || null}
            onChange={onChange && onChange}
            onFocus={onFocus && onFocus}
            {...props}
            allowClear={allowClear}
            className={
              props.noFormitem ? `alectify-input ${props.className}}` : ""
            }
            {...restProps}
          />
          {props.extra}
        </>
      )}
    </>
  );
};

const AlectifyInput = (
  props,
) => {
  const { label, name, rules, className, tooltip } = props;

  const customRules = rules
    ? rules.map((rule) => ({
        ...rule,
        type: rule.type,
        message: rule.message,
        required: rule.required,
      }))
    : [];

  return !props.noFormitem ? (
    <Form.Item
      label={label && label}
      tooltip={tooltip && tooltip}
      name={name}
      rules={customRules}
      className={`alectify-input ${className}`}
    >
      <TextFields {...props} />
    </Form.Item>
  ) : (
    <TextFields {...props} />
  );
};

AlectifyInput.defaultProps = {
  noFormitem: false,
};

export default memo(AlectifyInput);
