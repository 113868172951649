import React, { useEffect, useState } from 'react';
import { Layout, Spin, notification } from 'antd';
import { useParams, useLocation } from 'react-router-dom';
import BasicInfo from './BasicInfo';
import { useDispatch, useSelector } from 'react-redux';
import {
    createBiddingProject,
    getOrganizationsForBidding,
    getProjectDetailById,
    updateBiddingProject,
} from '../../../../store/bidding-management';
import UsersAccess from '../../../sub-projects/create-edit/users-access';
import { isEmpty } from 'lodash';
import { MESSAGES } from '../../../../../static/utils/messages';

const { Content } = Layout;

const CreateOrUpdateMainProject = ({ history }) => {
    const dispatch = useDispatch();
    const { projectId, type } = useParams();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const bidding = useSelector(({ bidding }) => bidding);
    const { organizations, loading, project } = bidding;
    const [current, setCurrent] = useState(+params.get('activeTab') || 1);
    const [users, setUsers] = useState([]);
    const { role_templates } = useSelector(({ admin }) => admin);
    const [loader, setLoader] = useState(false);

    const onFinish = (values) => {
        setCurrent(current + 1);
        if (projectId && project) {
            values.id = project.id;
            dispatch(updateBiddingProject(values, history));
        } else {
            dispatch(createBiddingProject(values, history));
        }
     };

    const onSubmit = () => {
        notification.success({
            message: 'Success',
            description: projectId
              ? MESSAGES.BIDDING.PROJECT_UPDATED
              : MESSAGES.BIDDING.PROJECT_CREATED,
          });
        setCurrent(0);
        history.push({
            pathname: `/bidding-management/${projectId}`,
        });
    };

    useEffect(() => {
        if (projectId) {
            dispatch(getProjectDetailById(projectId));
        }
        dispatch(getOrganizationsForBidding());
    }, [projectId]);

    const steps = [
        {
            title: 'Project Information',
            id: 1,
        },
        {
            title: 'Users Access',
            id: 2,
        },
    ];

    const onTabClick = (item) => {
        if (projectId) {
            setCurrent(item.id);
            history.replace({
                pathname: `/bidding-management/create/${projectId}`,
                search: `?activeTab=${item.id}`,
            });
        }
    };

    const goBack = () => {
        setCurrent(current - 1);
        history.replace({
            pathname: `/bidding-management/create/${projectId}`,
            search: `?activeTab=${current - 1}`,
        });
    };
    return (
        <Content>
            <div style={{ width: '95%', margin: 'auto' }}>
                <div className="page-content-container">
                    <div className="project-header">
                        <div className="project-steps">
                            <div className="project-step first-step">
                                {projectId ? (
                                    <p>
                                        <span>Update {project && project.name}</span>
                                    </p>
                                ) : (
                                    <p>
                                        <span>Create New Project</span>
                                    </p>
                                )}
                            </div>
                            {steps.map(
                                (item) =>
                                    !isEmpty(item) && (
                                        <div
                                            className={`project-step cursor-pointer ${
                                                item.id === current ? 'active' : ''
                                            }`}
                                            key={item.title}
                                            onClick={() => onTabClick(item)}
                                        >
                                            {item.title}
                                        </div>
                                    ),
                            )}
                        </div>
                    </div>
                    <div className="steps-content mt-30">
                     <Spin spinning={loader}>
                    {current === 1 ? (<BasicInfo
                            onFinish={onFinish}
                            projectId={projectId}
                            organizations={organizations || []}
                            loader={loading}
                            type={type}
                            project={(projectId && project) || null}
                            />)
                            : current === 2 ? (
                                <UsersAccess
                                organizations={organizations || []}
                                users={users}
                                setUsers={setUsers}
                                projectId={projectId}
                                goBack={goBack}
                                role_templates={role_templates}
                                onFinish={onSubmit}
                                setLoader={setLoader}
                                biddingPage
                                />
                                ) : null
                            }
                    </Spin>
                    </div>
                </div>
            </div>
        </Content>
    );
};
export default CreateOrUpdateMainProject;
