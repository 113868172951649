import moment from 'moment';
import { memo } from 'react';
import { Modal, Row, Card, Col, Avatar } from 'antd';
import { DISPLAY_DATETIME_FORMAT, makeAvatar, displayValue } from '../../../../../static/utils';

function ViewDetailModal({ onSubmit = () => {}, onCancel = () => {}, visible = false, details }) {
    const cancleHandler = () => {
        onCancel();
    };

    return (
        <Modal
            title="Shipping Details"
            width={600}
            visible={visible}
            onOk={cancleHandler}
            onCancel={cancleHandler}
            okText="Ok"
            footer={null}
            className="verticial-form-display"
        >
            <Card type="inner" className="mb-20" title="Status">
                <Row>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">
                                Shipping Status:
                                <span className="value">
                                    {displayValue(details?.shipment_status)}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">Updated by:
                              <span className='value'>{details?.updated_by.first_name} {details?.updated_by.last_name}</span>
                            </div>
                            {/* <Avatar
                                size="small"
                                className="circle-icon value"
                                src={
                                    details?.updated_by?.image ||
                                    makeAvatar(
                                        details?.updated_by.first_name[0],
                                        details?.updated_by.last_name[0],
                                    )
                                }
                            /> */}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">
                                Ship Date:
                                <span className="value">{displayValue(details?.ship_date)}</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="property">
                            <div className="title">
                                Updated at:
                                <span className="value">
                                    {moment(details?.updated_at, moment.ISO_8601).format(
                                        DISPLAY_DATETIME_FORMAT,
                                    )}
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card>
            {details?.shipment_status !== 'Revised' && (
                <Card type="inner" className="mb-20" bordered title="Carrier Details">
                    <Row>
                        <Col span={12}>
                            <div className="property">
                                <div className="title">
                                    Tracking Id:
                                    <div className="value">
                                        {displayValue(details?.carrier_tracking_id)}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="property">
                                <div className="title">
                                    Quantity:
                                    <div className="value">{displayValue(details?.quantity)}</div>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="property">
                                <div className="title">
                                    Shipping Method:
                                    <div className="value">
                                        {displayValue(details?.ship_method)}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="property">
                                <div className="title">
                                    Carrier:
                                    <div className="value">{displayValue(details?.carrier)}</div>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="property property-nextline">
                                <div className="title">
                                    From Address:
                                    <div className="value">
                                        {displayValue(details?.from_address)}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="property property-nextline">
                                <div className="title">
                                    To Address:
                                    <span className="value">
                                        {displayValue(details?.to_address)}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="property">
                                <div className="title">
                                    Bill of Landing:
                                    <span className="value">
                                        {displayValue(details?.bill_of_landing)}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        {/* <Col span={12}>
                <div className="property">
                  <div className="title">
                    Invoice Number:
                    <span className="value">{displayValue(details?.invoice_number)}</span>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="property">
                  <div className="title">
                    Empower Status:
                    <span className="value">{displayValue(details?.empower_status)}</span>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="property">
                  <div className="title">
                    Packing Slip:
                    <span className="value">{displayValue(details?.packing_slip)}</span>
                  </div>
                </div>
              </Col> */}
                    </Row>
                </Card>
            )}
        </Modal>
    );
}

export default memo(ViewDetailModal);
