import moment from 'moment';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { DISPLAY_DATE_FORMAT, renderAmount } from '../../../../../../static/utils';

function PurchaseOrderInfo() {
  const { summarySheet } = useSelector(({ financials }) => financials);

    return (
        <Row gutter={30} className="po-information">
            {/* <Col span={24} className="mb-0">
                <h1 className='po-summary-heading'>Total Sell</h1>
            </Col> */}
            <Col span={6}>
                <label>Customer:</label>
                <span>{summarySheet?.data?.customer?.name || '-'}</span>
            </Col>
            <Col span={6}>
                <label>Customer PO #:</label>
                <span>{summarySheet?.data?.customer_po_number || '-'}</span>
            </Col>
            <Col span={6}>
                <label>Original PO Date:</label>
                <span>
                    {moment(summarySheet?.data?.original_po_date).format(DISPLAY_DATE_FORMAT) || '-'}
                </span>
            </Col>
            <Col span={6}>
                <label>Original PO:</label>
                <span>{renderAmount(summarySheet?.data?.original_po_value)}</span>
            </Col>
            {/* <Col span={24} className="mb-0">
                <h1 className='po-summary-heading'>Total Cost</h1>
            </Col>
            <Col span={6}>
                <label>Date:</label>
                <span>{moment(summarySheet?.data?.vendor_cost_date).format(DISPLAY_DATE_FORMAT) || '-'}</span>
            </Col>
            <Col span={6}>
                <label>Original Vendor Cost:</label>
                <span>${summarySheet?.data?.vendor_original_cost?.toFixed(2) || '-'}</span>
            </Col> */}
        </Row>
    );
}

export default PurchaseOrderInfo;
