// React imports
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';

// Ant Design component
import { Button, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// Custom components
import AlectifyText from 'static/texts.json';
import { BIDDING_MANAGEMENT } from 'static/utils';
import SubProjectListing from './subProjectListing';
import MasterProjectDetailCard from './masterProjectDetailCard';
import { fetchContractorProjects } from 'app/services/bidding-management';

// Stylesheet import
import './masterProjectDetailCard.scss';

const BiddingProjectDetail = () => {
    const { loading, activeProject } = useSelector(({ bidding }) => bidding);
    const history = useHistory();
    const [contractorProjects, setContractorProjects] = useState({ fetching: false, data: [] });

    const getContractorProjects = async () => {
        try {
            setContractorProjects({ ...contractorProjects, fetching: true });
            const response = await fetchContractorProjects(activeProject?.id);
            setContractorProjects({ fetching: false, data: response?.data });
        } catch (err) {
            setContractorProjects({ ...contractorProjects, fetching: false });
            console.log(err);
        }
    };

    useEffect(() => {
        getContractorProjects();
    }, [activeProject]);

    return (
        <Spin spinning={loading} className="master-project-detail-container">
            <MasterProjectDetailCard
                isBiddingPageActive
                biddingActiveProject={activeProject}
                project={activeProject}
            />
            <div className="d-flex justify-space-between">
                <h1 className="list-sub-project-heading">{`${AlectifyText.LIST_CONTRACTORS}`}</h1>
                <Button
                    className="alectify-regular-button"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={(ev) => {
                        ev.stopPropagation();
                        history.push(`${BIDDING_MANAGEMENT}/contractor/create/${activeProject?.id}`);
                    }}
                >
                    {AlectifyText.NEW_CONTRACTOR}
                </Button>
            </div>
            <div className="sub-project-listing-container">
                <SubProjectListing
                    subProjects={contractorProjects}
                    activeProject={activeProject}
                    getContractorProjects={getContractorProjects}
                    isBiddingPageActive
                />
            </div>
        </Spin>
    );
};

export default memo(BiddingProjectDetail);
