import _, { uniqBy } from 'lodash';
import moment from 'moment';
import {
  Avatar, Button, Popconfirm, Space, Spin, Table, Tooltip,
} from 'antd';
import { DeleteTwoTone, DownloadOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DISPLAY_DATETIME_FORMAT, filterProjectDocumentsData, makeAvatar } from '../../../static/utils';
import { getSignedUrl } from '../../services';

export function DocumentsListing({
  documents = [],
  directoryName = '',
  shouldShowDeleteButton = true,
  deleteDocument = () => {},
  downloadDocument = () => {},
}) {
  const { activeProject } = useSelector(({ common }) => common);
  const { user } = useSelector(({ auth }) => auth);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [finalFiles, setFinalFiles] = useState([]);
  const [emailSet, setEmailSet] = useState([]);

  const [isFetchingFile, setFetchingFile] = useState(false);

  useEffect(() => {
    const selectedFilesPaths = selectedFiles.map((file) => file.file_path);

    if (finalFiles.length) {
      setEmailSet(
        uniqBy(
          finalFiles.filter((file) => selectedFilesPaths.includes(file.file_path)),
          'file_path',
        ),
      );
    } else {
      setEmailSet([]);
    }
  }, [finalFiles]);

  const getDocUrl = async (rec) => {
    setFetchingFile(true);
    const res = await getSignedUrl(rec.file_path);

    setFinalFiles((state) => [
      ...state,
      {
        ...rec,
        url: res.data,
      },
    ]);

    setFetchingFile(false);

    return res.data;
  };

  useEffect(() => {
    if (selectedFiles.length) {
      selectedFiles.map((record) => {
        getDocUrl(record);
      });
    } else {
      setFinalFiles([]);
    }
  }, [selectedFiles]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedFiles(selectedRows);
    },
  };

  function Mailto({ email, subject = '', children }) {
    const body = `Hi,%0D%0DAttaching files from the Project ${
      activeProject?.name
    }.%0D%0DFiles:%0D${emailSet
      .map((file) => `${encodeURIComponent(file?.url)}%0D`)
      .join('.%0D')}.%0D%0D%0D%0DRegards,%0D${user?.first_name}%0D%0D.`;

    return <a href={`mailto:?subject=${subject}&body=${body}`}>{children}</a>;
  }

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'file_name',
    },
    {
      title: 'Uploaded at',
      dataIndex: 'created_at',
      render: (value) => (value ? moment(value).format(DISPLAY_DATETIME_FORMAT) : ''),
    },
    {
      title: 'Uploaded by',
      align: 'center',
      dataIndex: 'account',
      render: (account) => (!_.isEmpty(account) ? (
        <Tooltip title={`${account?.first_name} ${account?.last_name}`}>
          <Avatar
            size="small"
            src={makeAvatar(account?.first_name?.[0], account?.last_name?.[0])}
          />
        </Tooltip>
      ) : (
        ''
      )),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      align: 'center',
      render: (_, record) => (
        <Space>
          <Tooltip title="Download">
            <Button
              style={{
                border: 'none',
              }}
              data-key="download"
              type="ghost"
              onClick={() => downloadDocument(record)}
            >
              <DownloadOutlined style={{ fontSize: 18, cursor: 'pointer' }} />
            </Button>
          </Tooltip>
          {shouldShowDeleteButton && (
          <Tooltip title="Delete">
            <Popconfirm
              okText="Yes"
              onConfirm={() => deleteDocument(record.id)}
              title="Are your sure?"
            >
              <DeleteTwoTone
                twoToneColor="#f56666"
                style={{ fontSize: 18, cursor: 'pointer' }}
              />
            </Popconfirm>
          </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    !_.isEmpty(documents) && (
    <>
      {/* <div className="mb-15 text-right">
                    <Button disabled={emailSet.length < 1 || isFetchingFile} type="link">
                        <Mailto email="" subject={`Files from ${directoryName}`}>
                            <ShareAltOutlined /> Email
                        </Mailto>
                    </Button>
                </div> */}
      <Table
        rowSelection={{
          ...rowSelection,
        }}
                    // rowKey="id"
        columns={columns}
        pagination={false}
        dataSource={filterProjectDocumentsData(documents, directoryName).map(
          (data, index) => ({
            ...data,
            key: `${data.file_name}+${index}`,
          }),
        )}
      />
    </>
    )
  );
}
