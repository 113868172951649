import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  Steps,
  Collapse,
  Space,
  Select,
  Row,
  Col,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {
  DISPLAY_DATETIME_FORMAT,
  makeAvatar,
  displayValue,
  DEFAULT_START_PAGE,
  DEFAULT_PAGE_SIZE,
} from '../../../../../static/utils';
import { getShippingUpdateByTag, updateShipping } from '../../../../store/tags/actions';
import ShippingUpdateModal from './ShippingUpdateModal';
import ViewDetailModal from './ViewDetailModal';
import { AlectifyTable } from '../../../../components/table';
import SearchModal from '../../../projects/listing/SearchModal';
import { getPackagesByProject } from '../../../../store/package';
import { searchTableColumns } from './searchTableColumns';

const { Panel } = Collapse;
const { Step } = Steps;

function Status({
  detail, getPackageDetail, uploadAfterImg, uploadBeforeImg,
}) {
  const isSystemGenerated = detail?.tags[0]?.is_system_generated;
  const isManualTracking = detail?.tags[0]?.is_manual_tracking;

  const dispatch = useDispatch();
  const { pkgId, projectId } = useParams();
  const {
    shippingDetails,
    shippingMeta,
    shippingLoader,
    updateLoader,
  } = useSelector(({ tags }) => tags);
  const [loader, setloader] = useState(false);
  const [tag, setTag] = useState(null);
  const [viewDetail, setViewDetail] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const { masterPackages, masterPackagesLoading } = useSelector(({ packages }) => packages);
  const shippingColumns = [
    {
      title: 'Tracking Id',
      dataIndex: 'carrier_tracking_id',
      render: (carrier_tracking_id) => displayValue(carrier_tracking_id),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (quantity) => displayValue(quantity),
    },
    {
      title: 'Ship Date',
      dataIndex: 'ship_date',
      render: (date) => {
        return date ? moment(date, moment.ISO_8601).format(DISPLAY_DATETIME_FORMAT) : "-";
      },
    },
    {
      title: 'Updated By',
      dataIndex: 'updated_by',
      render: (user) => (
        <Avatar
          className="circle-icon"
          src={user?.image || makeAvatar(user?.first_name[0], user?.last_name[0])}
        />
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      render: (date) => moment(date, moment.ISO_8601).format(DISPLAY_DATETIME_FORMAT),
    },
    {
      title: 'Status',
      dataIndex: 'shipment_status',
    },
    {
      title: 'Action',
      render: (_, record, idx) => (
        <Tooltip title="View Details">
            <ExpandOutlined onClick={() => {
              setViewDetail(record);
              setViewModal(true);
            }} />
        </Tooltip>
      ),
    },
  ];

  const handleTagSelect = (tag_id) => {
    setTag(tag_id);
    dispatch(getShippingUpdateByTag({
      id: tag_id,
      page: DEFAULT_START_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    }));
  };

  useEffect(() => {
    setTag(detail?.tags[0]?.id);
  }, []);

  useEffect(() => {
    if (detail?.tags[0]?.id) {
      handleTagSelect(detail?.tags[0]?.id);
    }
  }, [detail?.tags]);

  const updateShippingDetails = (values) => {
    dispatch(updateShipping(values));
    setUpdateModal(false);
  };

  const CardTitle = () => (
    <div className="d-flex justify-space-between">
      Shipment Tracking
      <Button
        disabled={isManualTracking ? false : (!tag || isSystemGenerated)}
        primary
        onClick={() => {
          setUpdateModal(true);
        }}
        label="Update Ship Date"
      >
        Add Tracking Info
      </Button>
    </div>
  );

  return (
<Card className="footer-body mt-10" title={<CardTitle />}>
      <div>
        <Row gutter={16}>
          {/* <Col
            span={24}
            className="mb-20"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Space>
              Equipment
              <Select
                onChange={handleTagSelect}
                value={tag || detail?.tags[0]?.id}
                options={detail?.tags.map((t) => ({
                  value: t?.id,
                  label: `${t?.line_number} - ${t?.name}`,
                }))}
              />
              <Tooltip title="View List">
                <UnorderedListOutlined
                  style={{ marginLeft: '2%' }}
                  onClick={() => setVisible(true)}
                />
              </Tooltip>
            </Space>
            <Tooltip title="Only works for Manual Schedule">
              <Button
                disabled={!tag || isSystemGenerated}
                primary
                onClick={() => {
                  setUpdateModal(true);
                }}
                label="Update Ship Date"
              >
                {' '}
                Add Tracking Info
              </Button>
            </Tooltip>
          </Col> */}
          <Col sm={24}>
            <AlectifyTable
              size="small"
              onDataFetch={(data) => dispatch(getShippingUpdateByTag(data))}
              columns={shippingColumns}
              isDownloadZip={false}
              isEmailSharing={false}
              onDownloadZip={() => {}}
              onEmailShare={() => {}}
              id={tag}
              dataSource={shippingDetails}
              loading={shippingLoader}
              selectRows={false}
              total={shippingMeta?.total_count}
              key={`${projectId}-shipment`}
            />
          </Col>
          <ShippingUpdateModal
            visible={updateModal}
            tag={tag}
            onSubmit={updateShippingDetails}
            onCancel={() => setUpdateModal(false)}
            loading={updateLoader}
          />
          <ViewDetailModal
            visible={viewModal}
            tag={tag}
            details={viewDetail}
            onCancel={() => setViewModal(false)}
          />
          <SearchModal
            columns={searchTableColumns({})}
            visible={visible}
            title="Tags List"
            data={{
              data: detail?.tags.map((t) => ({
                ...t,
                package_type: detail?.package_type,
              })),
            }}
            projectId={projectId}
            onDataFetch={getPackagesByProject}
            onCancel={() => setVisible(!visible)}
            onRow={(record, rowIndex) => {
              handleTagSelect(record?.id);
              setVisible(!visible);
            }}
            loading={masterPackagesLoading}
          />
        </Row>
      </div>
    </Card>
  );
}

export default Status;
