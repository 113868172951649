import _ from 'lodash';
import { memo, useEffect } from 'react';
import {
  Modal,
} from 'antd';
import '../../../assets/styles/components/task.scss';
import { useDispatch } from 'react-redux';
import {
  DEFAULT_PAGE_SIZE, DEFAULT_START_PAGE,
} from '../../../../static/utils';
import { AlectifyTable } from '../../../components';

function SearchModal({
  title = '',
  projectId,
  packageId,
  columns = [],
  onDataFetch = () => { },
  onRow = () => { },
  onUpdate = () => { },
  onCancel = () => { },
  visible = false,
  loading = false,
  data = {},
  projectSetup = false,
  onTagSearch = () => { },
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onDataFetch({
      page: DEFAULT_START_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      id: projectId,
      packageId,
    }));
  }, []);

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      confirmLoading={loading}
      okText="Close"
      style={{ minWidth: '1000px !important' }}
      className="review-room-modal"
      footer={null}
    >
      <AlectifyTable
        size="small"
        onDataFetch={(data) => dispatch(onDataFetch(data))}
        columns={columns}
        isDownloadZip={false}
        isEmailSharing={false}
        onDownloadZip={() => { }}
        onEmailShare={() => { }}
        id={projectId}
        packageId={packageId}
        dataSource={data?.data}
        loading={loading}
        selectRows={false}
        onRowSelectionAction={() => { }}
        total={data?.meta?.total_count}
        key={`${projectId}-eqp-files`}
        onRow={onRow}
      />
    </Modal>
  );
}

export default memo(SearchModal);
