import _ from 'lodash';
import {
  Spin, Collapse, Space, Tooltip, Badge, notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { AlectifyTable } from '../../components';
import { PROJECTS } from '../../../static/utils';
import { PROJECT_SETUP, setActiveProject, setActiveProjectTab } from '../../store/common';
import { fileColumns } from './project_columns';
import { packageColumns } from './package_columns';
import { reviewColumns } from './review_columns';
import { getSharedDocuments } from '../../store/documents';
import { downloadZip, getSignedUrl } from '../../services';
import ViewHistoryModal from './history';

const { Panel } = Collapse;

function SharedAccordian({
  list,
  isLoading = false,
  isCompleted,
  projectId,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(({ auth }) => auth);
  const { packages } = useSelector(({ chat }) => chat);
  const [record, setRecord] = useState(true);
  const [historyVisible, setHistoryVisible] = useState(false);

  const {
    documents,
    project_documents,
    equipment_documents,
    review_documents,
    project_documents_loading,
    equipment_documents_loading,
    review_documents_loading,
  } = useSelector(({ documents }) => documents);

  const onEditHandler = (ev, project, masterProject) => {
    ev.stopPropagation();
    dispatch(setActiveProject({ id: project?.id, name: project?.name, masterProject }));
    dispatch({ type: PROJECT_SETUP, payload: false });
    history.push(`${PROJECTS}/${masterProject?.id}/${project?.id}/edit?activeTab=1`);
  };

  const genExtra = (project) => (
    <>
      (
      {project?.projects.length}
      )
      <Tooltip title="Add Subproject" placement="top">
        <PlusOutlined
          onClick={(ev) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            ev.stopPropagation();
            dispatch({ type: PROJECT_SETUP, payload: true });
            history.push(`${PROJECTS}/${project?.id}/create`);
          }}
        />
      </Tooltip>

    </>
  );

  const panelHeader = (project) => {
    let masterProjectChatCount = 0;

    // check if any subprojects have notification
    _.map(project?.projects, (p) => {
      const subProject = _.find(packages, ['id', p?.id]);
      if (subProject) {
        masterProjectChatCount += _.sumBy(subProject?.packages, 'unread_messages_count');
      }
    });

    return (
      <Space>
        <Tooltip title="Edit Master" placement="top">
          <EditOutlined
            onClick={(ev) => {
              // If you don't want click extra trigger collapse, you can prevent this:
              ev.stopPropagation();
              history.push(`${PROJECTS}/master/${project?.id}`);
            }}
          />
        </Tooltip>
        <Badge
          dot={project.unread_activity_count || masterProjectChatCount > 0}
          offset={[10, 0]}
          size="small"
          showZero={false}
          onClick={(ev) => {
            ev.stopPropagation();
            dispatch(setActiveProject({ ...project, masterProject: project }));
            history.push(`${PROJECTS}/${project?.id}/aggregate`);
          }}
        >
          {project?.name}
        </Badge>
      </Space>
    );
  };

  const displayList = _.sortBy(_.filter(list, ['is_finished', isCompleted]), ['name']);

  // Hack do display on projects relavant
  // Construct displaylist based on the user
  const newdisplayList = _.filter(displayList, (m) => {
    // Fetch all the accounts in the subprojects
    let p = m?.account;
    for (let i = 0; i < m?.projects.length; i++) {
      p = [...p, ...m?.projects[i]?.account];
    }

    if (p.includes(user?.id)) {
      let sProjects = [];
      for (let i = 0; i < m?.projects.length; i++) {
        if (m?.projects[i]?.account.includes(user?.id)) {
          sProjects = [m?.projects[i], ...sProjects];
        }
      }
      m.projects = sProjects;
      return m; // Master Projects to render
    }
  });

  const downloadDocumentsInZip = async (s3Keys, type) => {
    const response = await downloadZip({
      projectId,
      payload: {
        docs_group: `${type}-docs`,
        s3_keys: s3Keys,
      },
    });

    if (response?.success) {
      notification.success({
        title: 'Received Download Zip Request',
        message: 'Zip link will be send to you mail id',
      });
    }
  };

  const onDownloadZip = async (data, type) => {
    const s3_keys = data.map((a) => a.file_path);
    await downloadDocumentsInZip(s3_keys, type);
  };

  const onDownloadSubmitalsZip = async (data, type) => {
    const s3Keys = data.reduce((accum, item) => {
      accum.push(item?.approval_file_path);
      if (item?.markup_file_path) accum.push(item?.markup_file_path);
      return accum;
    }, []);
    await downloadDocumentsInZip(s3Keys, type);
  };

  const downloadDocument = async ({ file_path, file_name }) => {
    const response = await getSignedUrl(file_path);
    window.open(response?.data);
  };

  return (
    <Spin spinning={isLoading} tip={isLoading ? 'Please wait, it will take a while' : ''}>
      <Collapse className="alectify-collapse mb-15" defaultActiveKey={['submittals']} onChange={() => { }}>
        <Panel header={`Equipment Submittal Files (${review_documents?.meta?.total_count ? review_documents?.meta?.total_count : 0})`} key="submittals">
          <AlectifyTable
            size="small"
            onDataFetch={(data) => dispatch(getSharedDocuments(data, 'review'))}
            isDownloadZip
            onDownloadZip={(data) => onDownloadSubmitalsZip(data, 'review')}
            type="review"
            columns={
              reviewColumns({
                type: 'review',
                downloadDocument,
                setRecord: (record) => {
                  setRecord(record);
                  setHistoryVisible(!historyVisible);
                },
              })
            }
            id={projectId}
            dataSource={review_documents?.data}
            loading={review_documents_loading}
            rowSelection={false}
            total={review_documents?.meta?.total_count}
            key={`${projectId}-review-files`}
          />
        </Panel>
        <Panel header={`Other Equipment Files (${equipment_documents?.meta?.total_count || 0})`} key="equipment-fies">
          <AlectifyTable
            size="small"
            onDataFetch={(data) => dispatch(getSharedDocuments(data, 'equipment'))}
            isDownloadZip
            onDownloadZip={(data) => onDownloadZip(data, 'equipment')}
            columns={packageColumns({
              type: 'equipment',
              downloadDocument,
              setRecord: (record) => {
                setRecord(record);
                setHistoryVisible(!historyVisible);
              },
            })}
            type="equipment"
            id={projectId}
            dataSource={equipment_documents?.data}
            loading={equipment_documents_loading}
            rowSelection={false}
            total={equipment_documents?.meta?.total_count}
            key={`${projectId}-eqp-files`}
          />
        </Panel>
        <Panel header={`Project Files (${project_documents?.meta?.total_count ? project_documents?.meta?.total_count : 0})`} key="project-files">
          <AlectifyTable
            size="small"
            onDataFetch={(data) => dispatch(getSharedDocuments(data, 'project'))}
            isDownloadZip
            onDownloadZip={(data) => onDownloadZip(data, 'project')}
            columns={fileColumns({
              type: 'project',
              downloadDocument,
              setRecord: (record) => {
                setRecord(record);
                setHistoryVisible(!historyVisible);
              },
            })}
            type="project"
            id={projectId}
            dataSource={project_documents?.data}
            loading={project_documents_loading}
            rowSelection={false}
            total={project_documents?.meta?.total_count}
            key={`${projectId}-project-files`}
          />
        </Panel>
      </Collapse>
      {historyVisible && (
        <ViewHistoryModal
          details={record}
          title="Shared History"
          onCancel={() => setHistoryVisible(!historyVisible)}
          visible={historyVisible}
        />
      )}
    </Spin>
  );
}

export default SharedAccordian;
