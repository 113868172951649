// React imports
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Ant Design components
import { Typography } from 'antd';

// Custom components
import AlectifyMap from 'app/components/map';
import AlectifyText from 'static/texts.json';
import { BIDDING_MANAGEMENT } from 'static/utils';
import WelcomeMessage from 'app/components/welcome-message';
import { setActiveBiddingProject } from 'app/store/bidding-management';
import { setShowBiddingSidebar } from 'app/store/common';

const { Title, Paragraph } = Typography;

const BiddingMapView = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(({ auth }) => auth);
    const { biddingProjects } = useSelector(({ common }) => common);

    const onProjectClickHandler = (masterProject) => {
        dispatch(setShowBiddingSidebar(false));
        dispatch(setActiveBiddingProject(masterProject));
        props.history.push(`${BIDDING_MANAGEMENT}/${masterProject?.id}`);
    };

    return (
        <div className="alectify-map-container">
            <Title level={5} className="mt-0 welcome-message-text">
                <WelcomeMessage />
                {user && ` ${user.first_name} ${user.last_name}`}
            </Title>
            <Paragraph>
                You have total{' '}
                <span className="text-blue-bold">{biddingProjects?.length || '0'}</span>{' '}
                {AlectifyText.BIDDING_MANAGEMENT_PROJECTS}
            </Paragraph>
            <AlectifyMap projects={biddingProjects} onClick={onProjectClickHandler} />
        </div>
    );
};

export default memo(BiddingMapView);
