import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { AutoComplete, Col, Form, Row, Select, Spin, Tooltip } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { SearchListModal } from './search-list-modal';
import { fetchFilteredPackages, fetchFilteredTags } from '../../services';
import { EQUIPMENT_DOCUMENT_TYPES, isInboxActive, isPackagesActive } from '../../../static/utils';

function SelectWithAdditonaliList(props) {
    const DEFAULT_EQUIPMENT_TYPE = (isPackagesActive() && props.enablePackages)
        ? EQUIPMENT_DOCUMENT_TYPES.PACKAGE
        : EQUIPMENT_DOCUMENT_TYPES.EQUIPMENT;
    const [visible, setVisible] = useState(false);
    const [equipmentType, setEquipmentType] = useState(DEFAULT_EQUIPMENT_TYPE);
    const [equipmentList, setEquipmentList] = useState({ fetching: false, data: [], text: '' });

    const onEquipmentSelection = (record) => {
        const pkgId = record?.packageroom?.[0]?.id || record?.id;
        const package_type = record?.packageroom?.[0]?.package_type || record?.package_type;
        setVisible(false);
        props.setValue({
            ...record,
            id: pkgId,
            package_type,
            tagId: record?.packageroom?.[0]?.id ? record?.id : null,
        });
        props.form?.setFieldsValue({ id: pkgId, [props.searchFieldName]: record?.name });
    };

    const displayEquipmentType = (type) => type.charAt(0).toUpperCase() + type.slice(1);

    const onChangeEquipmentType = (value) => {
        setEquipmentType(value);
        props.form?.setFieldsValue({ equipment: null });
        setEquipmentList({ ...equipmentList, data: [], text: '' });
    };

    const onSearch = _.debounce(async (name) => {
        try {
            setEquipmentList({ ...equipmentList, fetching: true, text: name });
            let response;
            if (equipmentType === EQUIPMENT_DOCUMENT_TYPES.PACKAGE) {
                response = await fetchFilteredPackages(props.projectId, { name });
            } else {
                response = await fetchFilteredTags(props.projectId, { name });
            }
            setEquipmentList({
                ...equipmentList,
                fetching: false,
                text: name,
                data: response?.data,
            });
        } catch (error) {
            console.log(error);
        }
    }, 500);
    return (
        <>
            <Row gutter={15}>
                {/* <Col span={6}>
                    <Form.Item
                        label={!props.noLabels ? 'Type' : null}
                        required={props.required}
                        className={props.formItemClass}
                    >
                        <Select value={equipmentType} onChange={onChangeEquipmentType}>
                            {isPackagesActive() ? (
                                Object.values(EQUIPMENT_DOCUMENT_TYPES).map((type) => (
                                    <Select.Option key={type} value={type}>
                                        {displayEquipmentType(type)}
                                    </Select.Option>
                                ))
                            ) : (
                                <Select.Option key="equipment" value="equipment">
                                    {displayEquipmentType('equipment')}
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col> */}
                <Col span={18} className={props.noLabels ? 'd-flex align-items-center' : ''}>
                    <Form.Item
                        label={
                            !props.noLabels ? (
                                <>
                                    {displayEquipmentType(equipmentType)}
                                    <div className="label-actions">
                                        <Tooltip title="View List">
                                            <UnorderedListOutlined
                                                onClick={() => setVisible(true)}
                                            />
                                        </Tooltip>
                                    </div>
                                </>
                            ) : null
                        }
                        className={`label-with-actions ${props.formItemClass}`}
                        name={props.searchFieldName}
                        rules={[
                            {
                                required: props.required,
                                message: 'Required',
                            },
                        ]}
                        style={props.noLabels ? { width: '92%' } : {}}
                    >
                        <AutoComplete
                            onSearch={onSearch}
                            notFoundContent={
                                equipmentList.fetching ? (
                                    <div className="text-center">
                                        <Spin size="small" />
                                    </div>
                                ) : equipmentList?.data?.length === 0 && equipmentList.text ? (
                                    'Not Found'
                                ) : null
                            }
                            options={(equipmentList.data || []).map((item) => ({
                                ...item,
                                value: item.id,
                                label: item.name,
                            }))}
                            onSelect={(_, option) => onEquipmentSelection(option)}
                            placeholder={`${props?.defaultValue?.name ? props?.defaultValue?.name : `Search for ${displayEquipmentType(equipmentType)}s`}`}

                        />
                    </Form.Item>
                    {props.noLabels && (
                        <Tooltip title="View List">
                            <UnorderedListOutlined
                                style={{ marginLeft: 5 }}
                                onClick={() => setVisible(true)}
                            />
                        </Tooltip>
                    )}
                </Col>
            </Row>
            {visible && (
                <SearchListModal
                    visible={visible}
                    onRow={onEquipmentSelection}
                    projectId={props.projectId}
                    equipmentType={equipmentType}
                    onCancel={() => setVisible(!visible)}
                    title={displayEquipmentType(equipmentType)}
                />
            )}
        </>
    );
}

SelectWithAdditonaliList.defaultProps = {
    typeFieldName: 'type',
    searchFieldName: 'equipment',
    projectId: '',
    form: null,
    required: false,
    noLabels: false,
    enablePackages: true,
    formItemClass: '',
    setValue: () => {},
};

SelectWithAdditonaliList.propTypes = {
    form: PropTypes.any,
    noLabel: PropTypes.bool,
    required: PropTypes.bool,
    setValue: PropTypes.func,
    projectId: PropTypes.string,
    formItemClass: PropTypes.string,
    typeFieldName: PropTypes.string,
    searchFieldName: PropTypes.string,
    enablePackages: PropTypes.bool,
};

export default memo(SelectWithAdditonaliList);
