import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { memo, useEffect, useState } from 'react';
import { AlectifyTable } from '../../components';
import { fetchCompanyProjects, fetchCompanyUsers } from '../../services';
import { DEFAULT_START_PAGE } from '../../../static/utils';
import useUsersColumns from './effects/useUsersColumns';
import useProjectsColumns from './effects/useProjectsColumns';

const FINISH_PARAMS = {
  completed: 'True',
  active: 'False',
};

const ACTIVE_PARAMS = {
  active: '1',
  completed: '1',
};

const HEADINGS = {
  users: 'Users',
  active: 'Active Sub Projects',
  completed: 'Completed Sub Projects',
};

export const CompanyInsightsModal = memo(
  ({ visible = false, variant = null, onCancel = () => { } }) => {
    const usersColumns = useUsersColumns();
    const projectColumns = useProjectsColumns();
    const { user } = useSelector(({ auth }) => auth);
    const [insights, setInsights] = useState({
      data: [],
      total: 0,
      fetching: false,
    });

    useEffect(() => {
      getCompanyProjects({ page: DEFAULT_START_PAGE, per_page: 10 });
    }, [variant]);

    const getCompanyProjects = async ({ page, per_page }) => {
      try {
        setInsights({ ...insights, fetching: true });
        const params = {
          is_active: ACTIVE_PARAMS[variant] || null,
          is_finished: FINISH_PARAMS[variant] || null,
          page,
          per_page,
        };
        let response = null;
        if (variant === 'users') {
          response = await fetchCompanyUsers(user?.company?.id, params);
        } else {
          response = await fetchCompanyProjects(user?.company?.id, params);
        }
        setInsights({
          ...insights,
          fetching: false,
          data: response?.data,
          total: response?.meta?.total_count,
        });
      } catch (error) {
        console.log(error);
        setInsights({ ...insights, fetching: false });
      }
    };

    return (
      <Modal
        open={visible}
        width={1000}
        footer={null}
        onCancel={onCancel}
        title={`Total ${HEADINGS[variant] || 'Sub Projects'}`}
      >
        <AlectifyTable
          size="small"
          selectRows={false}
          id={user?.company?.id}
          total={insights.total}
          key={`company-${variant}`}
          loading={insights.fetching}
          dataSource={insights.data}
          onDataFetch={getCompanyProjects}
          defaultPageSize={10}
          columns={variant === 'users' ? usersColumns : projectColumns}
        />
      </Modal>
    );
  },
);
