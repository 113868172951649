import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Avatar,
    Breadcrumb,
    Layout,
    Card,
    Modal,
    Tooltip,
    Form,
    Row,
    Col,
    Spin,
    Space,
    Tag,
    Input,
    Button,
    Empty,
} from 'antd';
import { InfoCircleFilled, LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
    fetchPackageDetails,
    saveEquipmentDocs,
    saveEquipmentDocsForVideos,
    fetchProjectSearchResults,
    fetchTagsByProjectV2,
} from '../../services';
import { getEquipmentDocuments } from '../../store/equipment-docs';
import { isAssetDetailReviewActive, PACKAGE_MENU, PROJECTS, PROJECT_TYPES } from '../../../static/utils';
import { DetailsTab } from './tabs/details';
import { ReviewTab } from './tabs/review';
import { InstallationTab } from './tabs/installation';
import { uploadAfterShipmentImages, uploadBeforeShipmentImages } from '../../services/shipment';
import { setActiveProject, setActiveProjectTab } from '../../store/common';
import flowChart from '../../assets/images/flowChart.png';
import '../../assets/styles/components/package-detail.scss';
import { EquipmentDocs } from './equipment_docs';
import { SelectWithAdditonaliList } from '../../components';
import { isNull } from 'lodash';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const { Content } = Layout;

function PackageDetail({ history, taskIbox = false }) {
    const dispatch = useDispatch();
    const [searchForm] = Form.useForm();
    const { pkgId, projectId } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [activeMenu, setActiveMenu] = useState(PACKAGE_MENU[0].title);
    const [check, setCheck] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const location = useLocation();

    const { equipmentDocs } = useSelector(({ equipments }) => equipments);
    const { activeProject } = useSelector(({ common }) => common);
    const [searchQuery, setSearchQuery] = useState(null);
    const [record, setRecord] = useState(null);
    const [tagId, setTagId] = useState(new URLSearchParams(location?.search).get(`tag_id`));
    const [searchResults, setSearchResults] = useState([]);
    const [isSearchLoading, setSearchLoading] = useState(false);

    useEffect(() => {
        if (searchQuery) {
            getProjectSearchResults(searchQuery);
        } else {
            setSearchResults([]);
        }
    }, [searchQuery]);

    const getProjectSearchResults = async (query) => {
        setSearchLoading(true);
        const res = await fetchProjectSearchResults({
            projectId: activeProject?.id,
            body: {
                search_str: query,
            },
        });

        if (res?.success) {
            setSearchLoading(false);
            setSearchResults(res?.data);
        } else {
            setSearchResults([]);
            setSearchLoading(false);
        }
    };

    const onResultClick = (pkgId) => {
        history.push(`/projects/${projectId}/package/${pkgId}`);
    };

    useEffect(() => {
        Promise.all([getPackageDetail(pkgId), getDocuments(pkgId)]);
    }, [pkgId]);

    const getDocuments = () => {
        dispatch(getEquipmentDocuments(projectId, pkgId));
    };

    const getPackageDetail = async () => {
        try {
            setLoading(true);
            const response = await fetchPackageDetails(projectId, pkgId);
            const tagID = response?.data?.tags[0].id;
            setTagId(tagID);
            setDetail(response?.data);
            setLoading(false);

            // Set tag if doesnt exist]
            if (isNull(tagId)) {
                setTagId(response?.data?.tags?.[0]?.id);
            }
        } catch (ex) {
            setLoading(false);
        }
    };

    const uploadEquipmentDocs = async (fileList, key) => {
        try {
            setLoading(true);
            const formData = new FormData();
            fileList.map((file) => formData.append('files', file));
            formData.append('is_package', true);
            formData.append('dir_key', key);
            formData.append('project_id', projectId);
            formData.append('id', pkgId);
            await saveEquipmentDocs(projectId, pkgId, formData);
            setLoading(false);
            getDocuments(pkgId);
        } catch (ex) {
            setLoading(false);
        }
    };

    const uploadEquipmentDocsForVideos = async (fileList, key) => {
        try {
            setLoading(true);
            const formData = new FormData();
            fileList.map((file) => formData.append('files', file));
            formData.append('project', projectId);
            formData.append('package', pkgId);
            await saveEquipmentDocsForVideos(formData);
            setLoading(false);
            getDocuments(pkgId);
        } catch (ex) {
            setLoading(false);
        }
    };

    const uploadBeforeImg = async (fileList) => {
        try {
            const formData = new FormData();
            fileList.map((file) => formData.append('files', file));
            const response = await uploadBeforeShipmentImages(projectId, pkgId, formData);
        } catch (ex) {
            throw ex;
        }
    };

    const uploadAfterImg = async (fileList) => {
        try {
            const formData = new FormData();
            fileList.map((file) => formData.append('files', file));
            await uploadAfterShipmentImages(projectId, pkgId, formData);
        } catch (ex) {
            throw ex;
        }
    };

    const getClassName = (menuItem) =>
        `packages-tab-menu ${activeMenu === menuItem ? 'active' : ''}`;

    const commonProps = {
        pkgId,
        uploadDocs: uploadEquipmentDocs,
        equipmentDocs: equipmentDocs.list,
        uploadForVideos: uploadEquipmentDocsForVideos,
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onSelect = async (tagId) => {
        setTagId(tagId);
    };

    useEffect(async () => {
        try {
            setLoading(true);
            const response = await fetchTagsByProjectV2({
                id: projectId,
                tagId,
            });
            if (response?.data && response?.data.length === 1) {
                setRecord(response?.data?.[0]);
            }
            setLoading(false);
        } catch (ex) {
            setLoading(false);
            throw ex;
        }
    }, [tagId]);

    return (
        <Content>
            <Modal
                title="Flow Chart"
                className="flow-chart-body"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <img className="modal-img" src={flowChart} />
            </Modal>
            <Card className="card_body">
                <div className="main_body">
                    {!taskIbox && (
                        <div className="bread justify-space-between mb-15">
                            <div className="d-flex align-items-center">
                                <div className="bread">
                                    <Breadcrumb separator=">">
                                        <Breadcrumb.Item
                                            onClick={() => {
                                                const masterProject = activeProject?.masterProject;
                                                dispatch(
                                                    setActiveProject({
                                                        ...masterProject,
                                                        masterProject,
                                                        type: PROJECT_TYPES.MASTER_PROJECT,
                                                    }),
                                                );
                                                history.push(`${PROJECTS}/${masterProject?.id}`);
                                            }}
                                        >
                                            <span className="cursor-pointer">
                                                {activeProject?.masterProject?.name}
                                            </span>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item
                                            onClick={() => {
                                                dispatch(
                                                    setActiveProject({
                                                        ...activeProject,
                                                        masterProject: activeProject?.masterProject,
                                                        type: PROJECT_TYPES.SUB_PROJECT,
                                                    }),
                                                );
                                                history.push(
                                                    `/projects/${activeProject?.masterProject.id}/sub-project/${activeProject.id}?page=insights`,
                                                );
                                            }}
                                        >
                                            <span className="cursor-pointer">
                                                {activeProject?.name}
                                            </span>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item
                                            onClick={() => {
                                                dispatch(
                                                    setActiveProject({
                                                        ...activeProject,
                                                        masterProject: activeProject?.masterProject,
                                                        type: PROJECT_TYPES.SUB_PROJECT,
                                                    }),
                                                );
                                                history.push(
                                                    `/projects/${activeProject?.masterProject.id}/sub-project/${activeProject.id}?page=equipments`,
                                                );
                                            }}
                                        >
                                            <span className="cursor-pointer">Equipments</span>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <span
                                                className="pkg-detail-breadcrumb"
                                                title={detail?.name}
                                            >
                                                {detail?.name}
                                            </span>
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <div className="pkg-detail ml-40">
                                    <Form layout="vertical" form={searchForm}>
                                        <SelectWithAdditonaliList
                                            noLabels
                                            form={searchForm}
                                            formItemClass="mb-0"
                                            projectId={projectId}
                                            setValue={(pkg) => onResultClick(pkg?.id)}
                                            defaultValue={record}
                                        />
                                    </Form>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <Space>
                                    <label>Manual Schedule:</label>
                                    <Tag
                                        className="custom-tag"
                                        style={
                                            !detail?.tags[0]?.is_manual_tracking
                                                ? { color: '#fff' }
                                                : {}
                                        }
                                        color={
                                            detail?.tags[0]?.is_manual_tracking
                                                ? '#1dbf73'
                                                : '#efa84a'
                                        }
                                    >
                                        {detail?.tags[0]?.is_manual_tracking ? 'Yes' : 'No'}
                                    </Tag>
                                </Space>
                                <div className="packages-tabs">
                                    {PACKAGE_MENU.filter(({ visible }) => visible).map(
                                        (menuItem) => {
                                            return (
                                                <span
                                                    className={getClassName(menuItem.title)}
                                                    onClick={() => setActiveMenu(menuItem.title)}
                                                >
                                                    {menuItem.title}
                                                </span>
                                            );
                                        },
                                    )}
                                </div>
                                <div className="info-icon-main cursor-pointer">
                                    <Tooltip title="Flow Chart">
                                        <InfoCircleFilled
                                            onClick={showModal}
                                            className="info-icon"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        {activeMenu === 'Details' && (
                            <Card className="footer-body">
                                <DetailsTab
                                    key={record?.id}
                                    projectId={projectId}
                                    detail={{ ...detail }}
                                    pkgId={pkgId}
                                    record={record}
                                    onSelect={onSelect}
                                    isLoading={isLoading}
                                />
                            </Card>
                        )}
                        {activeMenu === 'Review' && (
                            <ReviewTab
                                projectId={projectId}
                                detail={{ ...detail }}
                                getPackageDetail={getPackageDetail}
                                {...commonProps}
                                isLoading={isLoading}
                                equipmentDocs={equipmentDocs?.list}
                            />
                        )}
                        {activeMenu === 'Shipment' && (
                            <InstallationTab
                                projectId={projectId}
                                {...commonProps}
                                detail={detail}
                                getPackageDetail={getPackageDetail}
                                uploadAfterImg={uploadAfterImg}
                                uploadBeforeImg={uploadBeforeImg}
                                isLoading={isLoading}
                            />
                        )}
                        {activeMenu === 'Documents' && (
                            <Card className="footer-body">
                                <EquipmentDocs
                                    pkgId={pkgId}
                                    pkgName={detail?.name}
                                    defaultPackage={detail}
                                    projectId={projectId}
                                    data={equipmentDocs}
                                    isLoading={isLoading}
                                    uploadDocs={uploadEquipmentDocs}
                                    uploadForVideos={uploadEquipmentDocsForVideos}
                                />
                            </Card>
                        )}
                    </div>
                </div>
                {/* {isAssetDetailReviewActive() && (
                    <>
                        <input
                            className="checkBox-input"
                            type="checkbox"
                            name=""
                            id="check"
                            checked={check}
                            onChange={() => setCheck(!check)}
                        />
                        <div className="quick-chat">
                            <label htmlFor="check">
                                <span className="show" id="show">
                                    <LeftOutlined />
                                </span>
                                <span className="show" id="hide">
                                    <RightOutlined />
                                </span>
                                <div className="comet-chat-body">
                                    <div className="demo-package-chat-header">
                                        <h1>{detail?.name}</h1>
                                    </div>
                                    <div className="demo-package-chat-messages mt-30">
                                        <Empty description="No Message To Display" />
                                    </div>
                                    <div className="demo-package-chat-actions">
                                        <Row gutter={15}>
                                            <Col span={18}>
                                                <Input placeholder="Enter your message" />
                                            </Col>
                                            <Col span={6}>
                                                <Button type="primary" className="full-width">
                                                    Send
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </>
                )} */}
            </Card>
        </Content>
    );
}

export default PackageDetail;
