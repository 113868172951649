import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Papaparse from 'papaparse';
import {
  DeleteTwoTone,
  UploadOutlined,
  WarningTwoTone,
  ExclamationCircleFilled,
  CopyTwoTone,
  DownloadOutlined,
  UserOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  Button,
  Space,
  Typography,
  Form,
  Alert,
  notification,
  Upload,
  Modal,
  Dropdown,
  Collapse,
  message,
  Switch,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import constructionSampleCsv from '../../../../assets/sample_construction.csv';
import empowerSampleCsv from '../../../../assets/sample_empower.csv';
import {
  DATE_FIELDS,
  INPUT_DATE_FORMAT,
  SERVER_DATE_FORMAT,
  TAGS_CSV_HEADERS,
  CSV_DATE_FORMAT,
  DATES_KEYS,
  PROJECT_API_ENUM,
  isDatePast,
} from '../../../../../static/utils';
import {
  deleteTags,
  duplicateTags,
  fetchTagsByProjectV2,
  getInactiveTags,
  getOrganizations,
  newUpdateProject,
  updateTag,
  uploadEmpowerFile,
} from '../../../../services';

import ViewModal from '../../../../components/taglist/ViewModal';
import { AlectifyTable } from '../../../../components';
import { getTagsByProject } from '../../../../store/tags';
import AddEquipment from './AddEquipment';
import DeleteEquipment from './DeleteEquipment';
import UpdateEquipment from './UpdateEquipment';
import { dateViewColumns } from './effects/datesViewColumns';

const { Text } = Typography;
const { confirm } = Modal;

function ManageEquipments({
  basicInfo = {},
  tags = [],
  orgs = [],
  goBack = () => {},
  setTags = () => {},
  onFinish = () => {},
  setLoader = () => {},
  setEquipmentErrors = () => {},
  equipmentErrors = [],
  projectId = null,
}) {
  const { createNewProject } = useSelector(({ common }) => common);
  const { masterTags, masterTagsLoading } = useSelector(({ tags }) => tags);

  const [selectedTags, setSelectedTags] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [editingKey, setEditingKey] = useState('');
  const [dates, setDates] = useState({});
  const [formatError, setFormatError] = useState(false);
  const [isContractDateError, setIsContractDateError] = useState(false);
  const [organizations, setOrganizations] = useState(orgs);
  const [inactiveTags, setInactiveTags] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingRecord, setDeletingRecord] = useState({});
  const [toggleTagOpen, setToggleTagOpen] = useState(false);
  const [record, setRecord] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [uploadType, setUploadType] = useState('AddEquipment');
  const [modalType, setModalType] = useState(null);
  const [searchedColumn, setSearchColumn] = useState('');
  const [orderingColumn, setOrderingColumn] = useState('');
  const [searchText, setSearchText] = useState('');

  const toggleDeleteModal = () => setDeleteModalOpen((state) => !state);
  const toggleTagModal = () => setToggleTagOpen((state) => !state);

  useEffect(() => {
    getMasterTagsData(page, pageSize, searchText, searchedColumn, orderingColumn);
    fetchOrgs();
    fetchInactiveTags();
  }, []);

  const handleEditMfrChange = (val, option) => {
    form.setFieldsValue({
      manufacture: val,
    });
  };

  const fetchInactiveTags = async () => {
    try {
      const res = await getInactiveTags(projectId);
      if (res.success) {
        setInactiveTags(res.data);
      }
    } catch (err) {
      setInactiveTags([]);
      console.log(err);
    }
  };

  const fetchOrgs = async (val) => {
    try {
      const results = await getOrganizations(val);
      const filteredMfrs = results.data.filter(
        (item) => item?.org_type?.name === 'Manufacturer',
      );
      setOrganizations([...filteredMfrs]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTags = async () => {
    try {
      setLoader(true);
      const response = await fetchTagsByProjectV2({
        id: projectId,
        page,
        searchText,
        searchedColumn,
        orderingColumn,
        pageSize,
      });
      setTags([...response?.data]);
      setLoader(false);
    } catch (error) {}
  };

  const getMasterTagsData = async (
    page,
    pageSize,
    searchText,
    searchedColumn,
    orderingColumn,
  ) => {
    setLoader(true);
    dispatch(getTagsByProject({
      id: projectId,
      page,
      searchText,
      searchedColumn,
      orderingColumn,
      pageSize,
    }));
    // setTags(fetchTags.data);
    setLoader(false);
  };

  const normFile = async (e) => {
    if (uploadType === 'CreateAll') {
      if (Array.isArray(e)) {
        return e;
      }
      if (e.fileList.length) {
        readCsv(e.fileList[0].originFileObj);
      } else if (e.fileList.length === 0) {
        setTags([]);
      }
      return e && e.fileList;
    } if (uploadType === 'UpdateEmpower') {
      if (e.fileList.length) {
        setLoader(true);
        const response = await uploadEmpowerTags(e.fileList[0].originFileObj);
        setLoader(false);
      }
    } else {
    }
  };

  const showConfirm = (e) => {
    confirm({
      title: 'Do you want to proceed?',
      icon: <ExclamationCircleFilled />,
      content: 'This step will override all tags in the system',
      onOk() {
        normFile(e);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const uploadEmpowerTags = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    const response = await uploadEmpowerFile(projectId, formData);
    if (response.success) {
      getMasterTagsData(page, pageSize, searchText, searchedColumn, orderingColumn);
      setEquipmentErrors([]);
    } else {
      notification.error({
        message: 'Unable to upload file',
        description: response.message,
      });
    }
  };

  const validateDates = (tag, index) => {
    const {
      name,
      order_date,
      description,
      manufacture,
      approval_date,
      release_date,
      test_date,
      ship_date,
    } = tag;
    const _errors = [];

    if (!name.length) {
      _errors.push({
        message: `Row # ${index}: Check the Tag name field.`,
      });
    }

    if (!description.length) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()}, Check the Description field.`,
      });
    }

    if (!manufacture.length) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()}, Check the Manufacturer field.`,
      });
    }

    if (order_date !== null && !moment(order_date).isValid()) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()}, Check the date format of order_date.`,
      });
    }

    if (approval_date !== null && !moment(approval_date).isValid()) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()}, Check the date format of approval_date.`,
      });
    }

    if (release_date !== null && !moment(release_date).isValid()) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()}, Check the date format of release_date.`,
      });
    }

    if (test_date !== null && !moment(test_date).isValid()) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()}, Check the date format of test_date.`,
      });
    }

    if (ship_date !== null && !moment(ship_date).isValid()) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()}, Check the date format of ship_date.`,
      });
    }

    if (isDatePast(approval_date, order_date)) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()} approval date should be greater than or equal to order date`,
      });
    }
    if (isDatePast(release_date, approval_date)) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()} release date should be greater than or equal to approval date`,
      });
    }
    if (isDatePast(test_date, release_date)) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()} test date should be greater than or equal to release date`,
      });
    }
    if (isDatePast(ship_date, test_date)) {
      _errors.push({
        message: `Row # ${index}: ${name.bold()} ship date should be greater than or equal to test date`,
      });
    }
    return _errors;
  };

  const readCsv = (file) => {
    try {
      const reader = new FileReader();
      setLoader(true);
      reader.readAsBinaryString(file);
      reader.addEventListener('load', async (e) => {
        let _tags = [];
        let _errors = [];
        setEquipmentErrors([]);
        const data = e.target.result;
        Papaparse.parse(data, {
          complete(results) {
            let isFormatCorrect = false;

            results.data.forEach((row, index) => {
              if (index === 0) {
                isFormatCorrect = _.isEqual(row, [...TAGS_CSV_HEADERS, 'Package Room'])
                                    || _.isEqual(row, [...TAGS_CSV_HEADERS])
                                    || _.isEqual(row, [
                                      ...TAGS_CSV_HEADERS,
                                      'Package Room',
                                      'Price',
                                    ])
                                    || _.isEqual(row, [
                                      ...TAGS_CSV_HEADERS,
                                      'Package Room',
                                      'Quantity',
                                    ])
                                    || _.isEqual(row, [
                                      ...TAGS_CSV_HEADERS,
                                      'Package Room',
                                      'Price',
                                      'Quantity',
                                    ]);

                if (!isFormatCorrect) {
                  setFormatError(
                    'Incorrect CSV Format, Download sample CSV for the reference',
                  );
                } else {
                  setFormatError('');
                }
              }
              if (index !== 0 && !_.isEmpty(row[0])) {
                const [
                  reference_number,
                  name,
                  description,
                  manufacture,
                  order_date,
                  approval_date,
                  release_date,
                  final_date,
                  test_date,
                  ship_date,
                  package_room,
                  price,
                  quantity,
                ] = row;

                const tag = {
                  reference_number,
                  name,
                  description,
                  manufacture,
                  order_date: !_.isEmpty(order_date) ? order_date : null,
                  approval_date: !_.isEmpty(approval_date) ? approval_date : null,
                  approval_dwg: !_.isEmpty(approval_date),
                  release_date: !_.isEmpty(release_date) ? release_date : null,
                  test_date: !_.isEmpty(test_date) ? test_date : null,
                  ship_date: !_.isEmpty(ship_date) ? ship_date : null,
                  package_room: !_.isEmpty(package_room) ? package_room : null,
                  price: !_.isEmpty(price) ? price : null,
                  quantity: !_.isEmpty(quantity) ? quantity : null,
                  is_manual_tracking: true,
                };

                const fieldErrors = validateDates(tag, index);
                _errors = [..._errors, ...fieldErrors];
                _tags.push(tag);
              }
            });
          },
        });
        setEquipmentErrors([..._errors]);

        if (!_.isEmpty(_tags)) {
          _tags.forEach((tag) => {
            Object.keys(tag).forEach((key) => {
              if (DATES_KEYS.includes(key) && !_.isEmpty(tag[key])) {
                tag[key] = moment(moment(tag[key]), CSV_DATE_FORMAT).format(
                  SERVER_DATE_FORMAT,
                );
              }
            });

            return tag;
          });
        }

        if (_tags.length) {
          try {
            if (_errors.length === 0) {
              const response = await newUpdateProject(
                PROJECT_API_ENUM.TAGS,
                projectId,
                _tags,
              );

              if (response.success) {
                _tags = [...response?.data];
              } else {
                setFormatError(response.message);
              }
            }
          } catch (error) {
            setLoader(false);
          }
        }

        //
        setOrderingColumn(null);
        setPage(1);
        setSearchColumn(null);
        setSearchText(null);
        getMasterTagsData(page, pageSize, searchText, searchedColumn, orderingColumn);
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const deleteTagHandler = async (tagIds) => {
    try {
      Swal.fire({
        icon: 'warning',
        title: `Deleting ${tagIds.length} tag${tagIds.length > 1 ? 's' : ''}.`,
        text: `You are about to delete ${tagIds.length} tag${
          tagIds.length > 1 ? 's' : ''
        }, Do you want to proceed?`,
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        backdrop: true,
        confirmButtonColor: '#16aaff',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoader(true);
          const newTags = tags.filter((tag) => !tagIds.includes(tag.id));
          setTags([...newTags]);
          setSelectedTags([]);
          const res = await deleteTags(projectId, tagIds);

          if (res.success) {
            notification.success({ description: 'Removed successfully' });
            setLoader(false);
            getMasterTagsData(
              page,
              pageSize,
              searchText,
              searchedColumn,
              orderingColumn,
            );
          } else {
            setLoader(false);
            notification.error({
              message: 'Error deleting tags',
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedTags,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTags([...selectedRows.map((row) => row.id)]);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  const cancel = () => {
    setEditingKey('');
  };

  const updateTagHandler = async (tagId, data, cb) => {
    const response = await updateTag(projectId, tagId, data);
    if (response) {
      notification.success({
        message: 'Successfully updated.',
        placement: 'topRight',
      });
      cb && cb();
    }
  };

  const checkDateDifference = (items) => {
    items.forEach((item) => {
      const startDate = moment(item.pm_start_external, SERVER_DATE_FORMAT);
      const endDate = moment(item.pm_end_external, SERVER_DATE_FORMAT);
      if (endDate < startDate) {
        setIsContractDateError(true);
      }
    });
    setIsContractDateError(false);
    setFormatError('');
  };

  const onNext = () => onFinish({ tags });

  useEffect(() => {
    dispatch(getTagsByProject({ id: projectId, pageSize: 10 }));
  }, []);

  const onDuplicateTags = async (selectedTags) => {
    try {
      setLoader(true);
      await duplicateTags(projectId, selectedTags);
      message.success('Created Duplicates Successfully');
      getMasterTagsData(page, pageSize, searchText, searchedColumn, orderingColumn);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="mb-20">
        <h2 className="step-heading mb-10 d-flex justify-content-space-between">
          Equipment Information
          <Space size={5}>
            <Upload
              beforeUpload={() => {
                setUploadType('UpdateEmpower');
                return false;
              }}
              listType="text"
              maxCount={1}
              showUploadList={false}
              accept=".csv"
              onChange={showConfirm}
            >
              <Button type="primary">
                <UploadOutlined />
                Create / Update with Empower
              </Button>
            </Upload>
              {(!process.env.REACT_APP_STAGE
                            || process.env.REACT_APP_STAGE === 'stage') && (
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    label: (
                                      <Upload
                                        beforeUpload={() => {
                                          setUploadType('CreateAll');
                                          return false;
                                        }}
                                        listType="text"
                                        maxCount={1}
                                        showUploadList={false}
                                        accept=".csv"
                                        onChange={showConfirm}
                                      >
                                        Upload Alectify CSV
                                      </Upload>
                                    ),
                                  },
                                  {
                                    label: (
                                      <Link to={constructionSampleCsv} target="_blank">
                                        Download Alectify CSV
                                      </Link>
                                    ),
                                  },
                                  {
                                    label: (
                                      <Link to={empowerSampleCsv} target="_blank">
                                        Download Empower CSV
                                      </Link>
                                    ),
                                  },
                                ],
                              }}
                              arrow
                            >
                         <Button>
                         <MoreOutlined />
                         </Button>
                         </Dropdown>
            )}
          </Space>
        </h2>
      </div>
      <Collapse accordion className="mb-20">
        <Collapse.Panel
          header="Add Equipment Manual"
          key="add-equipment"
          extra={(
            <Tooltip title="Only works for system generated equipments">
              <Space onClick={(e) => e.stopPropagation()}>
                <label>Manual Schedule:</label>
                <Switch
                  checked
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  disabled
                />
              </Space>
            </Tooltip>
          )}
          >
          <AddEquipment
            dates={dates}
            tags={tags}
            visible={modalType === 'add'}
            projectId={projectId}
            organizations={organizations}
            fetchTags={() => getMasterTagsData(page, pageSize, searchText, searchedColumn, orderingColumn)}
            setModalType={setModalType}
            setTags={setTags}
            setDates={setDates}
            newUpdateProject={newUpdateProject}
          />
        </Collapse.Panel>
      </Collapse>
      {formatError && (
        <div className="mb-15" style={{ marginTop: -13 }}>
          <Text className="mt-" type="danger">
            {formatError}
          </Text>
        </div>
      )}
      {!_.isEmpty(equipmentErrors) && (
        <Alert
          message="Tags Errors"
          showIcon
          className="mb-20"
          description={equipmentErrors.map((error, idx) => (
            <span
              className="d-block"
              key={idx}
              dangerouslySetInnerHTML={{ __html: error.message }}
            />
          ))}
          type="error"
        />
      )}

      {selectedTags.length > 0 && (
      <div className="mb-10 text-right">
        <Space>
          <Button onClick={() => onDuplicateTags(selectedTags)}>
            <CopyTwoTone />
            {' '}
            Duplicate (
            {selectedTags.length}
            )
          </Button>
          <Button onClick={() => deleteTagHandler(selectedTags)}>
            <DeleteTwoTone twoToneColor="#f56666" />
            {' '}
            Delete (
            {selectedTags.length}
            )
          </Button>
        </Space>
        <p className="mt-8 mb-0">
          <span>
            <WarningTwoTone twoToneColor="#faad14" />
            {' '}
            If this tag is part of a
            package, it will be deleted from there.
          </span>
        </p>
      </div>
      )}

      <Form component={false}>
        <AlectifyTable
          size="small"
          onDataFetch={(data) => {
            setPage(data.page);
            setPageSize(data.pageSize);
            dispatch(getTagsByProject(data));
          }}
          columns={dateViewColumns({
            setModalType,
            toggleDeleteModal,
            setDeletingRecord,
            setRecord,
          })}
          rowSelection={{
            ...rowSelection,
          }}
          isDownloadZip={false}
          isEmailSharing={false}
          id={projectId}
          dataSource={masterTags?.data}
          loading={masterTagsLoading}
          onRowSelectionAction={(rows) => setSelectedTags(rows.map(({ id }) => id))}
          total={masterTags?.meta?.total_count}
          key={`${projectId}-review-files`}
          defaultPageSize={10}
        />
      </Form>

      <div className="text-right">
        <Space>
          <Button
            className="btn-lg btn-default save-btn mt-30"
            type="default"
            htmlType="button"
            onClick={goBack}
          >
            Previous
          </Button>
          <Button
            className="btn-lg btn-primary-lg save-btn mt-30"
            type="primary"
            onClick={onNext}
            disabled={isContractDateError}
          >
            Save & Finish
          </Button>
        </Space>
      </div>
      {/**
             * Delete Equipment Modal
             */}
      <DeleteEquipment
        title={`Deleting ${deletingRecord.name} ${
          deletingRecord?.reference_number ? `#${deletingRecord?.reference_number}` : ''
        } `}
        tag={deletingRecord}
        visible={isDeleteModalOpen}
        onOk={() => {
          deleteTagHandler([deletingRecord.id]);
          setDeletingRecord({});
          toggleDeleteModal();
        }}
        onCancel={() => {
          setDeletingRecord({});
          toggleDeleteModal();
        }}
      />
      {/**
             * Add Equipment Modal
             */}
      {/* <AddEquipmentModal
                dates={dates}
                tags={tags}
                visible={modalType === 'add'}
                projectId={projectId}
                organizations={organizations}
                fetchTags={fetchTags}
                setModalType={setModalType}
                setTags={setTags}
                setDates={setDates}
                newUpdateProject={newUpdateProject}
            /> */}
      <UpdateEquipment
        dates={dates}
        tags={tags}
        visible={modalType === 'update'}
        projectId={projectId}
        organizations={organizations}
        fetchTags={() => getMasterTagsData(page, pageSize, searchText, searchedColumn, orderingColumn)}
        setTags={setTags}
        setDates={setDates}
        updateTag={updateTag}
        setModalType={setModalType}
        initialData={record || {}}
      />
      <ViewModal
        key={record?.id}
        visible={modalType === 'view'}
        record={record}
        onOk={() => {
          setRecord(null);
          setModalType(null);
        }}
        onCancel={() => {
          setRecord(null);
          setModalType(null);
        }}
      />
    </>
  );
}

export default ManageEquipments;
