import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Spin,
    Upload,
} from 'antd';
import {
    updateCustomerPurchaseOrder,
    uploadCustomerPurchaseOrderDocuments,
} from '../../../../../../services';
import {
    getCustomerPurchaseOrders,
    getCustomerPurchaseOrdersSummary,
} from '../../../../../../store/billing';
import {
    BASIC_REQUIRED_VALIDATION_RULES,
    CUSTOMER_PO_REASONS,
    INPUT_DATE_FORMAT,
    SERVER_DATE_FORMAT,
} from '../../../../../../../static/utils';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const EditCustomerPurchaseOrder = memo((props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        form.setFieldsValue({
            ...props.data,
            vendor: props.data?.vendor?.name,
            po_date: moment(props.data?.po_date),
        });
    }, []);

    const uploadDocuments = async (customerPoId, values) => {
        try {
            const { invoice_date, invoice_number, documents } = values;
            const formData = new FormData();
            formData.append('invoice_date', invoice_date);
            formData.append('invoice_number', invoice_number);
            documents.fileList.map((file) => formData.append('files', file.originFileObj));
            await uploadCustomerPurchaseOrderDocuments(projectId, customerPoId, formData);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            const { invoice_date, invoice_number, documents } = values;
            values.po_date = values.po_date.format(SERVER_DATE_FORMAT);
            setSubmitting(true);
            await updateCustomerPurchaseOrder(projectId, props.data.id, values);
            if (documents || invoice_date || invoice_number) {
                values.invoice_date = values?.invoice_date?.format(SERVER_DATE_FORMAT);
                await uploadDocuments(props?.data.id, values);
            }
            message.success('Updated Successfully');
            dispatch(getCustomerPurchaseOrders(projectId, { page: 1, per_page: 10 }));
            dispatch(getCustomerPurchaseOrdersSummary(projectId));
            setSubmitting(false);
            form.resetFields();
            props.onCancel();
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <Modal
            width={700}
            okText="Update"
            onOk={form.submit}
            open={props.visible}
            title="Edit Customer Invoice"
            onCancel={props.onCancel}
        >
            <Spin spinning={submitting}>
                <Form layout="vertical" className="mb-20" onFinish={onSubmit} form={form}>
                    <Row gutter={30}>
                        <Col span={12}>
                            <Form.Item
                                label="Date"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                                name="po_date"
                            >
                                <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item
                                label="Reason"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                                name="reason"
                            >
                                <Select placeholder="Please Select">
                                    {Object.keys(CUSTOMER_PO_REASONS).map((key) => (
                                        <Option value={key} key={key}>
                                            {CUSTOMER_PO_REASONS[key]}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item
                                label="Amount"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                                name="amount"
                            >
                                <Input prefix="$" placeholder="0.00" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={24}>
                            <Form.Item
                                label="Comments"
                                name="comments"
                            >
                                <Input placeholder="Enter Comments" />
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <h1 style={{ fontSize: 18 }}>Invoice Details</h1>
                    <Row gutter={30}>
                        <Col span={8}>
                            <Form.Item label="Invoice Date" name="invoice_date">
                                <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Invoice #" name="invoice_number">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Documents" name="documents">
                                <Upload
                                    multiple
                                    beforeUpload={() => false}
                                    // onChange={onSelectDocuments}
                                >
                                    <Button icon={<UploadOutlined />}>Select Files</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
});

EditCustomerPurchaseOrder.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => {},
};

EditCustomerPurchaseOrder.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default EditCustomerPurchaseOrder;
