import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Row, Spin, Upload } from 'antd';
import { validateEmail } from '../../services';
import { OrganizationsAutoComplete } from '../auto-complete';
import {
    BASIC_REQUIRED_VALIDATION_RULES,
    EMAIL_REQUIRED_VALIDATION_RULES,
    ensureEmail,
} from '../../../static/utils';
import './styles.scss';

const CreateUpdateContactForm = (props) => {
    const [imageUrl, setImageUrl] = useState('');
    const [fetching, setFetching] = useState(false);
    const [existingUser, setExistingUser] = useState({});
    const previousEmailRef = useRef('');

    const onUploadProfileImage = (event) => {
        if (event.file) {
            setImageUrl(URL.createObjectURL(event.file));
        } else {
            setImageUrl('');
        }
    };

    useEffect(() => {
        if (props.user) {
            setImageUrl(props?.user?.image || null);
            props.form.setFieldsValue({
                ...props?.user,
                role_template: props?.user?.permission_group?.name,
                organization_name: props?.user?.organization?.name,
                organization_type: props?.user?.organization_type?.name,
            });
        }
    }, []);

    const onSubmit = (values) => {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
            if (values[key]) {
                formData.append(key, values[key]);
            }
        });
        if (values?.image_url?.file) {
            formData.append('image_url', values?.image_url?.file);
        }
        if (!isEmpty(existingUser)) {
            formData.append('id', existingUser.id);
            setExistingUser({});
        }
        props.onSubmit(formData);
    };

    const validateUser = async (ev) => {
        const email = ev.target.value;
        if (ensureEmail(email) && email !== previousEmailRef.current) {
            setFetching(true);
            const user = await validateEmail({ email });
            if (user.data) {
                setImageUrl(user?.data?.image_url);
                props.form.setFieldsValue({
                    ...user.data,
                    organization_name: user?.data?.organization?.name,
                    organization_type: user?.data?.role?.org_type?.id,
                });
                setExistingUser(user.data || {});
            } else {
                const values = props.form.getFieldsValue();
                Object.keys(values).forEach((key) => {
                    if (key !== 'email') {
                        props.form.setFieldsValue({ [key]: undefined });
                    }
                });
                setImageUrl('');
                setExistingUser({});
            }
            setFetching(false);
        }
        previousEmailRef.current = email;
    };

    const isValueEmpty = (key) => {
        return !isEmpty(existingUser[key]);
    };

    return (
        <Spin spinning={props.submitting || fetching}>
            <Form form={props.form} layout="vertical" onFinish={onSubmit}>
                <Row justify="center" gutter={30}>
                    <Col span={8}>
                        <Form.Item label="" name="image_url" className="text-center">
                            <Upload
                                maxCount={1}
                                accept="image/*"
                                showUploadList={false}
                                beforeUpload={() => false}
                                onChange={onUploadProfileImage}
                            >
                                {imageUrl && (
                                    <div className="text-center">
                                        <img
                                            src={imageUrl}
                                            alt="avatar"
                                            width={200}
                                            height={200}
                                            className="contact-profile-image"
                                        />
                                    </div>
                                )}
                                <Button>Upload Profile Image</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col span={24}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={EMAIL_REQUIRED_VALIDATION_RULES}
                        >
                            <Input
                                onBlur={validateUser}
                                placeholder="Email"
                                disabled={!isEmpty(props.user)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                        >
                            <Input placeholder="First Name" disabled={isValueEmpty('first_name')} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            rules={BASIC_REQUIRED_VALIDATION_RULES}
                        >
                            <Input placeholder="Last Name" disabled={isValueEmpty('last_name')} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <OrganizationsAutoComplete
                            name="organization_name"
                            disabled={isValueEmpty('organization')}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Job Title" name="job_title">
                            <Input
                                placeholder="Enter Job Title"
                                disabled={isValueEmpty('job_title')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Phone Number"
                            name="contact_number"
                            placeholder="Eg: +91 xxx xxx xxxx"
                        >
                            <Input
                                maxLength={20}
                                placeholder="Phone Number"
                                disabled={isValueEmpty('contact_number')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Business Contact Number" name="business_phone_number">
                            <Input
                                placeholder="Enter Business Contact number"
                                disabled={isValueEmpty('business_phone_number')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Business Address" name="business_address">
                            <Input.TextArea
                                placeholder="Enter Business Address"
                                disabled={isValueEmpty('business_address')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Address" name="address">
                            <Input.TextArea
                                placeholder="Enter Address"
                                disabled={isValueEmpty('address')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

CreateUpdateContactForm.defaultProps = {
    user: {},
    form: null,
    submitting: false,
    onSubmit: () => {},
    hidePermissionInput: false,
};

CreateUpdateContactForm.propTypes = {
    form: PropTypes.any,
    user: PropTypes.object,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    hidePermissionInput: PropTypes.bool,
};

export default memo(CreateUpdateContactForm);
