import { PROJECT_API_ENUM } from '../../static/utils';
import { http } from '../../static/utils/http';

export const deleteTag = async ({ projectId, slineId, coord_ids }) => {
  try {
    const response = await http.delete(
      `/projects/${projectId}/singlelines/${slineId}/edit-tag-coords/`,
      { coord_ids },
    );
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const addTag = async ({ projectId, slineId, data }) => {
  try {
    const response = await http.post(
      `/projects/${projectId}/singlelines/${slineId}/edit-tag-coords/`,
      data,
    );
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchTagDetails = async (tagId) => {
  try {
    const response = await http.get(`/tags/${tagId}/`);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const updateTag = async (projectId, tagId, reqBody) => {
  try {
    const response = await http.patch(`/projects/${projectId}/tags/${tagId}/`, reqBody);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchTagsByProject = async (data) => {
  try {
    const response = await http.get(`/projects/${data?.projectId}/tags/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchShippingUpdatesByTag = async (data) => {
  try {
    const response = await http.get(
      `/tag/${data?.id}/listshippingupdate/?page=${data?.page}&per_page=${data?.pageSize}`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const updateShippingDetail = async (data) => {
  try {
    const response = await http.patch(`/tag/${data?.tag_id}/tracking-info/`, data);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchTagsByPackage = async ({ id, packageId, page = 1, pageSize = 10 }) => {
  try {
    const response = await http.get(
      `/projects/${id}/package-rooms/${packageId}/paginatedtaglist/?page=${page}&per_page=${pageSize}`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchPaginatedTagsByProject = async (projectId, page = 1, pageSize = 10) => {
  try {
    const response = await http.get(
      `/projects/${projectId}/taglist/paginated?page=${page}&per_page=${pageSize}`,
    );
    return response?.data?.tags;
  } catch (ex) {
    throw ex;
  }
};

export const fetchTagsByProjectV2 = async ({
  id,
  page = 1,
  searchText,
  searchedColumn,
  orderingColumn,
  pageSize = 5,
  tagId,
}) => {
  try {
    const params = {
      page,
      per_page: pageSize,
      id: tagId,
      version: 2,
      ordering: orderingColumn,
      type: PROJECT_API_ENUM.TAGS,
    };
    if (searchedColumn && searchText) {
      params[`${searchedColumn}`] = searchText;
    }
    const response = await http.get(`projects/new/${id}/`, params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchAllTagsV2 = async (projectId, params) => {
  try {
    delete params?.id;
    delete params?.projectId;
    delete params?.pageSize;
    const response = await http.get(`projects/new/${projectId}/`, params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchPackages = async (projectId, full_descriptive = true) => {
  try {
    const response = await http.get(
      `/projects/${projectId}/package-rooms/?full_descriptive=${full_descriptive}`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getInactiveTags = async (projectId, page = 1) => {
  try {
    const response = await http.post(`/projects/${projectId}/taglist/`, {
      is_active: false,
    });
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const updateInActiveTags = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/tags/activestatus`, {
      ...body,
    });

    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createPackage = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/package-rooms/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createMultiplePackages = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/multiple/package-rooms/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchTagStatistics = async (projectId, type) => {
  try {
    const response = await http.get(`/projects/${projectId}/tags-statistics/?type=${type}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchDeadlines = async (projectId) => {
  try {
    const response = await http.get(`/projects/${projectId}/deadlines/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchTagStatisticsDetails = async (projectId, type) => {
  try {
    const response = await http.get(`/projects/${projectId}/tags-statistics/${type}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchTagCompletion = async (projectId) => {
  try {
    const response = await http.get(`/projects/${projectId}/tags-completion/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchEquipmentProgressStatistics = async (projectId) => {
  try {
    const response = await http.get(`/projects/${projectId}/project-statistics/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const GanttChartCreateImage = async (projectId) => {
  try {
    const response = await http.post(`/projects/${projectId}/gantt-chart-statistics/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const GanttChartGetImage = async (projectId) => {
  try {
    const response = await http.get(`/projects/${projectId}/gantt-chart-statistics/`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const releaseTagsMarkup = async (data) => {
  try {
    const response = await http.post('/projects/approval/upload/markup/', data);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const releasePackagesMarkup = async (data) => {
  try {
    const response = await http.post('/projects/approval/upload/markup/', data);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const getMarkupTags = async (data) => {
  try {
    const response = await http.get(
      `/projects/approval/upload/markup/?isPackage=${data.isPackage}&tagId=${data.tagId}`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createTag = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/tags/`, body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteTags = async (projectId, body) => {
  try {
    const response = await http.delete(`/projects/${projectId}/tags/`, body);
    return response?.data;
  } catch (ex) {
    throw (ex);
    return ex.response?.data;
  }
};

/**
 * -----------------
 * Gantt Chart APIs
 * -----------------
 *
 */

export const getGanttChartAllTags = async (projectId, body) => {
  try {
    const response = await http.post(`/gantt/${projectId}/tags`, body);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const getGanttCritical = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/gantt-charts/`, body);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const getFullGanttSeries = async (projectId, body) => {
  try {
    const response = await http.post(`/gantt/${projectId}/tagsfullgantt`, body);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const getGanttChartTypeData = async (projectId, body = {}, type) => {
  try {
    const response = await http.get(
      `/projects/${projectId}/gantt-charts/?type=${type.toLowerCase()}`,
      body,
    );
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const postFollowTagsGantt = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/gantt-charts/`, body);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const deleteFollowTagsGantt = async (projectId, body) => {
  try {
    const response = await http.delete(`/projects/${projectId}/gantt-charts/`, body);
    return response?.data;
  } catch (ex) {
    throw (ex);
  }
};

export const fetchFilteredTags = async (projectId, body) => {
  try {
    const response = await http.post(`/projects/${projectId}/tags/list/`, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const duplicateTags = async (projectId, tags) => {
  try {
    const response = await http.post(`/projects/${projectId}/tags/duplicate/`, { tags });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchManualSchedules = async (projectId, params = {}) => {
  try {
    const response = await http.get(`/projects/${projectId}/taglist/?is_manual_tracking=1`, params);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
