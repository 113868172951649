import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Popconfirm, Space, Tooltip } from 'antd';
import {
    DeleteTwoTone,
    EditOutlined,
    FileSearchOutlined,
    FileTextOutlined,
} from '@ant-design/icons';
import { CUSTOMER_PO_REASONS, DISPLAY_DATE_FORMAT, renderAmount } from '../../../../../../../../static/utils';
import { removeCustomerPurchaseOrders } from '../../../../../../../store/billing';

export default (onActionClick = () => {}, showActions = true) => {
    const dispatch = useDispatch();

    return React.useMemo(() => [
        {
            key: 'po_date',
            title: 'Date',
            dataIndex: 'po_date',
            render: (value) => moment(value).format(DISPLAY_DATE_FORMAT),
        },
        // {
        //     key: 'reason',
        //     title: 'Reason',
        //     dataIndex: 'reason',
        //     render: (value) => CUSTOMER_PO_REASONS[value] || '-'
        // },
        {
            key: 'amount',
            title: 'Amount',
            dataIndex: 'amount',
            render: renderAmount,
        },
        {
            key: 'cumm_billed_so_far',
            title: 'Billed so far',
            dataIndex: 'cumm_billed_so_far',
            render: renderAmount,
        },
        // {
        //     key: 'cumm_cost_to_date',
        //     title: 'Cost to date',
        //     dataIndex: 'cumm_cost_to_date',
        //     render: (value) => `$${value || '0.00'}`
        // },
        {
            key: 'remaining_to_be_billed',
            title: 'Remaining to bill',
            dataIndex: 'remaining_to_be_billed',
            render: renderAmount,
        },
        {
            key: 'files_count',
            title: 'Attachment(s)',
            dataIndex: 'files_count',
            render: (value) => (
                <Space>
                    <FileTextOutlined />
                    {value}
                </Space>
            ),
        },
        ...[
            showActions
                ? {
                      key: 'action',
                      title: 'Actions',
                      dataIndex: 'action',
                      render: (_, record) => (
                          <Space>
                              <Tooltip title="Details">
                                  <FileSearchOutlined
                                      style={{ fontSize: 17 }}
                                      onClick={() => onActionClick('view', record)}
                                  />
                              </Tooltip>
                              <Tooltip title="Edit">
                                  <EditOutlined
                                      style={{ fontSize: 17 }}
                                      onClick={() => onActionClick('edit', record)}
                                  />
                              </Tooltip>
                              <Popconfirm
                                  title="Are you sure?"
                                  onConfirm={(ev) => {
                                      ev.stopPropagation();
                                      dispatch(
                                          removeCustomerPurchaseOrders(record.master_project, [
                                              record.id,
                                          ]),
                                      );
                                  }}
                              >
                                  <Tooltip title="Delete">
                                      <DeleteTwoTone
                                          style={{ fontSize: 17 }}
                                          twoToneColor="#f56666"
                                      />
                                  </Tooltip>
                              </Popconfirm>
                          </Space>
                      ),
                  }
                : {},
        ],
    ]);
};
