import _ from 'lodash';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Collapse, notification, Spin, Upload,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AlectifyTable, UploadDocumentModal, UploadNote } from '../../../components';
import { DocView } from '../../../components/documents/docView';
import { downloadZip, getSignedUrl, shareFiles } from '../../../services';
import {
  getEquipDocuments,
  getProjectDocumentsV2,
  getReviewDocuments,
} from '../../../store/documents';
import { DEFAULT_PAGE_SIZE, DEFAULT_START_PAGE } from '../../../../static/utils';
import { packageColumns } from './columns/packages_columns';
import { fileColumns } from './columns/projects_columns';
import { reviewColumns } from './columns/review_columns';

const { Panel } = Collapse;

function ProjectDocsAccordian({
  isLoading, uploadDocs, deleteDocument, projectId,
}) {
  const {
    documents,
    project_documents,
    equipment_documents,
    review_documents,
    project_documents_loading,
    equipment_documents_loading,
    review_documents_loading,
  } = useSelector(({ documents }) => documents);
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [index, setIndex] = useState(null);
  const [docData, setDocData] = useState({});
  const [documentsToUpload, setDocumentsToUpload] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);

  const getAllDocuments = async () => {
    const params = {
      id: projectId,
      page: DEFAULT_START_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    };
    await Promise.all([
      dispatch(getProjectDocumentsV2(params)),
      dispatch(getEquipDocuments(params)),
      dispatch(getReviewDocuments(params)),
    ]);
  };

  useEffect(() => {
    getAllDocuments();
  }, []);

  const onUploadHandler = async (files, key) => {
    await uploadDocs(Object.values(files), key);
  };

  const goBack = () => {
    setShow(true);
  };

  const deleteDocumentHandler = (documentId) => {
    deleteDocument(documentId);
    const updatedDocs = docData.dir_files.filter(({ id }) => id !== documentId);
    docData.dir_files = [...updatedDocs];
    setDocData({ ...docData });
  };

  const downloadDocument = async ({ file_path, file_name }) => {
    const response = await getSignedUrl(file_path);
    window.open(response?.data);
  };

  const onRowSelectionAction = async (data) => {
    //    setRowSelections();
  };

  const downloadDocumentsInZip = async (s3Keys, type) => {
    const response = await downloadZip({
      projectId,
      payload: {
        docs_group: `${type}-docs`,
        s3_keys: s3Keys,
      },
    });

    if (response?.success) {
      notification.success({
        title: 'Received Download Zip Request',
        message: 'Zip link will be send to you mail id',
      });
    }
  };

  const onDownloadSubmitalsZip = async (data, type) => {
    const s3Keys = data.reduce((accum, item) => {
      accum.push(item?.approval_file_path);
      if (item?.markup_file_path) accum.push(item?.markup_file_path);
      return accum;
    }, []);
    await downloadDocumentsInZip(s3Keys, type);
  };

  const onDownloadZip = async (data, type) => {
    const s3_keys = data.map((a) => a.file_path);
    await downloadDocumentsInZip(s3_keys, type);
  };

  const onEmailShare = async (emails, data, type) => {
    const file_ids = data.map((a) => a?.id);
    const response = await shareFiles({
      projectId,
      payload: {
        to_users: emails,
        document_type: `${type}`,
        file_ids,
      },
    });

    if (response?.success) {
      notification.success({
        title: 'Received Download Zip Request',
        message: 'Shared files',
      });
    }
  };

  const onUploadDocuments = (ev) => {
    setDocumentsToUpload([...documentsToUpload, ...ev.fileList]);
    setUploadModal(true);
  };

    return (
        <Spin spinning={isLoading} tip={isLoading ? 'Please wait, it will take a while' : ''}>
            {show ? (
                <>
                    <div className="text-right">
                        <Upload
                            beforeUpload={() => false}
                            onChange={_.debounce(onUploadDocuments, 100)}
                            showUploadList={false}
                            multiple
                            fileList={[]}
                        >
                            <Button type="default" shape="round" className="main-upload-btn">
                                <span
                                    style={{
                                        margin: '0 8px 0 0',
                                    }}
                                >
                                    <PlusOutlined /> Add Documents
                                </span>
                                <UploadNote />
                            </Button>
                        </Upload>
                    </div>
                    <Collapse
                        className="alectify-collapse mb-15"
                        defaultActiveKey={['project-files']}
                        onChange={() => {}}
                    >
                        <Panel
                            header={`Project Files (${
                                project_documents?.meta?.total_count
                                    ? project_documents?.meta?.total_count
                                    : 0
                            })`}
                            key="project-files"
                        >
                            <AlectifyTable
                                size="small"
                                onDataFetch={(data) => dispatch(getProjectDocumentsV2(data))}
                                columns={fileColumns({
                                    downloadDocument,
                                })}
                                isDownloadZip={true}
                                isEmailSharing={true}
                                onDownloadZip={(data) => onDownloadZip(data, 'project')}
                                onEmailShare={(emails, data) =>
                                    onEmailShare(emails, data, 'project')
                                }
                                id={projectId}
                                total={project_documents?.meta?.total_count}
                                dataSource={project_documents?.data}
                                loading={project_documents_loading}
                                onRowSelectionAction={(data) =>
                                    onRowSelectionAction(data, 'project')
                                }
                                key={`${projectId}-project-files`}
                            />
                        </Panel>
                        <Panel
                            header={`Equipment Files (${
                                equipment_documents?.meta?.total_count
                                    ? equipment_documents?.meta?.total_count
                                    : 0
                            })`}
                            key="equipment-fies"
                        >
                            <AlectifyTable
                                size="small"
                                onDataFetch={(data) => dispatch(getEquipDocuments(data))}
                                columns={packageColumns({
                                    downloadDocument,
                                })}
                                isDownloadZip={true}
                                isEmailSharing={true}
                                onDownloadZip={(data) => onDownloadZip(data, 'equipment')}
                                onEmailShare={(emails, data) =>
                                    onEmailShare(emails, data, 'equipment')
                                }
                                id={projectId}
                                dataSource={equipment_documents?.data}
                                loading={equipment_documents_loading}
                                rowSelection={false}
                                onRowSelectionAction={(data) =>
                                    onRowSelectionAction(data, 'equipment')
                                }
                                total={equipment_documents?.meta?.total_count}
                                key={`${projectId}-eqp-files`}
                            />
                        </Panel>
                        {/* <Panel
                            header={`Submittal Files (${
                                review_documents?.meta?.total_count
                                    ? review_documents?.meta?.total_count
                                    : 0
                            })`}
                            key="submittals"
                        >
                            <AlectifyTable
                                size="small"
                                onDataFetch={(data) => dispatch(getReviewDocuments(data))}
                                columns={reviewColumns({
                                    downloadDocument,
                                })}
                                isDownloadZip={true}
                                isEmailSharing={true}
                                onDownloadZip={(data) => onDownloadSubmitalsZip(data, 'review')}
                                onEmailShare={(emails, data) =>
                                    onEmailShare(emails, data, 'review')
                                }
                                id={projectId}
                                dataSource={review_documents?.data}
                                loading={review_documents_loading}
                                rowSelection={false}
                                onRowSelectionAction={(data) =>
                                    onRowSelectionAction(data, 'review')
                                }
                                total={review_documents?.meta?.total_count}
                                key={`${projectId}-review-files`}
                            />
                        </Panel> */}
                    </Collapse>
                </>
            ) : (
                <DocView
                    goBack={goBack}
                    docFiles={documents[index]}
                    uploadHandler={onUploadHandler}
                    deleteDocument={deleteDocumentHandler}
                    hideUploadBtn
                />
            )}
            <UploadDocumentModal
                visible={uploadModal}
                projectId={projectId}
                onCancel={() => setUploadModal(false)}
                documentsToUpload={documentsToUpload}
                getAllDocuments={getAllDocuments}
                setDocumentsToUpload={setDocumentsToUpload}
            />
        </Spin>
    );
}

export default ProjectDocsAccordian;
