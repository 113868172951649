import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Spin,
    Upload,
} from 'antd';
import {
    ASSOCIATE_VENDOR_ITEMS_REASONS,
    BASIC_REQUIRED_VALIDATION_RULES,
    calculateAvgMargin,
    INPUT_DATE_FORMAT,
    SERVER_DATE_FORMAT,
} from '../../../../../../../../static/utils';
import {
    updateVendorAssociates,
    uploadVendorAssociatesDocuments,
} from '../../../../../../../services';
import { getFinancialVendorItemDetail, getFinancialVendorItems, getProjectFinancialSummary, getVendorAssociates } from '../../../../../../../store/financials';
import { UploadOutlined } from '@ant-design/icons';

const UpdateVendorAssociate = memo((props) => {
    const [form] = Form.useForm();
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        form.setFieldsValue({
            ...props.data,
            reason: props.data?.reason === "original" ? "Original" : props.data?.reason,
            entry_date: moment(props.data?.entry_date),
        });
        onCostSellChange(props.data?.cost, props.data?.sell);
    }, []);

    const uploadDocuments = async (vendorAssociateId, values) => {
        try {
            const { documents } = values;
            const formData = new FormData();
            documents.fileList.map((file) => formData.append('files', file.originFileObj));
            await uploadVendorAssociatesDocuments(
                projectId,
                props.data?.id,
                vendorAssociateId,
                formData,
            );
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            delete values.reason;
            setSubmitting(true);
            const dto = {
                ...values,
                entry_date: values.entry_date.format(SERVER_DATE_FORMAT),
            };
            await updateVendorAssociates(projectId, props.data?.vendorItemId, props.data.id, dto);
            if (values?.documents) {
                await uploadDocuments(props.data?.id, values);
            }
            setSubmitting(false);
            message.success('Updated Successfully');
            form.resetFields();
            props.onCancel();
            dispatch(
                getVendorAssociates(projectId, props.data?.vendorItemId, { page: 1, per_page: 5 }),
            );
            dispatch(getFinancialVendorItems(projectId, { page: 1, per_page: 10 }));
            dispatch(getProjectFinancialSummary(projectId));
            dispatch(getFinancialVendorItemDetail(projectId, props.data?.vendorItemId));
        } catch (error) {
            setSubmitting(false);
        }
    };

    const onCostSellChange = (_cost, _sell) => {
        const { cost, sell } = form.getFieldsValue();
        const margin = calculateAvgMargin(cost, sell);
        form.setFieldValue('margin_percent', margin);
    };

    return (
        <Modal
            centered
            width={1000}
            okText="Update"
            onOk={form.submit}
            open={props.visible}
            title="Update Price Summary"
            onCancel={props.onCancel}
            confirmLoading={submitting}
        >
            <Spin spinning={submitting}>
                <Form layout="vertical" form={form} onFinish={onSubmit}>
                    <Row gutter={30}>
                        <Col span={6}>
                            <Form.Item
                                label="Date"
                                name="entry_date"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <DatePicker className="full-width" format={INPUT_DATE_FORMAT} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Reason"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                                name="reason"
                            >
                                <Select placeholder="Please Select" disabled>
                                    {Object.keys(ASSOCIATE_VENDOR_ITEMS_REASONS).map((key) => (
                                        <Select.Option value={key} key={key}>
                                            {ASSOCIATE_VENDOR_ITEMS_REASONS[key]}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Quote #" name="quote_no">
                                <Input />
                            </Form.Item>
                        </Col>
                        {/* <Col span={6}>
                            <Form.Item name="comments" label="Comments">
                                <Input />
                            </Form.Item>
                        </Col> */}
                        <Col span={6}>
                            <Form.Item label="Documents" name="documents">
                                <Upload multiple beforeUpload={() => false}>
                                    <Button icon={<UploadOutlined />}>Select Files</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Total Sell"
                                name="sell"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <InputNumber
                                    prefix="$"
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    className="full-width"
                                    onChange={onCostSellChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Total Cost"
                                name="cost"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <InputNumber
                                    prefix="$"
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    className="full-width"
                                    onChange={onCostSellChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="margin_percent"
                                label="Avg. Margin"
                                rules={BASIC_REQUIRED_VALIDATION_RULES}
                            >
                                <Input
                                    suffix="%"
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    className="full-width"
                                    readOnly
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
});

UpdateVendorAssociate.defaultProps = {
    data: null,
    visible: false,
    onCancel: () => {},
};

UpdateVendorAssociate.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default UpdateVendorAssociate;
