import moment from 'moment';
import _, { isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import {
    AutoComplete,
    Card,
    Col,
    Form,
    Input,
    Modal,
    notification,
    Row,
    Space,
    Spin,
    Switch,
    Tooltip,
} from 'antd';
import { getOrganizations } from '../../../../services';
import { UpdateEquipmentDates } from '../../../../components';
import { DATES_KEYS, INPUT_DATE_FORMAT, SERVER_DATE_FORMAT } from '../../../../../static/utils';

const NOT_REQUIRED_EMPOWER_FIELDS = ['reference_number', 'line_number', 'catalog_number', 'marks'];

export function UpdateEquipment({
    dates,
    tags,
    projectId,
    visible = false,
    fetchTags,
    setDates,
    setTags,
    setModalType,
    updateTag,
    initialData = {},
}) {
    const [form] = Form.useForm();
    const [addApproval, setAddApproval] = useState(false);
    const [isManualTracking, setIsManualTracking] = useState(false);

    const [organizations, setOrganizations] = useState({
        data: [],
        value: '',
        fetching: false,
    });

    const handleAddApprovalChange = (val) => {
        setAddApproval(val);
        form.setFieldValue('approval_date', null);
        form.setFieldValue('release_date', null);
    };

    useEffect(async () => {
        // console.log("initialData: ", initialData);
        if (Object.keys(initialData).length) {
            // Get team members
            try {
                DATES_KEYS.forEach((key) => {
                    if (!_.isEmpty(initialData[key])) {
                        initialData[key] = moment(initialData[key], SERVER_DATE_FORMAT);
                    }
                });

                if (initialData?.manufacture?.name) {
                    initialData.manufacture = initialData?.manufacture?.name;
                }

                if (initialData?.approval_dwg) {
                    setAddApproval(initialData?.approval_dwg);
                }
                setIsManualTracking(initialData?.is_manual_tracking);
                // Fields modified
                form.setFieldsValue(initialData);
            } catch (e) {}
        }
    }, [form, initialData]);

    const updateTagHandler = async (values) => {
        try {
            if (initialData?.is_system_generated) {
                NOT_REQUIRED_EMPOWER_FIELDS.forEach((key) => {
                    delete values[key];
                });
            }
            Object.keys(values).forEach((key) => {
                if (DATES_KEYS.includes(key)) {
                    values[key] = values[key]
                        ? moment(values[key], INPUT_DATE_FORMAT).format(SERVER_DATE_FORMAT)
                        : null;
                }
            });
            values.id = initialData?.id;
            values.approval_dwg = isManualTracking;
            values.is_manual_tracking = isManualTracking;
            if (values?.lead_time_weeks) {
                values.lead_time_weeks = parseInt(values?.lead_time_weeks);
            }
            const response = await updateTag(projectId, initialData?.id, values);
            values.id = response?.data?.id;
            setTags([values, ...tags]);
            notification.success({
                message: `${values?.name} added`,
                description: 'The tag has been updated successfully',
            });
            form.resetFields();
            setModalType(null);
            fetchTags();
        } catch (error) {
            notification.error({
                message: `${values?.name} cannot be added`,
                description: 'Please check if the Tag is not a duplicate one.',
            });
        }
    };

    const onSearchOrganization = _.debounce(async (value) => {
        try {
            setOrganizations({ ...organizations, fetching: true });
            const response = await getOrganizations({ search: value });
            setOrganizations({
                ...organizations,
                fetching: false,
                data: response?.data,
                value,
            });
        } catch (error) {
            setOrganizations({ ...organizations, fetching: false, data: [] });
        }
    }, 500);

    const isSystemGenerated = initialData?.is_system_generated;

    return (
        <Modal
            title="Update Equipment"
            visible={visible}
            onOk={() => {
                form.validateFields().then((values) => {
                    form.submit();
                });
            }}
            key={initialData?.id}
            okText="Update Tag"
            width={1100}
            style={{ top: 10 }}
            onCancel={() => setModalType(null)}
        >
            <Form form={form} layout="vertical" onFinish={updateTagHandler}>
                <Card
                    title="Main Information"
                    className="mb-20 grey-title"
                    extra={(
                        <Tooltip
                            title={
                                !isSystemGenerated
                                    ? 'Only works for system generated equipments'
                                    : ''
                            }
                        >
                            <Space>
                                <label>Manual Schedule:</label>
                                <Switch
                                    checked={isManualTracking}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={setIsManualTracking}
                                    disabled={!isSystemGenerated}
                                />
                            </Space>
                        </Tooltip>
                    )}
                >
                    <Row gutter={15}>
                        <Col span={6}>
                            <Form.Item name="reference_number" label="Ref #">
                                <Input
                                    placeholder="Enter Reference Number"
                                    disabled={isSystemGenerated}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="line_number" label="Line #">
                                <Input placeholder="Enter Line Number" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="name"
                                label="Tag Name"
                                rules={[{ required: true, message: 'Tag name is required' }]}
                            >
                                <Input placeholder="Enter Tag Name" disabled={isSystemGenerated} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="manufacture"
                                label="Manufacture Name"
                                rules={[
                                    { required: true, message: 'Manufacture name is required' },
                                ]}
                            >
                                <AutoComplete
                                    showSearch
                                    placeholder="Search or Select a Manufacturer"
                                    options={_.map(organizations.data, (org) => ({
                                        name: org?.name,
                                        value: org?.name,
                                    }))}
                                    onSearch={onSearchOrganization}
                                    notFoundContent={
                                        organizations.fetching ? (
                                            <div className="text-center">
                                                <Spin />
                                            </div>
                                        ) : organizations.value &&
                                          organizations.data.length === 0 ? (
                                            'Not Found'
                                        ) : null
                                    }
                                    disabled={isSystemGenerated}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="mb-0"
                                name="description"
                                label="Description"
                                rules={[{ required: false }]}
                            >
                                <Input.TextArea disabled={isSystemGenerated} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title="Edit Dates" className="mb-20 grey-title">
                    <UpdateEquipmentDates hideComments />
                </Card>
                <Card title="Other Details" className="grey-title">
                    <div className="custom-row no-bordered">
                        <div className="col">
                            <Form.Item name="quote_number" label="Quote #">
                                <Input
                                    placeholder="Enter Quote Number"
                                    disabled={isSystemGenerated}
                                />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item name="marks" label="Marks">
                                <Input placeholder="Enter Marks" disabled={isSystemGenerated} />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item name="catalog_number" label="Catalog #">
                                <Input
                                    placeholder="Enter Catalog Number"
                                    disabled={isSystemGenerated}
                                />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item
                                name="price"
                                label="Price ($)"
                                rules={[
                                    {
                                        message: 'Price should be a number',
                                        validator: (_, value) => {
                                            if (Number.isInteger(Number(value)) || isEmpty(value)) {
                                                return Promise.resolve();
                                            }
                                            if (value.toString().split('.')[1].length <= 2) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject();
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    prefix="$"
                                    placeholder="0.00"
                                    disabled={isSystemGenerated}
                                />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <Form.Item
                                name="quantity"
                                label="Quantity"
                                rules={[
                                    {
                                        message: 'Quantity should be a number',
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.resolve();
                                            }
                                            if (
                                                (Number.isInteger(Number(value)) &&
                                                    value.toString().split('.').length === 1) ||
                                                isEmpty(value)
                                            ) {
                                                return Promise.resolve();
                                            }
                                            if (value.toString().split('.')[1].length > 0) {
                                                return Promise.reject();
                                            }
                                            return Promise.reject();
                                        },
                                    },
                                ]}
                            >
                                <Input placeholder="Enter Quantity" disabled={isSystemGenerated} />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item name="comments" label="Comments" className="mb-0">
                        <Input.TextArea disabled={isSystemGenerated} />
                    </Form.Item>
                </Card>
            </Form>
        </Modal>
    );
}

export default memo(UpdateEquipment);
