import { Card, Col, Row, Spin, message } from 'antd';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AlectifyTable } from '../../../../../../../components';
import { removeVendorEstimations } from '../../../../../../../store/financials';
import useColumns from './effects/useColumns';
import UpdateVendorItem from './UpdateVendorItem';
import CreateVendorItem from './CreateVendorItem';
import { renderAmount } from '../../../../../../../../static/utils';
import { deleteBiddingVendorEstimations } from '../../../../../../../services/bidding-management';
import {
    getBiddingFinancialVendorItems,
    getBiddingProjectFinancialSummary,
} from '../../../../../../../store/bidding-management';
import VendorAssociateDetail from './adder-deduct/VendorAssociateDetail';
import './index.scss';

const gridStyle = {
    width: '33.3333%',
    textAlign: 'center',
};

const VendorItemList = () => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { vendors, summarySheet } = useSelector(({ bidding }) => bidding.financials);
    const [editVendor, setEditVendor] = useState({ data: null, visible: false });
    const [action, setAction] = useState({ data: null, visible: false, variant: null });

    useEffect(() => {
        dispatch(getBiddingProjectFinancialSummary(projectId));
        getVendorslist();
    }, [projectId]);

    const onActionClick = async (action, vendorItem) => {
        if (action === 'edit') {
            setEditVendor({ data: vendorItem, visible: true });
        } else if (action === 'delete') {
            await deleteBiddingVendorEstimations(projectId, vendorItem.id);
            message.success('Deleted Successfully');
            dispatch(getBiddingProjectFinancialSummary(projectId));
            getVendorslist();
        } else {
            setAction({ data: vendorItem, visible: true, variant: action });
        }
    };

    const columns = useColumns(onActionClick);

    const getVendorslist = (pagination) => {
        const params = {
            page: pagination?.page || 1,
            per_page: pagination?.per_page || 10,
        };
        dispatch(getBiddingFinancialVendorItems(projectId, params));
    };

    return (
        <Card title="Project Summary Tally Sheet" className="mb-30">
            <Card.Grid className="full-width" hoverable={false}>
                <Spin spinning={summarySheet.fetching}>
                    <Row gutter={30}>
                        <Col span={8}>
                            <div className="page-content-container dashboard-card colorful-card bg-light-purple mb-0">
                                <h4 className="card-heading custom-card-heading">All Quotes (Sell)</h4>
                                <h1 className="dashboard-card-text">
                                    {renderAmount(summarySheet?.data?.total_sell)}
                                </h1>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="page-content-container dashboard-card colorful-card bg-light-green mb-0">
                                <h4 className="card-heading custom-card-heading">All Quotes (Cost)</h4>
                                <h1 className="dashboard-card-text">
                                    {renderAmount(summarySheet?.data?.total_cost)}
                                </h1>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="page-content-container dashboard-card colorful-card bg-light-yellow mb-0">
                                <h4 className="card-heading custom-card-heading">Number of Quotes</h4>
                                <h1 className="dashboard-card-text">
                                    {summarySheet?.data?.total_count || '0'}
                                </h1>
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </Card.Grid>
            <Card.Grid className="full-width" hoverable={false}>
                <CreateVendorItem />
            </Card.Grid>
            <Card.Grid className="full-width" hoverable={false}>
                <AlectifyTable
                    selectRows={false}
                    columns={columns}
                    defaultPageSize={10}
                    loading={vendors.fetching}
                    multipleDeleteOption={false} // disabled multiple delete option
                    onDataFetch={getVendorslist}
                    dataSource={vendors.data || []}
                    total={vendors?.meta?.total_count}
                    onMultipleDelete={(ids) => dispatch(removeVendorEstimations(projectId, ids))}
                />
            </Card.Grid>
            {editVendor.visible && (
                <UpdateVendorItem
                    {...editVendor}
                    onCancel={() => setEditVendor({ data: null, visible: false })}
                />
            )}
            {action.visible && (
                <VendorAssociateDetail
                    {...action}
                    onCancel={() => setAction({ data: null, visible: false, variant: null })}
                />
            )}
        </Card>
    );
};

export default VendorItemList;
