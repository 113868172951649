import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  Avatar,
  Tooltip,
  Card,
  Input,
  Row,
  Col,
  Steps,
  Table,
  Modal,
  Button,
  Timeline,
  Spin,
  DatePicker,
  Space,
  notification,
  Form,
} from 'antd';
import { ClockCircleOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import {
  makeAvatar,
  DISPLAY_DATETIME_FORMAT,
  SERVER_DATE_FORMAT,
  DATE_FORMAT,
} from '../../../../../static/utils';
import './status.scss';
import { useParams } from 'react-router-dom';
import { fetchConstructionDrawings, getSignedUrl, patchTagDates } from '../../../../services';

export function Status({
  detail, getPackageDetail, uploadEquipmentDocs, isLoading,
}) {
  const { Step } = Steps;
  const [isShow, setShow] = useState(false);
  const [constructionData, setConstructionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pkgId, projectId } = useParams();

  useEffect(() => {
    getConstructionDrawings();
  }, [isLoading]);

  const updateShipmentDate = async (values, tag) => {
    const res = await patchTagDates(detail.project, tag.id, {
      scheduled_ship_date: moment(
        values?.scheduled_ship_date ? values?.scheduled_ship_date : tag.ship_date,
      ).format(SERVER_DATE_FORMAT),
    });

    if (res.success) {
      getPackageDetail();
      notification.success({
        message: `Test date updated for ${tag.name}`,
      });
    } else {
      notification.error({
        message: `Error updating test date for ${tag.name}`,
      });
    }
  };

  const getConstructionDrawings = async () => {
    try {
      setLoading(true);
      const response = await fetchConstructionDrawings(projectId, pkgId);
      setConstructionData(response?.data.dir_files);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleImageUploadDocs = async (fileList, key) => {
    if (!isEmpty(fileList, key)) {
      await uploadEquipmentDocs(fileList, key);
    }
  };

  const handleView = async (file_path) => {
    window.open(file_path, '_blank');
  };

  const handleDownload = async (filePath) => {
    if (filePath) {
      const url = await getSignedUrl(filePath);
      await axios.get(url.data, { headers: { 'Content-Disposition': 'attachment' } });
      saveAs(url.data, filePath);
    }
  };

  const disabledDate = (current, end) => {
    if (end) {
      return current && current < end;
    }
  };

  const columnData = [
    {
      title: 'Filename',
      dataIndex: 'file_title',
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
    },
    {
      title: 'Submitted by',
      dataIndex: 'account',
      render: (value) => (
        // <Tooltip title={`${value?.first_name} ${value?.last_name} `}>
        <Avatar
          className="circle-icon"
          src={value?.image || makeAvatar(value?.first_name[0], value?.last_name[0])}
        />
        // </Tooltip>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (value, values) => (
        <Space>
          <Tooltip title={`View ${values.file_name}`}>
            <EyeOutlined
              className="icon-inner-delete"
              onClick={() => handleView(values.file_url)}
            />
          </Tooltip>
          <Tooltip title={`Download ${values.file_name}`}>
            <DownloadOutlined
              className="icon-inner-delete"
              onClick={() => handleDownload(values.file_path)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={isLoading} tip={isLoading ? 'Please wait, it will take a while' : ''}>
      {/* <Card className="lightColor mb-20">
                <Row gutter={[48]}>
                    <Col className="lightColor" span={24}>
                        <h1 className="mb-20">Revised Shipment Date from Manufacturer</h1>
                    </Col>
                    <Col className="lightColor" span={24}>
                        <Row>
                            {detail?.tags.map((tag) => (
                                <Col span={9}>
                                    <Form
                                        className="mb-20"
                                        onFinish={(val) => updateShipmentDate(val, tag)}
                                        key={tag.id}
                                        name={`tag-date-${tag.name}-form`}
                                    >
                                        <p>
                                            Select date for{' '}
                                            <strong
                                                style={{
                                                    color: '#aaa',
                                                }}
                                            >
                                                {tag.name}
                                            </strong>
                                        </p>
                                        <Space>
                                            <Form.Item
                                                className="mb-0"
                                                name={`scheduled_ship_date`}
                                            >
                                                <DatePicker
                                                    placeholder="Select Ship date"
                                                    disabledDate={(current) => {
                                                        return disabledDate(
                                                            current,
                                                            moment(tag.test_date),
                                                        );
                                                    }}
                                                    defaultValue={
                                                        tag.ship_date ? moment(tag.ship_date) : null
                                                    }
                                                />
                                            </Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Update
                                            </Button>
                                        </Space>
                                    </Form>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Card> */}

      <Card
        className="card-body mb-20"
        title="Construction Drawings"
      >
        <Modal
          title="Timeline"
          visible={isShow}
          onOk={() => setShow(false)}
          onCancel={() => setShow(false)}
        >
          <Timeline>
            <Timeline.Item>Release date 2015-09-01</Timeline.Item>
            <Timeline.Item dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
              Current date 2015-09-01
            </Timeline.Item>
            <Timeline.Item>Submission date 2015-09-01</Timeline.Item>
          </Timeline>
        </Modal>
        <div className="file_upload_wrapper">
          <Input
            className="file_upload_field"
            type="file"
            multiple
            onChange={(e) => handleImageUploadDocs(
              Object.values(e.target.files),
              'construction_drawings',
            )}
          />
        </div>
        <div className="construction-body">
          <Table
            columns={columnData}
            dataSource={constructionData}
            pagination={constructionData.length > 10}
            loading={loading}
            rowKey="id"
          />
        </div>
      </Card>
    </Spin>
  );
}

export default Status;
