import { memo, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { AlectifyTable } from '../table';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_START_PAGE,
  EQUIPMENT_DOCUMENT_TYPES,
  PROJECT_API_ENUM,
} from '../../../static/utils';
import { fetchAllPackagesv2, fetchAllTagsV2 } from '../../services';
import useEquipmentsColumns from './effects/useEquipmentsColumns';
import usePackagesColumns from './effects/usePackagesColumns';

export const SearchListModal = memo(
  ({
    title = '',
    projectId,
    onRow = () => {},
    onCancel = () => {},
    visible = false,
    loading = false,
    equipmentType = null,
  }) => {
    const packagesColumns = usePackagesColumns();
    const equipmentColumns = useEquipmentsColumns();
    const [apiResponse, setApiResponse] = useState({ fetching: false, data: [], meta: {} });

    const getAllPackages = async (_params = {}) => {
      try {
        setApiResponse({ ...apiResponse, fetching: true });
        const params = {
          type: 'dropdown',
          page: DEFAULT_START_PAGE,
          per_page: DEFAULT_PAGE_SIZE,
          ..._params,
        };
        delete params?.projectId;
        const response = await fetchAllPackagesv2(projectId, params);
        setApiResponse({ ...apiResponse, ...response });
      } catch (error) {
        console.log(error);
      }
    };

    const getAllTags = async (_params = {}) => {
      try {
        setApiResponse({ ...apiResponse, fetching: true });
        const params = {
          version: 3,
          page: DEFAULT_START_PAGE,
          type: PROJECT_API_ENUM.TAGS,
          per_page: DEFAULT_PAGE_SIZE,
          ..._params,
        };
        const response = await fetchAllTagsV2(projectId, params);
        setApiResponse({ ...apiResponse, ...response });
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getTableData();
    }, [equipmentType]);

    const getTableData = async (params = {}) => {
      if (equipmentType === EQUIPMENT_DOCUMENT_TYPES.PACKAGE) {
        await getAllPackages(params);
      } else {
        await getAllTags(params);
      }
    };

    const getTableColumns = () => (equipmentType === EQUIPMENT_DOCUMENT_TYPES.EQUIPMENT
      ? equipmentColumns
      : packagesColumns);

    return (
      <Modal
        title={`${title} List`}
        visible={visible}
        onCancel={onCancel}
        onOk={onCancel}
        confirmLoading={loading}
        okText="Close"
        width={1000}
        footer={null}
      >
        <AlectifyTable
          size="small"
          onDataFetch={getTableData}
          columns={getTableColumns()}
          isDownloadZip={false}
          isEmailSharing={false}
          onDownloadZip={() => {}}
          onEmailShare={() => {}}
          id={projectId}
          dataSource={apiResponse?.data}
          loading={apiResponse?.fetching}
          selectRows={false}
          onRowSelectionAction={() => {}}
          total={apiResponse?.meta?.total_count}
          key={`${projectId}-eqp-files`}
          onRow={onRow}
        />
      </Modal>
    );
  },
);
