import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { memo, useEffect, useState } from 'react';
import {
  Form, Modal, DatePicker, Input,
} from 'antd';
import { DATES_KEYS, INPUT_DATE_FORMAT, SERVER_DATE_FORMAT } from '../../../static/utils';

function ApprovalDatesModal({
  title = 'Information',
  initialValues = {},
  onSubmit = () => {},
  onCancel = () => {},
  visible = false,
}) {
  const [defaultValues, setDefaultValues] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      const obj = {};
      Object.keys(initialValues).forEach((key) => {
        if (DATES_KEYS.includes(key) && initialValues[key]) {
          obj[key] = moment(initialValues[key]);
        }
      });
      if (!_.isEmpty(initialValues?.maintenance_duration)) {
        obj.maintenance_duration = initialValues.maintenance_duration;
      }
      if (!_.isEmpty(initialValues?.serial_number)) {
        obj.serial_number = initialValues.serial_number;
      }
      form.setFieldsValue({ ...obj });
    } else {
      setDefaultValues({});
    }
  }, [initialValues]);

  const onSubmitHandler = (values) => {
    Object.keys(values).forEach((key) => {
      if (DATES_KEYS.includes(key) && values[key]) {
        values[key] = values[key]
          ? moment(values[key], SERVER_DATE_FORMAT).format(INPUT_DATE_FORMAT)
          : null;
      }
    });

    onSubmit(values);
    form.resetFields();
  };

  const cancleHandler = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={form.submit}
      onCancel={cancleHandler}
      okText="Save"
    >
      <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
          <>
            <Form.Item label="Order Date" name="order_date">
              <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
            </Form.Item>
            <Form.Item label="Approval Date" name="approval_date">
              <DatePicker
                style={{ width: '100%' }}
                format={INPUT_DATE_FORMAT}
                value={defaultValues?.approvalDate}
              />
            </Form.Item>
            <Form.Item label="Release Date" name="release_date">
              <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
            </Form.Item>
            <Form.Item label="Test Date" name="test_date">
              <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
            </Form.Item>
            <Form.Item label="Ship Date" name="ship_date">
              <DatePicker style={{ width: '100%' }} format={INPUT_DATE_FORMAT} />
            </Form.Item>
          </>
      </Form>
    </Modal>
  );
}

export default memo(ApprovalDatesModal);
