import { fetchAllBiddingProjects } from 'app/services/bidding-management';
import { fetchProjects } from '../../services';
import {
  ACTIVE_PROJECT,
  ACTIVE_PROJECT_TAB,
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_SUCCESS,
  PROJECT_DOCS,
  TITLE_STATE,
  MAP_VIEW,
  SHOW_SIDEBAR,
  SHOW_BIDDING_SIDEBAR,
  ENABLE_EVENT,
  ACTIVE_SUB_PROJECT,
  GET_BIDDING_PROJECTS,
  SHOW_LOADER,
} from './types';

export const mapViewDisplay = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MAP_VIEW, payload });
  } catch (error) {
    console.log(error);
  }
};

export const getAllProjects = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PROJECTS_START });
    const response = await fetchProjects();
    dispatch({ type: FETCH_PROJECTS_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: FETCH_PROJECTS_FAILURE });
  }
};

export const getBiddingProjects = (params) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await fetchAllBiddingProjects(params);
    dispatch({ type: GET_BIDDING_PROJECTS, payload: response?.data });
    dispatch(setLoader(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoader(false));
  }
};

export const setActiveProject = (project) => (dispatch) => {
  dispatch({ type: ACTIVE_PROJECT, payload: project });
};

export const setActiveSubProject = (project) => (dispatch) => {
  dispatch({ type: ACTIVE_SUB_PROJECT, payload: project });
};

export const setActiveProjectTab = (tabKey) => (dispatch) => {
  dispatch({ type: ACTIVE_PROJECT_TAB, payload: tabKey });
};

export const setProjectDocs = (data) => (dispatch) => {
  dispatch({ type: PROJECT_DOCS, payload: data });
};

export const setTitleValue = (data) => (dispatch) => {
  dispatch({ type: TITLE_STATE, payload: data });
};

export const setShowSidebar = (data) => (dispatch) => {
  dispatch({ type: SHOW_SIDEBAR, payload: data });
};

export const setShowBiddingSidebar = (boolean) => (dispatch) => {
  dispatch({ type: SHOW_BIDDING_SIDEBAR, payload: boolean });
};

export const setEnableEvent = (boolean) => (dispatch) => {
  dispatch({ type: ENABLE_EVENT, payload: boolean });
};

export const setLoader = (boolean) => (dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: boolean });
};
