import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Collapse,
  DatePicker,
  Divider,
  Drawer,
  Form,
  List,
  message,
  Modal,
  notification,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import {
  DeleteTwoTone,
  FolderOpenOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  APPROVAL_TYPES,
  DOCUMENT_CATEGORIES,
  EQUIPMENT_SUB_FOLDERS,
  makeTitle,
  PROJECT_SUB_FOLDERS,
} from '../../../../static/utils';
import {
  createNotification,
  saveEquipmentDocs,
  saveEquipmentDocsForVideos,
  saveProjectDocs,
  sendForApproval,
  uploadDocuments,
  fetchProjects,
  saveSlineEquipmentDocs,
} from '../../../services';
import { getEquipDocuments, getProjectDocuments } from '../../../store/documents';
import { SelectFromList } from './SelectFromList';
import { searchTableColumns } from './searchTablecolumns';
import { getMasterTags, getTagsByProject } from '../../../store/tags';
import { UploadNote } from '../../../components';

const { Option, OptGroup } = Select;
const { Panel } = Collapse;

function NewDocuments({
  projectId,
  documentsToUpload = [],
  hideUploadView = () => { },
  setDocumentsToUpload = () => { },
  onUploadFiles = () => { },
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { allPackages } = useSelector(({ packages }) => packages);
  const [docInfoDrawer, setDocInfoDrawer] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [category, setCategory] = useState('');
  const [equipmentType, setEquipmentType] = useState('');
  const [loader, setLoader] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [values, setValues] = useState({});
  const [equipment, setEquipment] = useState(null);
  const [hasEqpmtApprovalEnabled, setEqpmtApprovalEnabled] = useState(false);
  const [uploadDocumentsList, setuploadDocuments] = useState([]);
  const history = useHistory();
  const { masterTags, loading } = useSelector(({ tags }) => tags);

  useEffect(() => {
    const equipment = form?.getFieldsValue().equipment;
    if (equipment) {
      const currentEquipment = allPackages.filter((pkg) => pkg.id === equipment);
      const currentEquipmentApprvlStatus = currentEquipment[0]?.package_type !== 'individual_equiment_without_approval_drwng';

      setEquipment(equipment);
      setEqpmtApprovalEnabled(currentEquipmentApprvlStatus);
    }
  }, [equipment, allPackages]);

  const deleteDocument = (uid) => {
    const idx = documentsToUpload.findIndex((item) => item.uid === uid);
    const selectedIdx = selectedRecords.findIndex((item) => item.uid === uid);
    if (idx > -1) {
      documentsToUpload.splice(idx, 1);
      setDocumentsToUpload([...documentsToUpload]);
      message.success('Removed');
    }
    if (selectedIdx > -1) {
      selectedRecords.splice(selectedIdx, 1);
      setSelectedRecords([...selectedRecords]);
    }
  };

  const columns = [
    {
      title: 'File name',
      dataIndex: 'name',
      width: 240,
      render: (value) => (
        <p className="approval-file-name" title={value}>
          {value}
        </p>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: 200,
      render: (value) => (value ? makeTitle(value) : '-'),
    },
    {
      title: 'Sub Folder',
      dataIndex: 'sub_folder',
      width: 200,
      render: (value) => (value ? makeTitle(value) : '-'),
    },
    {
      title: 'Equipment',
      dataIndex: 'equipment',
      width: 200,
      render: (value) => (value ? allPackages.find((pkg) => pkg.id === value).name : '-'),
    },
    {
      title: 'Due Date',
      width: 200,
      dataIndex: 'approval_due_time',
      render: (value) => (value ? value.format('YYYY-MM-DD') : '-'),
    },
    {
      title: 'Assignee',
      width: 200,
      dataIndex: 'assignee',
      render: (value, record) => {
        if (!value) return '-';
        const pkg = allPackages.find((pkg) => pkg.id === record.equipment);
        const assignee = pkg.team_members.find((member) => member.id === value);
        return `${assignee.first_name} ${assignee.last_name}`;
      },
    },
    {
      title: '',
      dataIndex: 'uid',
      render: (value, record) => (!record.is_uploaded ? (
        <Tooltip title="Delete">
          <Popconfirm
            okText="Yes"
            onConfirm={() => deleteDocument(value)}
            title="Are your sure?"
          >
            <DeleteTwoTone
              twoToneColor="#f56666"
              style={{ fontSize: 18, cursor: 'pointer' }}
            />
          </Popconfirm>
        </Tooltip>
      ) : null),
    },
  ];

  useEffect(() => {
    if (selectedRecords.length) showDrawer();
    else hideDrawer();
  }, [selectedRecords]);

  const showDrawer = () => setDocInfoDrawer(true);
  const hideDrawer = () => setDocInfoDrawer(false);

  const removeUploadedFiles = () => {
    const nonUploadedDocuments = documentsToUpload.filter((doc) => {
      const isUploaded = selectedRecords.find((item) => item.uid === doc.uid);
      if (!isUploaded) return doc;
    });
    setDocumentsToUpload([...nonUploadedDocuments]);
  };

  const sendNotificationForApproval = async (approvals) => {
    const uploadedDocuments = approvals.map(({ approval_file_name }) => approval_file_name);
    const files = uploadedDocuments.length > 1 ? uploadedDocuments.join(', ') : uploadedDocuments[0];
    const approvalMessage = `Files sent for approval: ${files}`;
    const message = {
      package: approvals[0].package,
      message: approvalMessage,
    };
    await createNotification(projectId, message);
  };

  const getDocumentType = (folder) => {
    if (folder === 'approval_drawings') {
      return 'Approval';
    }

    if (folder === 'information_for_approval') {
      return 'Information';
    }

    return null;
  };

  const submitForApproval = async () => {
    const {
      equipment, approval_due_time, assignee, sub_folder,
    } = values;

    const formData = new FormData();
    selectedRecords.map((file) => formData.append('files', file.originFileObj));
    formData.append('project_id', projectId);
    const approvalDocuments = await uploadDocuments(projectId, equipment, formData);

    if (approvalDocuments.success) {
      form.resetFields();
      setConfirmModal(false);
      setSelectedRecords([]);
      setuploadDocuments(documentsToUpload);
      const approvals = approvalDocuments.data.map((item) => ({
        ...item,
        assignee,
        package: equipment.toString(),
        approval_file_name: item.fileName,
        approval_file_path: item.path,
        document_type: getDocumentType(sub_folder),
        approval_due_time:
          getDocumentType(sub_folder) === 'Approval'
            ? moment(`${approval_due_time.format('YYYY-MM-DD')} 23:59:00`).format(
              'YYYY-MM-DD HH:mm:ssZ',
            )
            : null,
      }));

      await Promise.all([
        sendForApproval(equipment, approvals),
        sendNotificationForApproval(approvals),
      ]);
    } else {
      notification.error({
        message: 'Error uploading file' || approvalDocuments.message,
      });
    }
  };

  const uploadEquipmentDocuments = async () => {
    const { equipment, sub_folder } = values;

    if (sub_folder === 'videos') {
      const formData = new FormData();
      selectedRecords.map((file) => formData.append('files', file.originFileObj));
      formData.append('project', projectId);
      formData.append('package', equipment);
      const res = await saveEquipmentDocsForVideos(formData);

      if (res.success) {
        notification.success({
          message: 'Uploaded Successfully',
        });
      } else {
        notification.error({
          title: 'Error on Upload',
          message: res.message || 'File Error',
        });
      }
    }

    const formData = new FormData();
    selectedRecords.map((file) => formData.append('files', file.originFileObj));
    formData.append('is_package', true);
    formData.append('dir_key', sub_folder);
    formData.append('project_id', projectId);
    formData.append('id', equipment);

    const res = await saveEquipmentDocs(projectId, equipment, formData);

    if (res.success) {
      notification.success({
        message: 'Uploaded Successfully',
      });

      if (equipmentType === APPROVAL_TYPES.INFORMATION_APPROVAL) {
        await submitForApproval();
      }
      form.resetFields();
      setConfirmModal(false);
      setSelectedRecords([]);
      setuploadDocuments(documentsToUpload);
    } else {
      form.resetFields();
      notification.error({
        title: 'Error on Upload',
        message: res.message || 'File Error',
      });
    }
  };

  const uploadProjectDocuments = async () => {
    const { sub_folder } = values;
    const formData = new FormData();
    selectedRecords.map((file) => formData.append('files', file.originFileObj));
    formData.append('dir_key', sub_folder);
    formData.append('id', projectId);

    let res;
    if (sub_folder === 'single_lines' || sub_folder === 'site_plan') {
      res = await saveSlineEquipmentDocs(projectId, formData);
    } else {
      res = await saveProjectDocs(projectId, formData);
    }

    if (res.success) {
      notification.success({
        message: 'Uploaded Successfully',
      });

      if (equipmentType === APPROVAL_TYPES.INFORMATION_APPROVAL) {
        await submitForApproval();
      }
      form.resetFields();
      setConfirmModal(false);
      setSelectedRecords([]);
      setuploadDocuments(documentsToUpload);
    } else {
      form.resetFields();
      notification.error({
        title: 'Error on Upload',
        message: res?.message || 'File Error',
      });
    }
  };

  const onSaveDocuments = async (values) => {
    try {
      setValues(values);
      const _documentsToUpload = documentsToUpload.map((item) => {
        const foundDocument = selectedRecords.some((i) => i.uid === item.uid);

        if (foundDocument) {
          return {
            ...foundDocument,
            ...item,
            ...values,
            is_uploaded: true,
          };
        }
        return item;
      });

      setSelectedRecords(
        selectedRecords.map((item) => ({
          ...item,
          ...values,
        })),
      );
      setDocumentsToUpload([..._documentsToUpload]);
      setConfirmModal(true);
    } catch (error) {
      setLoader(false);
    }
  };

  const resetState = (msg = '') => {
    setLoader(false);
    setDocInfoDrawer(false);
    setEquipmentType('');
    setCategory('');
  };

  const onUploadDocuments = async () => {
    try {
      setLoader(true);
      if (equipmentType === APPROVAL_TYPES.APPROVAL_DRAWINGS) {
        await submitForApproval();
      } else if (category === DOCUMENT_CATEGORIES.EQUIPMENT_DOCUMENTS) {
        await uploadEquipmentDocuments();
      } else {
        await uploadProjectDocuments();
      }
      resetState();
      dispatch(getProjectDocuments(projectId));
    } catch (error) {
      setLoader(false);
    }
  };

  const onSubmitDocument = () => {
    if (!selectedRecords.length) {
      message.warning('Please select documents to upload');
      return;
    }

    const missingInfo = selectedRecords.some((item) => !item?.category);

    if (missingInfo) {
      message.warning('Please select types for the selected documents ');
      return;
    }
    hideDrawer();
    setConfirmModal(true);
  };

  return (
    <div className="page-content-container full-width">
      <div className="card-heading d-flex justify-space-between">
        <span>
          Upload Documents
          <Tooltip title="Upload Pre-Construction & Manufacture related files here">
            <QuestionCircleOutlined className="cursor-pointer ml-10" />
          </Tooltip>
        </span>
        {/* <Button type="primary" className="btn-tab" onClick={onSubmitDocument}>
                    Submit Documents
                </Button> */}
        <Space>
          <Button
            type="default"
            shape="round"
            className="main-upload-btn"
            onClick={() => setDocumentsToUpload([])}
          >
            Clear
          </Button>
          <Upload
            beforeUpload={() => false}
            onChange={_.debounce(onUploadFiles, 100)}
            showUploadList={false}
            multiple
            fileList={[]}
          >
            <Button type="default" shape="round" className="main-upload-btn">
              <span
                style={{
                  margin: '0 8px 0 0',
                }}
              >
                <PlusOutlined />
                {' '}
                Add Documents
              </span>
              <UploadNote />
            </Button>
          </Upload>
        </Space>
      </div>
      <Collapse
        bordered={false}
        defaultActiveKey={['1', '2']}
        className="site-collapse-custom-collapse"
      >
        <Panel
          key="1"
          header="Documents To Upload"
          className="site-collapse-custom-panel-grey"
        >
          <Table
            className="taglist-table"
            size="small"
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedRecords.map(({ uid }) => uid),
              onChange: (_, selectedRows) => {
                setSelectedRecords(selectedRows);
              },
            }}
            columns={columns}
            rowKey="uid"
            dataSource={documentsToUpload.filter((d) => !d?.is_uploaded)}
            pagination={documentsToUpload.length > 10}
          />
        </Panel>
        <Panel key="2" header="Uploaded Documents" className="site-collapse-custom-panel">
          <Table
            className="taglist-table"
            size="small"
            columns={columns.filter((c) => c.dataIndex !== 'uid')}
            rowKey="uid"
            dataSource={uploadDocumentsList.filter((d) => d.is_uploaded)}
            pagination={uploadDocumentsList.length > 10}
          />
        </Panel>
      </Collapse>

      <div className="site-drawer-render-in-current-wrapper">
        <Drawer
          title="Document Information"
          placement="right"
          width={500}
          onClose={hideDrawer}
          visible={docInfoDrawer}
          // getContainer={false}
          // mask={false}
          keyboard
          footer={(
            <Space>
              <Button type="primary" loading={loader} onClick={form.submit}>
                Save
              </Button>
              <Button onClick={hideDrawer}>Cancel</Button>
            </Space>
          )}
        >
          <Spin spinning={loader}>
            <Form layout="vertical" form={form} onFinish={onSaveDocuments}>
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Select
                  placeholder="Please select"
                  onChange={(value) => {
                    setCategory(value);
                    form.setFieldsValue({ sub_folder: '' });
                  }}
                >
                  {Object.values(DOCUMENT_CATEGORIES).map((item) => (
                    <Option key={item} value={item}>
                      {makeTitle(item)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {category === DOCUMENT_CATEGORIES.PROJECT_DOCUMENTS && (
                <Form.Item
                  label="Type"
                  name="sub_folder"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Select placeholder="Please select">
                    {Object.values(PROJECT_SUB_FOLDERS).map((item) => (
                      <Option key={item} value={item}>
                        {makeTitle(item)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {category === DOCUMENT_CATEGORIES.EQUIPMENT_DOCUMENTS && (
                <>
                  <SelectFromList
                    columns={searchTableColumns({})}
                    onDataFetch={getTagsByProject}
                    data={masterTags}
                    dataFetch={() => { }}
                    title="Equipment List"
                    projectId={projectId}
                    form={form}
                    loading={loading}
                    setValue={setEquipment}
                  />
                  {/* <Form.Item
                                            label="Equipment"
                                            name="equipment"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Required',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Please select"
                                                onChange={(value) => {
                                                    const pkg = allPackages.find(
                                                        ({ id }) => id === value,
                                                    );
                                                    setTeamMembers([...pkg.team_members]);
                                                    setEquipment(value);
                                                }}
                                            >
                                                {allPackages.map((item) => (
                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item> */}
                  <Form.Item
                    label="Type"
                    name="sub_folder"
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      listHeight={500}
                      onChange={(value) => setEquipmentType(value)}
                    >
                      <OptGroup label="Documents">
                        {Object.values(EQUIPMENT_SUB_FOLDERS).map(
                          (item) => (
                            <Option key={item} value={item}>
                              {makeTitle(item)}
                            </Option>
                          ),
                        )}
                      </OptGroup>
                      {hasEqpmtApprovalEnabled && (
                        <OptGroup label="Approval Process">
                          {Object.values(APPROVAL_TYPES).map((item) => (
                            <Option key={item} value={item}>
                              {makeTitle(item)}
                            </Option>
                          ))}
                        </OptGroup>
                      )}
                    </Select>
                  </Form.Item>
                </>
              )}
              {equipmentType === APPROVAL_TYPES.APPROVAL_DRAWINGS && (
                <>
                  <Form.Item
                    label="Due Date"
                    name="approval_due_time"
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                      },
                    ]}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                  {/* <Form.Item label="Assignee" name="assignee">
                                        <Select placeholder="Please select">
                                            {teamMembers.map((m) => (
                                                <Option
                                                    value={m.id}
                                                    key={m.id}
                                                >{`${m.first_name} ${m.last_name}`}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item> */}
                </>
              )}
            </Form>
            <Divider orientation="left">Selected Documents</Divider>
            <List
              size="small"
              bordered
              dataSource={selectedRecords}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>{item.name}</Typography.Text>
                </List.Item>
              )}
            />
          </Spin>
        </Drawer>
      </div>

      <Modal
        centered
        closable={false}
        title="Summary of Files to Upload"
        visible={confirmModal}
        bodyStyle={{ padding: 10 }}
        width={1000}
        okText="Upload"
        onCancel={() => {
          setDocumentsToUpload([]);
          setConfirmModal(false);
        }}
        maskClosable={false}
        onOk={onUploadDocuments}
        confirmLoading={loader}
      >
        <Table
          loading={loader}
          size="small"
          className="taglist-table"
          columns={columns.filter((c) => c.dataIndex !== 'uid')}
          rowKey="uid"
          dataSource={selectedRecords.map((i) => ({ ...i, key: i.uid }))}
          pagination={selectedRecords.length > 10}
        />
      </Modal>
    </div>
  );
}

export default NewDocuments;
