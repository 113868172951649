import _, { uniqBy } from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import {
  Progress,
  Popconfirm,
  Avatar,
  Select,
  Button,
  Upload,
  Tooltip,
  notification,
  Space,
  Collapse,
  Table,
  Dropdown,
  Menu,
  Modal,
  Checkbox,
} from 'antd';
import {
  UploadOutlined,
  DownloadOutlined,
  CheckOutlined,
  EyeOutlined,
  DownOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import {
  DISPLAY_DATETIME_FORMAT,
  MARKUP_COMMENTS,
  COMMENTS_VALUE,
  makeAvatar,
  datePercentage,
  MARKUP_TYPES,
} from '../../../../../static/utils';
import {
  uploadDocuments,
  releaseMarkup,
  getSignedUrl,
  createNotification,
  fetchMarkup,
  patchResubmitAll,
} from '../../../../services';
import {
  updateApproval,
  getMarkups,
  getApprovals,
  removeMarkupFile,
} from '../../../../store/approval';
import { PdfEditor } from '../../../../components';
import { getSortedItems } from './status';
import FinalDocUploadModal from './FinalDocUploadModal';

const { Option } = Select;
const { Panel } = Collapse;

function Review({
  pkgId,
  projectId,
  isReleased = false,
  getPackageDetail = () => {},
  setMarkupDetail,
}) {
  const dispatch = useDispatch();
  const {
    markups, approvalsLoading, markupsLoading, approvalsForReview,
  } = useSelector(
    ({ approval }) => approval,
  );
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState('');
  const [fileName, setFileName] = useState('');
  const [currentRecord, setCurrentRecord] = useState({});
  const [isResubmittLoading, setResubmittLoading] = useState(false);
  const [finalDocOptionEnabled, setFinalDocOptionEnabled] = useState(false);
  const [finalDocuSubmitModalOpen, setFinalDocuSubmitModalOpen] = useState(false);
  const { activeProject } = useSelector(({ common }) => common);
  const [emailSet, setEmailSet] = useState([]);
  const { user } = useSelector(({ auth }) => auth);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [finalFiles, setFinalFiles] = useState([]);
  const [isFetchingFile, setFetchingFile] = useState(false);

  const getInitialData = () => {
    Promise.all([dispatch(getApprovals(pkgId)), dispatch(getMarkups(pkgId))]);
  };

  const uploadPdf = async (blob) => {
    addMarkupInApproval(blob, currentRecord.id, fileName);
  };

  const releaseOne = async (approval) => {
    if (!approval?.document_type) {
      notification.warning({ description: 'Type are required to create markups' });
      return;
    }

    if (
      approval.document_type === MARKUP_TYPES.APPROVAL
            && !approval?.markup_file_path
            && !approval.temp_file_name
            && approval?.markup_comment === 2
    ) {
      notification.warning({ description: 'Upload markup file' });
      return;
    }

    if (approval.document_type === MARKUP_TYPES.APPROVAL && !approval?.markup_comment) {
      notification.warning({ description: 'Comments are required to create markups' });
      return;
    }

    const sendForRelease = {
      package: pkgId,
      approval: approval.id,
      is_markup_uploaded: true,
      document_type: approval?.document_type,
      markup_comment: approval?.markup_comment,
    };

    await releaseMarkup(pkgId, [sendForRelease]);
    const markups = await fetchMarkup(projectId, pkgId);

    setMarkupDetail(markups.data);
    sendNotificationForMarkup(`Files Released: ${approval.approval_file_name}`);
    getInitialData();
  };

  const sendNotificationForMarkup = async (msg) => {
    const message = {
      package: pkgId,
      message: msg,
    };
    await createNotification(projectId, message);
  };

  const handleDownload = async (filePath) => {
    if (filePath) {
      const key = `${filePath}`;
      const url = await getSignedUrl(key);
      window.open(url.data);
      // await axios.get(url.data, { headers: { 'Content-Disposition': 'attachment' } });
      // saveAs(url.data, filePath);
    }
  };

  const handlePdfEditor = async (filePath, fileName) => {
    if (filePath) {
      const key = `${filePath}`;
      const url = await getSignedUrl(key);
      setCurrentDocument(url.data);
      setFileName(`${fileName}`);
      setEditModal(true);
    }
  };

  const handleView = async (filePath) => {
    if (filePath) {
      const key = `${filePath}`;
      const url = await getSignedUrl(key);
      window.open(url.data, '_blank');
    }
  };

  const onResubmitPackage = async () => {
    Swal.fire({
      icon: 'warning',
      title: '',
      text: '',
      html: '<div><p>All Approval drawings are about to be updated with Resubmit Status. <span><strong>Note : </strong>All approved drawings will also move to resubmit stage.</span></p></div>',
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      backdrop: true,
      confirmButtonColor: '#16aaff',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        setResubmittLoading(true);

        const reqBody = [];

        for (const approval of approvalsForReview) {
          const sendForRelease = {
            package: pkgId,
            approval: approval.id,
            is_markup_uploaded: true,
            markup_comment: 3,
          };

          reqBody.push(sendForRelease);
        }

        // if (_.isEmpty(approvalsForReview)) {
        //     reqBody = [{ is_released: true }];
        // }

        const released = await releaseMarkup(pkgId, reqBody);
        const markups = await fetchMarkup(projectId, pkgId);

        setMarkupDetail(markups.data);
        if (released?.success) {
          setResubmittLoading(false);
          sendNotificationForMarkup('Package has been asked for resubittal.');
          getInitialData();
          getPackageDetail();
        }
        setResubmittLoading(false);
        setLoading(false);
      }
    });
  };

  const onReleasePackage = async () => {
    const pendingApprovalsWithCommentApprvdWithComments = approvalsForReview.filter(
      (approval) => approval.document_type === MARKUP_TYPES.APPROVAL
                && approval.markup_comment === 2
                && !approval?.markup_file_path
                && !approval.temp_file_name,
    );

    if (pendingApprovalsWithCommentApprvdWithComments.length) {
      notification.warning({
        description:
                    'Please check if you have uploaded markup for all entries with comment as Approved with Comments.',
      });
      setLoading(false);
      return;
    }

    Swal.fire({
      icon: 'warning',
      title: '',
      // text: 'Once Released You will not be able to upload any more Approval drawings or Markups. Do you want to proceed?',
      // text: "Make sure Markups are uploaded. Once Released You will not be able to upload any more Approval drawings or Markups. Do you want to proceed?",
      text: "Make sure Markups are uploaded. Once Released, no uploads will be allowed. Do you want to proceed?",
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      backdrop: true,
      confirmButtonColor: '#16aaff',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let reqBody = [];
        for (const approval of approvalsForReview) {
          const sendForRelease = {
            package: pkgId,
            is_released: approval?.is_markup_uploaded,
            approval: approval.id,
            is_markup_uploaded: true,
            markup_comment: approval.markup_comment ? approval.markup_comment : 1,
          };
          reqBody.push(sendForRelease);
        }

        if (_.isEmpty(approvalsForReview)) {
          reqBody = [{ is_released: true }];
        }

        const released = await releaseMarkup(pkgId, reqBody);
        const markups = await fetchMarkup(projectId, pkgId);

        setMarkupDetail(markups.data);
        if (released?.success) {
          sendNotificationForMarkup('The Package has been released.');
          getInitialData();
          getPackageDetail();
        }
        setLoading(false);
      }
    });
  };

  const addCommentInApproval = (markup_comment, approvalId) => {
    dispatch(updateApproval(approvalId, { markup_comment }));
  };

  const addTypeInMarkup = (document_type, markupId) => {
    dispatch(updateApproval(markupId, { document_type }));
  };

  const removeMarkup = (approvalId) => {
    dispatch(removeMarkupFile(approvalId));
  };

  useEffect(() => {
    if (selectedFiles.length) {
      selectedFiles.map((record) => {
        getDocUrl(record);
      });
    } else {
      setFinalFiles([]);
    }
  }, [selectedFiles]);

  useEffect(() => {
    const selectedFilesPaths = selectedFiles.map((file) => file.approval_file_path);
    if (finalFiles.length) {
      setEmailSet(
        uniqBy(
          finalFiles.filter((file) => selectedFilesPaths.includes(file.approval_file_path)),
          'approval_file_path',
        ),
      );
    } else {
      setEmailSet([]);
    }
  }, [finalFiles]);

  function Mailto({ email, subject = '', children }) {
    const body = `Hi,%0D%0DAttaching files from the Project ${
      activeProject?.name
    }.%0D%0DFiles:%0D${emailSet
      .map((file) => `${encodeURIComponent(file?.url)}%0D`)
      .join('.%0D')}.%0D%0D%0D%0DRegards,%0D${user?.first_name}%0D%0D.`;

    return <a href={`mailto:?subject=${subject}&body=${body}`}>{children}</a>;
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedFiles(selectedRows);
    },
    type: 'checkbox',
    selectedRowKeys: selectedFiles.map((m) => m.key),
  };

  const getDocUrl = async (rec) => {
    setFetchingFile(true);
    const res = await getSignedUrl(rec.approval_file_name);

    setFinalFiles((state) => [
      ...state,
      {
        ...rec,
        url: res.data,
      },
    ]);

    setFetchingFile(false);

    return res.data;
  };

  const addMarkupInApproval = async (file, approvalId, temp_file_name) => {
    try {
      setLoading(true);
      dispatch(updateApproval(approvalId, { file, temp_file_name }));
      const formData = new FormData();
      formData.append('files', file, `${temp_file_name}`);
      formData.append('project_id', projectId.toString());
      formData.append('generate_markup', true);
      const response = await uploadDocuments(projectId, pkgId, formData);
      const reqBody = {
        approval: approvalId,
        markup_file_path: response?.data[0]?.path,
        markup_file_name: response?.data[0]?.fileName,
      };
      await releaseMarkup(pkgId, [reqBody]);
      getInitialData();
      setLoading(false);
    } catch (ex) {
      throw (ex);
      setLoading(false);
    }
  };

  const MARKUP_COLUMNS = [
    {
      title: 'Type',
      dataIndex: 'document_type',
    },
    {
      title: 'Original Received',
      dataIndex: 'approval_file_name',
      render: (_, record) => (
        <Space>
          <Tooltip title={`Download ${record?.approval_file_name}`}>
            <DownloadOutlined
              className="icon-inner-delete"
              onClick={() => handleDownload(record.approval_file_path)}
            />
          </Tooltip>
          <p className="approval-file-name" title={record.approval_file_name}>
            {record.approval_file_name}
          </p>
        </Space>
      ),
    },
    {
      title: 'Markup Uploaded',
      dataIndex: 'markup_file_name',
      render: (markup_file_name, markup) => {
        if (markup_file_name !== '') {
          return (
            !_.isEmpty(markup_file_name) && (
            <Space>
              <Tooltip title={`Download ${markup.markup_file_name}`}>
                <DownloadOutlined
                  className="icon-inner-delete"
                  onClick={() => handleDownload(markup.markup_file_path)}
                />
              </Tooltip>
              <p className="approval-file-name" title={markup.markup_file_name}>
                {markup.markup_file_name === '' ? '-' : markup.markup_file_name}
              </p>
            </Space>
            )
          );
        }

        return <p>-</p>;
      },
    },
    {
      title: 'Comments',
      dataIndex: 'markup_comment',
      width: '190px',
      render: (comment) => {
        if (comment !== undefined) {
          return <p>{COMMENTS_VALUE[comment]}</p>;
        }
        return <p>-</p>;
      },
    },
    {
      title: 'Due Date',
      width: '170px',
      dataIndex: 'approval_due_time',
      render: (value, record) => {
        if (record.approval_due_time !== null) {
          return (
            <>
              {moment(value).format(DISPLAY_DATETIME_FORMAT)}
              <Progress
                style={{ width: '20' }}
                percent={datePercentage({
                  endDate: record.approval_due_time,
                  startDate: record.created_at,
                })}
                showInfo={false}
              />
            </>
          );
        }

        return <p>-</p>;
      },
    },
    {
      title: 'Submitted at',
      width: '170px',
      dataIndex: 'updated_at',
      render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
    },
    {
      title: 'Submitted by',
      align: 'center',
      dataIndex: 'approval',
      render: (_, approval) => (
        <Tooltip
          title={`${approval?.account?.first_name} ${approval?.account?.last_name}`}
        >
          <Avatar
            className="circle-icon m-auto"
            src={
                                approval?.account?.image
                                || makeAvatar(
                                  approval?.account?.first_name[0],
                                  approval?.account?.last_name[0],
                                )
                            }
          />
        </Tooltip>
      ),
    },
  ];

  const APPROVAL_COLUMNS = [
    {
      title: 'Type',
      dataIndex: 'document_type',
      width: 140,
      render: (_, record) => {
        if (record.document_type !== null) {
          return <p>{record.document_type}</p>;
        }

        return <p>-</p>;
      },
    },
    {
      title: 'Original Received',
      dataIndex: 'approval',
      render: (_, record) => (
        <Space>
          <Tooltip title={`Download ${record.approval_file_name}`}>
            <DownloadOutlined
              className="icon-inner-delete"
              onClick={() => handleDownload(record.approval_file_path)}
            />
          </Tooltip>
          <p className="approval-file-name" title={record.approval_file_name}>
            {record.approval_file_name}
          </p>
        </Space>
      ),
    },
    {
      title: 'Upload Markup',
      dataIndex: 'approval_file_name',
      render: (_, record) => {
        if (record.document_type && record.document_type.toLowerCase() === 'approval') {
          return (
            <>
              <p style={{ marginBottom: 5 }}>
                {record.temp_file_name || record.markup_file_name}
              </p>
              {record?.approval_file_name?.split('.').pop() === 'pdf' ? (
                <Dropdown
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        key="1"
                        onClick={() => {
                          handlePdfEditor(
                            record?.markup_file_path
                              ? record?.markup_file_path
                              : record?.approval_file_path,
                            record?.markup_file_name
                              ? record?.markup_file_name
                              : record?.approval_file_name,
                          );
                          setCurrentRecord(record);
                        }}
                      >
                        Edit & Save Markup
                      </Menu.Item>
                      <Menu.Item
                        key="2"
                        disabled={
                                                    record.temp_file_name
                                                    && record.approval_file_name.split('.').pop()
                                                        === 'pdf'
                                                }
                      >
                        <Upload
                          disabled={
                                                        record.temp_file_name
                                                        && record.approval_file_name
                                                          .split('.')
                                                          .pop() === 'pdf'
                                                    }
                          beforeUpload={() => false}
                          showUploadList={false}
                          maxCount={1}
                          onChange={(e) => {
                            e.file.status !== 'removed'
                                                            && addMarkupInApproval(
                                                              e.file,
                                                              record.id,
                                                              e.file.name,
                                                            );
                          }}
                          onRemove={() => removeMarkup(record.id)}
                        >
                          Upload From Local
                        </Upload>
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <Button>
                    Add Markup
                    {' '}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Upload
                  beforeUpload={() => false}
                  showUploadList={false}
                  maxCount={1}
                  onChange={(e) => {
                    e.file.status !== 'removed'
                                            && addMarkupInApproval(e.file, record.id, e.file.name);
                  }}
                  onRemove={() => removeMarkup(record.id)}
                >
                  <Button icon={<UploadOutlined />}> Click to Upload </Button>
                </Upload>
              )}
            </>
          );
        }

        return <p>-</p>;
      },
    },
    {
      title: 'Comments',
      dataIndex: 'notes',
      width: 160,
      render: (_, record) => (
        <>
          {record?.document_type === MARKUP_TYPES.APPROVAL ? (
            <Select
              style={{ maxWidth: 160 }}
              placeholder="Select comment *"
              onChange={(val) => addCommentInApproval(val, record.id)}
            >
              {MARKUP_COMMENTS.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Due Date',
      dataIndex: 'approval_due_time',
      render: (value, record) => {
        if (record.approval_due_time !== null) {
          return (
            <>
              {moment(value).format(DISPLAY_DATETIME_FORMAT)}
              <Progress
                style={{ width: '20' }}
                percent={datePercentage({
                  endDate: record.approval_due_time,
                  startDate: record.created_at,
                })}
                showInfo={false}
              />
            </>
          );
        }

        return <p>-</p>;
      },
    },
    {
      title: 'Submitted by',
      align: 'center',
      dataIndex: 'approval',
      render: (_, record) => (
        <Tooltip title={`${record?.account?.first_name} ${record?.account?.last_name}`}>
          <Avatar
            className="circle-icon m-auto"
            src={
                            record?.account?.image
                            || makeAvatar(
                              record?.account?.first_name[0],
                              record?.account?.last_name[0],
                            )
                        }
          />
        </Tooltip>
      ),
    },
    // {
    //     title: 'Submitted at',
    //     dataIndex: 'created_at',
    //     render: (value) => moment(value).format(DISPLAY_DATETIME_FORMAT),
    // },
    // {
    //   title: 'Action',
    //   align: 'center',
    //   dataIndex: 'action',
    //   render: (_, record) => (
    //     <Button
    //       type="primary"
    //       shape="round"
    //       size="small"
    //       onClick={() => releaseOne(record)}
    //     >
    //       {record.markup_comment === 3 ? 'Resubmit' : 'Release'}
    //     </Button>
    //   ),
    // },
  ];

  const onClosePdfEditor = async (sendNotification = false) => {
    setEditModal(false);
    setFileName('');
    setCurrentDocument('');

    // Send Notification
    if (sendNotification) {
      const approvalMessage = `New Markups Added/Edited. ${currentRecord?.approval_file_name}`;
      await sendNotificationForMarkup(approvalMessage);
    }
  };

  // const handleFinalDocOptionChange = (e) => {
  //     const isChecked = e.target.checked;

  //     setFinalDocOptionEnabled(isChecked);
  //     setFinalDocuSubmitModalOpen(isChecked);
  // };

  const toggleFinalDocModal = () => {
    setFinalDocuSubmitModalOpen((state) => !state);
  };

  return (
    <div className="mb-40">
      <Collapse
        bordered={false}
        defaultActiveKey={[isReleased ? '2' : '1']}
        className="site-collapse-custom-collapse"
      >
        {!isReleased && (
        <>
          {/* <div className="mb-20">
                            <Space>
                                <Checkbox
                                    disabled={
                                        approvalsForReview.filter(
                                            (item) =>
                                                item.resubmit_status !== true &&
                                                item.document_type?.toLowerCase() === 'approval',
                                        ).length < 1
                                    }
                                    onChange={handleFinalDocOptionChange}
                                    checked={finalDocOptionEnabled}
                                >
                                    Upload one file for all Entries
                                </Checkbox>
                                <Tooltip
                                    title="This will replace all the approval entries with the document
                                        uploaded."
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Space>
                        </div> */}
          {/* <Panel
                            key="1"
                            header="Documents For Review"
                            className="site-collapse-custom-panel"
                        >
                            <Table
                                rowKey="id"
                                loading={approvalsLoading || loading}
                                columns={APPROVAL_COLUMNS}
                                dataSource={getSortedItems(approvalsForReview, ['created_at'])}
                                pagination={approvalsForReview?.length > 10}
                            />
                            <div className="text-right mt-10">
                                {!isReleased && (
                                    <Space>
                                        <Button
                                            type="link"
                                            onClick={onResubmitPackage}
                                            disabled={approvalsForReview.length <= 0}
                                            loading={
                                                approvalsLoading || isResubmittLoading || loading
                                            }
                                        >
                                            Resubmit Package
                                        </Button>

                                        <Button
                                            type="primary"
                                            onClick={onReleasePackage}
                                            loading={approvalsLoading || loading}
                                        >
                                            Release Package
                                        </Button>
                                    </Space>
                                )}
                            </div>
                        </Panel> */}
        </>
        )}
        <Panel
          key="1"
          header={`Documents For Review (${approvalsForReview?.length})`}
          className="site-collapse-custom-panel"
        >
          <Table
            rowKey="id"
            loading={approvalsLoading || loading}
            columns={APPROVAL_COLUMNS}
            dataSource={getSortedItems(approvalsForReview, ['created_at'])}
            pagination={approvalsForReview?.length > 10}
          />
          <div className="text-right mt-10">
            {!isReleased && (
            <Space>
              {/* <Button
                type="link"
                onClick={onResubmitPackage}
                disabled={approvalsForReview.length <= 0}
                loading={approvalsLoading || isResubmittLoading || loading}
              >
                Resubmit Package
              </Button> */}

              <Button
                type="primary"
                onClick={onReleasePackage}
                loading={approvalsLoading || loading}
              >
                Release Package
              </Button>
            </Space>
            )}
          </div>
        </Panel>
        <Panel
          key="2"
          header={`Reviewed Documents (${markups.filter((item) => item.markup_comment !== 3)?.length})`}
          className="site-collapse-custom-panel-grey"
        >
          {/* <div className="mb-15 text-right">
                        <Button disabled={emailSet.length < 1 || isFetchingFile} type="link">
                            <Mailto email="" subject={`Files from`}>
                                <ShareAltOutlined /> Email
                            </Mailto>
                        </Button>
                    </div> */}
          <Table
            dataSource={getSortedItems(
              markups.filter((item) => item.markup_comment !== 3),
              ['created_at'],
            ).map(
              (data, index) => ({
                ...data,
                key: `${data.approval_file_path}+${index}`,
              }),
            )}
            // rowSelection={{
            //   ...rowSelection,
            // }}
            loading={markupsLoading || loading}
            columns={MARKUP_COLUMNS}
            pagination={markups.length > 10}
          />
        </Panel>
        {/* <Panel
          key="3"
          header={`Resubmittal Documents (${markups.filter((item) => item.markup_comment === 3)?.length})`}
          className="site-collapse-custom-panel-warning"
        >
          {/* <div className="mb-15 text-right">
                        <Button disabled={emailSet.length < 1 || isFetchingFile} type="link">
                            <Mailto email="" subject={`Files from`}>
                                <ShareAltOutlined /> Email
                            </Mailto>
                        </Button>
                    </div> add closing comment statement here
          <Table
            rowKey="id"
            dataSource={getSortedItems(
              markups.filter((item) => item.markup_comment === 3),
              ['created_at'],
            )}
            loading={markupsLoading || loading}
            columns={MARKUP_COLUMNS}
            pagination={
                            approvalsForReview.filter((item) => item.resubmit_status)?.length > 10
                        }
          />
        </Panel> */}
      </Collapse>
      <Modal
        width="100%"
        title="Edit Document"
        className="edit-doc-modal"
        style={{ top: 0, bottom: 0 }}
        visible={editModal}
        onOk={async () => {
          setEditModal(false);
          onClosePdfEditor(true);
        }}
        onCancel={() => {
          setEditModal(false);
          onClosePdfEditor(false);
        }}
        footer={null}
        centered
      >
     <PdfEditor
          isOpen={editModal}
          s3Key={currentDocument}
          pdf={currentDocument}
          uploadPdf={uploadPdf}
          onClosePdfEditor={() => onClosePdfEditor(true)}
      />
      </Modal>
      <FinalDocUploadModal
        projectId={projectId}
        pkgId={pkgId}
        getInitialData={getInitialData}
        isOpen={finalDocuSubmitModalOpen}
        onOk={() => {
          toggleFinalDocModal();
          setFinalDocOptionEnabled(false);
        }}
        apprvlEntries={getSortedItems(
          approvalsForReview.filter(
            (item) => item.resubmit_status !== true
                            && item.document_type?.toLowerCase() === 'approval',
          ),
          ['created_at'],
        )}
        onCancel={() => {
          toggleFinalDocModal();
          setFinalDocOptionEnabled(false);
        }}
      />
    </div>
  );
}

export default Review;
