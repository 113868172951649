import _ from 'lodash';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import React from 'react';
import {
    ProjectListing,
    SinglelineDetail,
    TagDetail,
    Dashboard,
    PackageDetail,
    CreateEditProject,
    CreateEditMasterProject,
    ProjectsAdmin,
    ITAdmin,
    Calendar,
    ProfileSettings,
    ProjectsView,
    CreateEditSubProject,
} from './app/pages';

import TaskDetail from './app/pages/projects/task';

import {
    PROJECTS,
    SLINES,
    TAGS,
    PACKAGE,
    DASHBOARD,
    CONTACTS,
    SETTING,
    HELP,
    USERINFO,
    SHARED_WITH_ME,
    PROFILE_SETTINGS,
    BIDDING_MANAGEMENT,
} from './static/utils';

import { Login, Signup, ForgotPassword, ResetPassword } from './web/pages';
import { AlectifyLayout } from './app/components';
import * as Permissions from './static/utils/permissions';
import Contacts from './app/pages/contacts';
import Shared from './app/pages/shared';
import Setting from './app/pages/setting';
import Help from './app/pages/help';
import UserInfo from './app/pages/user-info';
import CreateOrUpdateMainProject from './app/pages/BiddingManagement/MainProjects/CreateOrUpdateMainProject';
import CreateOrUpdateContractors from './app/pages/BiddingManagement/Contractors/CreateOrUpdateContractors';

function Routes() {
    return (
        <Router>
            <Switch>
                {/* <Route exact path="/" component={() => <Redirect to="/login" />} /> */}
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password/:uidb64/:token" component={ResetPassword} />
                <PrivateRoute component={ProtectedRoutes} />
            </Switch>
        </Router>
    );
}

function PrivateRoute({ component: Component, ...rest }) {
    const { user } = useSelector(({ auth }) => auth);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (_.isEmpty(user)) {
                    return <Redirect to={{ pathname: '/login' }} />;
                }
                return <Component {...props} />;
            }}
        />
    );
}

function ProtectedRoutes() {
    const { user } = useSelector(({ auth }) => auth);
    return (
        <AlectifyLayout>
            <Switch>
                {Permissions.can_view_dashboard_sidebar(user?.platform_permissions) && (
                    <Route exact path={DASHBOARD} component={Dashboard} />
                )}
                {/*  Only Project permission handled differently */}
                {/* <Route exact path={PROJECTS} component={ProjectListing} /> */}
                {Permissions.can_view_contacts_sidebar(user?.platform_permissions) && (
                    <Route exact path={CONTACTS} component={Contacts} />
                )}
                {Permissions.can_view_sharedwithme_sidebar(user?.platform_permissions) && (
                    <Route exact path={SHARED_WITH_ME} component={Shared} />
                )}
                <Route exact path={PROFILE_SETTINGS} component={ProfileSettings} />
                {Permissions.can_view_sharedwithme_sidebar(user?.platform_permissions) && (
                    <Route exact path={SETTING} component={Setting} />
                )}
                {/* {Permissions.can_view_projects_sidebar(user?.platform_permissions) && (
                    <Route
                        exact
                        path={`${PROJECTS}/:projectId/master/aggregate`}
                        component={MasterAggregate}
                    />
                )} */}
                <Route exact path={HELP} component={Help} />
                <Route exact path={USERINFO} component={UserInfo} />
                <Route
                    exact
                    path={`${PROJECTS}/:projectId${SLINES}/:slineId`}
                    component={SinglelineDetail}
                />
                <Route exact path={`${PROJECTS}/:projectId${TAGS}/:tagId`} component={TagDetail} />
                <Route
                    exact
                    path={`${PROJECTS}/:projectId${PACKAGE}/:pkgId`}
                    component={PackageDetail}
                />
                <Route
                    exact
                    path={`${PROJECTS}/create-master`}
                    component={CreateEditMasterProject}
                />
                <Route
                    exact
                    path={`${PROJECTS}/master/:projectId`}
                    component={CreateEditMasterProject}
                />
                <Route
                    exact
                    path={`${PROJECTS}/:masterProjectId/create`}
                    component={CreateEditSubProject}
                />
                <Route
                    exact
                    path={`${PROJECTS}/:masterProjectId/:projectId/edit`}
                    component={CreateEditSubProject}
                />
                {/* <Route
                    exact
                    path={`${PROJECTS}/:masterProjectId/create`}
                    component={CreateEditProject}
                /> */}
                <Route exact path={`${PROJECTS}/:projectId/:tabId`} component={ProjectListing} />
                <Route
                    exact
                    path={`${PROJECTS}/:masterProjectId/:projectId/edit`}
                    component={CreateEditProject}
                />
                <Route
                    exact
                    path={`${PROJECTS}/:projectId/:packageId/task/:taskId/`}
                    component={TaskDetail}
                />
                {user.is_superuser && (
                    <Route exact path={`${PROJECTS}-admin`} component={ProjectsAdmin} />
                )}
                <Route exact path="/calendar" component={Calendar} />
                {Permissions.can_use_admin_panel(user?.platform_permissions) &&
                    !user.is_superuser && <Route exact path={`/it-admin`} component={ITAdmin} />}
                {/* <Redirect to="/" /> */}

                <Route
                    exact
                    path={`${BIDDING_MANAGEMENT}/create`}
                    component={CreateOrUpdateMainProject}
                />
                <Route
                    exact
                    path={`${BIDDING_MANAGEMENT}/create/:projectId`}
                    component={CreateOrUpdateMainProject}
                />
                <Route
                    exact
                    path={`${BIDDING_MANAGEMENT}/contractor/create/:projectId`}
                    component={CreateOrUpdateContractors}
                />
                <Route
                    exact
                    path={`${BIDDING_MANAGEMENT}/contractor/create/:projectId/:contractorId`}
                    component={CreateOrUpdateContractors}
                />
                <Route path={`${BIDDING_MANAGEMENT}`} component={ProjectsView} />
                <Route path={`${PROJECTS}`} component={ProjectsView} />
            </Switch>
        </AlectifyLayout>
    );
}

export default Routes;
