import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  Table,
  Button,
  Input,
  Tooltip,
  Row,
  Col,
  Collapse,
  notification,
  Space,
  Checkbox,
  Avatar,
  Popconfirm,
  Popover,
  Form,
  Select,
} from 'antd';
import {
  SearchOutlined,
  DeleteTwoTone,
  PlusOutlined,
  QuestionCircleOutlined,
  EditTwoTone,
  WarningTwoTone,
  InfoCircleTwoTone,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import CreatePackageModal from '../listing/create-package';
import EditPackageModal from '../listing/edit-package';
import {
  deletePackageRoom,
  createPackage,
  removeMemberFromPackage,
  addMemberInPackage,
  newUpdateProject,
  makeProjectActive,
  newfetchProjectDetail,
  updatePackage,
  fetchPackagesSectionWise,
  getAllIndividualPackages,
} from '../../../services';
import {
  checkPackageManufactures,
  PAGE_SIZE_OPTIONS,
  makeAvatar,
  PROJECT_API_ENUM,
} from '../../../../static/utils';
import { CustomAPILoader } from '../../../components/loaders';

const { Option } = Select;
const { Panel } = Collapse;

function Packages({
  tags = [],
  projectId = null,
  goBack = () => {},
  setTags = () => {},
  onFinish = () => {},
  onSubmit = () => {},
  setLoader = () => {},
}) {
  const { user } = useSelector(({ auth }) => auth);

  const [form] = Form.useForm();
  const searchInput = useRef();

  const [createPackageVisible, setCreatePackageVisible] = useState(false);
  const [editPackageVisible, setEditPackageVisible] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchColumn] = useState('');
  const [activePackage, setActivePackage] = useState({});
  const [allInOnePackage, setAllInOnePackage] = useState(false);

  const [displayAddMembers, setDisplayAddMembers] = useState('');
  const [users, setUsers] = useState([]);
  const [editPkgLoading, setEditPkgLoading] = useState(false);

  const [individualPackages, setIndividualPackages] = useState({ data: [], meta: {} });
  const [roomPackages, setRoomPackages] = useState({ data: [], meta: {} });
  const [pkgsWithoutApprovalDwg, setPkgsWithoutApprovalDwg] = useState({ data: [], meta: {} });

  const [loading, setLoading] = useState(false);
  const [isPackagesRoomFetching, setPackagesRoomFetching] = useState(false);
  const [isEqpmtWithApprvlFetching, setEqpmtWithApprvlFetching] = useState(false);
  const [isEqpmtWithoutApprvlFetching, setEqpmtWithoutApprvlFetching] = useState(false);

  const [packagesRoomPage, setPackagesRoomPage] = useState(1);
  const [EqpmtWithApprvlPage, setEqpmtWithApprvlPage] = useState(1);
  const [EqpmtWithoutApprvlPage, setEqpmtWithoutApprvlPage] = useState(1);

  const [packagesRoomPageSize, setPackagesRoomPageSize] = useState(5);
  const [EqpmtWithApprvlPageSize, setEqpmtWithApprvlPagSize] = useState(5);
  const [EqpmtWithoutApprvlPageSize, setEqpmtWithoutApprvlPageSize] = useState(5);

  const [allIndividualTags, setAllIndividualTags] = useState([]);

  useEffect(() => {
    Promise.all([
      fetchAllIndividualPackages(projectId),
      fetchUsers(),
      getPackages('room_packages'),
    ]);
  }, []);

  useEffect(() => {
    // onPageChange(1, searchText, searchedColumn, packagesRoomPageSize);
  }, [packagesRoomPageSize]);

  useEffect(() => {
    fetchAllIndividualPackages(projectId);
    getPackages('room_packages');
  }, [projectId]);

  const fetchAllIndividualPackages = async (id = projectId) => {
    const response = await getAllIndividualPackages(id);
    setAllIndividualTags(response);
  };

  const getPackages = async (section, page = 1, pageSize = 5) => {
    if (section === 'room_packages') {
      setPackagesRoomFetching(true);
      const response = await fetchPackagesSectionWise(projectId, section, page, pageSize);
      if (response.success) {
        setPackagesRoomPage(page);
        setRoomPackages({ data: [...response?.data], meta: { ...response.meta } });
        setPackagesRoomFetching(false);
        setLoader(false);
      } else {
        setPackagesRoomFetching(false);
        setLoader(false);
      }
    } else if (section === 'individual_packages') {
      setEqpmtWithApprvlFetching(true);
      const response = await fetchPackagesSectionWise(projectId, section, page, pageSize);
      if (response.success) {
        setEqpmtWithApprvlPage(page);
      }
      setIndividualPackages({ data: [...response?.data], meta: { ...response.meta } });
      setEqpmtWithApprvlFetching(false);
      setLoader(false);
    } else {
      setEqpmtWithoutApprvlFetching(true);
      const response = await fetchPackagesSectionWise(projectId, section, page, pageSize);
      if (response.success) {
        setEqpmtWithoutApprvlPage(page);
      }
      setPkgsWithoutApprovalDwg({ data: [...response?.data], meta: { ...response.meta } });
      setEqpmtWithoutApprvlFetching(false);
      setLoader(false);
    }
  };

  const onPageChange = async (page, pageSize, section) => {
    await getPackages(section, page, pageSize);
  };

  const fetchPackages = async () => {
    try {
      setLoader(true);
      const response = await newfetchProjectDetail(
        PROJECT_API_ENUM.PACKAGES,
        projectId,
        false,
      );
      setRoomPackages([...response?.data.room_packages]);
      setIndividualPackages([...response?.data.individual_packages]);
      setPkgsWithoutApprovalDwg([...response?.data.packages_without_approval_dwg]);
      setLoader(false);
    } catch (error) {}
  };

  const fetchUsers = async () => {
    const response = await newfetchProjectDetail(PROJECT_API_ENUM.USERS, projectId);
    setUsers([...response?.data]);
  };

  const showNotification = (message = '', type = 'success', placement = 'topRight') => {
    notification[type]({
      message,
      placement,
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8, width: 200 }}>
        <Row gutter={5}>
          <Col span={24}>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
          </Col>
          <Col span={12}>
            <Button
              onClick={() => handleReset(clearFilters, confirm)}
              size="small"
              style={{ width: '100%' }}
            >
              Reset
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: '100%' }}
            >
              Search
            </Button>
          </Col>
        </Row>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : '#838383' }} />
    ),

    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },

    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText('');
  };

  const onCreatePackage = async (data, cb) => {
    try {
      setLoader(true);

      data.tags = data.tags.map(({ id }) => id);

      await newUpdateProject(PROJECT_API_ENUM.PACKAGES, projectId, data);
      setCreatePackageVisible(false);
      setLoader(false);
      showNotification('Package successfully created');
    } catch (error) {
      console.log('Error', error);
    } finally {
      cb && cb();
      Promise.all([getAllSections(), fetchAllIndividualPackages()]);
      setLoader(false);
    }
  };

  const handleEditPkg = (item) => {
    setActivePackage(item);
    setEditPackageVisible(true);
  };

  const onUpdatePackage = async (data, cb) => {
    try {
      setEditPkgLoading(true);
      const res = await updatePackage(activePackage.id, data);

      if (res.success) {
        cb();
        setEditPkgLoading(false);
        setActivePackage({});
        setEditPackageVisible(false);
        Promise.all([getAllSections(), fetchAllIndividualPackages()]);

        notification.success({
          message: `${activePackage.name} updated successfully!`,
        });
      }
    } catch (ex) {
      notification.error({
        message: 'Could not update Package',
      });
      setEditPkgLoading(false);
    }
  };

  const onDeletePackage = async (pkg, section) => {
    try {
      setLoader(true);
      await deletePackageRoom(projectId, pkg.id);
      showNotification('Package successfully deleted');
    } catch (ex) {
      setLoader(false);
    } finally {
      fetchAllIndividualPackages();

      if (pkg.name === 'All In One Package') {
        Promise.all([getAllSections(), fetchAllIndividualPackages()]);
        setAllInOnePackage(false);
      } else {
        Promise.all([getAllSections(), fetchAllIndividualPackages()]);
      }
    }
  };

  const onEditCancel = () => {
    setActivePackage({});
    setEditPackageVisible(false);
  };

  const onCancel = () => {
    setActivePackage({});
    setCreatePackageVisible(false);
  };

  const onAddMemberInPackage = async (values, section = '') => {
    try {
      const { pkg, member_id } = values;
      const user = users.find((user) => user.id === member_id);
      const isUserExist = pkg.team_members.some((team_member) => team_member.id === user.id);
      if (isUserExist) {
        form.setFields([{ name: 'member_id', errors: ['User already exist'] }]);
        return;
      }
      setLoader(true);
      const response = await addMemberInPackage(projectId, pkg.id, member_id);
      showNotification(response.message);
      setDisplayAddMembers('');
      form.resetFields();
      getPackages(section);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const getAllSections = async () => {
    Promise.all([
      getPackages('room_packages', packagesRoomPage, packagesRoomPageSize),
      getPackages('individual_packages', EqpmtWithApprvlPage, EqpmtWithApprvlPageSize),
      getPackages('packages_without_approval_dwg', EqpmtWithoutApprvlPage, EqpmtWithoutApprvlPageSize),
    ]);
  };

  const addMembersContent = (pkg, section = '') => (
    <Form
      form={form}
      style={{ width: 200 }}
      layout="vertical"
      onFinish={(values) => onAddMemberInPackage({ ...values, pkg }, section)}
    >
      <Form.Item name="member_id" rules={[{ required: true, message: 'Please select' }]}>
        <Select
          showSearch
          placeholder="Please select"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          allowClear
          style={{ width: '100%' }}
        >
          {users.map((user) => (
            <Option key={user.id} value={user.id}>
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div className="text-right mt-15">
        <Space>
          <Button type="default" onClick={() => setDisplayAddMembers('')}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Space>
      </div>
    </Form>
  );

  const removeMember = async (memberId, pkgId, section) => {
    try {
      setLoader(true);
      const response = await removeMemberFromPackage(projectId, pkgId, memberId);
      showNotification(response.message);
      getPackages(section);
      setDisplayAddMembers('');
    } catch (error) {
      setLoader(false);
      if (error?.response?.data?.message) { showNotification(error.response?.data.message, 'error'); }
    }
  };

  const individualPackageColumns = [
    {
      title: '',
      dataIndex: 'warning',
      width: 0,
      render: (_, record) => checkPackageManufactures(record),
    },
    {
      title: 'Tag Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      // ...getColumnSearchProps('name'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => ({
        props: {
          className: 'description-td',
        },
        children: (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      }),
    },
    {
      title: 'Team Members',
      dataIndex: 'team_members',
      key: 'team_members',
      render: (team_members, record) => (
        team_members && (
        <div className="package-team-members">
          <Avatar.Group>
            {team_members.map((member, idx) => (
              <Tooltip
                key={member.email}
                title={`${member.first_name} ${member.last_name}`}
              >
                <Popconfirm
                  onConfirm={() => removeMember(
                    member.id,
                    record?.tags[0].packageroom[0],
                    'individual_packages',
                  )}
                  title="Are your want to remove this user?"
                >
                  <Avatar
                    className="circle-icon"
                    src={makeAvatar(
                      member.first_name[0],
                      member.last_name[0],
                    )}
                  />
                </Popconfirm>
              </Tooltip>
            ))}

            <Tooltip title="Add Member">
              <Popover
                placement="rightTop"
                title="Add New Member"
                content={addMembersContent(
                  {
                    ...record,
                    id: record?.tags[0]?.packageroom[0],
                  },
                  'individual_packages',
                )}
                trigger="click"
                visible={displayAddMembers === record.id}
              >
                <Avatar
                  className="circle-icon"
                  style={{
                    color: '#f56a00',
                    backgroundColor: '#fde3cf',
                  }}
                  onClick={() => setDisplayAddMembers(record.id)}
                >
                  <PlusOutlined />
                </Avatar>
              </Popover>
            </Tooltip>
          </Avatar.Group>
        </div>
        )
      ),
    },
  ];

  const individualPackageWithoutEqpmtColumns = [
    {
      title: '',
      dataIndex: 'warning',
      width: 0,
      render: (_, record) => checkPackageManufactures(record),
    },
    {
      title: 'Tag Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      // ...getColumnSearchProps('name'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => ({
        props: {
          className: 'description-td',
        },
        children: (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      }),
    },
    {
      title: 'Team Members',
      dataIndex: 'team_members',
      key: 'team_members',
      render: (team_members, record) => (
        team_members && (
        <div className="package-team-members">
          <Avatar.Group>
            {team_members.map((member, idx) => (
              <Tooltip
                key={member.email}
                title={`${member.first_name} ${member.last_name}`}
              >
                <Popconfirm
                  onConfirm={() => removeMember(
                    member.id,
                    record?.tags[0]?.packageroom[0],
                    'packages_without_approval_dwg',
                  )}
                  title="Are your want to remove this user?"
                >
                  <Avatar
                    className="circle-icon"
                    src={makeAvatar(
                      member.first_name[0],
                      member.last_name[0],
                    )}
                  />
                </Popconfirm>
              </Tooltip>
            ))}

            <Tooltip title="Add Member">
              <Popover
                placement="rightTop"
                title="Add New Member"
                content={addMembersContent(
                  {
                    ...record,
                    id: record?.tags[0]?.packageroom[0],
                  },
                  'packages_without_approval_dwg',
                )}
                trigger="click"
                visible={displayAddMembers === record.id}
              >
                <Avatar
                  className="circle-icon"
                  style={{
                    color: '#f56a00',
                    backgroundColor: '#fde3cf',
                  }}
                  onClick={() => setDisplayAddMembers(record.id)}
                >
                  <PlusOutlined />
                </Avatar>
              </Popover>
            </Tooltip>
          </Avatar.Group>
        </div>
        )
      ),
    },
  ];

  const groupsPackageColumns = [
    {
      title: '',
      dataIndex: 'warning',
      width: 0,
      render: (_, record) => checkPackageManufactures(record),
    },
    {
      title: 'Room Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      // ...getColumnSearchProps('name'),
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (text, record) => ({
        props: {
          className: 'description-td',
        },
        children: (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      }),
    },
    {
      title: 'Total Tags',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, record) => record.tags.length,
    },
    {
      title: 'Team Members',
      dataIndex: 'team_members',
      key: 'team_members',
      render: (team_members, record) => team_members && (
      <div className="package-team-members">
        <Avatar.Group>
          {team_members.map((member, idx) => (
            <Tooltip
              key={member.email}
              title={`${member.first_name} ${member.last_name}`}
            >
              <Popconfirm
                onConfirm={() => removeMember(member.id, record.id, 'room_packages')}
                title="Are your want to remove this user?"
              >
                <Avatar
                  className="circle-icon"
                  src={makeAvatar(
                    member.first_name[0],
                    member.last_name[0],
                  )}
                />
              </Popconfirm>
            </Tooltip>
          ))}

          <Tooltip title="Add Member">
            <Popover
              placement="rightTop"
              title="Add New Member"
              content={addMembersContent(record, 'room_packages')}
              trigger="click"
              visible={displayAddMembers === record.id}
            >
              <Avatar
                className="circle-icon"
                style={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                }}
                onClick={() => setDisplayAddMembers(record.id)}
              >
                <PlusOutlined />
              </Avatar>
            </Popover>
          </Tooltip>
        </Avatar.Group>
      </div>
      ),
    },
    {
      title: '',
      key: 'edit',
      dataIndex: 'edit',
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            onClick={(ev) => {
              ev.stopPropagation();
              handleEditPkg(record);
            }}
            style={{
              border: 'none',
            }}
          >
            <Tooltip title="Edit Package">
              <EditTwoTone
                twoToneColor="#16aaff"
                style={{ fontSize: 18, cursor: 'pointer' }}
              />
            </Tooltip>
          </Button>
          <Button
            type="link"
            style={{
              border: 'none',
            }}
            onClick={(ev) => {
              ev.stopPropagation();
              onDeletePackage(record, 'room_packages');
            }}
          >
            <Tooltip title="Delete Package">
              <DeleteTwoTone
                twoToneColor="#f56666"
                style={{ fontSize: 18, cursor: 'pointer' }}
              />
            </Tooltip>
          </Button>
        </Space>
      ),
    },
  ];

  const nestedTagsColumn = [
    {
      title: 'Tag Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      // ...getColumnSearchProps('name'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => ({
        props: {
          className: 'description-td',
        },
        children: (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      }),
    },
    {
      title: 'Approval',
      dataIndex: 'approval',
      key: 'approval_dwg',
      render: (text, record) => <p>{record?.approval_dwg ? 'Yes' : 'No'}</p>,
    },
  ];

  const validatePackageName = (pkgName) => roomPackages.data.some((pkg) => pkg.name === pkgName);

  // const onNext = async () => {
  //     onFinish({});
  //     // await newUpdateProject(PROJECT_API_ENUM.SERVICE_HISTORY, projectId, []);
  // };

  const handleAllInOnePackage = async (isChecked) => {
    if (isChecked && _.isEmpty(allIndividualTags)) {
      notification.warning({
        message: 'No tags found',
        description: "You haven't upload or add any tag.",
      });

      fetchAllIndividualPackages();

      return;
    } if (isChecked && !_.isEmpty(roomPackages.data)) {
      notification.warning({
        message: "Can't perform this action",
        description:
                    "You can't keep all tags in one package because you already created a package below, remove that package if you want to keep all in one package.",
        duration: 10,
      });
      return;
    } if (isChecked) {
      const allTags = [...allIndividualTags.map((tag) => tag.id)];

      const packageRoom = {
        name: 'All In One Package',
        description: '',
        tags: allTags,
      };

      setLoader(true);
      await newUpdateProject(PROJECT_API_ENUM.PACKAGES, projectId, packageRoom);
      showNotification('Package successfully created');
      getAllSections();
      setLoader(false);
    } else {
      onDeletePackage(roomPackages[0], 'room_packages');
    }

    setAllInOnePackage(!isChecked);
  };

  // const activateProject = async (projectId) => {
  //     await makeProjectActive(projectId);
  // }

  const handlePageSizeChange = (current, pageSize, room_name) => {
    if (room_name === 'room_packages') {
      setPackagesRoomPageSize(pageSize);
    } else if (room_name === 'individual_packages') {
      setEqpmtWithApprvlPagSize(pageSize);
    } else {
      setEqpmtWithoutApprvlPageSize(pageSize);
    }
  };

  return (
    <Form onFinish={onSubmit}>
      <h2 className="step-heading step-heading--full align-item-center align-items-center">
        <span>
          Manage Equipment Packages
          {' '}
          <br />
          {' '}
          {/* <Checkbox
                        disabled={allIndividualTags.length > 50}
                        checked={allInOnePackage}
                        onChange={({ target }) => {
                            handleAllInOnePackage(target.checked);
                        }}
                    >
                        <span className="text-grey text-size-s">Keep all tags in one package </span>
                    </Checkbox>
                    <Tooltip title="Check this box if all equipments in this order should be tracked as one">
                        <QuestionCircleOutlined
                            className="text-grey"
                            style={{ fontSize: 19, verticalAlign: 'middle' }}
                        />
                    </Tooltip>
                    <p
                        className="mb-0"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        <WarningTwoTone twoToneColor="#faad14" />
                        <span
                            style={{
                                margin: '0 0 0 8px',
                            }}
                        >
                            All team members will be added by default to the Package.
                        </span>
                    </p>
                    <p
                        style={{
                            fontSize: 14,
                        }}
                    >
                        <InfoCircleTwoTone twoToneColor="#faad14" />
                        <span
                            style={{
                                margin: '0 0 0 8px',
                            }}
                        >
                            All in One Package will only work upto 50 items.
                        </span>
                    </p> */}
        </span>
        <Button
          className="btn-tab"
          type="primary"
          onClick={() => setCreatePackageVisible(true)}
          disabled={allInOnePackage}
        >
          <PlusOutlined />
          {' '}
          Create Package
        </Button>
      </h2>
      <Space direction="vertical" size={15} style={{ width: '100%' }}>
        {/* <Collapse className="packages-collapse alectify-collapse document-collapse">
                    <Panel header="Package Equipments" key="1">
                        <Table
                            size="small"
                            columns={groupsPackageColumns}
                            pagination={{
                                total: roomPackages.length,
                                showSizeChanger: false,
                            }}
                            className="taglist-table"
                            expandable={{
                                expandedRowRender: (record) => (
                                    <Table
                                        rowKey="id"
                                        columns={nestedTagsColumn}
                                        dataSource={record.tags}
                                        className="ant-table-container"
                                        pagination={{
                                            total: record.tags.length,
                                            showSizeChanger: false,
                                        }}
                                    />
                                ),
                            }}
                            dataSource={roomPackages.map((room) => ({
                                ...room,
                                key: room?.id || room?.name,
                            }))}
                        />
                    </Panel>
                </Collapse> */}

        <Collapse
          className="packages-collapse alectify-collapse document-collapse"
          onChange={(e) => {
            getPackages('room_packages');
          }}
        >
          <Panel header="Package Rooms" key="1">
            {isPackagesRoomFetching ? (
              <CustomAPILoader text="Fetching items..." />
            ) : (
              <Table
                size="small"
                loading={loading}
                columns={groupsPackageColumns}
                pagination={{
                  total: roomPackages.meta.total_count,
                  onChange: (page, pageSize) => onPageChange(page, pageSize, 'room_packages'),
                  pageSize: packagesRoomPageSize,
                  showSizeChanger: true,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  onShowSizeChange: (current, pageSize) => handlePageSizeChange(current, pageSize, 'room_packages'),
                  current: packagesRoomPage,
                }}
                lo
                className="taglist-table"
                expandable={{
                  expandedRowRender: (record) => (
                    <Table
                      rowKey="id"
                      columns={nestedTagsColumn}
                      dataSource={record.tags}
                      className="ant-table-container"
                      pagination={{
                        total: record.tags.length,
                        showSizeChanger: true,
                      }}
                    />
                  ),
                }}
                dataSource={roomPackages.data.map((room) => ({
                  ...room,
                  key: room?.id,
                }))}
              />
            )}
          </Panel>
        </Collapse>

        {/**
                 * Equipment with Approval Drawings
                 */}
        <Collapse
          className="packages-collapse alectify-collapse document-collapse"
          onChange={() => getPackages('individual_packages')}
        >
          <Panel
            header={'Equipment with Approval Drawings'}
            key="2"
          >
            {isEqpmtWithApprvlFetching ? (
              <CustomAPILoader text="Fetching items..." />
            ) : (
              <Table
                size="small"
                columns={individualPackageColumns}
                dataSource={individualPackages.data.map((tag) => ({
                  ...tag,
                  key: tag.id,
                }))}
                className="taglist-table"
                loading={loading}
                pagination={{
                  total: individualPackages.meta.total_count,
                  onChange: (page, pageSize) => onPageChange(page, pageSize, 'individual_packages'),
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  pageSize: EqpmtWithApprvlPageSize,
                  onShowSizeChange: (current, pageSize) => handlePageSizeChange(current, pageSize, 'individual_packages'),
                  showSizeChanger: true,
                  current: EqpmtWithApprvlPage,
                }}
              />
            )}
          </Panel>
        </Collapse>

        <Collapse
          className="packages-collapse alectify-collapse document-collapse"
          onChange={() => getPackages('packages_without_approval_dwg')}
        >
          <Panel
            header={'Equipment without Approval Drawings'}
            key="3"
          >
            {isEqpmtWithoutApprvlFetching ? (
              <CustomAPILoader text="Fetching items..." />
            ) : (
              <Table
                size="small"
                columns={individualPackageWithoutEqpmtColumns}
                dataSource={pkgsWithoutApprovalDwg.data.map((tag) => ({
                  ...tag,
                  key: tag.id,
                }))}
                className="taglist-table"
                loading={loading}
                pagination={{
                  total: pkgsWithoutApprovalDwg.meta.total_count,
                  onChange: (page, pageSize) => onPageChange(page, pageSize, 'packages_without_approval_dwg'),
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  pageSize: EqpmtWithoutApprvlPageSize,
                  onShowSizeChange: (current, pageSize) => handlePageSizeChange(current, pageSize, 'packages_without_approval_dwg'),
                  showSizeChanger: true,
                  current: EqpmtWithoutApprvlPage,
                }}
              />
            )}
          </Panel>
        </Collapse>
      </Space>
      <div className="text-right">
        <Space>
          <Button
            className="btn-lg btn-default save-btn mt-30"
            type="default"
            htmlType="button"
            onClick={goBack}
          >
            Previous
          </Button>
          <Button
            className="btn-lg btn-primary-lg save-btn mt-30"
            type="primary"
            htmlType="submit"
          >
            Save & Finish
          </Button>
        </Space>
      </div>

      {createPackageVisible && (
      <CreatePackageModal
        title={`${!_.isEmpty(activePackage) ? 'Update' : 'Create'} Package`}
        visible={createPackageVisible}
        individualRoomsData={allIndividualTags}
        initialData={{ ...activePackage }}
        onSubmit={onCreatePackage}
        onCancel={onCancel}
        validatePackageName={validatePackageName}
        projectSetup
        getAllPackages={fetchAllIndividualPackages}
      />
      )}

      {editPackageVisible && (
      <EditPackageModal
        title="Update Package"
        visible={editPackageVisible}
        individualRoomsData={allIndividualTags}
        initialData={{ ...activePackage }}
        getAllPackages={fetchAllIndividualPackages}
        onSubmit={onUpdatePackage}
        onCancel={onEditCancel}
        validatePackageName={validatePackageName}
        projectSetup={false}
        loading={editPkgLoading}
      />
      )}
    </Form>
  );
}

export default Packages;
