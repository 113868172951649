import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import { Calendar, Card, DatePicker, Popover, Select, Space, Spin } from 'antd';
import { CheckOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { displayDate, PROJECTS, renderDate } from '../../../static/utils';
import { fetchProjectCalendarEvents } from '../../services';
import './styles.scss';

const { Option } = Select;

const getMonthString = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const CalendarEvents = memo((props) => {
    const history = useHistory();
    const [currentMonth, setCurrentMonth] = useState('');
    const [currentYear, setCurrentYear] = useState('');
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(moment()); // Use state to store the selected date
    const [equipmentType, setEquipmentType] = useState('1'); // Show Manual equipments by default

    const getCalendarEvents = async (startDate, endDate) => {
        try {
            if (props.subProjectId) {
                setLoading(true);
                const params = {
                    is_manual_tracking: equipmentType === '0' ? null : equipmentType,
                    start_date: startDate || moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: endDate || moment().endOf('month').format('YYYY-MM-DD'),
                };
                const response = await fetchProjectCalendarEvents(props.subProjectId, params);
                setEvents(response.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        const currentDate = new Date();
        setCurrentMonth(currentDate.getMonth() + 1);
        setCurrentYear(currentDate.getFullYear());
        setDate(moment());
        getCalendarEvents();
    }, [props.subProjectId, equipmentType]);

    const onPanelChange = async (value, mode) => {
        const startOfMonth = moment(value).startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment(value).endOf('month').format('YYYY-MM-DD');
        getCalendarEvents(startOfMonth, endOfMonth);
    };

    const goToPackage = (record) => {
        const url = `${PROJECTS}/${record?.project}/package/${record?.package}?tag_id=${record?.id}`;
        history.push(url);
    };

    const serviceData = (events) => {
        return events.map((ev) => {
            const details = (
                <div key={ev.id}>
                    <p>Name: {ev.name || '-'}</p>
                    <p>Line number: {ev.line_number || '-'}</p>
                    <p>Reference Number: {ev.reference_number || '-'}</p>
                    <p>Event: {ev.event_name}</p>
                    <p>Planned Date: {ev.original_event_date}</p>
                    <p>Actual Date: {ev.actual_event_date}</p>
                </div>
            );

            const taskName = (evt) => {
                return (
                    <p
                        className="event-box text-ellipsis d-flex justify-content-space-between"
                        style={{
                            borderLeft: `solid ${'grey'}`,
                            background: ev.color || '#D6E4E5',
                            marginBottom: '5px',
                        }}
                        onClick={() => goToPackage(ev)}
                    >
                        {evt.actual_event_date && <CheckOutlined />}
                        <span>
                            {evt.name} - {ev.event_name}
                        </span>
                    </p>
                );
            };

            return (
                <Popover content={details} title="Details">
                    <li key={ev.id}>{taskName(ev)}</li>
                </Popover>
            );
        });
    };

    const dateCellRender = (date) => {
        const dateFormat = date.format('YYYY-MM-DD');
        const filterServices = events[dateFormat];

        if (events && filterServices?.length) {
            const listData = serviceData(filterServices);

            return (
                <>
                    {listData?.map((item, index) => (
                        <p className="event-heading" key={index}>
                            {item}
                        </p>
                    ))}
                </>
            );
        }
    };

    const handleSelect = (day) => {
        const month = day.format('M');
        const year = day.format('yyyy');

        if (month !== currentMonth || year !== currentYear) {
            setCurrentMonth(month);
            setCurrentYear(year);
        }
    };

    const handleMonthChange = (value) => {
        const newDate = moment(date).month(value.month());
        setDate(newDate);
        setCurrentMonth(newDate.format('M'));
        const startOfMonth = newDate.startOf('month').format('YYYY-MM-DD');
        const endOfMonth = newDate.endOf('month').format('YYYY-MM-DD');
        getCalendarEvents(startOfMonth, endOfMonth);
    };

    const handleYearChange = (value) => {
        const newDate = moment(date).year(value.year());
        setDate(newDate);
        setCurrentYear(newDate.format('yyyy'));
        const startOfMonth = newDate.startOf('month').format('YYYY-MM-DD');
        const endOfMonth = newDate.endOf('month').format('YYYY-MM-DD');
        getCalendarEvents(startOfMonth, endOfMonth);
    };

    const handlePrevious = () => {
        const newDate = moment(date).subtract(1, 'month');
        setDate(newDate);
        setCurrentMonth(newDate.format('M'));
        setCurrentYear(newDate.format('yyyy'));
        const startOfMonth = newDate.startOf('month').format('YYYY-MM-DD');
        const endOfMonth = newDate.endOf('month').format('YYYY-MM-DD');
        getCalendarEvents(startOfMonth, endOfMonth);
    };

    const handleNext = () => {
        const newDate = moment(date).add(1, 'month');
        setDate(newDate);
        setCurrentMonth(newDate.format('M'));
        setCurrentYear(newDate.format('yyyy'));
        const startOfMonth = newDate.startOf('month').format('YYYY-MM-DD');
        const endOfMonth = newDate.endOf('month').format('YYYY-MM-DD');
        getCalendarEvents(startOfMonth, endOfMonth);
    };

    const renderHeader = () => {
        const { MonthPicker, YearPicker } = DatePicker;
        return (
            <div className="calendar-header">
                <div className="calendar-nav">
                    <button className="nav-button" onClick={handlePrevious}>
                        <LeftOutlined />
                    </button>
                </div>
                <div className="calendar-selects">
                    <Space>
                        <MonthPicker
                            className="month-select"
                            value={date}
                            onChange={handleMonthChange}
                            format="MMMM"
                        />
                        <YearPicker
                            className="year-select"
                            value={date}
                            onChange={handleYearChange}
                            picker="year"
                        />
                        <Select
                            style={{ width: 170 }}
                            onChange={setEquipmentType}
                            value={equipmentType}
                        >
                            <Option value="0">All Equipments</Option>
                            <Option value="1">Manual Schedule</Option>
                        </Select>
                    </Space>
                </div>
                <div className="calendar-nav">
                    <button className="nav-button" onClick={handleNext}>
                        <RightOutlined />
                    </button>
                </div>
            </div>
        );
    };

    return (
        <Spin tip="Please wait..." spinning={loading}>
            <div className="calender_container">
                <Card>
                    <Calendar
                        onSelect={handleSelect}
                        onPanelChange={onPanelChange}
                        dateCellRender={dateCellRender}
                        headerRender={renderHeader}
                        value={date}
                    />
                </Card>
            </div>
        </Spin>
    );
});

CalendarEvents.defaultProps = {
    subProjectId: '',
};

CalendarEvents.propTypes = {
    subProjectId: PropTypes.string,
};

export default CalendarEvents;
