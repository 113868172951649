import React, { memo } from "react";
import { EmptyIcon } from "../icons/custom-icons";

const AlectifyEmpty = ({ description }) => {
  return (
    <div className="text-align-center">
      <EmptyIcon />

      <p className="color-light-grey">{description || "No Data"}</p>
    </div>
  );
};

export default memo(AlectifyEmpty);
