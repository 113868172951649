// React imports
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Ant Design components
import { Col, Row } from 'antd';

// Lodash imports
import { isEmpty } from 'lodash';

// Custom component imports
import SubProjectCard from './subProjectCard';
import { MESSAGES } from 'static/utils/messages';
import { setActiveSubProject } from 'app/store/common';
import { BIDDING_MANAGEMENT, CONTRACTORS } from 'static/utils';
import { setActiveContractor } from 'app/store/bidding-management';
import AlectifySearchAnimation from '../alectify-search-animation';

const SubProjectListing = ({ subProjects, activeProject, isBiddingPageActive = false, getContractorProjects }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleNavigation = (subProject) => {
            if (isBiddingPageActive) {
                dispatch(setActiveContractor(subProject));
                history.push(
                    `${BIDDING_MANAGEMENT}/${activeProject?.id}${CONTRACTORS}/${subProject?.id}`,
                    );
                } else {
                    dispatch(setActiveSubProject(subProject));
                    history.push(
                        `/projects/${activeProject?.id}/sub-project/${subProject?.id}?page=insights`,
                        );
                    }
    };

        return (
        <>
            {!isEmpty(subProjects.data) ? (
                <Row gutter={[20, 20]}>
                    {subProjects?.data?.map((subProject) => (
                        <Col span={6} key={subProject?.id}>
                            <SubProjectCard
                                subProject={subProject}
                                handleNavigation={handleNavigation}
                                activeProject={activeProject}
                                isBiddingPageActive={isBiddingPageActive}
                                getContractorProjects={getContractorProjects}
                            />
                        </Col>
                    ))}
                </Row>
            ) : (
                <AlectifySearchAnimation description={MESSAGES.SUB_PROJECT.SUB_PROJECTS_EMPTY} />
            )}
        </>
    );
};

export default memo(SubProjectListing);
