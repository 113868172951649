import { http } from '../../static/utils/http';

export const createOrganizations = async (body) => {
  try {
    const response = await http.post('/organizations/', body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getOrganizations = async (params = {}) => {
  try {
    const response = await http.get('/organizations/', params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const createOwnerOrganization = async (body) => {
  try {
    const response = await http.post('/organizations/owner/', body);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getOrganizationTypes = async (param) => {
  try {
    const response = await http.get('/organization-types/');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getRoles = async (type = null) => {
  try {
    const params = { type };
    const response = await http.get('/roles/', params);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
