import { Badge, Empty, Tabs } from 'antd';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import {
    isActivityActive,
    isBirdseyeActive,
    isFilesActive,
    isInboxActive,
    isInsightsActive,
    isManualScheduleActive,
    isPackagesActive,
    isTasksActive,
    PROJECT_PERMISSIONS,
} from '../../../../../static/utils';
import Inbox from './inbox';
import Tasks from './tasks';
import Packages from './packages';
import Insights from './insights';
import Activity from './activity';
import Documents from './documents';
import Equipments from './equipments';
import BirdsEyeView from './birds-eye-view';
import ManualSchedule from './manual-schedule';
import * as Permissions from '../../../../../static/utils/permissions';
import { useSelector } from 'react-redux';
import { isExternalUser } from '../../../dashboard/helper';

const SubProjectDetailTabs = memo((props) => {
    const history = useHistory();
    const { user } = useSelector(({ auth }) => auth);
    const { activeProject, activeSubProject } = useSelector(({ common }) => common);
    const activeTab = new URLSearchParams(props.location.search).get('page') || 'insights';
    const hasProjectPermission = (permission) => {
        return (
            Permissions.hasProjectPermission(activeSubProject?.permissions, permission) &&
            !isExternalUser(user)
        );
    };

    const showCount = (key) => `(${activeSubProject[key] || 0})`;

    const tabItems = [
        {
            key: 'insights',
            label: 'Insights',
            children: <Insights />,
            visible:
            isInsightsActive() && hasProjectPermission(PROJECT_PERMISSIONS.CAN_VIEW_INSIGHTS),
        },
        {
            key: 'visual-tracking',
            label: 'Visual Tracking',
            children: <BirdsEyeView />,
            visible:
                isBirdseyeActive() &&
                hasProjectPermission(PROJECT_PERMISSIONS.CAN_VIEW_BIRDS_EYE_VIEW),
        },
        {
            key: 'equipments',
            label: `Equipments ${showCount('active_tag_count')}`,
            children: <Equipments />,
            visible:
             hasProjectPermission(PROJECT_PERMISSIONS.CAN_VIEW_BIRDS_EYE_VIEW), // @TODO: temp project permission
        },
        {
            key: 'manual-schedule',
            label: `Manual Schedule ${showCount('active_manual_tag_count')}`,
            children: <ManualSchedule />,
            visible:
                isManualScheduleActive() &&
                hasProjectPermission(PROJECT_PERMISSIONS.CAN_USE_MANUAL_SCHEDULE),
        },
        {
            key: 'packages',
            label: 'Packages',
            children: <Packages />,
            visible:
                isPackagesActive() && hasProjectPermission(PROJECT_PERMISSIONS.CAN_VIEW_PACKAGES),
        },
        {
            key: 'documents',
            label: 'Documents',
            children: <Documents />,
            visible: isFilesActive() && hasProjectPermission(PROJECT_PERMISSIONS.CAN_VIEW_FILES),
        },
        {
            key: 'inbox',
            label: (
                <Badge
                    className="site-badge-count-109"
                    count={activeProject?.unread_chat_count || 0}
                    overflowCount={9}
                    offset={[15, 0]}
                    color="#ff6f71"
                >
                    Inbox
                </Badge>
            ),
            children: <Inbox />,
            visible:
                isInboxActive() &&
                hasProjectPermission(PROJECT_PERMISSIONS.CAN_USE_INBOX_PACKAGE_CHAT),
        },
        {
            key: 'activity',
            label: (
                <Badge
                    offset={[15, 0]}
                    count={activeProject?.unread_activity_count || 0}
                    overflowCount={9}
                    color="#faad14"
                >
                    Activity
                </Badge>
            ),
            children: <Activity />,
            visible:
                isActivityActive() && hasProjectPermission(PROJECT_PERMISSIONS.CAN_VIEW_ACTIVITY),
        },
        {
            key: 'tasks',
            label: 'Tasks',
            children: <Tasks />,
            visible:
                isTasksActive() &&
                hasProjectPermission(PROJECT_PERMISSIONS.CAN_USE_MANUAL_SCHEDULE), // @TODO: temp project perission
        },
    ];

    return (
        <div className="dashboard-tabs">
            {tabItems.filter(({ visible }) => visible).length ? (
                <Tabs
                    animated={false}
                    activeKey={activeTab}
                    destroyInactiveTabPane
                    items={tabItems.filter(({ visible }) => visible)}
                    className="project-detail-tabs white-bg-tabs nested-tabs"
                    onChange={(tabId) => history.push({ search: `page=${tabId}` })}
                />
            ) : (
                <Empty description="Contact your admin for more permissions" />
            )}
        </div>
    );
});

export default SubProjectDetailTabs;
